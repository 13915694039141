import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route,withRouter,Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import {logoutAdmin,
  setCurrentAdmin} from '../../components/adminSignIn/store/actions/codeVerifyAuth'
  import setAuthToken from '../../components/adminSignIn/setAuthToken';
  import jwt_decode from 'jwt-decode';
  import AdminSignIn from '../Pages/adminSignIn'
  import AdminPassReset from '../Pages/adminPasswordReset'
  import AdmineReceipnestFile from '../Pages/filesReceptionist/receipnestFIle'
  import openFilePage from '../Pages/openFile'
  import AccountantQuotationPage from '../Pages/QuotationPage'
  import FileInvoicesPage from '../Pages/fileInvoices'
  import PreviewQuotationPage from '../Pages/quotationPreview'
  import AccountantInvoicePage from '../Pages/inoivePage'
  import FileInvoicesPreviewPage from '../Pages/fileInvoicePreview'
  import AllCustomersPage from '../Pages/customerPage'
  import AddAccountantQuotationPage from '../Pages/addQuotationPage'
  import ChequesPages from '../Pages/chequesPages'
  import InvoicePreviewPage from '../Pages/invoiceReviewPage'
  import ExpensePreviewPage from '../Pages/expensePreviewPage'
  import FileChequesPages from '../Pages/fileCheques'
  import BankReceiptPages from '../Pages/bankReceiptPages'
  import AddAccountantInvoicePage from '../Pages/addInvoicePage'
  import UpdateAccountantInvoicePage from '../Pages/updateInvoice'
  import UpdateAccountantExpensePage from '../Pages/updateExpense'
  import ExpensesAllPage from '../Pages/allExpense'
  import AddAccountantExpensePage from '../Pages/addExpense'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import './App.css';
import history from '../../history';
import store from '../../store';
/*    <HeaderTop className="headerTop" /> */
class App extends Component {

  constructor(props, context) {
        super(props, context);
        this.state = {
          authFlag:false,
          adminAuth:false
    } 
   
    store.subscribe(() => {
   

      /* AdminAuthRiderReducer */
      
      let resNumber2 = store.getState().App1.AdminAuthRiderReducer;
      if(resNumber2.isAuthenticated){
          this.setState({adminAuth:true})
    
  }else{
  
    this.setState({adminAuth:false})
  }
    });
    
    
  }

   componentDidMount(){
   

/* logoutAdmin,
setCurrentAdmin
 */
    if(localStorage.accountantToken) {
   
      const decoded1 = jwt_decode(localStorage.accountantToken);
   
    
    
      const currentTime = Date.now() / 1000;
      if(decoded1.exp < currentTime) {
        store.dispatch(logoutAdmin());
        window.location.reload('/login');
      }else{
        setAuthToken(localStorage.accountantToken);
        store.dispatch(setCurrentAdmin(localStorage.getItem('accountant')));
      }
    }

  } 
    render() {
      /*   window.googleTranslateElementInit(); */
        return (
            <Fragment>
                
                <BrowserRouter history={history}>
                
                    <ToastContainer autoClose={2500} position="top-center" />
                  
                    <Switch>
                      {/* AdmineReceipnest AddAccountantQuotationPage */}
        
    
                      <Route exact path="/login" component={AdminSignIn} />
                      {this.state.adminAuth || localStorage.accountantToken ? 
                      <>
                      <Route exact path="/createFile" component={openFilePage} />
                      <Route exact path="/allfiles" component={AdmineReceipnestFile} />
                      <Route exact path="/quotationAll" component={AccountantQuotationPage} />
                      <Route exact path="/invoicesAll" component={AccountantInvoicePage} />
                      <Route exact path="/expenseAll" component={ExpensesAllPage} />
                      <Route exact path="/" component={AccountantInvoicePage} />
                      <Route exact path="/customers" component={AllCustomersPage} />
                      <Route exact path="/addQuotation" component={AddAccountantQuotationPage} />
                      <Route exact path="/quotationpreview" component={PreviewQuotationPage} />
                      <Route exact path="/invoicepreview" component={InvoicePreviewPage} />
                      <Route exact path="/expensepreview" component={ExpensePreviewPage} />
                      <Route exact path="/addInvoice" component={AddAccountantInvoicePage} />
                      <Route exact path="/addExpense" component={AddAccountantExpensePage} />
                      <Route exact path="/updateInvoice" component={UpdateAccountantInvoicePage} />
                      <Route exact path="/updateexpense" component={UpdateAccountantExpensePage} />
                      <Route exact path="/fileInvoices" component={FileInvoicesPage} />
                      <Route exact path="/fileInvoicePreview" component={FileInvoicesPreviewPage} />
                      <Route exact path="/bankReceipts" component={BankReceiptPages} />
                      <Route exact path="/casecheques" component={ChequesPages} />
                      <Route exact path="/filecheques" component={FileChequesPages} />
                          {/*           {name:'Add Expense',route:'addExpense',id:'addExpense'},
 */} 
                      </>
                      :null}

                      <Route exact path="/Adminpassreset" component={AdminPassReset} />
                     
                      <Route exact path="/login" component={AdminSignIn} />
                      <Redirect to="/login" />
                      </Switch>
                    
                </BrowserRouter>
            </Fragment>
        );
    }
}

export default compose(
  withReducer("App1", reducer),
  connect()
)(App);   
 {/* <Route exact path="/founder" component={ComingSoon} /> */}