import React,{useEffect,useState} from 'react'
import { NavLink, Link } from 'react-router-dom'
import '../sideBar/style.scss';
import './style.css';
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import AccountantExpenseAll from './allExpenses'
import AdminSideBar from '../sideBar/sidebar'
import withReducer from "../../store/withReducer";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import Header from '../header'
function ExpensesAccountant ()  {
    
    return (
        <div><Header />
        <div className="adminPanel-area">
            <div className="container-custom">
                <div className="adminPanelBackarea">
                
                <div className="row">
                
                    <div className="col-xs-12 col-lg-3 col-sm-12 admin-cols">
                <AdminSideBar />
                    </div>
                    <div className="col-lg-9 col-xs- col-sm-12 admin-cols1">
                
                    <AccountantExpenseAll />
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>
    )
}
export default  withReducer("AccountantInvoice1", reducer)
  (ExpensesAccountant); 