import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const ALL_CUSTOMERS_ACCOUTNANT = "ALL_CUSTOMERS_ACCOUTNANT";
export const ALL_CUSTOMERS_ACCOUTNANT_LOADING =
"ALL_CUSTOMERS_ACCOUTNANT_LOADING";
export const ALL_CUSTOMERS_ACCOUTNANT_LOADING_FAILED =
"ALL_CUSTOMERS_ACCOUTNANT_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getAllCustomersLoading = ms => ({
    type: ALL_CUSTOMERS_ACCOUTNANT_LOADING,
    payload: ms
    });
    
    export const getAllCustomersLoadingFailed = ms => ({
    type: ALL_CUSTOMERS_ACCOUTNANT_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getAllCustomers(obj) {
    if(obj.userId===undefined){
        var request = axios.get(
            `${process.env.REACT_APP_PROFILE}/api/v1/accountant/userData/getUsersWithPagination?page=${obj.page}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
        
    }else{
        var request = axios.get(
            `${process.env.REACT_APP_PROFILE}/api/v1/accountant/userData/getUsersWithPagination?userId=${obj.userId}&page=${obj.page}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
    }
    
    return dispatch => {

    dispatch(getAllCustomersLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response getAllCustomer")
    dispatch({
    type: ALL_CUSTOMERS_ACCOUTNANT,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getAllCustomersLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getAllCustomersLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getAllCustomersLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getAllCustomersLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


