import React, { Component, useEffect } from "react";

import './newnooneinvoice.scss';
import { Dropdown, Input,Label,Checkbox,TextArea,Button,Header,Image, Divider } from 'semantic-ui-react'
 import ReactToPrint from 'react-to-print';
/*  import html2canvas from 'html2canvas'; */
 import { Icon, Step } from 'semantic-ui-react'
 import { Popover,DatePicker,Popconfirm } from 'antd';
/* import jsPDF from 'jspdf'; */
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Media,
  Modal,
  ButtonGroup,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,withRouter,
  browserhistory,
} from "react-router-dom";
 import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";

 import reducer from "./store/reducers";
 import withReducer from "../../store/withReducer";
 import store from "../../store";
 import InvoiceFileView from './invoiecFIleView';

    

  

    


 var moment = require('moment');
const options = [
  { key: 'save', icon: 'save', text: 'Save and Send', value: 'Save and Send' },
  { key: 'delete', icon: 'credit card outline', text: 'Save and Record A Payment', value: 'Save and Record A Payment' },
]
class FileInvoiceTopAreaPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      fileInvoiceData:{},
      open: false,
      show:false,
      sendInvoiceLoading:false,
      sendInvoiceSuccess:false,
      sendInvoiceErrorFlag:false,
      sendInvoiceFlag:false,
      sendInvoiceError:'',
      show1:false,
      loaderFlag:false,
      recordPaymentSuccess:false,
      recoredPaymentError:'',
      recordPaymentFlag:false,
      recordPaymentFlagError:false,
      loaderFlag:false

    };
    this.ref=React.createRef();
  }
 
  handleClose=()=> {
    this.setState({ show: false,sendInvoiceError:'',sendInvoiceErrorFlag:false,sendInvoiceLoading:false });
  }

  handleShow=()=> {
    this.setState({ show: true });
  }
  handleClose1=()=> {
    this.setState({ show1: false,loaderFlag:false,recoredPaymentError:''});
  }

  handleShow1=()=> {
    this.setState({ show1: true });
  }

  show = (dimmer) => () => this.setState({ dimmer, open: true })

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
  }

  close = () => this.setState({ open: false ,opentwo: false, openthree: false})
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }
/* sendInvoiceToCustomer */

handleSendFileInvoice=()=>{
  this.setState({show:true,sendInvoiceLoading:true,sendInvoiceError:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:true,sendInvoiceFlag:true})
  let obj={
    invoiceId:this.state.fileInvoiceData._id
  }
  this.props.dispatch(Actions.sendFileInvoice(obj))
 
}
  componentWillReceiveProps(nextProps) {
    let resNumber4 = nextProps.getFileIinvoiceReducer;
    console.log(resNumber4)
    if (!resNumber4.isLoading) {
      if (resNumber4.errMsg == null && !(resNumber4.data === undefined)) {
 
        this.setState({ fileInvoiceData: resNumber4.data.fileInvoices.docs[0] });
      } else {
        /*  this.setState({createFileError:resNumber4.errMsg,loaderFlag:false,invoiceFlag78:false})  
     console.log(resNumber4.errMsg) CONVERTED_TO_INVOICE*/
      }
    }

    let resNumber5 = nextProps.sendFileInvoiceReducer;
    if (!resNumber5.isLoading) {
      if (resNumber5.errMsg == null && !(resNumber5.data === undefined)) {
          if(this.state.sendInvoiceFlag){
    this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:true,sendInvoiceErrorFlag:false,sendInvoiceFlag:false})
          }
      } else {
        if(this.state.sendInvoiceErrorFlag){
          this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:false,sendInvoiceError:resNumber5.errMsg})
          }
      }
    }
    let resNumber3=nextProps.recoredFilePaymentReducer
    if (!resNumber3.isLoading) {
      if (resNumber3.errMsg == null && !(resNumber3.data === undefined)) {
          if(this.state.recordPaymentFlag){
            this.setState({loaderFlag:false,recordPaymentFlag:false,recordPaymentSuccess:true})
      }
      } else {
      if(this.state.recordPaymentFlagError){
        this.setState({loaderFlag:false,recordPaymentFlagError:false,recoredPaymentError:resNumber3.errMsg})
      }
      }
    }

  }
  handleRecordPayment=()=>{
    this.setState({show1:true})

  }
  handleRecordPaymentSubmit=()=>{
    this.setState({loaderFlag:true,recordPaymentFlag:true,recordPaymentFlagError:true,recoredPaymentError:''})
    let obj={
      fileId:this.state.fileInvoiceData.fileId
    }
    this.props.dispatch(Actions.recoredFilePayment(obj))
    setTimeout(()=>{
      this.props.dispatch(Actions.getFileINvoice(localStorage.fileInvoiceId));
    },1000)
    setTimeout(()=>{
      this.props.dispatch(Actions.getFileINvoice(localStorage.fileInvoiceId));
    },2000)
  
  }
  hnadleConfirm=()=>{
    this.setState({show1:false,recordPaymentSuccess:false})
  }
  componentWillMount(){
    this.props.dispatch(Actions.getFileINvoice(localStorage.fileInvoiceId));
  }
  render() {
    const { open, closeOnEscape, closeOnDimmerClick, dimmer, opentwo, openthree} = this.state;
    return (
      <div>







        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} mg={12}>
                <div className="invoice-payment-voucher-view-area">
                
                  <div className="new-invoice-payment-voucher-header-text-area" role="alert">
                    <h2>File Invoice # {this.state.fileInvoiceData.invoiceNo &&
                                    this.state.fileInvoiceData.invoiceNo}</h2>

                   
                  </div>

                  <div></div>

  <div className="invoice-previe-div-body-le">
        <center>
        <div className="invoice-contemplary-body-inside" style={{paddingLeft:'50px',marginRight:'10px'}}>



<div style={{display:'flex',justifyContent:'center',overflow:'auto'}}>            <Step.Group stackable='tablet'>
<Step active>
<Icon color='grey' name='mail' />
<Step.Content>
<Step.Description  className="step_responsine-of-quotation">
<Popconfirm
                 title="Are you sure send to this Open File Invoice to Client?"
                 onConfirm={this.handleSendFileInvoice}
                 onCancel={()=>console.log("cancel")}
                 okText="Send"
                 cancelText="No"
              
               >
             <Button  style={{backgroundColor:'#666666',color:'white'}} className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
             Send File Invoice
     
   </Button> 
               </Popconfirm>

</Step.Description>
</Step.Content>
</Step>
<Step >
<Icon color='grey' name='print' />
<Step.Content>
<Step.Description className="step_responsine-of-quotation">
<Button className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
     <ReactToPrint
removeAfterPrint={true}
  trigger={() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.
    return  <div >      Print Invoice</div>;
  }}
  content={() => this.componentRef}

/>
     
     </Button> 
</Step.Description>
</Step.Content>
</Step>
<Step active  >
<Icon color='grey' name='cc amazon pay' />
<Step.Content>
<Step.Description className="step_responsine-of-quotation">
{this.state.fileInvoiceData.invoiceStatus==="PAID"?<Button disabled style={{backgroundColor:'#666666',color:'white'}} className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
    Already Paid
     
     </Button> :<Button onClick={this.handleRecordPayment} style={{backgroundColor:'#666666',color:'white'}} className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
    Record a Payment
     
     </Button> }

</Step.Description>
</Step.Content>
</Step>
</Step.Group></div>


              

              <Container>


</Container>
      




          


{/* */}
            </div>
        </center>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>



             <div style={{overflow:'auto'}} > <InvoiceFileView    ref={el => (this.componentRef = el)} /></div>
       






              <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        
          <Modal.Body>
            {/* fa fa-exclamation-circle */}
            {this.state.sendInvoiceLoading ?
             <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
             <center>
                 <div className="loader1188"></div>
                 <p style={{paddingTop:'20px'}}>Sending Please wait....</p>
             </center>
            </div>
            :
           
            this.state.sendInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
            <center>
                <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>The Invoice has been successfully send to the Customer email </p>
                <button className="sent-button-des" onClick={this.handleClose}>Ok</button>
            </center>
           </div> :
            <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
            <center>
                <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
                <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
                <p>{this.state.sendInvoiceError} </p>
                <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
            </center>
           </div>
             
           }
          </Modal.Body>
      
        </Modal> 
        
        
        <Modal show={this.state.show1} onHide={this.handleClose1} size="sm" centered  >
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Confirmation
          </Modal.Title>
        </Modal.Header>
    
      <Modal.Body>
      {this.state.recordPaymentSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
            <center>
                <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>You Have Successfully Record Payment of this File Invoice </p>

                <button className="sent-button-des" onClick={this.hnadleConfirm}>Ok</button>
            </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Have you received AED 1000? Confirm it before recording the payment!</p>
  
   
       {this.state.loaderFlag? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
       <p style={{color:'red'}}>{this.state.recoredPaymentError}</p>
     </div>
      }
     
        </Modal.Body>
        {this.state.recordPaymentSuccess?'':
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose1}>Cancel</Button>
    <Button.Or />
    <Button onClick={this.handleRecordPaymentSubmit} color='black'>Record Payment</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
      
     







      </div>

      
    );
  }
}
/* quotationToInvoiceReducer */
const mapStateToProps=(state)=> {
  return {
    getFileIinvoiceReducer: state.FileInvoiceTopAreaPreview1.getFileIinvoiceReducer,
    sendFileInvoiceReducer:state.FileInvoiceTopAreaPreview1.sendFileInvoiceReducer,
    recoredFilePaymentReducer:state.FileInvoiceTopAreaPreview1.recoredFilePaymentReducer
} 


}

 export default compose(
 withReducer("FileInvoiceTopAreaPreview1", reducer),
 connect(mapStateToProps),withRouter)(FileInvoiceTopAreaPreview); 

