import React, { Component, useEffect } from "react";

import ReactToPrint, {
  PrintContextConsumer,
} from "react-to-print"; /* 
import { Visible, Hidden, setConfiguration } from "react-grid-system"; */

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
  Media,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  withRouter,
  browserhistory,
} from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";

var QRCode = require('qrcode.react');
var moment = require('moment');
class QuotationView extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      invoiceFlag78: false,
      createFileError: "",
      quotationData: {},
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }
  componentDidMount() {
    /*   store.subscribe(() => {
      let resNumber5 = store.getState().Invoiceview1.getInvoiceReducer;
      if(!(resNumber5.data===null)){
      this.setState({quotationData:resNumber5.data.invoice})
      }

      }) */
    /*   if (window.performance) {
        if (performance.navigation.type == 1) {
        this.props.history.push("/AccountantInvoice")
        } else {
       
        }
      } */
  }
  componentWillMount() {
    /* getUserInvoice */
    this.props.dispatch(Actions.getUserQuotation(localStorage.quotationId));
  }
  componentWillReceiveProps(nextProps) {
    let resNumber4 = nextProps.getUserQuotationReducer;
   
    if (!resNumber4.isLoading) {
      if (resNumber4.errMsg == null && !(resNumber4.data === undefined)) {
   
        this.setState({ quotationData: resNumber4.data.quotation });
      } else {
        /*  this.setState({createFileError:resNumber4.errMsg,loaderFlag:false,invoiceFlag78:false})  
     console.log(resNumber4.errMsg) */
      }
    }
  }
  render() {
    return (
      <div id="divIdToPrintInovoice">
        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="invoice-le-view_area">
                  <div class="invoice-preview__warning-message le-text--body"></div>

                  <div className="invoice-previe-div-body-le">
                    <div className="invoice-contemplary-body-inside">
                    <section className="invoice-contemplary-body-inside__header">

<Row>



                        <Col lg={12} xl={12} md={12} sm={12} xs={12}>

                        





<Media
                          as="li"
                          className="media-ride-list-invoice"
                          
                        >
                          <div className="list-steps-of-ride-invoice">
                              <div className="invoice-contemplary-body-inside__header__logo">
                                <img style={{marginTop:'-40px',marginBottom:'15px'}} src="https://wave-prod-accounting.s3.amazonaws.com/uploads/invoices/business_logos/efcee799-07f1-4667-a72c-eaf2265ecbb7.png" />
                              </div>
                          </div>
                          <Media.Body className="ride-body-content">
                          <div className="contemporary-template__header__info">
                          <div className="le-heading--title" style={{fontWeight:'700'}}>BADER HAMAD AL ZAABI
                           </div>
                          <strong className="le-text--strong">
                            ADVOCATES & LEGAL CONSULTANTS
                          </strong>
                          <div className="address-le-view-invoice fs-exclude">
                            <div className="address__field-invoice-view">
                              <p className="le-text le-text--body" style={{fontWeight:'550',lineHeight:'13px'}}>
                           
                              Office No. 3403 - 34th Floor - Burj Al Salam Tower
                                 - Trade Centre 1 - Sheikh Zayed Road <br />
                                Dubai - United Arab Emirates  - P.O. Box No.
                                89498, Dubai. {/* TRN: 10037287460003 */}
                                Tel: +9714 2555111, Fax: +9714 2368888<br />
                                E-MAIL: info@legalemirates.com
                              </p>
                            </div>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>
                        </div>

                          </Media.Body>
                        </Media>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={12} xs={12}>
    {/* #BD9B3E
    #CBBC99
    */}
    <div className="preview-quotation-top-area">
     <div className="preview-quotation-top-area-left-Area">
      <div className="preview-quotation-top-area-left-Area-box">
      </div> 
      <div className="preview-quotation-top-area-left-Area-text">
      Quotation
      </div>
     </div>
     <div className="preview-quotation-top-area-right-Area">
 Quotation No : {this.state.quotationData.quotationNo && this.state.quotationData.quotationNo}{" "} | {" " } TRN # 100455656700003
</div>
    </div>
{/*   <div className="invoice-contemplary-body-inside__header__logo-invoice">
    <img src="https://drive.google.com/uc?export=view&id=1-_drJvm5qH_2kdvRpPvcFhhOEm3vOn8F"/>
   </div> */}
</Col>
                        </Row>
                      </section>

                      <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top"></div>
                      </div>

                      <div class="description_of_card_of_invoicetable">
                        <div class="description_of_card_of_invoicetable_area">
                          <div className="contemporary-template__header__logo-invoice-view">
                            <Row style={{ width: "100%" }}>
                              <Col lg={4} xl={4} md={4} sm={4} sm={4} xs={4}>
                                <div style={{ marginLeft: "0px" }}>
                                  <h2 className="le-table-customer-data-heading">
                                    <span>
                                      <i class="fa fa-square box-gradient-icon-invoice-view"></i>
                                      Client Details
                                    </span>
                                  </h2>

                                  <div>
                                    <table className="le-table--plain">
                                      <tbody className="le-table__body">
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong  className="le-text--strong-invoice">
                                              {this.state.quotationData
                                                .userProfile &&
                                                this.state.quotationData
                                                  .userProfile.firstName +
                                                  " " +
                                                  this.state.quotationData
                                                    .userProfile.lastName}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong  style={{fontWeight:'600'}} className="le-text--invoice">
                                              {this.state.quotationData
                                                .userProfile &&
                                                this.state.quotationData
                                                  .userProfile.phoneNumber}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong style={{fontWeight:'600'}} className="le-text--invoice">
                                              {this.state.quotationData
                                                .userProfile &&
                                                this.state.quotationData
                                                  .userProfile.email}
                                            </strong>
                                          </td>
                                        </tr>

                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong
                                              style={{ maxWidth: "24ch",fontWeight:'600' }}
                                              className="le-text--invoice"
                                            >
                                              {this.state.quotationData
                                                .userProfile &&
                                                this.state.quotationData
                                                  .userProfile.address}
                                            </strong>
                                          </td>
                                        </tr>

                                        {/*    <tr className="le-table__row">
            <td className="le-table__cell" colspan="1">
              <strong className="le-text--invoice">United Arab Emirates</strong>
              </td>
            </tr> */}

                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--invoice">
                                              {/* {this.props.data.customer.email && this.props.data.customer.email} */}
                                            </strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={2} xl={2} md={2} sm={12} xs={2}>
                                <div className="invoice-contemplary-body-inside__header__logo-inside-tale-customer">
                                { this.state.quotationData.userProfile &&
                                   <div style={{position:'relative',width:'150px',height:'150px'}}>
     <QRCode size="150" style={{width:'150px'}} value={`Quotation \n Name:${ this.state.quotationData
                                                  .userProfile.firstName +
                                                  " " +
                                                  this.state.quotationData
                                                    .userProfile.lastName} \n Your email: ${ this.state.quotationData
                                                      .userProfile.email}  \n  Your Contact: ${this.state.quotationData
                                                        .userProfile.phoneNumber} \n \n Quotation No: BZ/${this.state.quotationData.quotationNo} \n File No: BZ/${this.state.quotationData.fileNo} \n Expiry Date:  ${ moment( this.state.quotationData.expireAt).format('MMMM Do YYYY')}  \n Total Amount: AED ${this.state.quotationData.amountToBePaid}  \n \n Bader Al Zaabi Advocates Office \n Contact:+971 4 2555111 \n Email info@legalemirates.com \n Website: https://legalemirates.com/` } />
     <img src={require('../../images/favicon.png')} style={{position:'absolute',top:'0',left:'0',bottom:'0',right:'0',margin:'auto'}} />
       </div>
       }
                                </div>
                              </Col>
                              <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                               <center>
                               <div className="date-info-invoice-le">
                                  <h2 style={{textAlign:'left',marginLeft:'50px'}} className="le-table-customer-data-heading">
                                    <span style={{marginLeft:'30px'}}>
                                      <i class="fa fa-square box-gradient-icon-invoice-view"></i>
                                      DATE & TIME
                                    </span>
                                  </h2>

                                  <div>
                                    <table className="le-table--plain">
                                      <tbody className="le-table__body">
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--issuedate-invoice">
                                              Issue date:{" "}
                                              {this.state.quotationData
                                                .generatedAt &&
                                                moment( this.state.quotationData.generatedAt).format('MMMM Do YYYY')
                                               }
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--issuedate-invoice">
                                              Issue Time: { moment(new Date(this.state.quotationData.expireAt)).format('h:mm:ss a')}
                                          {/*     {console.log(this.state.quotationData.expireAt,"fffffffffffff")} */}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <div style={{backgroundColor:'#BD9B3E',fontWeight:'550',padding:'5px',color:'white',marginTop:'15px',minWidth:'200px'}} className="le-text--issuedate-invoice">
                                            <center>
                                            Due Date : {" "}
                                              {this.state.quotationData
                                                .expireAt &&
                                                moment( this.state.quotationData.expireAt).format('MMMM Do YYYY')
                                              }
                                            </center>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                               </center>
                              </Col>
                            </Row>
                          </div>

                          <Col xl={12} md={12} lg={12}>
                            <div class="contemporary-template__items-table">
                              <table class="le-table">
                                <thead
                                  class="le-table__header"
                                  style={{
                                    backgroundColor: "rgb(189, 155, 62)",
                                  }}
                                >
                                  <tr class="le-table__row">
                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                     
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Sr. No
                                    </th>
                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                     
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Type
                                    </th>

                                    <th
                                      class="le-table__cell--amount class-table-particular"
                                      colspan="5"
                                      style={{
                                        color: "rgb(255, 255, 255)",
                                        width: "335px",
                                      }}
                                    >
                                      Description of Services
                                    </th>

                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Amount
                                    </th>
                                  </tr>
                                </thead>

                                {this.state.quotationData.items &&
                                  this.state.quotationData.items.map(
                                    (item, index) => {
                                      return (
                                        <tr class="le-table__row">
                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized"  style={{fontWeight:'600',color:'#666666'}}>
                                                {index + 1}.
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized"  style={{fontWeight:'600',color:'#666666'}}>
                                                {item.serviceType}
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            class="le-table__cell"
                                            colspan="5"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized" style={{fontWeight:'600',color:'#666666'}}>
                                                {item.detail}
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized" style={{fontWeight:'600',color:'#666666',textAlign:'right'}}>
                                                AED{" "}
                                                {new Intl.NumberFormat().format(
                                                  item.amount
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </table>
                            </div>
                          </Col>

                          <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                            <div class="le-divider-below"></div>
                          </div>

                          <Row className="how-to-work-grid">
                            <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                              <div className="quotaion-signature-quotation-area">
                                <p style={{fontWeight:'550'}}>To Accept this quotation, sign here and return</p>
                                <div className="quotaion-signature-quotation-area-box">

                                </div>
                              </div>
                            </Col>
                            <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                              <div class="contemporary-template__totals__amounts">
                                <div class="contemporary-template__totals__amounts__line">
                                  <div class="contemporary-template__totals__amounts__line__label">
                                    <strong class="le-text--strong-totals__amounts" >
                                      Sub Total:
                                    </strong>
                                  </div>
                                  <div class="contemporary-template__totals__amounts__line__amount">
                                    <span class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                      AED{" "}
                                      {this.state.quotationData.amountToBePaid &&
                                        new Intl.NumberFormat().format(
                                          this.state.quotationData
                                            .amountToBePaid -
                                            this.state.quotationData.vatIncluded
                                        )}
                                    </span>
                                  </div>
                                </div>
                                <div></div>

                                <div>
                                  <div class="contemporary-template__totals__amounts__line">
                                    <div class="contemporary-template__totals__amounts__line__label">
                                      <strong class="le-text--strong-totals__amounts">
                                        VAT (5%):
                                      </strong>
                                    </div>
                                    <div class="contemporary-template__totals__amounts__line__amount">
                                      <strong class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                        AED{" "}
                                        {this.state.quotationData.vatIncluded &&
                                          new Intl.NumberFormat().format(
                                            this.state.quotationData.vatIncluded
                                          )}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                
                                </div>
                              </div>

                              

                              <div className="amount-padding-color-price">
                           Total  Amount  : &nbsp; &nbsp;&nbsp; 
                                          &nbsp; <span style={{marginRight:'3px'}}> AED </span>  {" "}{" "}
                                {/* pendingAmount */}
                             <span style={{marginRight:'-8px'}}>   {this.state.quotationData.amountToBePaid &&
                                 new Intl.NumberFormat().format(
                                  this.state.quotationData.amountToBePaid
                                )}  </span>
                              </div>

                                     
                              {/* <div class="contemporary-template__divider--bold contemporary-template__divider--small-margin">
                <div class="le-divider">
                  </div>
                  </div> */}
                            </Col>
                          </Row>

                          <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                            <div class="le-divider"></div>
                          </div>

                          <div class="description_of_card_of_blogpost2">
                            <div class="description_of_card_of_newsroom_area-blogpost2">
                              <h2 class="blogs-description-area-of-main-headings-blogpost2">
                                Terms & Conditions
                              </h2>

                           
                              <p style={{fontWeight:'600',fontSize:'13px'}} class="invoice-description-area-of-preview--text">
                                1. This a computer generated invoice for the quotation payment. This is an electronic receipt; does not required any signature or stamp on it.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} class="invoice-description-area-of-preview--text">
                                2.	In the event of failing to pay the agreed service fees, including but not limited to our lawyer’s fees and expenses, experts fees, as well as other expenses,   BADER AlZAABI Advocate and LEGAL CONSULTANTS will refrain from providing any further legal services. In such event, BADER AlZAABI Advocates and LEGAL CONSULTANTS will preserve the rights to claim the pending fees and expenses and  will not be held responsible neither liable for the cessation of the legal services.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} className="invoice-description-area-of-preview--text">
                                3. The abovementioned fees cover the Firm's legal fees for the described services under the terms of this quotation. Client shall pay additional expenses and fees, including but not limited to the court's fees, expert fees, and all other fees and expenses in relation to this litigation.
                              </p>

                              <p style={{fontWeight:'900',fontSize:'13px'}} className="invoice-description-area-of-preview---belowfooter-text">
                                For Questions concerning this Quotation, please
                                contact info@legalemirates.com |
                                www.legalemirates.com
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* {console.log(this.props.creatInvoiceReducer,"kkkkkkkkkkkkkkyyyyyyyyyyyy")} */}
      </div>
    );
  }
}
/* creatInvoiceReducer */

const mapStateToProps = (state) => {
  return {
    getUserQuotationReducer: state.QuotationView1.getUserQuotationReducer,
  };
};
export default compose(
  withReducer("QuotationView1", reducer),
  connect(mapStateToProps),
  withRouter
)(QuotationView);
