import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const SEND_INVOICE_CUSTOMER = "SEND_INVOICE_CUSTOMER";
export const SEND_INVOICE_CUSTOMER_LOADING =
"SEND_INVOICE_CUSTOMER_LOADING";
export const SEND_INVOICE_CUSTOMER_LOADING_FAILED =
"SEND_INVOICE_CUSTOMER_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const sendInvoiceToCustomerLoading = ms => ({
    type: SEND_INVOICE_CUSTOMER_LOADING,
    payload: ms
    });
    
    export const sendInvoiceToCustomerLoadingFailed = ms => ({
    type: SEND_INVOICE_CUSTOMER_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function sendInvoiceToCustomer(obj,handleSuccessSendInvoice) {
    const request = axios.post(
    `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseInvoices/sendInvoice`,obj,
    {
      headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
    });
    return dispatch => {

    dispatch(sendInvoiceToCustomerLoading());
   console.log(request,"request")
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response sendInvoiceToCustomer")
    dispatch({
    type: SEND_INVOICE_CUSTOMER,
    payload: response.data
    });
  if(handleSuccessSendInvoice){
    handleSuccessSendInvoice()
  }
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        sendInvoiceToCustomerLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(sendInvoiceToCustomerLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(sendInvoiceToCustomerLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(sendInvoiceToCustomerLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


