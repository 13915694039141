import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const GET_ALL_QUOTATIONS = "GET_ALL_QUOTATIONS";
export const GET_ALL_QUOTATIONS_LOADING =
"GET_ALL_QUOTATIONS_LOADING";
export const GET_ALL_QUOTATIONS_LOADING_FAILED =
"GET_ALL_QUOTATIONS_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getAllQuotionsLoading = ms => ({
    type: GET_ALL_QUOTATIONS_LOADING,
    payload: ms
    });
    
    export const getAllQuotionsLoadingFailed = ms => ({
    type: GET_ALL_QUOTATIONS_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getAllQuotions(obj) {
   if(obj.status===undefined && obj.isSent===undefined && obj.userId===undefined){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else if(obj.status===undefined && obj.isSent!=undefined && obj.userId===undefined){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?isSent=${obj.isSent}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else if(obj.isSent===undefined && obj.status!=undefined && obj.userId===undefined ){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?status=${obj.status}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else if(obj.isSent===undefined && obj.status!=undefined && obj.userId!=undefined ){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?userId=${obj.userId}&status=${obj.status}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else if(obj.isSent!=undefined && obj.status===undefined && obj.userId!=undefined ){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?isSent=${obj.isSent}&userId=${obj.userId}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else if(obj.isSent===undefined && obj.status===undefined && obj.userId!=undefined ){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?userId=${obj.userId}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else if(obj.isSent!=undefined && obj.status!=undefined && obj.userId===undefined ){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?isSent=${obj.isSent}&status=${obj.status}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else if(obj.isSent!=undefined && obj.status===undefined && obj.userId===undefined ){
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?isSent=${obj.isSent}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }else{
    var request = axios.get(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationWithPaginate?userId=${obj.userId}&status=${obj.status}&isSent=${obj.isSent}&limit=${obj.limit}&pageNo=${obj.pageNo}`,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
  }
 
    return dispatch => {

    dispatch(getAllQuotionsLoading());
 
    request
    .then(response => {   
   /*    console.log(response,"response")   */ 
    if (response.status == 200) {
  /*    console.log(response,"response getAllQuotions") */
    dispatch({
    type: GET_ALL_QUOTATIONS,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getAllQuotionsLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getAllQuotionsLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getAllQuotionsLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getAllQuotionsLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


