


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import $ from 'jquery'
import './style.css';
import './style.scss';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Header, Table, Rating,Popup } from 'semantic-ui-react'
import { Dropdown, Input,Label,Icon,Checkbox } from 'semantic-ui-react'
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import QuotationView from './Quotationview'
import {Form,ButtonToolbar,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import { AutoComplete } from 'antd';
import { Popover,DatePicker,Popconfirm } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Dimmer, Loader, Image, Segment,Button } from 'semantic-ui-react'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { Alert } from 'antd';
import reducer from "./store/reducers";
import { useReactToPrint } from 'react-to-print';
import withReducer from "../../store/withReducer";

var moment = require('moment');
class  AccountantQuotionAll extends Component{
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageNo:1,
      InvoiceExist:false,
      type:'text',
      type1:'text',
      AllCustomer:'',
      AllStatements:'',
      endDate:'',
      startDate:'',
      filterDropdown:false,
      counterFilter:0,
      quotationData:[],
      pageNo:1,
      limit:10,
      status:'',
      isSent:false, 
      allInovices:[],
      allInovicesSent:[],
      allInovicesPaid:[],
      allInovicesUpaid:[],
      show:false,
      sendInvoiceLoading:false,
      sendInvoiceSuccess:false,
      sendInvoiceErrorFlag:false,
      sendInvoiceFlag:false,
      sendInvoiceError:'',
      show1:false,
      loaderFlag:false,
      recordPaymentSuccess:false,
      recoredPaymentError:'',
      recordPaymentFlag:false,
      recordPaymentFlagError:false,
      printInvoice:{},
      printQuotation:{},
      show2:false,
      stats:{},
      convertQuotationLoading:false,
      convertQuotationSuccess:false,
      convertQuotationError:'',
      convertQuotationFlag:false,
      convertQuotationErrorFlag:false,
      totalDocs:1,
      totalPages:1,
      userId:undefined,
      options:[],
      direction:null,
      column:null,
      cancelQuotationSuccess:false,
      cancelQuotationFlag:false,
      cancelQuotationErrorFlag:false,
      loaderFlag1:false,
      show3:false,
      cancelQuotationError:'', 
      cancelItem:{},
      editInvoice:{},
      show4:false,
      editInvoiceSuccess:false,
      editInvoiceFlag:false,
      editInvoiceErrorFlag:false,
      editInvoiceError:'',
      editInvoice1:{}  
} 
/* this.componentRef = React.createRef(); */
}
handleClose4=()=> {
  this.setState({ show4: false,loaderFlag2:false,editInvoiceError:'',editInvoiceSuccess:false });
}

handleShow4=()=> {
  this.setState({ show4: true });
}
handleClose3=()=> {
  this.setState({ show3: false,loaderFlag1:false,cancelQuotationError:'',cancelQuotationSuccess:false });
}

handleShow3=()=> {
  this.setState({ show3: true });
}
handleClose=()=> {
  this.setState({ show: false,sendInvoiceError:'',sendInvoiceErrorFlag:false,sendInvoiceLoading:false });
}

handleShow=()=> {
  this.setState({ show: true });
}
handleClose1=()=> {
  this.setState({ show1: false,loaderFlag:false,convertQuotationErrorFlag:false,convertQuotationLoading:false,convertQuotationFlag:false});
}

handleShow1=()=> {
  this.setState({ show1: true });
}
handleClose2=()=> {
  this.setState({ show2: false });
}

handleShow2=()=> {
  this.setState({ show2: true });
}
handlePageInc=()=>{
    if(this.state.totalPages>this.state.pageNo){
  this.setState((prevState, props) => ({
    pageNo: prevState.pageNo + 1
}));
let obj={
  status:this.state.status,
  isSent:this.state.isSent,
  limit:this.state.limit,
  pageNo:this.state.pageNo+1,
  userId:this.state.userId
}
this.props.dispatch(Actions.getAllQuotions(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNo>1){
    this.setState((prevState, props) => ({
      pageNo: prevState.pageNo - 1
  }));
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:this.state.pageNo-1,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
  }

}
changeHandler = event => {
 
    this.setState({
        [event.target.name]: event.target.value 
    })


};
handleStatement=()=>{
    this.setState({AllStatements:''})
}
handleEndDate=()=>{
    this.setState({endDate:'',type1:'text'}) 
}
handleStartDate=()=>{
    this.setState({startDate:'',type:'text'}) 
}
handleCustomer=()=>{
    this.setState({AllCustomer:''}) 
}
handleFilterDrop=()=>{
    if(this.state.filterDropdown){
 this.setState({filterDropdown:false})
    }else{
        this.setState({filterDropdown:true})
    }
}
componentWillMount(){
  this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllQuotions
  status=UNPAID&isSent=false&limit=10&pageNo=1
  */
 this.setState({quotationData:[],status:undefined,isSent:undefined})
  let obj={
    status:undefined,
    isSent:undefined,
    limit:10,
    pageNo:1,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
  this.props.dispatch(Actions.quotationCounter())
}
handelAllInvoice=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all'); 
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({quotationData:[],status:undefined,isSent:undefined})
  let obj={
    status:undefined,
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
}
handelAllInvoiceSent=()=>{
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all'); 
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({quotationData:[],status:undefined,isSent:true})
  let obj={
    status:undefined,
    isSent:true,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
}
handelAllQuotaionPending=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({quotationData:[],status:'PENDING',isSent:undefined})
  let obj={
    status:'PENDING',
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
}
handelAllQuotationConverted=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  

  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({quotationData:[],status:'CONVERTED_TO_INVOICE',isSent:undefined})
  let obj={
    status:'CONVERTED_TO_INVOICE',
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
}
handelAllQuotationCancel=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({quotationData:[],status:'CANCEL',isSent:undefined})
  let obj={
    status:'CANCEL',
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
}
handelAllQuotationNotSent=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');
  /* allInvoiceNotSend */

  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({quotationData:[],status:undefined,isSent:false})
  let obj={
    status:undefined,
    isSent:false,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
}
handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,quotationData:[],pageNo:1})
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:e.target.value,
    pageNo:1,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllQuotions(obj))
}
handleJumpPage=(e)=>{
    this.setState({pageNo:e.target.value,quotationData:[]})
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:e.target.value,
      userId:this.state.userId
    }
    this.props.dispatch(Actions.getAllQuotions(obj))
   
  }

componentDidMount(){
/* nd-invoices-filter-area-items-alla removeClass */
$(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-alla');

}
/* getAllQuotationsReducer */
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.getAllQuotationsReducer;

  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.quotations.docs.length>0){
        this.setState({quotationData:resNumber1.data.quotations.docs,totalDocs:resNumber1.data.quotations.totalDocs,totalPages:resNumber1.data.quotations.totalPages})  
     

      }else{
        this.setState({quotationData:["nothing"]})  
      }
     
    } else {
      this.setState({invoicesErr:resNumber1.errMsg})  
    }
  }
  let resNumber5 = nextProps.sendQuotationEmailReducer;
  if (!resNumber5.isLoading) {
    if (resNumber5.errMsg == null && !(resNumber5.data === undefined)) {
        if(this.state.sendInvoiceFlag){
  this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:true,sendInvoiceErrorFlag:false,sendInvoiceFlag:false})
        }
    } else {
      if(this.state.sendInvoiceErrorFlag){
        this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:false,sendInvoiceError:resNumber5.errMsg})
        }
    }
  }

  let resNumber6 = nextProps.quotationToInvoiceReducer;
  if (!resNumber6.isLoading) {
    if (resNumber6.errMsg == null && !(resNumber6.data === undefined)) {
        if(this.state.convertQuotationFlag){
  this.setState({loaderFlag:false,convertQuotationLoading:false,convertQuotationSuccess:true,convertQuotationErrorFlag:false,convertQuotationFlag:false})
        }
    } else {
      if(this.state.convertQuotationErrorFlag){
        this.setState({loaderFlag:false,convertQuotationLoading:false,convertQuotationSuccess:false,convertQuotationErrorFlag:false,convertQuotationError:resNumber6.errMsg})
        }
    }
  }
  let resNumberCounter = nextProps.getQuotationCounterReducer;
  if (!resNumberCounter.isLoading) {
    if (resNumberCounter.errMsg == null && !(resNumberCounter.data === undefined)) {
              this.setState({stats:resNumberCounter.data.stats})
    } else {
    
    }
  }
  let resNumber2 =nextProps.fileSearchReducer;
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }
    /* cancelInvoiceReducer */
    let resNumber7 = nextProps.cancelQuotationReducer;
    if (!resNumber7.isLoading) {
      if (resNumber7.errMsg == null && !(resNumber7.data === undefined)) {
          if(this.state.cancelQuotationFlag){
    this.setState({loaderFlag1:false,cancelQuotationSuccess:true,cancelQuotationError:false,cancelQuotationFlag:false})
          }
      } else {
        if(this.state.cancelQuotationErrorFlag){
          this.setState({loaderFlag1:false,cancelQuotationSuccess:false,cancelQuotationErrorFlag:false,cancelQuotationError:resNumber7.errMsg})
          }
      }
    }
    let resNumber8 = nextProps.updateQuotationReducer;
    if (!resNumber8.isLoading) {
      if (resNumber8.errMsg == null && !(resNumber8.data === undefined)) {
          if(this.state.editInvoiceFlag){
    this.setState({loaderFlag2:false,editInvoiceSuccess:true,editInvoiceError:'',editInvoiceFlag:false})
          }
      } else {
        if(this.state.editInvoiceErrorFlag){
          this.setState({loaderFlag2:false,editInvoiceSuccess:false,editInvoiceErrorFlag:false,editInvoiceError:resNumber8.errMsg})
          }
      }
    }
}
handleViewQuotation=(id)=>{
localStorage.setItem("quotationId",id)
this.props.history.push('/quotationpreview')
}

handleSendInvoice=(item)=>{
  this.setState({show:true,sendInvoiceLoading:true,sendInvoiceError:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:true,sendInvoiceFlag:true})
  let obj={
    invoiceId:item._id
  }
/*   this.props.dispatch(Actions.sendInvoiceToCustomer(obj)) */
  setTimeout(()=>{
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:1
    }
    this.props.dispatch(Actions.getAllQuotions(obj))
  },1000)
}

  handleRecordPayment=(item)=>{
    this.setState({show1:true,quotationData:item})

  }
  handleRecordPaymentSubmit=(e)=>{
    e.preventDefault()
    this.setState({loaderFlag:true,recordPaymentFlag:true,recordPaymentFlagError:true})
    let amount=this.amountPaid.value;
    let memo=this.memo.value;
    let obj={
      invoiceId:this.state.quotationData._id,
      paidAmount:amount,
      userId:this.state.quotationData.userId,
      memo:memo
  }
 /*  this.props.dispatch(Actions.recordPaymentCustomer(obj)) */
  setTimeout(()=>{
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:1
    }
    this.props.dispatch(Actions.getAllQuotions(obj))
  },1500)
  }
  handlePrint=(item)=>{
    this.setState({printQuotation:item})
  
    this.setState({show2:true})
  }
  handleConvetToInvoice=(item)=>{
    this.setState({loaderFlag:true,show1:true,convertQuotationLoading:true,convertQuotationFlag:true,convertQuotationErrorFlag:false})
   
    let obj={
      quotationId:item._id,
      generatedAt:item.generatedAt,
      expireAt:item.expireAt,
  }
  this.props.dispatch(Actions.convertInovoiceTOQuotation(obj))
  setTimeout(()=>{
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:1
    }
    this.props.dispatch(Actions.getAllQuotions(obj))
    this.props.dispatch(Actions.quotationCounter())
  },1000)
  }
 
  handleSendQuotaion=(item)=>{
    this.setState({show:true,sendInvoiceLoading:true,sendInvoiceError:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:true,sendInvoiceFlag:true})
    let obj={
      quotationId:item._id
    }
    this.props.dispatch(Actions.sendQuotationToCustomer(obj))
    setTimeout(()=>{
      let obj={
        status:this.state.status,
        isSent:this.state.isSent,
        limit:this.state.limit,
        pageNo:1
      }
      this.props.dispatch(Actions.getAllQuotions(obj))
      this.props.dispatch(Actions.quotationCounter())
    },1000)
  }

  handleSearch=(e)=>{
 
    if(e.length>0){
      let uniq = {}
      let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
      this.setState({options:arrFiltered}) 
    this.props.dispatch(Actions.getSeachedFiles(e))
    }else{
       
      let obj={
        status:this.state.status,
        isSent:this.state.isSent,
        limit:this.state.limit,
        pageNo:this.state.pageNo,
        userId:this.state.userId
      }
      this.setState({quotationData:[]}) 
      this.props.dispatch(Actions.getAllQuotions(obj))
    
    }
    }
  handleSelect=(e,value)=>{
    let uniq = {}
    let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
     let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:value.data._id
  }
  this.setState({options:arrFiltered,quotationData:[]}) 
  this.props.dispatch(Actions.getAllQuotions(obj))
  
  }
  handleSort=(action)=>{
    switch (action.type) {
      case 'CHANGE_SORT':
        this.setState({column:action.column})
        this.setState((state)=>({
          direction:state.direction === 'ascending' ? 'descending' : 'ascending'
        }))
        this.setState((state)=>({
          quotationData:state.quotationData.reverse()
        }))
      default:
        
    }
    }
    handleCancelQuotaion=(item)=>{
      this.setState({show3:true,cancelItem:item})
     
    }   
    handleCancelQuotaionSubmit=()=>{
      this.setState({loaderFlag1:true,cancelQuotationFlag:true,cancelQuotationErrorFlag:true,cancelQuotationError:''})
    this.props.dispatch(Actions.cancelQuotation(this.state.cancelItem._id))
    setTimeout(()=>{
      let obj={
        status:this.state.status,
        isSent:this.state.isSent,
        limit:this.state.limit,
        pageNo:this.state.pageNo
      }
      this.props.dispatch(Actions.getAllQuotions(obj))
    },1000)
    setTimeout(()=>{
      let obj={
        status:this.state.status,
        isSent:this.state.isSent,
        limit:this.state.limit,
        pageNo:this.state.pageNo
      }
      this.props.dispatch(Actions.getAllQuotions(obj))
    },2000)
    }  
    handleEditInvoice=(item)=>{
      let obj={
      amountToBePaid: item.amountToBePaid,
      expireAt: item.expireAt.toLocaleString().slice(0,10),
      vatIncluded: item.vatIncluded,
      items:item.items
      }
    /*  console.log(item,"gggggggggg") */
this.setState({editInvoice:obj,show4:true,editInvoice1:item})
    }
    handleDateUpdate=(e)=>{
      let d = new Date(e._d)
d.setDate(d.getDate() + 1)
      let d1=(new Date(d).toISOString()).slice(0,10)
      let t1=(new Date().toISOString()).slice(10)
     /*  console.log(d1+t1,"nnnnnnnnnnnn") */
  let date= this.state.editInvoice  
  date.expireAt=d1+t1;
  this.setState({editInvoice:date})

    }
    handleUpdateAmount=(e,item,index)=>{

     let v=e.target.value
      let arrayEdit=this.state.editInvoice;
      arrayEdit.items.map((ite)=>{
     
        if(item._id===ite._id){
        ite.amount=v
        }
      })
   
      this.setState({editInvoice:arrayEdit})

    let totalAmount=0;
    for(let i=0;i<arrayEdit.items.length;i++){
     totalAmount+=Number(arrayEdit.items[i].amount)
    }  
    if(this.state.editInvoice.vatIncluded>0){
      let totalAmount1=totalAmount+5/100*totalAmount
      let objEdit=this.state.editInvoice;
      objEdit.vatIncluded=totalAmount*0.05
    objEdit.amountToBePaid=totalAmount1;
      this.setState({editInvoice:objEdit})
    }else{
      let objEdit=this.state.editInvoice;
      objEdit.amountToBePaid=totalAmount;
        this.setState({editInvoice:objEdit})
    }
    
    

    }
    handleVat=()=>{
      if(this.state.editInvoice.vatIncluded>0){
       let obj=this.state.editInvoice;
       let vat=obj.vatIncluded
       obj.vatIncluded=0
       obj.amountToBePaid=obj.amountToBePaid-(vat)
       this.setState({editInvoice:obj})
      }else{
        let obj=this.state.editInvoice;
        obj.vatIncluded=this.state.editInvoice.amountToBePaid*0.05
        obj.amountToBePaid=this.state.editInvoice.amountToBePaid+(this.state.editInvoice.amountToBePaid*0.05)
        this.setState({editInvoice:obj})
      }
    }
    handleUpdateInvoiceSubmit=()=>{
      this.setState({loaderFlag2:true,editInvoiceFlag:true,editInvoiceErrorFlag:true})
      this.props.dispatch(Actions.updateQuotation(this.state.editInvoice,this.state.editInvoice1._id))
    
      setTimeout(()=>{
        let obj={
          status:this.state.status,
          isSent:this.state.isSent,
          limit:this.state.limit,
          pageNo:this.state.pageNo
        }
        this.props.dispatch(Actions.getAllQuotions(obj))
      },1000)
      setTimeout(()=>{
        let obj={
          status:this.state.status,
          isSent:this.state.isSent,
          limit:this.state.limit,
          pageNo:this.state.pageNo
        }
        this.props.dispatch(Actions.getAllQuotions(obj))
      },2000)
    }
render(){
  
  
  return (
    <div className="admin-cutomer-profile-area" >
    <div className="receipnest-file-customer-files-area" >
    <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
{/*     <Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}}  tag>
      <div style={{fontSize:'20px',transform:'rotate(180deg)'}}>Accountant Panel</div>
    </Label>  */}
       <div  className="admin-cutomer-REcepnest-files-rea">
     
       <div className="receipnest-file-customer-files-area-top-heading">
             <p style={{fontSize:'22px',fontWeight:'550',marginTop:'15px',marginLeft:'0px'}}>Quotation</p>
            </div>
          
           

        </div>
        <div  className="admin-cutomer-REcepnest-files-rea">
        <div className="create-invoice-button">
            <p><Link style={{color:'white'}} to="/addQuotation">Create a Quotation</Link> </p>
            {/* AccountantInvoiceAdd */}
           </div>
       {/*  <div className="filter-area-button">
  <p style={{marginLeft:'0px'}} onClick={this.handleFilterDrop}>Filter {this.state.filterDropdown ? <i style={{cursor:'pointer'}} class="icono5454 fa fa-chevron-up"></i>:<i style={{cursor:'pointer'}} class="icono derecha fa fa-chevron-down"></i>}</p>
        </div> */}
                <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>
        
        </div>
        {this.state.filterDropdown ?        <div className="invoice-filter-area">
            <div className="invoive-Filter-Form">
               <div className="container"> 
                <div className="row">
              <div className="col-12">
                  <div className="filter-counter-area-invoice">
                   <div className="filter-counter-area-invoice-left">
                   <span className="filter-counter-area-invoice-counter">{this.state.counterFilter}</span> <span>Active Filter </span>
                   </div>
                   <div  className="filter-counter-area-invoice-right">
                   Clear Filter
                   </div>
                  </div>
                 
                </div>      
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="AllCustomer"
               value={this.state.AllCustomer}
               onChange={this.changeHandler}
               placeholder="All Customer"
               autoComplete="on"
               style={{ width: "100%" }}
               list="AllCustomer"
             />
            {this.state.AllCustomer.length >0? <span className="formInput invoice-filter-close2"><i  onClick={this.handleCustomer} style={{color:'#666666'}} className="fa fa-close"></i></span>
            :''}
             <datalist id="AllCustomer">
               {/*      <option selected disabled>Nationality</option> */}
               <option value="Asad">Asad</option>
               <option value="Haider">Haider</option>
               <option value="Usman">Usman</option>
               <option value="Asim">Asim</option>
</datalist>
</div>
               </div>
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col1">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="AllStatements"
               value={this.state.AllStatements}
               onChange={this.changeHandler}
               placeholder="All Statement"
               autoComplete="on"
               style={{ width: "100%" }}
               list="statement"
             />
             {this.state.AllStatements.length > 0 ?        <span className="formInput invoice-filter-close2"><i onClick={this.handleStatement} style={{color:'#666666'}} className="fa fa-close"></i></span>
             :''}            
             <datalist id="statement">
               {/*      <option selected disabled>Nationality</option> */}
               <option value="Sent">Sent</option>
               <option value="Unsent">Unsent</option>
               <option value="Viewed">Viewed</option>
               <option value="Paid">Paid</option>
</datalist>
</div>
               </div>
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col">
               <div className="formInput  invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="startDate"
               value={this.state.startDate}
               placeholder="From"
               onChange={this.changeHandler}
               autoComplete="on"
               style={{ width: "100%",display:'inline' }}
               onFocus={()=>this.setState({type:'date'})}
               type="text"
               type={this.state.type}
             />
               {this.state.startDate.length>0 ?     <span className="formInput invoice-filter-close1"><i  onClick={this.handleStartDate} style={{color:'#666666'}} className="fa fa-close"></i></span>
            :''}
             </div>
             </div>
             <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col1">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="endDate"
               value={this.state.endDate}
               onChange={this.changeHandler}
               placeholder="To"
               autoComplete="on"
               style={{ width: "100%" }}
               onFocus={()=>this.setState({type1:'date'})}
               type={this.state.type1}
             />
          {this.state.endDate.length>0 ?     <span className="formInput invoice-filter-close1"><i onClick={this.handleEndDate} style={{color:'#666666'}} className="fa fa-close"></i></span>
           :''}
            </div>
             </div>
</div>

            </div>
            </div>
        </div> :''}
       
       <div className="nd-invoices-filter-area">
       <div className="nd-invoices-filter-area-items">
  <span className="nd-invoices-filter-area-items-all" id="allInvoice" onClick={this.handelAllInvoice}><span className="filter-counter-area-invoice-counter">{this.state.stats.ALL && this.state.stats.ALL }</span> All Quotations</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceSent" onClick={this.handelAllInvoiceSent}><span className="filter-counter-area-invoice-counter">{this.state.stats.SENT && this.state.stats.SENT }</span> Sent</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceNotSend" onClick={this.handelAllQuotationNotSent}><span className="filter-counter-area-invoice-counter">{this.state.stats.UNSEND && this.state.stats.UNSEND}</span>Draft</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoicePaid" onClick={this.handelAllQuotaionPending}><span className="filter-counter-area-invoice-counter">{this.state.stats.PENDING && this.state.stats.PENDING }</span> Pending</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceUnpaid" onClick={this.handelAllQuotationConverted}><span className="filter-counter-area-invoice-counter">{this.state.stats.CONVERTED_TO_INVOICE && this.state.stats.CONVERTED_TO_INVOICE }</span> Converted to Invoice</span>
         
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceCancel" onClick={this.handelAllQuotationCancel}><span className="filter-counter-area-invoice-counter">{this.state.stats.CANCEL&& this.state.stats.CANCEL }</span> Cancelled</span>
         
       </div>
       </div>
         
        <div className="receipnest-file-customer-files-area655-bottom-area table-area-invoive" style={{display:'block'}} >
        <div className="session-table-area-receptionist" style={{marginTop:'25px'}}>
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
        textAlign='left' singleLine>Customer</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'Number' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Number' })}
         textAlign='center' singleLine>Quotation No</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'Status' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Status' })}
        singleLine textAlign='center' width={3}>Status</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Date' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Date' })}
        singleLine textAlign='center'>Created Date</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'Due' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Due' })}
        singleLine textAlign='center'>Due Date</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'AmountDue' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'AmountDue' })}
        singleLine textAlign='center'>Amount Due</Table.HeaderCell>
        <Table.HeaderCell singleLine><center>Actions</center></Table.HeaderCell>
     
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.quotationData.length>0 ?
                  this.state.quotationData[0]!="nothing"?
                this.state.quotationData.map((item)=>{
                return  <Table.Row>
                <Table.Cell textAlign='left'>
                {item.userProfile && item.userProfile.firstName+" "+item.userProfile.lastName}
              
                </Table.Cell>
                  <Table.Cell textAlign='center'>
               {item.quotationNo}
                </Table.Cell>
                {item.status==='PENDING'  ?
                <Table.Cell width={15} warning textAlign='center' singleLine>
                <div className="invoiceStatus" style={{color:item.status==='PENDING'  ?'color: #A90941':'#008000'}}> <Icon name='attention' />{item.status}</div>
                </Table.Cell>
                :
              <Table.Cell width={15} textAlign='center' singleLine>
                <div className="invoiceStatus" style={{color:item.status==='PENDING' ?'color: #A90941':'#008000'}}>{item.status}</div>
                </Table.Cell>
              }
                

                <Table.Cell textAlign='center'>
              {item.generatedAt.slice(0,10)}
                </Table.Cell>
              
                <Table.Cell textAlign='center' >

               {item.expireAt.slice(0,10)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
AED {item.amountToBePaid}
            
                </Table.Cell>
                <Table.Cell textAlign='center'>
                <Popover placement="bottomRight" content={( <ul  className="dropw-down-cursor-menu-invoice">
                <li onClick={()=>this.handleViewQuotation(item._id)}>View</li>
                {item.status==='PENDING' ?<li><div onClick={()=>{this.handleEditInvoice(item)}}>Edit Quotation</div></li>: <li></li> }
                <li >
               <div onClick={()=>{this.handlePrint(item)}}>
               Print & PDF
             
             
               </div>
                </li>
               <li >
               <Popconfirm
                 title="Are you sure to send this Quotation to Client?"
                 onConfirm={()=>this.handleSendQuotaion(item)}
                 onCancel={()=>console.log("cancel")}
                 okText="Send"
                 cancelText="No"
                 placement="left"
               >
                 <div>Send Quotation</div>
               </Popconfirm>
                 </li>
                {item.status==="PENDING"  ?  <li><div  onClick={()=>this.handleConvetToInvoice(item)} className="add-border-drop">Convert to Invoice</div></li>:<li></li>}
                
                {item.status==='CANCEL' || item.status==='CONVERTED_TO_INVOICE' ?   <li></li>:<li>
                  
                  <div  onClick={()=>this.handleCancelQuotaion(item)}>Cancel Quotation</div>
                  </li>}
               
                
                
               
                
               
               
                </ul>)}  trigger="hover">
                <Icon name='play circle outline' size="large" />
    </Popover>

          
          </Table.Cell>
            
            </Table.Row>
              })
              :<Table.Row>
                   <Table.Cell colSpan='7'
textAlign='center'>
                  <center>
                <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                No Quotation Exist Yet!
                </div>
                </center>    
              </Table.Cell>
              </Table.Row>:<Table.Row>
                   <Table.Cell  textAlign='center' colSpan='7' 
textAlign='center'>
                   <center>
             <div className="loader-for-tabel-invoce">
             <div className="loader1188">
              </div> 
             </div>
             </center>  
              </Table.Cell>
              </Table.Row>}
   
  
   
  </Table.Body>
</Table>

        </div>
      
      
            {this.state.InvoiceExist ?<div className="dont-have-quotation">
            <p> <i className="fa fa-info-circle"></i> You don't have any Invoice. Why not create an Invoice?</p>
            </div> :''}
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
            <option value='10'>10</option>
              <option value='30'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per Page/total:{this.state.totalDocs}
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNo} of {this.state.totalPages}  <span className="pagination-table-quotaion-dec"><i onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} className="fa fa-caret-right"></i></span>
              </span>
             Jump to Page <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
        </div>
        </div> 
        <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        
        <Modal.Body>
          {/* fa fa-exclamation-circle */}
          {this.state.sendInvoiceLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
           </center>
          </div>
          :
         
          this.state.sendInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
              <p>The Quotation has been successfully send to the Customer email </p>
              <button className="sent-button-des" onClick={this.handleClose}>Ok</button>
          </center>
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.sendInvoiceError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
          </center>
         </div>
           
         }
        </Modal.Body>
    
      </Modal> 
      
      <Modal show={this.state.show2}  onHide={this.handleClose2}  size="sm" centered  >
      <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Quotation Number#{this.state.printQuotation.quotationNo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa-print"></i></p>
              {/* <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p> */}
              <p>Your Quotation is ready to Print. </p>
              <button className="sent-button-des" >
              <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
          {({ handlePrint }) => (
              <div onClick={handlePrint}>Print </div>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
              </button>
          </center>
         </div>
        </Modal.Body>
    
      </Modal> 
      
      <Modal show={this.state.show1} /* onHide={this.handleClose} */ size="sm" centered  >
        
        <Modal.Body>
          {/* fa fa-exclamation-circle */}
          {this.state.convertQuotationLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
           </center>
          </div>
          :
         
          this.state.convertQuotationSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
              <p>The Quotation has been successfully Converted to Invoice </p>
              <button className="sent-button-des" onClick={this.handleClose1}>Ok</button>
          </center>
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.convertQuotationError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose1}>Try Again later</button>
          </center>
         </div>
           
         }
        </Modal.Body>
    
      </Modal> 
      <Modal show={this.state.show3} onHide={this.handleClose3} size="sm" centered  >
      {this.state.cancelQuotationSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Confirmation
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body>
      {this.state.cancelQuotationSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="this Quotation is Successfuly cancelled"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose3} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Are you sure to cancel this  Quotation!</p>
  
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag1? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
     {this.state.cancelQuotationError.length>0?<Alert message={this.state.cancelQuotationError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.cancelQuotationSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose3}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleCancelQuotaionSubmit} color='red'>Cancel Invoice</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
      <Modal show={this.state.show4} onHide={this.handleClose4} size="sm" centered  >
      {this.state.editInvoiceSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <Icon name="edit outline" size="large" /> Edit Quotation
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body style={{maxHeight:'430px',overflow:'auto'}}>
      {this.state.editInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="this Quotaion is Successfuly Updated"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose4} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div >
         {this.state.editInvoice.items && this.state.editInvoice.items.map((value,index)=>{
return  <div>

         <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px',marginTop:'-5px'}}>{index+1}. {value.serviceType}</div>
<Input icon='edit outline' onChange={(e)=>this.handleUpdateAmount(e,value,index)} style={{width:"263px"}} iconPosition='right'  defaultValue={value.amount}  placeholder='Search users...' />
<br />
<br />
</div>
         })}
   

  <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px',marginTop:'-5px'}}>Due Date</div>
  <Input  labelPosition='right' type='text' placeholder='Amount'>
 
    <Label basic style={{paddingRight:'4px'}}> <Icon name='clock outline'></Icon></Label>
    <DatePicker onChange={(e)=>this.handleDateUpdate(e)} defaultValue={moment(this.state.editInvoice.expireAt, 'YYYY/MM/DD')} style={{width:"217px"}} />
   
 
  </Input>

  <br />
  <br />
  <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px',marginTop:'-5px'}}>Vat Amount</div>
  <Input  labelPosition='right' type='text' placeholder='Amount'>
 
    <Label basic> <Checkbox onChange={(e)=>this.handleVat()} checked={this.state.editInvoice.vatIncluded>0?true:false}  /></Label>
    <input  value={this.state.editInvoice.vatIncluded} style={{width:"172px"}} />
    <Label style={{fontSize:'13px',paddingTop:'15px'}}>AED</Label>
  </Input>
  <br />
  <br />
  <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px'}}>Total Amount</div>
     <Input  labelPosition='right' type='text' placeholder='Amount'>
     <Label style={{fontSize:'12px',paddingTop:'13px'}} basic>AED</Label>
    <input value={this.state.editInvoice.amountToBePaid} style={{width:"171px"}} />
    <Label>.00</Label>
  </Input>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag2? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
     {this.state.editInvoiceError.length>0?<Alert message={this.state.editInvoiceError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.editInvoiceSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose4}>Cancel</Button>
    <Button.Or />
    <Button onClick={this.handleUpdateInvoiceSubmit} color='black'>Update Quotation</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
     
<div style={{display:'none'}}><QuotationView printQuotation={this.state.printQuotation} ref={el => (this.componentRef = el)} /></div>
    </div>
    </div>
)
}  
  

}
const mapStateToProps=(state)=> {
  return {
    getAllQuotationsReducer: state.AccountantQuotionAll1.getAllQuotationsReducer,
    sendQuotationEmailReducer:state.AccountantQuotionAll1.sendQuotationEmailReducer,
    quotationToInvoiceReducer:state.AccountantQuotionAll1.quotationToInvoiceReducer,
    getQuotationCounterReducer:state.AccountantQuotionAll1.getQuotationCounterReducer,
    fileSearchReducer:state.AccountantQuotionAll1.fileSearchReducer,
    cancelQuotationReducer:state.AccountantQuotionAll1.cancelQuotationReducer,
    updateQuotationReducer:state.AccountantQuotionAll1.updateQuotationReducer
  };
} 
export default compose(
  withReducer("AccountantQuotionAll1", reducer),
  connect(mapStateToProps),withRouter
)(AccountantQuotionAll); 

