


import * as Actions from '../actions';

const initialState = {
    isLoading: true,
    errMsg: null,
    data: []
    };


// get active categories
export const getFileIinvoiceReducer = function(state = initialState, action) {
switch (action.type) {
case Actions.GET_FILE_INVOICE:
return { ...state, isLoading: false, errMsg: null, data: action.payload };
case Actions.GET_FILE_INVOICE_LOADING:
return { ...state, isLoading: true, errMsg: null, data: [] };
case Actions.GET_FILE_INVOICE_LOADING_FAILED:
return { ...state, isLoading: false, errMsg: action.payload, data: [] };
default:
return state;
}
};
