


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import $ from 'jquery'
import './style.css';
import './style.scss';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { AutoComplete } from 'antd';
import { Dropdown, Input,Label,Icon,Checkbox } from 'semantic-ui-react'
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import { Alert } from 'antd';
import Invoiceview from './invoiceview'
import {Form,ButtonToolbar,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import { Header, Table, Rating,Popup , Button} from 'semantic-ui-react'
import { Popover,DatePicker,Popconfirm,message } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Dimmer, Loader, Image, Segment,List } from 'semantic-ui-react'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import { useReactToPrint } from 'react-to-print';
import withReducer from "../../store/withReducer";
import Webcam from "react-webcam";

const WebcamComponent = () => <Webcam />;
var moment = require('moment');
class  AccountantInvoicesAll extends Component{
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageNo:1,
      InvoiceExist:false,
      type:'text',
      type1:'text',
      AllCustomer:'',
      AllStatements:'',
      endDate:'',
      startDate:'',
      filterDropdown:false,
      counterFilter:0,
      invoicesData:[],
      pageNo:1,
      limit:10,
      status:'',
      isSent:false, 
      allInovices:[],
      allInovicesSent:[],
      allInovicesPaid:[],
      allInovicesUpaid:[],
      show:false,
      sendInvoiceLoading:false,
      sendInvoiceSuccess:false,
      sendInvoiceErrorFlag:false,
      sendInvoiceFlag:false,
      sendInvoiceError:'',
      show1:false,
      loaderFlag:false,
      recordPaymentSuccess:false,
      recoredPaymentError:'',
      recordPaymentFlag:false,
      recordPaymentFlagError:false,
      invoiceData:'',
      printInvoice:{},
      printInvoice:{},
      show2:false,
      stats:{},
      totalDocs:1,
      totalPages:1,
      sendInvoiceLoading:false,
      sendInvoiceSuccess:false,
      sendInvoiceErrorFlag:false,
      sendInvoiceFlag:false,
      sendInvoiceError:'',
      loaderFlag:false,
      recordPaymentSuccess:false,
      recoredPaymentError:'',
      recordPaymentFlag:false,
      recordPaymentFlagError:false,
      show2:false,
      options:[],
      userId:undefined,
      direction:null,
      column:null,
      open:false,
      CancelInvoiceSuccess:false,
      CancelInvoiceFlag:false,
      CancelInvoiceErrorFlag:false,
      loaderFlag1:false,
      show3:false,
      CancelInvoiceError:'',
      cancelItem:{},
      editInvoice:{},
      show4:false,
      editInvoiceSuccess:false,
      editInvoiceFlag:false,
      editInvoiceErrorFlag:false,
      editInvoiceError:'',
      editInvoice1:{},
      show5:false,
      BankReceiptSuccess:false,
      BankReceiptError:'',
      BankReceiptFlag:false,
      BankReceiptErrorFlag:false,
      loaderFlag3:false,
      preview:'',
      photo:'',
      uploadReceipt:{} ,
      depositedAmount:'',
      recordPaymentType:'', 
      preview1:'',
      photo1:'',
      show6:false,
      show7:false,
      loaderFlagCheque:false,
      chequeDetail:{},
      imageLoader:false,
      show11:false,
      sendInvoiceSmsLoading:false,
      sendInvoiceSmsSuccess:false,
      sendInvoiceErrorSmsFlag:false,
      sendInvoiceSmsFlag:false,
      sendInvoiceSmsError:'',
      currentPreview:''
} 
/* this.componentRef = React.createRef(); */
this.webcamRef=React.createRef(null);
}
handleClose=()=> {
  this.setState({ show: false,sendInvoiceError:'',sendInvoiceErrorFlag:false,sendInvoiceLoading:false });
}

handleShow=()=> {
  this.setState({ show: true });
}
handleClose11=()=> {
  this.setState({ show11: false,sendInvoiceSmsError:'',sendInvoiceErrorSmsFlag:false,sendInvoiceSmsLoading:false });
}

handleShow11=()=> {
  this.setState({ show11: true });
}
handleClose1=()=> {
  this.setState({ show1: false,loaderFlag:false,recoredPaymentError:'',recordPaymentSuccess:false });
}

handleShow1=()=> {
  this.setState({ show1: true });
}


handleClose3=()=> {
  this.setState({ show3: false,loaderFlag1:false,CancelInvoiceError:'',CancelInvoiceSuccess:false });
}

handleShow3=()=> {
  this.setState({ show3: true });
}

handleClose4=()=> {
  this.setState({ show4: false,loaderFlag2:false,editInvoiceError:'',editInvoiceSuccess:false });
}

handleShow4=()=> {
  this.setState({ show4: true });
}
handleClose2=()=> {
  this.setState({ show2: false });
}

handleShow2=()=> {
  this.setState({ show2: true });
}
handleClose5=()=> {
  this.setState({ show5: false });
  setTimeout(()=>{
    this.setState({ preview:'',photo:'',loaderFlag3:false,BankReceiptSuccess:false,BankReceiptFlag:false,BankReceiptErrorFlag:false,BankReceiptError:'' });

  },500)

}

handleShow5=()=> {
  this.setState({ show5: true });
}
handleShow6=()=> {
  this.setState({ show6: true });
}
handleClose6=()=> {
  this.setState({ show6: false });
}
handleShow7=()=> {
  this.setState({ show7: true });
}
handleClose7=()=> {
  this.setState({ show7: false });
}
handlePageInc=()=>{
    if(this.state.totalPages>this.state.pageNo){
  this.setState((prevState, props) => ({
    pageNo: prevState.pageNo + 1
}));
let obj={
  status:this.state.status,
  isSent:this.state.isSent,
  limit:this.state.limit,
  pageNo:this.state.pageNo+1,
  userId:this.state.userId
}
this.setState({invoicesData:[]})
this.props.dispatch(Actions.getAllInvoices(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNo>1){
    this.setState((prevState, props) => ({
      pageNo: prevState.pageNo - 1
  }));
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:this.state.pageNo-1,
    userId:this.state.userId
  }
  this.setState({invoicesData:[]})
  this.props.dispatch(Actions.getAllInvoices(obj))
  }

}
changeHandler = event => {
 
    this.setState({
        [event.target.name]: event.target.value 
    })


};
handleStatement=()=>{
    this.setState({AllStatements:''})
}
handleEndDate=()=>{
    this.setState({endDate:'',type1:'text'}) 
}
handleStartDate=()=>{
    this.setState({startDate:'',type:'text'}) 
}
handleCustomer=()=>{
    this.setState({AllCustomer:''}) 
}
handleFilterDrop=()=>{
    if(this.state.filterDropdown){
 this.setState({filterDropdown:false})
    }else{
        this.setState({filterDropdown:true})
    }
}
componentWillMount(){
  this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllInvoices
  status=UNPAID&isSent=false&limit=10&pageNo=1
  */
 this.setState({invoicesData:[],status:undefined,isSent:undefined})
  let obj={
    status:undefined,
    isSent:undefined,
    limit:10,
    pageNo:1,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
  this.props.dispatch(Actions.invoiceCounter())
}
handelAllInvoice=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all'); 
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');  
  $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({invoicesData:[],status:undefined,isSent:undefined})
  let obj={
    status:undefined,
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handelAllInvoiceSent=()=>{
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all'); 
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');  
  $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({invoicesData:[],status:undefined,isSent:true})
  let obj={
    status:undefined,
    isSent:true,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handelAllInvoicesPiad=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');  
  $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({invoicesData:[],status:'PAID',isSent:undefined})
  let obj={
    status:'PAID',
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handelAllInvoicesUnpaid=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');  
  $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-all');

  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({invoicesData:[],status:'UNPAID',isSent:undefined})
  let obj={
    status:'UNPAID',
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handelAllInvoicesPartialPaid=()=>{
    $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
    $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
    $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
    $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
    $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
    $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
    $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
    $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
    $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
    $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
    $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
    $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');  
    $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-alla');
    $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-all');

    $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-all');
    $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-alla');

    
    this.setState({invoicesData:[],status:'PARTIAL_PAID',isSent:undefined})
    let obj={
      status:'PARTIAL_PAID',
      isSent:undefined,
      limit:this.state.limit,
      pageNo:this.state.pageNo,
      userId:this.state.userId
    }
    this.props.dispatch(Actions.getAllInvoices(obj))
  }
handelAllInvoiceNotSent=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');  
  $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-all');
  /* allInvoiceNotSend */

  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({invoicesData:[],status:undefined,isSent:false})
  let obj={
    status:undefined,
    isSent:false,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handelAllInvoicesCancel=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  
  $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-all');
  /* allInvoiceNotSend */

  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({invoicesData:[],status:'CANCEL',isSent:undefined})
  let obj={
    status:'CANCEL',
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handelAllInvoicesCheques=()=>{
  $(document.getElementById("allInvoiceSent")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceSent")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceUnpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceUnpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoicePartialpaid")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoicePartialpaid")).addClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceNotSend")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceNotSend")).addClass('nd-invoices-filter-area-items-all');
  
  $(document.getElementById("allInvoiceCancel")).removeClass('nd-invoices-filter-area-items-alla');
  $(document.getElementById("allInvoiceCancel")).addClass('nd-invoices-filter-area-items-all');
  /* allInvoiceNotSend */

  $(document.getElementById("allInvoiceCheques")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoiceCheques")).addClass('nd-invoices-filter-area-items-alla');
  this.setState({invoicesData:[],status:'CHEQUE_RECIEVED',isSent:undefined})
  let obj={
    status:'CHEQUE_RECIEVED',
    isSent:undefined,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,invoicesData:[],pageNo:1})
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:e.target.value,
    pageNo:1,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
}
handleJumpPage=(e)=>{
    this.setState({pageNo:e.target.value,invoicesData:[]})
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:e.target.value,
      userId:this.state.userId
    }
    this.props.dispatch(Actions.getAllInvoices(obj))
   
  }

componentDidMount(){
/* nd-invoices-filter-area-items-alla removeClass */
$(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-alla');

}
/* getAllInvoicesReducer */
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.getAllInvoicesReducer;

  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.invoices.docs.length>0){
        this.setState({invoicesData:resNumber1.data.invoices.docs,totalDocs:resNumber1.data.invoices.totalDocs,totalPages:resNumber1.data.invoices.totalPages})  
     

      }else{
        this.setState({invoicesData:["nothing"]})  
      }
     
    } else {
      this.setState({invoicesErr:resNumber1.errMsg})  
    }
  }
  let resNumberCounter = nextProps.getInvociesCounterReducer;
  if (!resNumberCounter.isLoading) {
    if (resNumberCounter.errMsg == null && !(resNumberCounter.data === undefined)) {
              this.setState({stats:resNumberCounter.data.stats})
    } else {
    
    }
  }
  let resNumber5 = nextProps.sendInvoiceEmailReducer;
  if (!resNumber5.isLoading) {
    if (resNumber5.errMsg == null && !(resNumber5.data === undefined)) {
        if(this.state.sendInvoiceFlag){
  this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:true,sendInvoiceErrorFlag:false,sendInvoiceFlag:false})
        }
    } else {
      if(this.state.sendInvoiceErrorFlag){
        this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:false,sendInvoiceError:resNumber5.errMsg})
        }
    }
  }
  let resNumber515 = nextProps.sendSmsInvoiceReducer;
  if (!resNumber515.isLoading) {
    if (resNumber515.errMsg == null && !(resNumber515.data === undefined)) {
        if(this.state.sendInvoiceSmsFlag){
  this.setState({sendInvoiceSmsLoading:false,sendInvoiceSmsSuccess:true,sendInvoiceErrorSmsFlag:false,sendInvoiceSmsFlag:false})
        }
    } else {
      if(this.state.sendInvoiceErrorFlag){
        this.setState({sendInvoiceSmsLoading:false,sendInvoiceSmsSuccess:false,sendInvoiceErrorSmsFlag:false,sendInvoiceSmsError:resNumber515.errMsg})
        }
    }
  }

  /* RecoredUserPaymentReducer */
  let resNumber6 = nextProps.RecoredUserPaymentReducer;
  if (!resNumber6.isLoading) {
    if (resNumber6.errMsg == null && !(resNumber6.data === undefined)) {
        if(this.state.recordPaymentFlag){
  this.setState({loaderFlag:false,recordPaymentSuccess:true,recordPaymentFlagError:false,recordPaymentFlag:false})
        }
    } else {
      if(this.state.recordPaymentFlagError){
        this.setState({loaderFlag:false,recordPaymentSuccess:false,recordPaymentFlagError:false,recoredPaymentError:resNumber6.errMsg})
        }
    }
  }
  /* cancelInvoiceReducer */
  let resNumber7 = nextProps.cancelInvoiceReducer;
  if (!resNumber7.isLoading) {
    if (resNumber7.errMsg == null && !(resNumber7.data === undefined)) {
        if(this.state.CancelInvoiceFlag){
  this.setState({loaderFlag1:false,CancelInvoiceSuccess:true,CancelInvoiceError:false,CancelInvoiceFlag:false})
        }
    } else {
      if(this.state.CancelInvoiceErrorFlag){
        this.setState({loaderFlag1:false,CancelInvoiceSuccess:false,CancelInvoiceErrorFlag:false,CancelInvoiceError:resNumber7.errMsg})
        }
    }
  }
  /* updateInvoiceReducer */
  let resNumber8 = nextProps.updateInvoiceReducer;
  if (!resNumber8.isLoading) {
    if (resNumber8.errMsg == null && !(resNumber8.data === undefined)) {
        if(this.state.editInvoiceFlag){
  this.setState({loaderFlag2:false,editInvoiceSuccess:true,editInvoiceError:'',editInvoiceFlag:false})
        }
    } else {
      if(this.state.editInvoiceErrorFlag){
        this.setState({loaderFlag2:false,editInvoiceSuccess:false,editInvoiceErrorFlag:false,editInvoiceError:resNumber8.errMsg})
        }
    }
  }

  /* bankReceiptInvoiceReducer */
  let resNumber9= nextProps.bankReceiptInvoiceReducer;
  if (!resNumber9.isLoading) {
    if (resNumber9.errMsg == null && !(resNumber9.data === undefined)) {
        if(this.state.BankReceiptFlag){
  this.setState({loaderFlag3:false,BankReceiptSuccess:true,BankReceiptError:'',BankReceiptFlag:false})
        }
    } else {
      if(this.state.BankReceiptErrorFlag){
        this.setState({loaderFlag3:false,BankReceiptSuccess:false,BankReceiptErrorFlag:false,BankReceiptError:resNumber9.errMsg})
        }
    }
  }

  /* chequeDetailReducer */
  let resNumber10= nextProps.chequeDetailReducer;
  if (!resNumber10.isLoading) {
    if (resNumber10.errMsg == null && !(resNumber10.data === undefined)) {
     /*  console.log(resNumber10.data.cheques.docs[0],"fffffff") */
      this.setState({loaderFlagCheque:false,chequeDetail:resNumber10.data.cheques.docs[0]})
    } else {
     /*  this.setState({loaderFlagCheque:false}) */
    }
  }
  let resNumber2 =nextProps.fileSearchReducer;

  
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }
}
handleViewInvoice=(id)=>{
localStorage.setItem("invoiceId",id)
this.props.history.push('/invoicepreview')
}

handleSendInvoice=(item)=>{
  this.setState({show:true,sendInvoiceLoading:true,sendInvoiceError:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:true,sendInvoiceFlag:true})
  let obj={
    invoiceId:item._id
  }
  this.props.dispatch(Actions.sendInvoiceToCustomer(obj,this.handleSuccessSendInvoice))
}
handleSuccessSendInvoice=()=>{
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:1
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
  this.props.dispatch(Actions.invoiceCounter())
}
handleSendInvoicesms=(item)=>{
  this.setState({show11:true,sendInvoiceSmsLoading:true,sendInvoiceSmsError:false,sendInvoiceSmsSuccess:false,sendInvoiceSmsErrorFlag:true,sendInvoiceSmsFlag:true})
  let obj={
    invoiceId:item._id
  }
  this.props.dispatch(Actions.sendSMSInvoice(obj,this.handleSendInvoicesmsSuccess))
}
handleSendInvoicesmsSuccess=()=>{
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:1
  }
  this.props.dispatch(Actions.getAllInvoices(obj))
  this.props.dispatch(Actions.invoiceCounter())
}
  handleRecordPayment=(item)=>{
    this.setState({show1:true,invoiceData:item})

  }
  handleRecordPaymentSubmit=(e)=>{
    e.preventDefault()
    if(this.state.recordPaymentType==="CHEQUE"){
     if(this.state.photo1){
      this.setState({loaderFlag:true,recordPaymentFlag:true,recordPaymentFlagError:true})
      let amount=this.amountPaid.value;
      let memo=this.memo.value;
   /*    let obj={
        invoiceId:this.state.invoiceData._id,
        paidAmount:amount,
        userId:this.state.invoiceData.userId,
        notes:memo,
        paymentType:"CHEQUE",
        chequeNumber:this.checkNumber.value,
        expiry:new Date(this.checkDate.value).toISOString(),
        chequeImage:this.state.preview1[0], 
    } */
    let  Data=  new FormData();
      
    Data.append('invoiceId',this.state.invoiceData._id) 
    Data.append('paidAmount',amount) 
    Data.append('chequeImage',this.state.photo1) 
    Data.append('userId',this.state.invoiceData.userId) 
    Data.append('notes',memo) 
    Data.append('paymentType',"CHEQUE") 
    Data.append('chequeNumber',this.checkNumber.value) 
    Data.append('expiry',new Date(this.checkDate.value).toISOString()) 
    this.props.dispatch(Actions.recordPaymentCustomer(Data))
     }else{
   this.setState({recoredPaymentError:'Please select the Cheque image  First'})
     }
    }else{
      this.setState({loaderFlag:true,recordPaymentFlag:true,recordPaymentFlagError:true})
      let amount=this.amountPaid.value;
      let memo=this.memo.value;
   /*    let obj={
        invoiceId:this.state.invoiceData._id,
        paidAmount:amount,
        userId:this.state.invoiceData.userId,
        notes:memo,
        paymentType:"CASH"
    } */
    let  Data=  new FormData();
      
    Data.append('invoiceId',this.state.invoiceData._id) 
    Data.append('paidAmount',amount) 
    Data.append('userId',this.state.invoiceData.userId) 
    Data.append('notes',memo) 
    Data.append('paymentType',this.state.recordPaymentType) 
    this.props.dispatch(Actions.recordPaymentCustomer(Data,this.recordPaymentCustomerSuccess))
    }
 

  }
  recordPaymentCustomerSuccess=()=>{
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:1
    }
    this.props.dispatch(Actions.getAllInvoices(obj))
    this.props.dispatch(Actions.invoiceCounter())
  }
  handlePrint=(item)=>{
    this.setState({printInvoice:item})
  
    this.setState({show2:true})
  }
  handleSearch=(e)=>{
 
    if(e.length>0){
      let uniq = {}
      let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
      this.setState({options:arrFiltered}) 
    this.props.dispatch(Actions.getSeachedFiles(e))
    }else{
       
      let obj={
        status:this.state.status,
        isSent:this.state.isSent,
        limit:this.state.limit,
        pageNo:this.state.pageNo,
        userId:this.state.userId
      }
      this.setState({invoiceData:[]}) 
      this.props.dispatch(Actions.getAllInvoices(obj))
    
    }
    }
  handleSelect=(e,value)=>{
    let uniq = {}
    let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
     let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:value.data._id
  }
  this.setState({options:arrFiltered,invoiceData:[]}) 
  this.props.dispatch(Actions.getAllInvoices(obj))
  
  }
  handleSort=(action)=>{
    switch (action.type) {
      case 'CHANGE_SORT':
        this.setState({column:action.column})
        this.setState((state)=>({
          direction:state.direction === 'ascending' ? 'descending' : 'ascending'
        }))
        this.setState((state)=>({
          invoicesData:state.invoicesData.reverse()
        }))
      default:
        
    }
    }

    handleCancelInvoice=(item)=>{
      this.setState({show3:true,cancelItem:item})
     
    }   
    handleCancelInvoiceSubmit=()=>{
      this.setState({loaderFlag1:true,CancelInvoiceFlag:true,CancelInvoiceErrorFlag:true,CancelInvoiceError:''})
    this.props.dispatch(Actions.cancelInvoice(this.state.cancelItem._id,this.cancelInvoiceSuccess))

    }
    cancelInvoiceSuccess=()=>{
      let obj={
        status:this.state.status,
        isSent:this.state.isSent,
        limit:this.state.limit,
        pageNo:this.state.pageNo
      }
      this.props.dispatch(Actions.getAllInvoices(obj))
      this.props.dispatch(Actions.getAllInvoices(obj))
      this.props.dispatch(Actions.invoiceCounter())
    }
    handleEditInvoice=(item)=>{

      this.props.history.push({
        pathname:'/updateInvoice',
        state:item
      })
/*       let obj={
      amountToBePaid: item.amountToBePaid,
      expireAt: item.expireAt.toLocaleString().slice(0,10),
      vatIncluded: item.vatIncluded,
      items:item.items
      }
this.setState({editInvoice:obj,show4:true,editInvoice1:item}) */
    }
    handleDateUpdate=(e)=>{
      let d = new Date(e._d)
d.setDate(d.getDate() + 1)
      let d1=(new Date(d).toISOString()).slice(0,10)
      let t1=(new Date().toISOString()).slice(10)
     /*  console.log(d1+t1,"nnnnnnnnnnnn") */
  let date= this.state.editInvoice  
  date.expireAt=d1+t1;
  this.setState({editInvoice:date})

    }
    handleUpdateAmount=(e,item,index)=>{

     let v=e.target.value
      let arrayEdit=this.state.editInvoice;
      arrayEdit.items.map((ite)=>{
     
        if(item._id===ite._id){
        ite.amount=v
        }
      })
   
      this.setState({editInvoice:arrayEdit})

    let totalAmount=0;
    for(let i=0;i<arrayEdit.items.length;i++){
     totalAmount+=Number(arrayEdit.items[i].amount)
    }  
    if(this.state.editInvoice.vatIncluded>0){
      let totalAmount1=totalAmount+5/100*totalAmount
      let objEdit=this.state.editInvoice;
      objEdit.vatIncluded=totalAmount*0.05
    objEdit.amountToBePaid=totalAmount1;
      this.setState({editInvoice:objEdit})
    }else{
      let objEdit=this.state.editInvoice;
      objEdit.amountToBePaid=totalAmount;
        this.setState({editInvoice:objEdit})
    }
    
    

    }
    handleVat=()=>{
      if(this.state.editInvoice.vatIncluded>0){
       let obj=this.state.editInvoice;
       let vat=obj.vatIncluded
       obj.vatIncluded=0
       obj.amountToBePaid=obj.amountToBePaid-(vat)
       this.setState({editInvoice:obj})
      }else{
        let obj=this.state.editInvoice;
        obj.vatIncluded=this.state.editInvoice.amountToBePaid*0.05
        obj.amountToBePaid=this.state.editInvoice.amountToBePaid+(this.state.editInvoice.amountToBePaid*0.05)
        this.setState({editInvoice:obj})
      }
    }
    handleUpdateInvoiceSubmit=()=>{
      this.setState({loaderFlag2:true,editInvoiceFlag:true,editInvoiceErrorFlag:true})
      this.props.dispatch(Actions.updateInvoice(this.state.editInvoice,this.state.editInvoice1._id))
    
    
    }
    handleREceipt=(item)=>{
      this.setState({show5:true,uploadReceipt:item,depositedAmount:''})
     
    }
    handleFileChange = e => {
     
      this.setState({
        photo: e.target.files[0],BankReceiptError:''
      })
     
      var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend =  (e)=> {
      this.setState({
          preview: reader.result
      })
    }
    }
    handleFileChange1 = e => {
     
      this.setState({
        photo1: e.target.files[0],BankReceiptError:''
      })
     
      var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend =  (e)=> {
     
      this.setState({
          preview1: [reader.result]
      })
    }
    }
    handleBankReceiptSumbit=()=>{
      if(!(this.state.depositedAmount==="")){
      if(!(this.state.photo==="")){
        this.setState({loaderFlag3:true,BankReceiptFlag:true,BankReceiptErrorFlag:true})
        /*       const formData = new FormData();
        console.log(this.state.photo,"kkkkkkkkk")
          formData.append('cv', this.state.cv); */
       const  Data=  new FormData();
        Data.append('caseInvoiceId',this.state.uploadReceipt._id) 
       Data.append('fileId',this.state.uploadReceipt.id) 
       Data.append('bankReceipt',this.state.photo) 
       Data.append('userId',this.state.uploadReceipt.userProfile.userId) 
       Data.append('depositedAmount',this.state.depositedAmount) 
          this.props.dispatch(Actions.uploadBankReceipt(Data))
      }else{
        message.error('Please select the Bank Receipt First');
        this.setState({BankReceiptError:"Please select the Bank Receipt First"})
      }
    }else{
      this.setState({BankReceiptError:"Please Enter the Deposit Amount"})
    }
     
    }
    b64toBlob=(b64Data, contentType, sliceSize)=> {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);

          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
      }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
}
    handleCapturePhoto=()=>{
      let ImageURL=this.webcamRef.current.getScreenshot();
    
      if(ImageURL!=null){
        if(this.state.currentPreview==="preview"){
          this.setState({preview:ImageURL})
        }else{
          this.setState({preview1:ImageURL})
        }
     
      }
      this.setState({show6:false})
      var block = ImageURL.split(";");
// Get the content type of the image
var contentType = block[0].split(":")[1];// In this case "image/gif"
// get the real base64 content of the file
var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

// Convert it to a blob to upload
     let blob = this.b64toBlob(realData, contentType);
     console.log(blob,"photoblob")
     if(this.state.currentPreview==="preview"){
      this.setState({photo:blob})
     }else{
    this.setState({photo1:blob})
     }
 
    }
    handleChechDetail=(item)=>{
      this.setState({show7:true,loaderFlagCheque:true})
let obj={
  id:item._id
}
this.props.dispatch(Actions.getChequeDetail(obj))
/* getChequeDetail */
    }

render(){

  
  return (
    <div className="admin-cutomer-profile-area" >
    <div className="receipnest-file-customer-files-area" >
    <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
{/*     <Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}}  tag>
      <div style={{fontSize:'20px',transform:'rotate(180deg)'}}>Accountant Panel</div>
    </Label>  */}
       <div  className="admin-cutomer-REcepnest-files-rea">
     
       <div className="receipnest-file-customer-files-area-top-heading">
             <p style={{fontSize:'22px',fontWeight:'550',marginTop:'15px',marginLeft:'0px'}}>All Invoices</p>
            </div>
          
           

        </div>
        <div  className="admin-cutomer-REcepnest-files-rea">
      
        <div className="create-invoice-button">
            <p><Link style={{color:'white'}} to="/addInvoice">Create an Invoice</Link> </p>
          
           </div>
           <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>

      {/*   <div className="filter-area-button">
  <p style={{marginLeft:'0px'}} onClick={this.handleFilterDrop} >Filter {this.state.filterDropdown ? <i style={{cursor:'pointer'}} class="icono5454 fa fa-chevron-up"></i>:<i style={{cursor:'pointer'}} class="icono derecha fa fa-chevron-down"></i>}</p>
        </div> */}
        </div>
        {this.state.filterDropdown ?        <div className="invoice-filter-area">
            <div className="invoive-Filter-Form">
               <div className="container"> 
                <div className="row">
              <div className="col-12">
                  <div className="filter-counter-area-invoice">
                   <div className="filter-counter-area-invoice-left">
                   <span className="filter-counter-area-invoice-counter">{this.state.counterFilter}</span> <span>Active Filter </span>
                   </div>
                   <div  className="filter-counter-area-invoice-right">
                   Clear Filter
                   </div>
                  </div>
                 
                </div>      
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="AllCustomer"
               value={this.state.AllCustomer}
               onChange={this.changeHandler}
               placeholder="All Customer"
               autoComplete="on"
               style={{ width: "100%" }}
               list="AllCustomer"
             />
            {this.state.AllCustomer.length >0? <span className="formInput invoice-filter-close2"><i  onClick={this.handleCustomer} style={{color:'#666666'}} className="fa fa-close"></i></span>
            :''}
             <datalist id="AllCustomer">
               {/*      <option selected disabled>Nationality</option> */}
               <option value="Asad">Asad</option>
               <option value="Haider">Haider</option>
               <option value="Usman">Usman</option>
               <option value="Asim">Asim</option>
</datalist>
</div>
               </div>
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col1">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="AllStatements"
               value={this.state.AllStatements}
               onChange={this.changeHandler}
               placeholder="All Statement"
               autoComplete="on"
               style={{ width: "100%" }}
               list="statement"
             />
             {this.state.AllStatements.length > 0 ?        <span className="formInput invoice-filter-close2"><i onClick={this.handleStatement} style={{color:'#666666'}} className="fa fa-close"></i></span>
             :''}            
             <datalist id="statement">
               {/*      <option selected disabled>Nationality</option> */}
               <option value="Sent">Sent</option>
               <option value="Unsent">Unsent</option>
               <option value="Viewed">Viewed</option>
               <option value="Paid">Paid</option>
</datalist>
</div>
               </div>
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col">
               <div className="formInput  invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="startDate"
               value={this.state.startDate}
               placeholder="From"
               onChange={this.changeHandler}
               autoComplete="on"
               style={{ width: "100%",display:'inline' }}
               onFocus={()=>this.setState({type:'date'})}
               type="text"
               type={this.state.type}
             />
               {this.state.startDate.length>0 ?     <span className="formInput invoice-filter-close1"><i  onClick={this.handleStartDate} style={{color:'#666666'}} className="fa fa-close"></i></span>
            :''}
             </div>
             </div>
             <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col1">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="endDate"
               value={this.state.endDate}
               onChange={this.changeHandler}
               placeholder="To"
               autoComplete="on"
               style={{ width: "100%" }}
               onFocus={()=>this.setState({type1:'date'})}
               type={this.state.type1}
             />
          {this.state.endDate.length>0 ?     <span className="formInput invoice-filter-close1"><i onClick={this.handleEndDate} style={{color:'#666666'}} className="fa fa-close"></i></span>
           :''}
            </div>
             </div>
</div>

            </div>
            </div>
        </div> :''}
       
       <div className="nd-invoices-filter-area">
       <div className="nd-invoices-filter-area-items">
  <span className="nd-invoices-filter-area-items-all" id="allInvoice" onClick={this.handelAllInvoice}><span className="filter-counter-area-invoice-counter">{this.state.stats.ALL && this.state.stats.ALL }</span> All Invoices</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceSent" onClick={this.handelAllInvoiceSent}><span className="filter-counter-area-invoice-counter">{this.state.stats.SENT && this.state.stats.SENT }</span> Sent</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceNotSend" onClick={this.handelAllInvoiceNotSent}><span className="filter-counter-area-invoice-counter">{this.state.stats.UNSEND && this.state.stats.UNSEND}</span>Draft</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoicePaid" onClick={this.handelAllInvoicesPiad}><span className="filter-counter-area-invoice-counter">{this.state.stats.PAID && this.state.stats.PAID }</span> Paid</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceUnpaid" onClick={this.handelAllInvoicesUnpaid}><span className="filter-counter-area-invoice-counter">{this.state.stats.UNPAID && this.state.stats.UNPAID }</span> Unpaid</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoicePartialpaid" onClick={this.handelAllInvoicesPartialPaid}><span className="filter-counter-area-invoice-counter">{this.state.stats.PARTIAL_PAID && this.state.stats.PARTIAL_PAID }</span> Partial Paid</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceCancel" onClick={this.handelAllInvoicesCancel}><span className="filter-counter-area-invoice-counter">{this.state.stats.CANCEL && this.state.stats.CANCEL }</span> Cancelled</span>
         <span className="nd-invoices-filter-area-items-all" id="allInvoiceCheques" onClick={this.handelAllInvoicesCheques}><span className="filter-counter-area-invoice-counter">{this.state.stats.CHEQUE_RECIEVED && this.state.stats.CHEQUE_RECIEVED }</span> Cheques Pending</span>
   
         
       </div>
       </div>
         
        <div className="receipnest-file-customer-files-area655-bottom-area table-area-invoive" style={{display:'block'}} >
      
   
        <div className="session-table-area-receptionist" style={{marginTop:'25px'}}>
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
        textAlign='left' singleLine>Customer</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'Number' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Number' })}
         textAlign='center' singleLine>Invoice No</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'Status' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Status' })}
        singleLine textAlign='center' width="3">Status</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Date' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Date' })}
        singleLine textAlign='center'>Created Date</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'Due' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Due' })}
        singleLine textAlign='center'>Due Date</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'AmountDue' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'AmountDue' })}
        singleLine textAlign='center'>Total Amount </Table.HeaderCell>
  <Table.HeaderCell
         sorted={this.state.column === 'AmountPen' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'AmountPen' })}
        singleLine textAlign='center'>Pending Amount</Table.HeaderCell>
      
        <Table.HeaderCell singleLine><center>Actions</center></Table.HeaderCell>
     
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.invoicesData.length>0 ?
                  this.state.invoicesData[0]!="nothing"?
                this.state.invoicesData.map((item)=>{
                return  <Table.Row>
                <Table.Cell textAlign='left'>
                {item.userProfile && item.userProfile.firstName+" "+item.userProfile.lastName}
              
                </Table.Cell>
                  <Table.Cell textAlign='center'>
                {item.invoiceNo}
                </Table.Cell>
                {item.status==='UNPAID' || item.status==='PARTIAL_PAID'  || item.status==='CANCEL' ?
                <Table.Cell warning textAlign='center' singleLine>
                <div className="invoiceStatus" style={{color:'#A90941'}}> <Icon name='attention' />{item.status}</div>
                </Table.Cell>
                :
              <Table.Cell textAlign='center' singleLine>
                <div className="invoiceStatus" style={{color:'#008000'}}>{item.status}</div>
                </Table.Cell>
              }
                

                <Table.Cell textAlign='center'>
              {item.generatedAt.slice(0,10)}
                </Table.Cell>
              
                <Table.Cell textAlign='center' >

               {item.expireAt.slice(0,10)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
AED {item.amountToBePaid.toFixed(2)}
            
                </Table.Cell>
                <Table.Cell textAlign='center'>
AED {item.pendingAmount && item.pendingAmount.toFixed(2)}
            
                </Table.Cell>
                  {/* pendingAmount */}
                <Table.Cell textAlign='center'>
                <Popover placement="bottomRight" content={( <ul  className="dropw-down-cursor-menu-invoice">
                <li onClick={()=>this.handleViewInvoice(item._id)}>View</li>
                <li><div onClick={()=>{this.handleEditInvoice(item)}}>Edit Invoice</div></li>
                
                <li >
               <div onClick={()=>{this.handlePrint(item)}}>
               Print & PDF
             
             
               </div>
                </li>
                {item.status==='UNPAID' || item.status==='PARTIAL_PAID'  ?  <li><div  onClick={()=>this.handleRecordPayment(item)} className="add-border-drop">Record  Payment</div></li>:<li></li>}
                {item.status==='UNPAID' || item.status==='PARTIAL_PAID'  ? 
                 <li > <Popconfirm
                 title="Are you sure to send this Invoice to Client Email?"
                 onConfirm={()=>this.handleSendInvoice(item)}
                 onCancel={()=>console.log("cancel")}
                 okText="Send"
                 cancelText="No"
                 placement="left"
               >
                 <div>Send Email</div>
               </Popconfirm>
               </li>:
                <li></li>}
                {item.status==='UNPAID' || item.status==='PARTIAL_PAID'  ? 
                 <li > <Popconfirm
                 title="Are you sure to send this Invoice to Client by SMS?"
                 onConfirm={()=>this.handleSendInvoicesms(item)}
                 onCancel={()=>console.log("cancel")}
                 okText="Send"
                 cancelText="No"
                 placement="left"
               >
                 <div>Send SMS</div>
               </Popconfirm>
               </li>:
                <li></li>}
                {item.status==='CANCEL'  ?   <li></li>:<li>
                  
                  <div  onClick={()=>this.handleCancelInvoice(item)}>Cancel Invoice</div>
                  </li>}
                  {item.status==='UNPAID' || item.status==='CANCEL'  ? <li></li>  :<li><div onClick={()=>this.handleREceipt(item)}>Upload Receipt</div></li>}
                  {item.status==='CHEQUE_RECIEVED'  ? <li><div onClick={()=>this.handleChechDetail(item)}>Cheque Detail</div></li>:<li></li> }
               
               
               {/* CHEQUE_RECIEVED
 */}
                
                
               
                
               
               
                </ul>)}  trigger="hover">
                <Icon name='play circle outline' size="large" />
    </Popover>
 {/*              
<center><ul className="INvoive-main-list-action">
                <li>
                <div className="invoiceStatusAction"><i  className="fa fa-caret-down"></i></div>
                <ul  className="subMenu">
                <li onClick={()=>this.handleViewInvoice(item._id)}>View</li>
                <li >
               <div onClick={()=>{this.handlePrint(item)}}>
               Print & PDF
             
             
               </div>
                </li>
                {item.status==='UNPAID' || item.status==='PARTIAL_PAID'  ?  <li><div  onClick={()=>this.handleRecordPayment(item)} className="add-border-drop">Record  Payment</div></li>:<li></li>}
                {item.status==='UNPAID' || item.status==='PARTIAL_PAID'  ?  <li onClick={()=>this.handleSendInvoice(item)}>Send Reminder</li>:<li></li>}
                
               
               
                
                
               
                
               
               
                </ul>
                </li>
                </ul></center> */}
          
                </Table.Cell>
            
              </Table.Row>
                })
                :<Table.Row>
                     <Table.Cell colSpan='8'
 textAlign='center'>
                    <center>
                  <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                  No Invoice Exist Yet!
                  </div>
                  </center>    
                </Table.Cell>
                </Table.Row>:<Table.Row>
                     <Table.Cell  textAlign='center' colSpan='8' 
 textAlign='center'>
                     <center>
               <div className="loader-for-tabel-invoce">
               <div className="loader1188">
                </div> 
               </div>
               </center>  
                </Table.Cell>
                </Table.Row>}
     
    
     
    </Table.Body>
  </Table>

          </div>
           
        
            {this.state.InvoiceExist ?<div className="dont-have-quotation">
            <p> <i className="fa fa-info-circle"></i> You don't have any Invoice. Why not create an Invoice?</p>
            </div> :''}
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
            <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per Page/total:{this.state.totalDocs}
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNo} of {this.state.totalPages}  <span className="pagination-table-quotaion-dec"><i onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} className="fa fa-caret-right"></i></span>
              </span>
             Jump to Page <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
        </div>
        </div> 
        <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        
        <Modal.Body>
          {/* fa fa-exclamation-circle */}
          {this.state.sendInvoiceLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Sending Please wait....</p>
           </center>
          </div>
          :
         
          this.state.sendInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
              <p>The Invoice has been successfully send to the Customer email </p>
              <button className="sent-button-des" onClick={this.handleClose}>Ok</button>
          </center>
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.sendInvoiceError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
          </center>
         </div>
           
         }
        </Modal.Body>
    
      </Modal> 
      <Modal show={this.state.show11} /* onHide={this.handleClose} */ size="sm" centered  >
        
        <Modal.Body>
          {/* fa fa-exclamation-circle */}
          {this.state.sendInvoiceSmsLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Sending Please wait....</p>
           </center>
          </div>
          :
         
          this.state.sendInvoiceSmsSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
              <p>The Invoice has been successfully send to the Customer Phone number </p>
              <button className="sent-button-des" onClick={this.handleClose11}>Ok</button>
          </center>
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.sendInvoiceSmsError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose11}>Try Again later</button>
          </center>
         </div>
           
         }
        </Modal.Body>
    
      </Modal> 
      <Modal show={this.state.show2}  onHide={this.handleClose2}  size="sm" centered  >
      <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Invoice Number#{this.state.printInvoice.invoiceNo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa-print"></i></p>
              {/* <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p> */}
              <p>Your Invoice is ready to Print. </p>
              <button className="sent-button-des" >
              <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
          {({ handlePrint }) => (
              <div onClick={handlePrint}>Print </div>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
              </button>
          </center>
         </div>
        </Modal.Body>
    
      </Modal> 
   
      
      <Modal show={this.state.show1} onHide={this.handleClose1} size="md" centered  >
      <Modal.Header closeButton>
          <Modal.Title className="heading-modal"
          style={{textAlign:'left',fontWeight:'550'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Record a Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{paddingBottom:'0px'}}>

          {this.state.recordPaymentSuccess? <div className="sent-message-area">
             <center>
                 <p><i class="fa fa fa-check-circle-o"></i></p>
                 <h2>thank you!</h2>
                 <p>Payment Record has been Updated successfully.</p>
                 <button className="sent-button-des" onClick={this.handleClose1}>Ok</button>
             </center>
            </div> :
             <div className="sent-message-area" style={{paddingTop:'10px',paddingBottom:'10px'}}>
               <p style={{fontWeight:'550'}}>Record a Payment You've already Received, Such as cash, cheque or Bank Payment.</p>
             <center>
               <div className="record-a-payment-form-area">
               <Form onSubmit={this.handleRecordPaymentSubmit}>
<Form.Group as={Row} controlId="formHorizontalEmail">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
    Amount
  </Form.Label>
  <Col sm={8}>
    <Form.Control type="text" value={this.state.invoiceData.pendingAmount && this.state.invoiceData.pendingAmount.toFixed(2)+" "+ 'AED'} placeholder=" 44 AED" />
  </Col>
</Form.Group>

<Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
  Paid Amount
  </Form.Label>
  <Col sm={8}>
    <Form.Control required ref={(input=>this.amountPaid=input)} type="number" placeholder="Amount in AED" />
  </Col>
</Form.Group>
<Form.Group as={Row} controlId="formHorizontalPassword">
    <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>Payment Method</Form.Label>
    <Col sm={8}>
    <Form.Control as="select"  onChange={(e)=>{this.setState({recordPaymentType:e.target.value})}}  defaultValue="Choose..." required>
      <option value=''>Choose...</option>
      <option value="CASH">Cash</option>
      <option value="CHEQUE">Cheque</option>
      <option value="ONLINE_BANK_TRANSFER">Bank Transfer</option>
      {/* ONLINE_BANK_TRANSFER */}
    </Form.Control>
   </Col> 
  </Form.Group>
  {this.state.recordPaymentType==="CHEQUE"?
  <div>
     <Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
  Check No
  </Form.Label>
  <Col sm={8}>
    <Form.Control required ref={(input=>this.checkNumber=input)} type="text" placeholder="Enter Check No" />
  </Col>
</Form.Group>
    <Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
  Expiry Date
  </Form.Label>
  <Col sm={8}>
    <Form.Control required  ref={(input=>this.checkDate=input)} type="date"  />
  </Col>
</Form.Group>
{/* <Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
  Check Amount
  </Form.Label>
  <Col sm={8}>
    <Form.Control required ref={(input=>this.checkAmount=input)} type="number" placeholder="Amount in AED" />
  </Col>
</Form.Group> */}
<Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
  Upload Check
  </Form.Label>
  <Col sm={8}>
  <div className="upload-photo-area-check">
                     <div>
                      
          <center>{this.state.preview1.length>0?<embed width="100" height="60" name="plugin" src={this.state.preview1[0]}  /> :<Icon style={{marginTop:'30px'}} name="upload" size="large" />}
                    
                     <div className="image-upload">
                                    <label for="file-input-cheque">
                                    <p style={{fontSize:'12px',cursor:'pointer',fontWeight:'550'}}>Select Cheque Image/PDF</p>
                                    </label>
                                    <br/>
                                    <div /* onClick={this.handlePhoto} */ onClick={()=>this.setState({show6:true,currentPreview:"preview1"})} style={{color:'#666666',fontWeight:'550'}}><Icon name="camera" /> Take Photo</div>

                                    <input id="file-input-cheque"  name="photos" type="file" accept=".png,.jpg,.jpeg,.pdf"    onChange={this.handleFileChange1} />
                                  </div>
                                  </center>
                    </div>    
                   
            </div>
  </Col>
</Form.Group>
  </div>
  :''}
  <Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>Memo / Notes</Form.Label>
  <Col sm={8}>
  <Form.Control required ref={(input=>this.memo=input)} as="textarea" rows="1" />
  </Col>
</Form.Group>
<Form.Group >
  <p style={{color:'red',fontWeight:'550'}}>{this.state.recoredPaymentError}</p>
{this.state.loaderFlag? 
<div > <div className="loader"></div>
<p>Please Wait...</p></div>
:''}
  <div className="bottom-button-area-recored-paymenfomr">
    <div></div>
    <div>
      
      <button onClick={()=>this.setState({show1:false})} className="bottom-button-area-recored-paymenfomr-cancel">
        Cancel
      </button>
      <button  type={"submit"} className="bottom-button-area-recored-paymenfomr-submit">Submit</button>
    </div>
  </div>
</Form.Group>
</Form>
               </div>
             </center>
            </div>
            } 
           
         
        </Modal.Body>
    
      </Modal>
      
      <Modal show={this.state.show3} onHide={this.handleClose3} size="sm" centered  >
      {this.state.CancelInvoiceSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Confirmation
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body>
      {this.state.CancelInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="this Invoice is Successfuly cancelled"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose3} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Are you sure to cancel this  Invoice!</p>
  
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag1? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
     {this.state.CancelInvoiceError.length>0?<Alert message={this.state.CancelInvoiceError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.CancelInvoiceSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose3}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleCancelInvoiceSubmit} color='red'>Cancel Invoice</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
      
      <Modal show={this.state.show4} onHide={this.handleClose4} size="sm" centered  >
      {this.state.editInvoiceSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <Icon name="edit outline" size="large" /> Edit Invoice
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body style={{maxHeight:'430px',overflow:'auto'}}>
      {this.state.editInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="this Invoice is Successfuly Updated"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose4} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div >
         {this.state.editInvoice.items && this.state.editInvoice.items.map((value,index)=>{
return  <div>

         <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px',marginTop:'-5px'}}>{index+1}. {value.serviceType}</div>
<Input icon='edit outline' onChange={(e)=>this.handleUpdateAmount(e,value,index)} style={{width:"263px"}} iconPosition='right'  defaultValue={value.amount}  placeholder='Search users...' />
<br />
<br />
</div>
         })}
   

  <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px',marginTop:'-5px'}}>Due Date</div>
  <Input  labelPosition='right' type='text' placeholder='Amount'>
 
    <Label basic style={{paddingRight:'4px'}}> <Icon name='clock outline'></Icon></Label>
    <DatePicker onChange={(e)=>this.handleDateUpdate(e)} defaultValue={moment(this.state.editInvoice.expireAt, 'YYYY/MM/DD')} style={{width:"217px"}} />
   
 
  </Input>

  <br />
  <br />
  <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px',marginTop:'-5px'}}>Vat Amount</div>
  <Input  labelPosition='right' type='text' placeholder='Amount'>
 
    <Label basic> <Checkbox onChange={(e)=>this.handleVat()} checked={this.state.editInvoice.vatIncluded>0?true:false}  /></Label>
    <input  value={this.state.editInvoice.vatIncluded} style={{width:"172px"}} />
    <Label style={{fontSize:'13px',paddingTop:'15px'}}>AED</Label>
  </Input>
  <br />
  <br />
  <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px'}}>Total Amount</div>
     <Input  labelPosition='right' type='text' placeholder='Amount'>
     <Label style={{fontSize:'12px',paddingTop:'13px'}} basic>AED</Label>
    <input value={this.state.editInvoice.amountToBePaid} style={{width:"171px"}} />
    <Label>.00</Label>
  </Input>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag2? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
     {this.state.editInvoiceError.length>0?<Alert message={this.state.editInvoiceError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.editInvoiceSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose4}>Cancel</Button>
    <Button.Or />
    <Button onClick={this.handleUpdateInvoiceSubmit} color='black'>Update Invoice</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
      <Modal show={this.state.show5} onHide={this.handleClose5} size="sm" centered  >
      {this.state.BankReceiptSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Upload Bank Receipt
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body>
      {this.state.BankReceiptSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="Bank Receipt of this Invoice is Successfuly Uploaded"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose5} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Upload the Bank Receipt of this Invoice here Please selete the Bank Receipt and then Upload</p>
       <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px'}}>Deposit Amount</div>
     <Input  labelPosition='right' type='text' placeholder='Amount'>
     <Label style={{fontSize:'12px',paddingTop:'13px'}} basic>AED</Label>
    <input onChange={(e)=>this.setState({depositedAmount:e.target.value})} value={this.state.depositedAmount} style={{width:"180px"}} />
    <Label>.00</Label>
  </Input>
  <br />
  <br />
       <div className="upload-photo-area">
                     <div>
          <center>{this.state.preview.length>0? <embed width="150" height="60" name="plugin" src={this.state.preview}  />:<Icon style={{marginTop:'30px'}} name="upload" size="huge" />}
                    
                     <div className="image-upload">
                                    <label for="file-input2">
                                    <p style={{fontSize:'12px',cursor:'pointer',fontWeight:'550'}}>Select Bank Receipt</p>
                                    </label>
                                    <br/>
                                    <div /* onClick={this.handlePhoto} */ onClick={()=>this.setState({show6:true,currentPreview:"preview"})} style={{color:'#666666',fontWeight:'550'}}><Icon name="camera" /> Take Photo</div>

                                    <input id="file-input2"  name="photo15" type="file" accept=".png,.jpg,.jpeg,.pdf"    onChange={this.handleFileChange} />
                                  </div>
                                  </center>
                    </div>    
                   
            </div>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag3? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}>Uploading Please wait....</p>
            </center>:''}
     {this.state.BankReceiptError.length>0?<Alert message={this.state.BankReceiptError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.BankReceiptSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose5}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleBankReceiptSumbit} color='black'>Upload</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
      <Modal show={this.state.show6} onHide={this.handleClose6} size="xl" centered  >
     
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/camera.png')} /> Camera
          </Modal.Title>
        </Modal.Header>
        <button onClick={this.handleCapturePhoto}>Capture photo</button>
        <Webcam
        audio={false}
        height={640}
        ref={this.webcamRef}
        screenshotFormat="image/jpeg"
        width={'100%'}
        videoConstraints={{
          width: '100%',
          height: 720,
          facingMode: "user"
        }}
      />
    
     {/*  <Modal.Body>
      <video id="video" width="640" height="480" className="cameraFrame" src={this.state.videoSrc} autoPlay="true"
        ref={(input) => { this.videoElement = input; }}></video>
        <center><Button onClick={this.handleCapture}>Capture Photo</Button></center>
        </Modal.Body> */}
        
    
      </Modal> 
      <Modal show={this.state.show7} onHide={this.handleClose7} size="md" centered  >
     
     <Modal.Header closeButton>
       <Modal.Title 
       style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
    <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Cheque Detail
       </Modal.Title>
     </Modal.Header>
   <Modal.Body>
  
  {this.state.loaderFlagCheque?
<center>

<div style={{marginTop:'150px',marginBottom:'150px'}}>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
            </div>
</center>:<div>
<List divided verticalAlign='middle'>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
        {this.state.chequeDetail.chequeAmount && this.state.chequeDetail.chequeAmount} AED
      </List.Content>
     
      <List.Content><Icon name="credit card outline" />Cheque Amount</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.chequeNumber && this.state.chequeDetail.chequeNumber}
      </List.Content>
     
      <List.Content><Icon name="calendar outline" />Cheque No</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.createdAt &&  moment(this.state.chequeDetail.createdAt).format('MMMM Do YYYY,h:mm:ss a')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />Cheque Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.expiry && moment(this.state.chequeDetail.expiry).format('MMMM Do YYYY')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />PDC Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.status && this.state.chequeDetail.status}
      </List.Content>
     
      <List.Content><Icon name="dna" />Status Subject to Realization</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
     BZ/{this.state.chequeDetail.fileNo && this.state.chequeDetail.fileNo}
      </List.Content>
     
      <List.Content><Icon name="file alternate outline" />File NO</List.Content>
    </List.Item>
    
  </List>
  {/* {console.log(this.state.chequeDetail,"oppppppppppppp")} */}
  {this.state.chequeDetail.chequePath?this.state.chequeDetail.chequePath.slice(this.state.chequeDetail.chequePath.length-3)==="pdf"?<iframe  style={{border:'1px solid lightgrey',width:'100%',minHeight:'330px',msOverflowY:'auto'}} onLoad={()=>this.setState({imageLoader:true})} src={this.state.chequeDetail.chequePath.slice(0,5)==="https"? this.state.chequeDetail.chequePath:`${process.env.REACT_APP_FILE}leServiceleService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.chequeDetail.chequePath}`}  ></iframe>
   :<img style={{border:'1px solid lightgrey',width:'100%'}} onLoad={()=>this.setState({imageLoader:true})} src={this.state.chequeDetail.chequePath.slice(0,5)==="https"? this.state.chequeDetail.chequePath:`${process.env.REACT_APP_FILE}leServiceleService/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.chequeDetail.chequePath}`} />
:''
 }
 {this.state.imageLoader?'':
 <center>

 <div style={{marginTop:'50px',marginBottom:'50px'}}>
                 <div className="loader"></div>
                 <p style={{paddingTop:'20px'}}>Loading Cheque Image....</p>
             </div>
 </center>
 }
  </div>}
     </Modal.Body>
     
 
   </Modal> 
<div style={{display:'none'}}><Invoiceview printInvoice={this.state.printInvoice} ref={el => (this.componentRef = el)} /></div>
    </div>
    </div>
)
}  
  

}
const mapStateToProps=(state)=> {
  return {
    getAllInvoicesReducer: state.AccountantInvoicesAll1.getAllInvoicesReducer,
    sendInvoiceEmailReducer:state.AccountantInvoicesAll1.sendInvoiceEmailReducer,
    RecoredUserPaymentReducer:state.AccountantInvoicesAll1.RecoredUserPaymentReducer,
    getInvociesCounterReducer:state.AccountantInvoicesAll1.getInvociesCounterReducer,
    fileSearchReducer:state.AccountantInvoicesAll1.fileSearchReducer,
    cancelInvoiceReducer:state.AccountantInvoicesAll1.cancelInvoiceReducer,
    updateInvoiceReducer:state.AccountantInvoicesAll1.updateInvoiceReducer,
    bankReceiptInvoiceReducer:state.AccountantInvoicesAll1.bankReceiptInvoiceReducer,
    chequeDetailReducer:state.AccountantInvoicesAll1.chequeDetailReducer,
    sendSmsInvoiceReducer:state.AccountantInvoicesAll1.sendSmsInvoiceReducer
  };
} 
export default compose(
  withReducer("AccountantInvoicesAll1", reducer),
  connect(mapStateToProps),withRouter
)(AccountantInvoicesAll); 

