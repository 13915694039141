import React, { Component } from 'react'
import Joi from 'joi-browser'
import { toast } from 'react-toastify';
import { NavLink, Link } from 'react-router-dom'
import '../adminSignIn/formStyle.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import ReCAPTCHA from "react-google-recaptcha";
  const recaptchaRef = React.createRef();
class FormAdminSignIn extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        email: '',
        password:'',
        password1:'',
        error: {},
        type:'password',
        type1:'password',
        passFlag:false,
        passFlag1:false,
        captcha:null,
        buttonFlag:true,
        passSubmitFlag:false,
        passFlag11:false
    } 
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
    schema = {
      
     
        password:Joi.string().min(6).max(15).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'password  between 6 and 15 ';
                        break;
                }
            });
            return errors;
        }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            password:this.state.password,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
            if(this.state.passFlag11){
            this.setState({
                show:true,
                email: '',
                pass1:'',
                pass2:''
            })
        }else{
            this.setState({passSubmitFlag:true})   
           }
         
           
        }
    }
    handleSent=()=>{
        this.setState({show:false})
    }
    handleHidePass=()=>{
this.setState({passFlag:true,type:'text'})
    }
    handleShowPass=()=>{
        this.setState({passFlag:false,type:'password'})
    }
    handleHidePass1=()=>{
        this.setState({passFlag1:true,type1:'text'})
            }
            handleShowPass1=()=>{
                this.setState({passFlag1:false,type1:'password'})
            }
    handleConfirmPass=(e)=>{
        let pass2=e.target.value;
        this.setState({password1:pass2})
        if((pass2.match(this.state.password)) && ((pass2.toString()).length===(this.state.password.toString()).length)){
            this.setState({passFlag11:true})
        
        }
        else{
          this.setState({passFlag11:false,passSubmitFlag:true})
        }

    }
    onChangeCaptha=(value)=>{
        if(value===null){
          this.setState({captcha:null,buttonFlag:true})
        }
        else{
          this.setState({captcha:value,buttonFlag:false})
        }
      }
    render() {

       
        return (
            <form onSubmit={this.submitHandler} className='contactForm'>
                <div className="row">
                    
                <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Password*</p> 
                    <div className="formInput form-control field-design1">
                    <img className="password-image1" src={require('../../images/signIn/04-01.png')} />
                            <input
                                placeholder="********"
                                value={this.state.password}
                                name="password"
                                onChange={this.changeHandler}
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type={this.state.type} />
                             {this.state.passFlag ?   <img className="password-image2" onClick={this.handleShowPass} title="hide password" src={require('../../images/signIn/hide-01.png')} /> :
                                 <img className="password-image2" onClick={this.handleHidePass} title="show password" src={require('../../images/signIn/show-01.png')} />     }   
                           <div style={{marginTop:'8px'}} className={this.state.error.password ?"password-area" :"password-area1" }>
                            {this.state.error.password && <p style={{fontWeight:'550'}}>{this.state.error.password}</p>}
                             </div>    
                      {/* forgotpassword */}
                        </div>
                    
                     </div> 
                  
                    <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Confirm Password*</p> 
                    <div className="formInput form-control field-design1">
                    <img className="password-image1" src={require('../../images/signIn/04-01.png')} />
                            <input
                                placeholder="********"
                                value={this.state.password1}
                                name="password1"
                                onChange={this.handleConfirmPass}
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type={this.state.type1} />
                             {this.state.passFlag1 ?   <img className="password-image2" onClick={this.handleShowPass1} title="hide password" src={require('../../images/signIn/hide-01.png')} /> :
                                 <img className="password-image2" onClick={this.handleHidePass1} title="show password" src={require('../../images/signIn/show-01.png')} />     }   
                          
                           {this.state.passSubmitFlag ? (
                this.state.passFlag11 ? (
               ""
                ) : (
                    <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                    Password do not match 
                  </p>
                )
              ) : (
                ""
              )}        
                      {/* forgotpassword */}
                        </div>
                      
                     </div>   
                     <div className="col-12" style={{marginTop:'10px',marginBottom:'20px'}}>
                    <center>
                    <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       />
                    </center>
                     </div>
                   
                    <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="signon-bottom-area">
                      {/*   <p style={{color:'#666666',textDecoration:'underline'}}><NavLink style={{color:'#fff',textDecoration:'underline'}} exact to='/termsconditions'>  Terms & Conditions</NavLink></p>    
                       */}  <button  disabled={this.state.buttonFlag} style={{cursor:'pointer',marginTop:'5px',borderRadius:'12px',width:'100%'}} type="submit">Reset </button>
                       </div>
                       
                    </div>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose} size="sm" centered  >
        
          <Modal.Body>
            
              <div className="admin-otp-verification">
               <center>
                   <p>OTP VERIFICATION</p>
                   <img style={{width:'180px'}} src={require('../../images/address.jpg')} />
                 
                   <p>Enter 6-digit Authentication Code </p>
                   <input type="text" placeholder="Code" className="otp-code-legaldfd" maxLength='6' />
               </center>
               <div className="admin-otp-verification-button">
                   <p onClick={this.handleClose}>Back</p>
                   <p style={{marginLeft:'10px'}}>Continue</p>
               </div>
              </div>
           
          </Modal.Body>
      
        </Modal>
            </form>
        )
    }
}
export default FormAdminSignIn