import React, { useEffect, useState, Component } from "react";
import { NavLink, Link,withRouter } from "react-router-dom";

import "./style.css";
import "./formStyle.scss";
import "./style.scss";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import ReactToPrint from 'react-to-print';
import  {Modal as Modal1} from "react-bootstrap";
import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Button,Modal,Header, Feed } from 'semantic-ui-react'
import * as Actions from "./store/actions";
import { compose } from "redux";
import axios from "axios";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import './invoiceview.scss'
import { Alert,message } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import {
  Form,
  ButtonToolbar,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
  Accordion,
  Card,
} from "react-bootstrap";
import Webcam from "react-webcam";
import  QuotationView from './quotaionView'
import { useDispatch, useSelector } from "react-redux";

/*   <li>Save and Send</li>
                          <li>Save and Record A Payment</li> */

const options = [
/*   { key: 'save', icon: 'save', text: 'Save and Send', value: 'Save and Send' },
  { key: 'delete', icon: 'credit card outline', text: 'Save and Record A Payment', value: 'Save and Record A Payment' },
 */]
class AccountantExpenseAddition extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      InvoiceType: "Type",
      invoiceStatus:'INVOICE',
      invoiceViewFlag:true,
      AccordionToggle: false,
      previewFlag:false,
      searchFlag:false,
      key:'',
      searchFlag1:false,
      key1:'',
      customerAdded:false,
      falgd:true,
      vat:false,
      subTotal:0,
      total:0,
      vatTotal:0,
      open:false,
      checked:false,
      itemAdded:[],
      addCustomerFlag:true,
      errMsgUser:'',
      fileError:false,
      addedCustomer:{},
      customers:[],
      options1:[],
      options2:[],
      caseError:'',
      submitError:false,
      invoiceDate:'',
      invoiceDueDate:'',
      fileNo:'',
      invoiceRemarks:'',
      fileLoading:false,
      fileError1:'',
      userId:'',
      loaderFlag:false,
      createFileError:'',
      modalOpen:false,
      invoiceFlag78:false,
      sendInvoice:{},
      typeOptions:[],
      serviceTypeError:'',
      serviceTypeFlag:true,
      show1:false,
      serviceTypeAddSuccess:false,
      serviceTypeAddFlag:false,
      serviceTypeAddErrorFlag:false,
      serviceTypeAddError:'',
      loaderFlag:false,
      getFileFlag:false,
      getFileFlag1:false,
      show:false,
      preview:'',
      photo:'',
      ServiceImageError:'',
      show6:false,
      imageUploadItem:{},
      ServiceImageError:'',
      loaderFlag3:false
      
    };
    this.toastId=React.createRef(null);
    this.webcamRef=React.createRef(null);
  }
  handleClose1=()=> {
    this.setState({ show1: false });
  }
  
  handleShow1=()=> {
    this.setState({ show1: true });
  }
  handleCloses=()=> {
    this.setState({ show: false });
  }
  handleShow6=()=> {
    this.setState({ show6: true });
  }
  handleClose6=()=> {
    this.setState({ show6: false });
  }
  handleShow=()=> {
    this.setState({ show: true });
  }
  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })
/*    var reg45 = new RegExp(this.state.key.split('').join('.*'), 'i');
          if (item.country.match(reg45)){ */
            handleSearch=(e)=>{
             let search=e.target.value
             this.setState({searchFlag:true,key:search})
          
              
            }   
    componentWillMount(){
      this.props.dispatch(Actions.getAllUsersInvoice())
      this.props.dispatch(Actions.getServiceTypes())
           /*  defaultValue= */
        if((new Date().getMonth())<10){
          var monthZero="0"    
        }   else{
          var monthZero=""    
        }  
        if((new Date().getDate())<10){
          var dayZero="0"    
        }   else{
          var dayZero=""    
        } 
       let invoiceDate=(new Date().getFullYear())+"-"+monthZero+((new Date().getMonth()+1))+"-"+dayZero+(new Date().getDate())
     this.setState({invoiceDate:invoiceDate,invoiceDueDate:invoiceDate}) 

    }    
       componentDidMount(){
/*         document.getElementById("itmen-drop-doendd")[0].style.maxWidth = "30ch";   */
       } 
     /*   handleAddItem=(item)=>{
   
         let myFlag=true;
      if(this.state.itemAdded.length>0){
        const found = this.state.itemAdded.some(el => el.id === item.id);
          if (!found) {
            this.state.itemAdded.push(item);
            this.setState({falgd:true})
            this.setState(prevState => {
              return {total: prevState.total + item.amount}
           })
           this.setState(prevState => {
            return {subTotal: prevState.subTotal + item.amount}
         })
          }else{
            if(! toast.isActive(this.toastId.current)) {
              this.toastId.current =toast.error('this Item is already exist', {
              position: "top-right",
              toastId:'logined',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            }
          };
        }else{
    
          this.state.itemAdded.push(item);
          this.setState({total:item.amount,subTotal:item.amount})
         
        }
      */
        
      handleChange=(e)=>{
        this.setState({
          [e.target.name]:e.target.value
        })
    
      }
      hanldePreviewButton=()=>{
        if(this.state.previewFlag){
         this.setState({previewFlag:false,invoiceViewFlag:true})
         window.scrollTo({top:0, behavior: 'smooth'})
        }else{
          this.setState({previewFlag:true,invoiceViewFlag:false})
          window.scrollTo({top:0, behavior: 'smooth'})
        }
      }  

      
      handleAddItem=()=>{
        let id=Math.random().toString(36).substring(7);
        let obj= {
        id:id,
        detail:'',
        amount:0.00,
        serviceType:'',
        imageUrl:''
      } 
      this.state.itemAdded.push(obj);
      this.setState({falgd:true})
      }

      handleRemove=(item)=>{
      let array=this.state.itemAdded;
      let item888=item.amount
      let array1=array.filter((value)=>value.id!=item.id) 
      this.setState({itemAdded:array1}) 

      this.setState(prevState => {
        return {subTotal: prevState.subTotal - item.amount}
     })

     if(!this.state.checked){
      this.setState(prevState => {
        return {vatTotal: prevState.vatTotal - item888*5/100}
     })
    
     this.setState(prevState => {
      return {total: prevState.total - (Number(item888)+Number(item888*5/100))}
   })
     }else{

      this.setState(prevState => {
        return {total: prevState.total -item888}
     })
     }
   


      }

      handleItemDetail=(e,item,index)=>{
       let array= this.state.itemAdded
       array.map((value)=>{
        let array1=this.state.itemAdded.filter((item2)=>item2.id!=item.id)
        if(value.id===item.id){
         value.detail=e.target.value
         let array2=[
          // part of the array before the specified index
          ...array1.slice(0, index),
          // inserted item
          value,
          // part of the array after the specified index
          ...array1.slice(index)
        ]
        this.setState({itemAdded:array2})

        }  
       })
      }
      handleItemImage=(imageUrl)=>{
        const {index}=this.state.imageUploadItem
        let item=this.state.imageUploadItem;
        let array= this.state.itemAdded
        array.map((value)=>{
         let array1=this.state.itemAdded.filter((item2)=>item2.id!=item.id)
         if(value.id===item.id){
          value.imageUrl=imageUrl
          let array2=[
           // part of the array before the specified index
           ...array1.slice(0, index),
           // inserted item
           value,
           // part of the array after the specified index
           ...array1.slice(index)
         ]
         this.setState({itemAdded:array2,loaderFlag3:false,show:false,photo:'',preview:''})
       
         }  
        })
        message.success("Service image successfully uploaded")
       }
 

      handleItemAmount=(e,item,index)=>{
        let array= this.state.itemAdded
        let v1=e.target.value
        let value1=item.amount
        array.map((value)=>{
         let array1=this.state.itemAdded.filter((item2)=>item2.id!=item.id)
         if(value.id===item.id){
          value.amount=v1
          let array2=[
           ...array1.slice(0, index),
           value,
           ...array1.slice(index)
         ]
         this.setState({itemAdded:array2})
       
        
    
         this.setState(prevState => {
          return {subTotal: prevState.subTotal + Number(v1)}
       })
       this.setState(prevState => {
        return {subTotal: prevState.subTotal - Number(value1)}
     })

     this.setState(prevState => {
      return {total: prevState.total + Number(v1)}
   })
   this.setState(prevState => {
    return {total: prevState.total - Number(value1)}
 })
setTimeout(()=>{
  this.handleVatValue()
},200)
         }  
        })
       }
       handleVatValue=()=>{
        if(!this.state.checked){
       
          let vat=this.state.subTotal*5/100
           
          this.setState(prevState => {
            return {total: prevState.subTotal+vat}
         })
         this.setState(prevState => {
          return {vatTotal: vat}
       })

    }

       } 
       handleVat=(e,g)=>{
        if(this.state.checked){
          let vat=this.state.total*5/100
          this.setState(prevState => {
            return {total: prevState.total + vat}
         })
         this.setState(prevState => {
          return {vatTotal: prevState.vatTotal + vat}
       })
       this.setState({checked:false})
        }else{
   
          let vat2=this.state.vatTotal
          this.setState(prevState => {
            return {total: prevState.total - vat2}
         })
         this.setState(prevState => {
          return {vatTotal: prevState.vatTotal - vat2}
       })
       this.setState({checked:true})
        }
      }

      
      componentWillReceiveProps(nextProps){
        let resNumber2 =nextProps.getUserCreateInvoceReducer;
 
        if (!resNumber2.isLoading) {
          if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
            if(this.state.addCustomerFlag){
             this.setState({addCustomerFlag:false,errMsgUser:'',customers:resNumber2.data.users})
            }
     /*      console.log(resNumber2.data.users) */
          }else{
            this.setState({errMsgUser:resNumber2.errMsg})
          }
        }


        /* getUserCreateInvoceFilesReducer getAllUsersInvoiceFiles */

        let resNumber3 =nextProps.getUserCreateInvoceFilesReducer;
 
        if (!resNumber3.isLoading) {
          if (resNumber3.errMsg == null && !(resNumber3.data===undefined)) {
            if(resNumber3.data.file.length>0){
              resNumber3.data.file.map((item)=>{
                let obj={ level: item.fileNo, value: item.fileNo }
                this.state.options1.push(obj)
                this.setState({fileError:false,fileLoading:false})
              })
         
          
            }else{
              if(this.state.getFileFlag){
                this.setState({fileError:true,fileLoading:false,getFileFlag:false})
              }
            
            }
        /*     if(this.state.addCustomerFlag){ */
        /*       console.log(resNumber3,"gggggggggggggggggg") */

        if(resNumber3.data.cases.length>0){
          resNumber3.data.cases.map((item)=>{
            let obj={ level: item.caseNo, value: item.caseNo }
            this.state.options2.push(obj)
           
          })
     
      
        }else{
          if(this.state.getFileFlag1){
            this.setState({caseError:"Don't have any open case"})
          }
        
        }

          }else{
            this.setState({fileLoading:false,fileError1:resNumber2.errMsg}) 
          }
        }
        /* addExpenseReducer */

        let resNumber4 =nextProps.addExpenseReducer;
 
        if (!resNumber4.isLoading) {
          if (resNumber4.errMsg == null && !(resNumber4.data===undefined)) {
         
           if(this.state.invoiceFlag78){ 
           this.setState({loaderFlag:false,createFileError:'',modalOpen:true,sendInvoice:resNumber4.data})  
       
           }
          }else{
           this.setState({createFileError:resNumber4.errMsg,loaderFlag:false,invoiceFlag78:false})  
     console.log(resNumber4.errMsg)
          }
        }
     /* RecoredUserPaymentReducer  sendInvoiceEmailReducer*/

     /* getServicesTypeReducer */

     let resNumber44 =nextProps.getServicesTypeReducer;
 
     if (!resNumber44.isLoading) {
       if (resNumber44.errMsg == null && !(resNumber44.data===undefined)) {
         if(this.state.serviceTypeFlag){
          this.setState({typeOptions:[],serviceTypeFlag:false})  
       /*    alert('yes') */
     setTimeout(()=>{    if(resNumber44.data.serviceTypes.length>0){
      resNumber44.data.serviceTypes.map((item)=>{
       let obj={
        key: item.type,
        text: item.type,
        value:item.type,
        label: { empty: true, circular: true },
      } 
      this.state.typeOptions.push(obj)
      this.setState({flaged:false})
      })
    }},1000)
           /* */
         }
       
       }else{
        this.setState({serviceTypeError:resNumber44.errMsg})  
       }
     }
     /* addServicesTypeReducer */
     let resNumber444 =nextProps.addServicesTypeReducer;
 
     if (!resNumber444.isLoading) {
       if (resNumber444.errMsg == null && !(resNumber444.data===undefined)) {
        if(this.state.serviceTypeAddFlag){
          this.setState({serviceTypeAddFlag:false,serviceTypeAddSuccess:true,serviceTypeAddError:'',loaderFlag:false})
         
             let obj={
              key: resNumber444.data.serviceType.type,
              text: resNumber444.data.serviceType.type,
              value:resNumber444.data.serviceType.type,
              label: { empty: true, circular: true },
            } 
           this.state.typeOptions.push(obj)
           this.setState({flaged:true})
          }
       }else{
         if(this.state.serviceTypeAddErrorFlag){
        this.setState({serviceTypeAddError:resNumber444.errMsg,serviceTypeAddErrorFlag:false,loaderFlag:false})  
           
      }
       }
     }
      }
      handleCustomer=(item)=>{
        this.setState({addedCustomer:item,getFileFlag:true,getFileFlag1:true,caseError:'',options2:[],customerAdded:true,options1:[],fileLoading:true,fileError1:'',userId:item._id})
        this.props.dispatch(Actions.getAllUsersInvoiceFiles(item._id))
        localStorage.setItem("userId8855",item._id)
       /*  console.log(item) "5ef1e66c55faca3525f432d7" */
      }
      
      hanldeSaveQuotaion=()=>{
      
        let {userId,vatTotal,itemAdded,invoiceStatus,invoiceRemarks,addedCustomer,options1,submitError,invoiceDate,invoiceDueDate,fileNo,total}=this.state;
   /*  && addedCustomer.length>0 */
      if(options1.length>0 && invoiceDate.length>6 && invoiceDueDate.length>6 && fileNo.length>0 && total>0 ){
        let d1=(new Date(invoiceDate).toISOString()).slice(0,10)
        let t1=(new Date().toISOString()).slice(10)

        let d=(new Date(invoiceDueDate).toISOString()).slice(0,10)
        let t=(new Date().toISOString()).slice(10)

        this.setState({loaderFlag:true,invoiceFlag78:true})
        let obj={
      fileNo: fileNo,
      caseNo: this.caseNo.value,
      userId: userId,
      amountToBePaid: total.toFixed(2),
      generatedAt: d1+t1,
      expireAt: d+t,
      vatIncluded: vatTotal.toFixed(2),
      description: invoiceRemarks,
      termAndCondition: "The acknowledgment of this opening a file receipt by the customer of the paid amount is shown as a NON-REFUNDABLE payment. The customer has no right to claim the refund of opening a file payment.",
      items: itemAdded
  }
  this.props.dispatch(Actions.addExpenseCustomer(obj))
      }else{
        this.setState({submitError:true})
        window.scrollTo({top:0, behavior: 'smooth'})
      }
    
      }
      handleSuccess=()=>{
        this.handleClose();
          this.props.history.push('/expenseAll')
      }
    /* sendInvoice */
  handlePrintInvoice=()=>{
  
    localStorage.setItem('invoiceId',this.state.sendInvoice.invoice._id)
    this.props.history.push('/expenseAll')
    
/*  setTimeout(()=>{   this.props.dispatch(Actions.currentInvoicePrint(this.state.sendInvoice))},800) */
  }
  handleSeletType=(value1,index,id)=>{
    let array= this.state.itemAdded
    array.map((value)=>{
     let array1=this.state.itemAdded.filter((item2)=>item2.id!=id)
     if(value.id===id){
      value.serviceType=value1
      let array2=[
       // part of the array before the specified index
       ...array1.slice(0, index),
       // inserted item
       value,
       // part of the array after the specified index
       ...array1.slice(index)
     ]
     this.setState({itemAdded:array2})

     }  
    })
  } /*  */
  handleAddServices=()=>{
    this.setState({show1:true,serviceTypeAddError:'',serviceTypeAddErrorFlag:true,serviceTypeAddErrorFlag:true,serviceTypeFlag:true})
  }
  handleAddServiceSubmit=(e)=>{
    e.preventDefault()
    this.setState({loaderFlag:true,serviceTypeAddFlag:true})
    let typeName=this.typeName.value
    this.props.dispatch(Actions.addServiceType({type:typeName}))
   
  }
  handleOpenFile=()=>{
    /* localStorage.setItem("fileLink","addQuotation") */
    this.setState({fileError:false})
    localStorage.setItem('accountantLink','createFileAccountant')
  }
  handleFileChange = e => {
     
    this.setState({
      photo: e.target.files[0]
    })
   
    var reader = new FileReader();
  var url = reader.readAsDataURL(e.target.files[0]);
  reader.onloadend =  (e)=> {
    this.setState({
        preview: reader.result
    })
  }
  }
  b64toBlob=(b64Data, contentType, sliceSize)=> {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
  handleCapturePhoto=()=>{
    let ImageURL=this.webcamRef.current.getScreenshot();
  
    if(ImageURL!=null){
      this.setState({preview:ImageURL})
   
    }
    this.setState({show6:false})
    var block = ImageURL.split(";");
// Get the content type of the image
var contentType = block[0].split(":")[1];// In this case "image/gif"
// get the real base64 content of the file
var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

// Convert it to a blob to upload
   let blob = this.b64toBlob(realData, contentType);
   this.setState({photo:blob})

  }
  handleUploadImage=(item,index)=>{
    this.setState({imageUploadItem:{...item,index},show:true})
  }
  handleServiceImageSumbit=()=>{

    if(!(this.state.photo==="")){
      this.setState({loaderFlag3:true})
      /*       const formData = new FormData();
      console.log(this.state.photo,"kkkkkkkkk")
        formData.append('cv', this.state.cv); */
     const  Data=  new FormData();
      Data.append('image',this.state.photo) 
         
    this.uploadImage(Data)
/*         this.props.dispatch(Actions.uploadBankReceipt(Data)) */

//handleItemImage 

    }else{
      message.error('Please select the Service Image First');
      this.setState({ServiceImageError:"Please select the Service Image First"})
    }
  }
  uploadImage = async (data) => {
    const res = await axios.post(
      `${process.env.REACT_APP_FILE}/publicRoutes/api/v1/imageUpload/uploadImage`,
      data,
      {
        headers: {
          Authorization: "bearer " + localStorage.getItem("accountantToken"),
          "Content-Type": "application/json",
        },
      }
    );
    this.handleItemImage(res.data.location)
/*     console.log(res.data.location, "image upload"); */
  };
  
  render() {
    console.log(this.state.itemAdded,"lllllllllllllllllllll")

    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
      
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "200px" }}
          >
            <div className="AddInvoice-Area">
              <div className="addInvoiceTpArea">
                <div className="addInvoiceTpArea-heading-left">
                  <p> Add an Expense</p>
                </div>
                <div className="addInvoiceTpArea-heading-right">
                 {this.state.loaderFlag? <div className="loader"></div>:''}
    {/*               <div className="addInvoiceTpArea-heading-left-type">
                    <ul className="INvoive-main-list-action">
                      <li>
                        {this.state.InvoiceType}{" "}
                        <i className="fa fa-caret-down"></i>
                        <ul className="subMenu1">
                          <li
                            onClick={() =>
                              this.setState({ InvoiceType: "Invoice",invoiceStatus:'INVOICE' })
                            }
                          >
                            Invoice
                          </li>
                          <li
                            onClick={() =>
                              this.setState({ InvoiceType: "Payment Voucher",invoiceStatus:'PAYMENT VOUCHER' })
                            }
                          >
                            Payment Voucher
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div> */}

                  <div className="addInvoiceTpArea-heading-left-type" onClick={this.hanldePreviewButton}>
                   {this.state.previewFlag? <div style={{paddingLeft:'13px',paddingRight:'13px'}}>Edit</div>:'Preview'} 
                   
                  </div>
                  <div  style={{ marginLeft: "10px" }}>
                  <Button.Group color='black'>
    <Button onClick={this.hanldeSaveQuotaion}   style={{borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px',marginRight:'-2px'}}>  Save & continue</Button>
    <Dropdown
      className='button icon'
      style={{borderTopRightRadius:'20px',borderBottomRightRadius:'20px',borderLeft:'1px solid dimgray'}}
      floating
      options={options}
      trigger={<React.Fragment />}
    />
  </Button.Group>
                  </div>
                  {/* <div className="addInvoiceTpArea-heading-left-save-cont">
                    <ul className="INvoive-main-list-action">
                      <li>
                        Save & continue{" "}
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-caret-down"
                        ></i>
                        <ul className="subMenu">
                          <li>Save & continue</li>
                          <li>Save and Send</li>
                          <li>Save and Record A Payment</li>
                        </ul>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
   {this.state.submitError?            <div className="invoice-le-view_area" style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'10px',paddingBottom:'0px'}}>
              <div class="invoice-view__topmessage">
                    <div class="le-notify--info1" role="alert">
                      <div class="le-notify__icon-holder">
                        <svg
                          className="le-svg-icon"
                          viewBox="0 0 20 20"
                          id="notification-info"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 19a9 9 0 110-18 9 9 0 010 18zM9 6a1 1 0 112 0 1 1 0 01-2 0zm0 2a1 1 0 000 2v3a1 1 0 100 2h2a1 1 0 100-2V9a1 1 0 00-1-1H9z"
                          ></path>
                        </svg>
                      </div>
                      <div class="le-notify__content-wrapper">
                        <div class="le-notify__content">
                          <div class="le-notify__message">
                            <span style={{fontWeight:'520'}}>
                            Oops! There was an issue with your Invoice. Please provide complete detail and try again.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>:''  }
             {this.state.invoiceViewFlag ? '':  <center>
              <div className="addInvoiceTpArea-heading-left-type-print" >
             
                <ReactToPrint
       removeAfterPrint={true}
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return  <div style={{paddingLeft:'5px',paddingRight:'5px',fontSize:'20px'}}>  <Icon name="print" ></Icon> Print</div>;
          }}
          content={() => this.componentRef}
          /* bodyClass="printScreenddddddddddddddddddddd" */
        />
                  </div>
              </center>}
     {this.state.invoiceViewFlag ?'':<div className="invoice-le-view_area" style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'10px',paddingBottom:'0px'}}>
     <div class="invoice-view__topmessage">
                    <div class="le-notify--info" role="alert">
                      <div class="le-notify__icon-holder">
                        <svg
                          className="le-svg-icon"
                          viewBox="0 0 20 20"
                          id="notification-info"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 19a9 9 0 110-18 9 9 0 010 18zM9 6a1 1 0 112 0 1 1 0 01-2 0zm0 2a1 1 0 000 2v3a1 1 0 100 2h2a1 1 0 100-2V9a1 1 0 00-1-1H9z"
                          ></path>
                        </svg>
                      </div>
                      <div class="le-notify__content-wrapper">
                        <div class="le-notify__content">
                          <div class="le-notify__message">
                            <span>
                              This is a preview of your Quotation. Switch back to
                              Edit if you need to make changes.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
       </div>}         
              {this.state.invoiceViewFlag ?
              <div className="AddInvoiceBottomArea">
                <Accordion >
                  <Card>
                    <Accordion.Toggle
                      style={{ cursor: "pointer" }}
                      as={Card.Header}
                      onClick={() => {
                        this.state.AccordionToggle
                          ? this.setState({ AccordionToggle: false })
                          : this.setState({ AccordionToggle: true });
                      }}
                      eventKey="0"
                    >
                      <div className="InvoiceAccordationHeader">
                        <div>
                          {" "}
                          <i className="	fa fa-edit"></i> Business address and
                          contact details, title, summary, and logo
                        </div>
                        <div>
                          {" "}
                          {this.state.AccordionToggle ? (
                            <i className="fa fa-chevron-up myAccordationInvoice"></i>
                          ) : (
                            <i className="fa fa-chevron-down myAccordationInvoice"></i>
                          )}{" "}
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="row">
                          <div className="col-12 col-lg-8">
                            <div className="accordation-invoice-title-area">
                              <div className="AddInvoiceForm">
                                {/* <div className="row">
                                  <div className="col-lg-10 col-12">
                                    <div className="formInput">
                                      <input
                                        type="text"
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "550",
                                        }}
                                        placeholder="Invoice Title"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-10 col-12">
                                    <div className="formInput">
                                      <input
                                        type="text"
                                        placeholder="Summary (e.g. project name, description of invoice)"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                              <div className="">
                                <p
                                  style={{
                                    fontSize: "22px",
                                    fontWeight: "550",
                                  }}
                                >
                                  Legal Emirates
                                </p>
                                <p
                                  style={{
                                    maxWidth: "60ch",
                                    fontSize: "13px",
                                    fontWeight: "550",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Office No. 3403 - 34th Floor - Burj Al Salam
                                  Tower - Trade Centre 1 - Sheikh Zayed Road -
                                  Dubai - United Arab Emirates - P.O. Box No.
                                  89498.
                                </p>
                                <p
                                  style={{
                                    maxWidth: "40ch",
                                    fontSize: "13px",
                                    fontWeight: "550",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Dubai, Dubai 000000 United Arab Emirates
                                </p>
                                <p
                                  style={{
                                    maxWidth: "40ch",
                                    fontSize: "13px",
                                    fontWeight: "550",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Phone: +9714 2555111
                                </p>
                                <p
                                  style={{
                                    maxWidth: "40ch",
                                    fontSize: "13px",
                                    fontWeight: "550",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Fax: +9714 2368888
                                </p>
                               {/*  <p
                                  style={{
                                    maxWidth: "40ch",
                                    fontSize: "16px",
                                    fontWeight: "550",
                                    marginBottom: "0px",
                                    cursor: "pointer",
                                    color: "black",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Edit your business address and contact details
                                </p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4">
                            <div className="invoice-accordation-right-area">
                              <div>
                                <center>
                                  <img
                                    style={{ maxWidth: "200px" }}
                                    src={require("../../images/logo/logo2.png")}
                                  />
                                {/*   <p
                                    style={{
                                      color: "#666666",
                                      fontWeight: "550",
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      marginTop: "20px",
                                    }}
                                  >
                                    Remove Logo
                                  </p> */}
                                </center>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <div className="invoice-basic-info-detail">
                  <div className="invoice-basic-info-detail-top">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                      <div className="AddInvoiceForm">
                        <div className="row">
                         {/*  <div className="col-lg-10 col-12">
                            <div className="formInput">
                              <p>Invoice Number*</p>
                              <input
                                type="number"
                                placeholder=""
                                defaultValue="151"
                                required
                                 style={{height:'40px'}}
                              />
                            </div>
                          </div> */}
                           <center><p style={{color:'red'}}>{this.state.errMsgUser}</p></center>
                          <div className="col-lg-10 col-12">
                            <div className="formInput">
                            <p>File No*</p>
                            {/* 
        { level: 'Add Customer then Select', value: '' }, */}
                            <select
                                    
                                        className="form-control"
                                        value={this.state.fileNo}
                                        onChange={this.handleChange}
                                        name="fileNo"
                                         style={{height:'40px',backgroundColor:'white',borderRadius:'8px'}}
                                       >
                                        <option selected disabled value=""> Add Client then Select</option>  
                                        {this.state.options1.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                             </select>
                             {this.state.fileError ? <p style={{color:'red'}}>You don't have any Open a File <Link to='/createFile' onClick={this.handleOpenFile} style={{color:'white',backgroundColor:'#666666',paddingLeft:'5px',paddingRight:'5px',borderRadius:'10px'}} >Open File</Link> </p>:''}
                             {this.state.submitError? this.state.fileNo.length<1? <p style={{color:'red'}}>please select File No  </p>:'':''}
                             {this.state.fileLoading?<p style={{color:'red'}}>Loading Please wait...  </p>:''}
                             <p style={{color:'red'}}>{this.state.fileError1}  </p>
                            {/*   <input
                                type="text"
                                placeholder="Add customer then it  will automatically come"
                                value=""
                                required
                                style={{height:'40px'}}
                              /> */}
                            </div>
                          </div>
                          <div className="col-lg-10 col-12">
                            <div className="formInput">
                            <p>Case No</p>
                            {/* 
        { level: 'Add Customer then Select', value: '' }, */}
                            <select
                                    
                                        className="form-control"
                                        ref={(select=>this.caseNo=select)}
                                        
                                        style={{height:'40px',backgroundColor:'white',borderRadius:'8px'}}
                                       >
                                        <option selected disabled value=""> Add Client then Select</option>  
                                        {this.state.options2.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                             </select>
                              <p >{this.state.caseError} </p>
                           
                            {/*   <input
                                type="text"
                                placeholder="Add customer then it  will automatically come"
                                value=""
                                required
                                style={{height:'40px'}}
                              /> */}
                            </div>
                          </div>
                          <div className="col-lg-10 col-12">
                            <div className="formInput">
                            <p>Invoice date*</p>
                              <input
                                type="date"
                                value={this.state.invoiceDate}
                                onChange={this.handleChange}
                                name="invoiceDate"
                                placeholder=""
                                style={{height:'40px',backgroundColor:'white',borderRadius:'8px'}}
                                required
                              />
                              {this.state.submitError? this.state.invoiceDate.length<6? <p style={{color:'red'}}>please select the invoice date  </p>:'':''}
                            </div>
                          </div>
                          <div className="col-lg-10 col-12">
                            <div className="formInput">
                            <p>Payment due<span style={{fontSize:'12px'}}>(On Receipt)</span></p>
                              <input
                                type="date"
                                value={this.state.invoiceDueDate}
                                onChange={this.handleChange}
                                name="invoiceDueDate"
                                placeholder=""
                                required
                                style={{height:'40px',backgroundColor:'white',borderRadius:'8px'}}
                              />
                                   {this.state.submitError? this.state.invoiceDueDate.length<6? <p style={{color:'red'}}>please select the invoice due date  </p>:'':''}
                         
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 invoice-basic-info-detail-top-col">
                        <div className="invoice-basic-info-detail-top-Add-Cutomer" style={{backgroundColor:'white'}}>
                       {this.state.customerAdded ?<div className="customer-addes-area">
                         Name:{this.state.addedCustomer.firstName+' '+this.state.addedCustomer.lastName}<br/>
                         email:{this.state.addedCustomer.email}<br/>
                         Phone Number:{this.state.addedCustomer.phoneNumber}<br/>
                          Address:{this.state.addedCustomer.address}<br/><br/>
                          <center><p onClick={()=>this.setState({customerAdded:false,open:true,searchFlag:false})} style={{fontSize:'14px',textTransform:'capitalize',fontWeight:'520'}} >Choose Different Customer</p></center>
                    
                       </div> : 
                          <p><span style={{fontSize:'20px'}}><i className="fa fa-user-plus"></i> </span>
                          <Dropdown
    text='Add Client'
    floating
    className='icon'
    defaultOpen={this.state.open}
  >
    <Dropdown.Menu>
      <Input icon='search' onChange={this.handleSearch} placeholder="Type Customer Name" onClick={e => e.stopPropagation()} iconPosition='left' name='search' />
     {this.state.errMsgUser.length>0 ? <Dropdown.Item>
        <center><p style={{color:'red'}}>{this.state.errMsgUser}</p></center>
        </Dropdown.Item>:''}
      
      <Dropdown.Header style={{fontSize:'14px',textTransform:'capitalize',fontWeight:'520'}} icon='user outline' content='Existing Customers' />
  
      <Dropdown.Divider />
     {/*  <div  className="dropSown-area-addcutomer"> */}
     <Dropdown.Menu scrolling>
      {this.state.customers.length>0 && this.state.customers.map((item,index)=>{
    
        if(this.state.searchFlag){
       
          var reg45 = new RegExp(this.state.key.split('').join('.*'), 'i');
          if ((item.firstName+' '+item.lastName).match(reg45)){
            return <Dropdown.Item onClick={()=>this.handleCustomer(item)}>
            <span style={{fontSize:'14px',fontWeight:'520'}}>{item.firstName+' '+item.lastName}</span> <br />
        <span  style={{fontSize:'13px',fontWeight:'500'}}>{item.phoneNumber}</span>
          </Dropdown.Item>
          }else{
            if(index===0){
              return   <Dropdown.Item key={index}>
              <span  style={{fontSize:'14px',fontWeight:'520'}}>Not Found</span>
                </Dropdown.Item>
            }
      
          }
        }else{
          return <Dropdown.Item onClick={()=>this.handleCustomer(item)}>
          <span style={{fontSize:'14px',fontWeight:'520'}}>{item.firstName+' '+item.lastName}</span> <br />
      <span  style={{fontSize:'13px',fontWeight:'500'}}>{item.phoneNumber}</span>
        </Dropdown.Item>
        }
     
        
      })}
   
      </Dropdown.Menu>
    
   
    {/*   <Dropdown.Divider />
      <Dropdown.Item
      >
        <center> <p> <center><i className="fa fa-plus-circle"></i> Add Customers</center></p></center>
        </Dropdown.Item> */}
     
    </Dropdown.Menu>
  </Dropdown>
  </p>
                       }
                        </div>

                      </div>
                      
                    </div>
                  </div>

                  <div className="invoice-basic-info-bottom-col-area"  style={{overflowX:'scroll',overflowY:'hidden'}} >
                {/*   <div className="edit-col-area-main">
                  <div className="edit-col-area">
                  <i className="	fa fa-edit"></i> Edit Columns
                  </div>
                </div> */}
                <div>
                <div className="add-invoice-table-area" >
                <table  className="add-invoice-table"   responsive>
            <tr>
                <th><div style={{width:'100px',paddingLeft:'10px'}}><center>Sr.NO</center></div></th>
                <th ><div style={{width:'150px'}}> Expense Type</div></th>
                <th><div style={{width:'380px'}} >Description of Expense</div></th>
                <th><div style={{width:'140px'}} >Expense Image</div></th>
                <th ><div style={{width:'140px'}}>Amount</div></th>
            </tr>
            <tr>
          
            </tr>
            {this.state.itemAdded.length > 0 ? 
            this.state.itemAdded.map((item,index)=>{
              return <tbody key={index}  className="add-invoice-table-items">
              <tr>
            <td ><div  style={{width:'100px'}} /* className="inovice-item-descript-area-title" */><center>{index+1}</center></div></td>
            <td ><div style={{width:'150px'}}>
            <Dropdown
    text={item.serviceType===''?"Select type":item.serviceType}
    floating
    labeled

    className="custom-text-area-addInvoice"

  >
    <Dropdown.Menu >
      <Dropdown.Menu scrolling style={{position:'absolute',minWidth:'150px'}}>
        {this.state.typeOptions.map((option) => (
          <Dropdown.Item onClick={()=>this.handleSeletType(option.value,index,item.id)} key={option.value} {...option} />
        ))}
        {/* serviceTypeError */}
         <Dropdown.Item onClick={this.handleAddServices} style={{cursor:'pointer'}} >
           <center> <i class="fa fa-plus-circle"></i> add Line   </center>
         </Dropdown.Item>
         {this.state.serviceTypeError.length>0 ?
          <Dropdown.Item style={{cursor:'pointer'}} >
          <p style={{color:'red'}}>  {this.state.serviceTypeError} </p>
        </Dropdown.Item>
        :''}
      </Dropdown.Menu>
    </Dropdown.Menu>
  </Dropdown>
              </div></td>
               <td>
                   
                <TextArea onChange={(e)=>this.handleItemDetail(e,item,index)} value={item.detail.length>0 ? item.detail :""} className="custom-text-area-addInvoice" rows={1} placeholder='Enter the Detail of Service' /></td>
                <td>
                  {item.imageUrl.length===0?
                <Dropdown
                          onClick={()=>this.handleUploadImage(item,index)}
                          text='upload image'
   icon='add circle'
   floating
   style={{paddingRight:'1px',fontSize:'12px'}}
   labeled
   button
   className='icon'
  >
  </Dropdown>:<div style={{ color:'green',padding:'5px 10px',border:'1px solid green',borderRadius:'10px',width:'115px'}}>
    <i class="fa fa-check-circle-o"></i> Uploaded
    </div>}
                </td>
                  <td>
                  
                   <Input placeholder='' onChange={(e)=>this.handleItemAmount(e,item,index)} style={{maxWidth:'110px',marginTop:'-5px'}} value={item.amount}  type="number" /> <Icon onClick={()=>{this.handleRemove(item)}}  style={{cursor:'pointer'}} name="trash alternate outline"></Icon>
                   </td>
     
               
                 </tr>

             {/*     <tr>
                   <td>
             <div style={{width:'100px',paddingLeft:'10px'}} > <center> --</center></div>
                   </td>
                   <td></td>
                   <td>  <Checkbox id={item.id} onChange={(e)=>{this.handleVat(item,index,e)}} defaultChecked={item.vat} label='VAT Tax 5%' /></td>
            <td>{item.vat?<center>  {item.vatAmount.toFixed(2)} AED </center>:''}</td>
                 </tr> */}

                 </tbody>
            })
             
            :''}
          
        
            
           
            </table>
         
           
                </div>
                <div className="dont-have-Item-add-invoice">
            <p> <center>
                          <Dropdown
                          onClick={this.handleAddItem}
                          text='Add Expense'
   icon='add circle'
   floating
   labeled
   button
   className='icon'
  >
  </Dropdown>
  </center>
  </p>
            </div>
            </div>
                <div className="add-invocie-sub-total-area">
                  <div className="add-invocie-sub-total-area-box">
                 <div className="add-invocie-sub-total-area-box-left">
                 <Label basic color='#666666'  pointing='right'>
                 Subtotal
                  </Label>    <br />
                  <br />
            <div>
              <Label basic color='#666666'   pointing='right'>
              <Checkbox readOnly  /* onChange={(e)=>this.handleVat(e,"4")} */ defaultChecked={true}  label='VAT Tax 5%' />
                  </Label>    <br />  <br />
              </div>
                 
                 <span style={{fontWeight:'550'}} className="add-invocie-sub-total-area-box-left-data"> <span style={{marginTop:'29px'}}>    <Label basic color='#666666'  pointing='right'>
                 Total Amount
                  </Label>  </span> 
                        </span>
                 </div>
                 <div className="add-invocie-sub-total-area-box-right">
                 <Label as='a' color='' tag>
                 {new Intl.NumberFormat().format(this.state.subTotal.toFixed(2))}     AED
               </Label>  <br /><br />
           <div>  <Label as='a' color='' tag>
           {new Intl.NumberFormat().format(this.state.vatTotal.toFixed(2))}   AED
               </Label>  <br /><br /><br/></div>
                  <span style={{fontWeight:'550'}}>   <Label as='a' color='' tag>
                  {new Intl.NumberFormat().format(this.state.total.toFixed(2))} AED
               </Label> </span>
                 </div>

                  </div>
                </div>

                <div className="termsandCondtions-addInvoice-area">
                  <p style={{fontSize:'14px',fontWeight:'550'}}>
                  <Label basic color='red'  pointing='below'>
                  Notes / Terms
                  </Label>
                  </p>
                  <p>terms & Conditions</p>
                  <ol>
                    <li>Kindly retain the original computer generated receipt for any upcoming claims. If original receipt is lost, no claims or reprint of the receipt will be entertained.</li>
                    <li>If no claims or objections are made within the previously mentioned period in clause, then it shall be deemed as an approval of consumer in relation to the provided transaction and their values.</li>
                    <li>	Documents for the transactions done to be forwarded along with the original invoice.</li>
                    <li>	The maximum liability in the event of any claims or objections is limited to the value of the claimed transactions, which is/are mentioned in the invoice.</li>
                    <li>	BADER HAMAD AL ZAABI ADVOCATES & LEGAL CONSULTANTS reserve the right to review and aimed the above terms from time as it judges fit, and as per the applicable laws.</li>
                  </ol>

                </div>
                <br />
                  </div>

                 

                </div>
                <div className="AddInvoiceForm">
                        <div className="row">
                          <div className="col-12">
                            <div className="formInput">
                              <textarea
                                className="form-control"
                                value={this.state.invoiceRemarks}
                                onChange={this.handleChange}
                                name="invoiceRemarks"
                                placeholder="Give Your Remarks*"
                                style={{height:'100px',backgroundColor:'white'}}
                              />
                         
                           {this.state.submitError? this.state.invoiceRemarks.length<1? <p style={{color:'red'}}>please give your Remarks </p>:'':''}
                       
                            </div>
                          </div>
                         </div>
                         </div> 
                         <div className="addInvoiceTpArea">
                <div className="addInvoiceTpArea-heading-left">
                  <p> </p>
                </div>
                <div className="addInvoiceTpArea-heading-right">
                 <div style={{color:'red'}} >{this.state.createFileError}</div> 
                {this.state.loaderFlag? <div className="loader"></div>:''}
                  
                  <div className="addInvoiceTpArea-heading-left-type" onClick={this.hanldePreviewButton}>
                   {this.state.previewFlag? <div style={{paddingLeft:'13px',paddingRight:'13px'}}>Edit</div>:'Preview'} 
                   
                  </div>
                  <div  style={{ marginLeft: "10px" }}>
                  <Button.Group color='black'>
    <Button onClick={this.hanldeSaveQuotaion}  style={{borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px',marginRight:'-2px'}}>  Save & continue</Button>
    <Dropdown
      className='button icon'
      style={{borderTopRightRadius:'20px',borderBottomRightRadius:'20px',borderLeft:'1px solid dimgray'}}
      floating
      options={options}
      trigger={<React.Fragment />}
    />
  </Button.Group>
                  </div>
                </div>
              </div>
              </div>
                  : <div style={{overflow:'auto'}}  ><QuotationView data={{fileNo:this.state.fileNo,customer:this.state.addedCustomer,item:this.state.itemAdded,invoiceDate:this.state.invoiceDate,invoiceDueDate:this.state.invoiceDueDate,total:this.state.total,subTotal:this.state.subTotal,vatTotal:this.state.vatTotal,invoiceRemarks:this.state.invoiceRemarks}}  ref={el => (this.componentRef = el)} /></div>}
            </div>
          </div>
      
        </div>
        <Modal
       /*  trigger={<Button onClick={this.handleOpen}>Show Modal</Button>} */
        open={this.state.modalOpen}
      
        basic
        size='small'
        centered
       /*  style={{display:'flex',justifyContent:'center',alignItem:'center'}} */
      >
        <Header icon='browser' content='Congratulations' />
        <Modal.Content >
          <p style={{color:'white',fontSize:'22px'}}>You have Successfully Added Expense thanks!</p>
        </Modal.Content>
        <Modal.Actions>
        <Button color='Standard' onClick={this.handlePrintInvoice} inverted>
            <Icon name='print' /> View & Print
          </Button>
          <Button color='green' onClick={this.handleSuccess} inverted>
            <Icon name='checkmark' /> Got it
          </Button>
        </Modal.Actions>
      </Modal>
            
      <Modal1 show={this.state.show1} /* onHide={this.handleClose1} */ size="sm" centered  >
      <Modal1.Header closeButton>
          <Modal1.Title className="heading-modal"
          style={{textAlign:'left',fontWeight:'550'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/addtype.png')} /> Add Type
          </Modal1.Title>
        </Modal1.Header>
        <Modal1.Body style={{paddingBottom:'0px'}}>

          {this.state.serviceTypeAddSuccess? <div className="sent-message-area" style={{paddingTop:'10px',paddingBottom:'10px'}}>
             <center>
                 <p><i style={{fontSize:'120px'}} class="fa fa fa-check-circle-o"></i></p>
                 <h2>thank you!</h2>
                 <p>You Have Successfully added the Service Type.</p>
                 <button className="sent-button-des" onClick={this.handleClose1}>Ok</button>
             </center>
            </div> :
             <div className="sent-message-area" style={{paddingTop:'10px',paddingBottom:'10px'}}>
               <p style={{fontWeight:'550'}}>Add the Service Type name here </p>
             <center>
               <div className="record-a-payment-form-area">
               <Form onSubmit={this.handleAddServiceSubmit}>
<Form.Group as={Row} controlId="formHorizontalEmail">
  <Form.Label column sm={3} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
    Type 
  </Form.Label>
  <Col sm={9}>
    <Form.Control required ref={(input=>this.typeName=input)} placeholder="Enter Service Type" type="text" />
  </Col>
</Form.Group>


<Form.Group >
  <p style={{color:'red',fontWeight:'550'}}>{this.state.serviceTypeAddError}</p>
{this.state.loaderFlag? 
<div > <div className="loader"></div>
<p>Please Wait...</p></div>
:''}
  <div className="bottom-button-area-recored-paymenfomr">
    <div></div>
    <div>
      
      <button onClick={()=>this.setState({show1:false})} className="bottom-button-area-recored-paymenfomr-cancel">
        Cancel
      </button>
      <button  type={"submit"} className="bottom-button-area-recored-paymenfomr-submit">Submit</button>
    </div>
  </div>
</Form.Group>
</Form>
               </div>
             </center>
            </div>
            } 
           
         
        </Modal1.Body>
    
      </Modal1> 
      <Modal1 show={this.state.show} onHide={this.handleCloses} size="sm" centered  >
      {this.state.BankReceiptSuccess? null:
        <Modal1.Header closeButton>
          <Modal1.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Upload Service Image
          </Modal1.Title>
        </Modal1.Header>
}
      <Modal1.Body>
      {this.state.BankReceiptSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="Bank Receipt of this Invoice is Successfuly Uploaded"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleCloses} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
    
  <br />
  <br />
       <div className="upload-photo-area">
                     <div>
          <center>{this.state.preview.length>0? <embed width="150" height="60" name="plugin" src={this.state.preview}  />:<Icon style={{marginTop:'30px'}} name="upload" size="huge" />}
                    
                     <div className="image-upload">
                                    <label for="file-input2">
                                    <p style={{fontSize:'12px',cursor:'pointer',fontWeight:'550'}}>Select Service Image</p>
                                    </label>
                                    <br/>
                                    <div /* onClick={this.handlePhoto} */ onClick={()=>this.setState({show6:true})} style={{color:'#666666',fontWeight:'550'}}><Icon name="camera" /> Take Photo</div>

                                    <input id="file-input2"  name="photo15" type="file" accept=".png,.jpg,.jpeg,.pdf"    onChange={this.handleFileChange} />
                                  </div>
                                  </center>
                    </div>    
                   
            </div>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag3? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}>Uploading Please wait....</p>
            </center>:''}
     {this.state.ServiceImageError.length>0?<Alert message={this.state.ServiceImageError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal1.Body>
        {this.state.BankReceiptSuccess?null:
        <Modal1.Footer>
        <Button.Group>
    <Button onClick={this.handleCloses}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleServiceImageSumbit} color='black'>Upload</Button>
  </Button.Group>
        </Modal1.Footer> }
    
      </Modal1> 
      <Modal1 show={this.state.show6} onHide={this.handleClose6} size="xl" centered  >
     
     <Modal1.Header closeButton>
       <Modal1.Title 
       style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
    <img style={{width:'30px'}} src={require('../../images/profile/camera.png')} /> Camera
       </Modal1.Title>
     </Modal1.Header>
     <button onClick={this.handleCapturePhoto}>Capture photo</button>
     <Webcam
     audio={false}
     height={640}
     ref={this.webcamRef}
     screenshotFormat="image/jpeg"
     width={'100%'}
     videoConstraints={{
       width: '100%',
       height: 720,
       facingMode: "user"
     }}
   />
 
  {/*  <Modal.Body>
   <video id="video" width="640" height="480" className="cameraFrame" src={this.state.videoSrc} autoPlay="true"
     ref={(input) => { this.videoElement = input; }}></video>
     <center><Button onClick={this.handleCapture}>Capture Photo</Button></center>
     </Modal.Body> */}
     
 
   </Modal1> 
 

      </div>
    );
  }
}
const mapStateToProps=(state)=> {
    return {
      getUserCreateInvoceReducer: state.AccountantExpenseAddition1.getUserCreateInvoceReducer,
      getUserCreateInvoceFilesReducer:state.AccountantExpenseAddition1.getUserCreateInvoceFilesReducer,
      addExpenseReducer:state.AccountantExpenseAddition1.addExpenseReducer,
      getServicesTypeReducer:state.AccountantExpenseAddition1.getServicesTypeReducer,
      addServicesTypeReducer:state.AccountantExpenseAddition1.addServicesTypeReducer
    };
  } 
export default compose(
  withReducer("AccountantExpenseAddition1", reducer),
  connect(mapStateToProps),withRouter
)(AccountantExpenseAddition); 
