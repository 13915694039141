import React, { Fragment } from 'react'
import AdminSignINArea from '../../../components/adminSignIn'
import './style.scss'
const AdminSignIn = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            
           
           <AdminSignINArea
           className="AdminSignInArea"
            />
        </Fragment>
    )
}
export default AdminSignIn