


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../sideBar/style.scss';
import '../sideBar/style.css';
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import {Form,ButtonToolbar, Button,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { Header, Table, Rating,Icon } from 'semantic-ui-react'
import { message } from 'antd';
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
var moment = require('moment');
 class  CustomersAll extends Component{
      constructor(props, context) {
        super(props, context);
        this.state = {
          show:false,
          limit:10,
          page:1,
          userId:undefined,
          totalPages:11,
          usersData:[],
          pageNumber:1,
          options:[],
          show:false,
          updateUser:{},
          loaderFlag:false,
          updateFlag:false,
          direction:null,
          column:null
    } 
    }
    handleClose=()=> {
      this.setState({ show: false,loaderFlag:false });
    }
  
    handleShow=()=> {
      this.setState({ show: true });
    }
componentWillMount(){
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  page:this.state.page
}
this.props.dispatch(Actions.getAllCustomers(obj))
this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllCustomers */
}  
handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,usersData:[],page:1})
  let obj={
    userId:this.state.userId,
    limit:e.target.value,
    page:1
  }
  this.props.dispatch(Actions.getAllCustomers(obj))

} 
handleJumpPage=(e)=>{
  this.setState({pageNumber:e.target.value,usersData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    page:e.target.value
  }
  this.props.dispatch(Actions.getAllCustomers(obj))
 
}
handlePageInc=()=>{
  if(this.state.totalPages>this.state.pageNumber){
  this.setState((prevState, props) => ({
    pageNumber: prevState.pageNumber + 1
}));
this.setState({usersData:[]})
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  page:this.state.pageNumber+1
}
this.props.dispatch(Actions.getAllCustomers(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNumber>1){
    this.setState({usersData:[]})
    this.setState((prevState, props) => ({
      pageNumber: prevState.pageNumber - 1
  }));
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    page:this.state.pageNumber-1
  }
  this.props.dispatch(Actions.getAllCustomers(obj))
  }

}
/* allCustomerReducer */ 
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.allCustomerReducer;

  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.users.docs.length>0){
        this.setState({usersData:resNumber1.data.users.docs,totalPages:resNumber1.data.users.totalPages})
      }else{
        this.setState({usersData:["nothing"]})
      }
   
    } else {
    this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
    }
  }
  let resNumber2 =nextProps.fileSearchReducer;

  
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }

  let resNumber55 =nextProps.updareCustomerReducer;

  
  if (!resNumber55.isLoading) {
    if (resNumber55.errMsg == null && !(resNumber55.data===undefined)) {
      if(this.state.updateFlag){
        this.setState({show:false,updateFlag:false,loaderFlag:false})
        message.success(`Customer Updated Succesfully!`);
      }

    } else {
    this.setState({loaderFlag:false,updateError:resNumber55.errMsg})
    }
  }
}
/* fileSearchReducer */
handleSearch=(e)=>{
 
if(e.length>0){
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered}) 
this.props.dispatch(Actions.getSeachedFiles(e))
}else{
   
  this.setState({usersData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    page:this.state.page
  }
  this.props.dispatch(Actions.getAllCustomers(obj))

}
}
handleSelect=(e,value)=>{
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered,usersData:[]}) 
  let obj={
    userId:value.data._id,
    limit:this.state.limit,
    page:this.state.page
  }
  this.props.dispatch(Actions.getAllCustomers(obj))

}
handleUpdateCustomer=(e)=>{
  e.preventDefault();
  this.setState({loaderFlag:true,updateFlag:true})
  let obj={
      firstName:this.firstName.value,
      lastName:this.lastName.value,
      refNo:this.refNo.value,
      phoneNumber:this.phoneNumber.value,
      email:this.email.value,
      userId:this.state.updateUser._id
  }
  this.props.dispatch(Actions.upadateCustomer(obj))
  setTimeout(()=>{
    let obj={
      userId:this.state.userId,
      limit:this.state.limit,
      page:this.state.page
    }
    this.props.dispatch(Actions.getAllCustomers(obj))
  },1000)
  setTimeout(()=>{
    let obj={
      userId:this.state.userId,
      limit:this.state.limit,
      page:this.state.page
    }
    this.props.dispatch(Actions.getAllCustomers(obj))
  },2000)
}
handleUpadte=(item)=>{
  this.setState({updateUser:item,show:true})

 
}

handleSort=(action)=>{
  switch (action.type) {
    case 'CHANGE_SORT':
      this.setState({column:action.column})
      this.setState((state)=>({
        direction:state.direction === 'ascending' ? 'descending' : 'ascending'
      }))
      this.setState((state)=>({
        usersData:state.usersData.reverse()
      }))
    default:
      
  }
  }
  render(){
    return (
        <div className="admin-cutomer-profile-area" >
        <div className="receipnest-file-customer-files-area">
        <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
           
           <div  className="admin-cutomer-REcepnest-files-rea">
           <div className="receipnest-file-customer-files-area-top-heading">
                 <p style={{fontSize:'16px',fontWeight:'550',marginTop:'25px'}}> All Customers</p>
                </div>
                <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>
               
  
            </div>
            <div className="receipnest-file-customer-files-area655-bottom-area" style={{overflow:'auto',display:'block'}} >
                          <div className="session-table-area-receptionist">
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
       width={3} singleLine textAlign='left'>Customer</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'DateofBirth' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'DateofBirth' })}
        singleLine textAlign='center'>Date Of Birth</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'email' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'email' })}
        singleLine textAlign='center'>Email</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Contact' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Contact' })}
        singleLine textAlign='center'>Contact</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'RefNo' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'RefNo' })}
        singleLine textAlign='center'>Ref No</Table.HeaderCell>
      
        <Table.HeaderCell singleLine textAlign='center'>Action</Table.HeaderCell>
     
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.usersData.length>0 ?
                  this.state.usersData[0]!="nothing"?
                this.state.usersData.map((item)=>{
                return  <Table.Row>
                <Table.Cell  singleLine textAlign='left'>
                <img style={{width:'30px',height:'30px',borderRadius:'50%',marginRight:'5px'}} src={item.profileImage?item.profileImage:require('../../images/profiled1.png')} />{item.firstName+" "+item.lastName}
              
                </Table.Cell>
                <Table.Cell textAlign='center' singleLine>{moment(item.dob).format('MMMM Do YYYY')}</Table.Cell>
                <Table.Cell textAlign='center'>
                {item.email}
              
                </Table.Cell>
                <Table.Cell textAlign='center'>
              {item.phoneNumber}
              
                </Table.Cell>
                <Table.Cell textAlign='center' >

                {item.refNo}
                </Table.Cell>
                <Table.Cell textAlign='center'>
<div style={{color:'#666666',fontSize:'16px',cursor:'pointer'}} onClick={()=>this.handleUpadte(item)} className=""><i  class="fa fa-edit"></i></div>

                </Table.Cell>
               
              </Table.Row>
                })
                :<Table.Row>
                     <Table.Cell colSpan='6'
 textAlign='center'>
                    <center>
                  <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                  No Customer Exist Yet!
                  </div>
                  </center>    
                </Table.Cell>
                </Table.Row>:<Table.Row>
                     <Table.Cell  textAlign='center' colSpan='6'  
 textAlign='center'>
                     <center>
               <div className="loader-for-tabel-invoce">
               <div className="loader1188">
                </div> 
               </div>
               </center>  
                </Table.Cell>
                </Table.Row>}
     
    
     
    </Table.Body>
  </Table>

          </div>
          
           
            </div>
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per page
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNumber} of {this.state.totalPages} <span className="pagination-table-quotaion-dec"><i title="Previous Page" onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} title="Next Page" className="fa fa-caret-right"></i></span>
              </span>
            jump to page  <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
            </div> 
           
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} size="sm" centered  >
        <Modal.Header closeButton>
            <Modal.Title className="heading-modal"
            style={{textAlign:'left',fontWeight:'550'}} >
         <img style={{width:'30px'}} src={require('../../images/about/ade-01.png')} /> Update Customer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

               <div className="sent-message-area" style={{paddingTop:'10px',paddingBottom:'10px'}}>
         
                 <div className="record-a-payment-form-area">
                 <Form onSubmit={this.handleUpdateCustomer}>
                 <Form.Group controlId="formBasicEmail">
    <Form.Label style={{textAlign:'left',fontSize:'13px',fontWeight:'550'}}>First Name</Form.Label>
    <Form.Control  required type="text" ref={(input)=>this.firstName=input} defaultValue={this.state.updateUser.firstName} placeholder="Muhammad" />
  
  </Form.Group>
  <Form.Group controlId="formBasicEmail">
    <Form.Label style={{textAlign:'left',fontSize:'13px',fontWeight:'550'}}>Last Name</Form.Label>
    <Form.Control  required type="text" ref={(input)=>this.lastName=input} defaultValue={this.state.updateUser.lastName} placeholder="Tahir" />
  
  </Form.Group>
  <Form.Group controlId="formBasicEmail">
    <Form.Label style={{textAlign:'left',fontSize:'13px',fontWeight:'550'}}>Phone No</Form.Label>
    <Form.Control  required type="text" ref={(input)=>this.phoneNumber=input} defaultValue={this.state.updateUser.phoneNumber} placeholder="+97155888265" />
  
  </Form.Group>
  <Form.Group controlId="formBasicEmail">
    <Form.Label style={{textAlign:'left',fontSize:'13px',fontWeight:'550'}}>Reference No</Form.Label>
    <Form.Control  required type="text" ref={(input)=>this.refNo=input} defaultValue={this.state.updateUser.refNo}  />
  
  </Form.Group>
  <Form.Group controlId="formBasicEmail">
    <Form.Label style={{textAlign:'left',fontSize:'13px',fontWeight:'550'}}>Email</Form.Label>
    <Form.Control required type="text" ref={(input)=>this.email=input} defaultValue={this.state.updateUser.email} placeholder="tahir@wowtes.com" />
  
  </Form.Group>
 

  <Form.Group >
    <p style={{color:'red',fontWeight:'550'}}>{this.state.updateError}</p>
 <center>
 {this.state.loaderFlag? 
  <div > <div className="loader"></div>
  <p>Please Wait...</p></div>
  :''}
 </center>
    <div className="bottom-button-area-recored-paymenfomr">
      <div></div>
      <div>
        
        <button onClick={()=>this.setState({show:false})} className="bottom-button-area-recored-paymenfomr-cancel">
          Cancel
        </button>
        <button  type={"submit"} className="bottom-button-area-recored-paymenfomr-submit">Update</button>
      </div>
    </div>
  </Form.Group>
</Form>
                 </div>
              </div>
             
           
          </Modal.Body>
      
        </Modal> 



        </div>
    )
  }
}
  const mapStateToProps=(state)=> {
    return {
        allCustomerReducer: state.CustomersAll1.allCustomerReducer,
        fileSearchReducer:state.CustomersAll1.fileSearchReducer,
        updareCustomerReducer:state.CustomersAll1.updareCustomerReducer
    };
  } 
export default compose(
  withReducer("CustomersAll1", reducer),
  connect(mapStateToProps),withRouter
)(CustomersAll); 