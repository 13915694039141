import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import AccountantInvoiceUpdateArea from '../../../components/updateInvoice'
import './style.scss'
const UpdateAccountantInvoicePage = () => {

    return (
        <Fragment>
            
           <AccountantInvoiceUpdateArea
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default UpdateAccountantInvoicePage
/* UpdateAccountantInvoicePage */