import React, { useEffect, useState, Component } from "react";
import { NavLink, Link,withRouter } from "react-router-dom";

import "./style.css";
/* import "./style.css"; */
import "./formStyle.scss";
import "./style.scss";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import ReactToPrint from 'react-to-print';

import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Button,Modal,Header } from 'semantic-ui-react'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";

import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import ExpenseTopAreaPreview from './expensePreviewTop'
import Invoiceview from './expenseView';
import './newnooneinvoice.scss'

import { ToastContainer, toast } from 'react-toastify';
import {
  Form,
  ButtonToolbar,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
  Accordion,
  Card,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

class ExpensePreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.toastId=React.createRef(null);
  }
  
  render() {
 /*    console.log(this.state.customers,"lllllllllllllllllllll") */

    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
      
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "300px",overflow:'auto' }}
          >
            <ExpenseTopAreaPreview />
          </div>
      
        </div>
    
      </div>
    );
  }
}
  const mapStateToProps=(state)=> {
    return {
 /*      getUserCreateInvoceReducer: state.AccountantInvoicesAddition1.getUserCreateInvoceReducer,
      getUserCreateInvoceFilesReducer:state.AccountantInvoicesAddition1.getUserCreateInvoceFilesReducer,
      creatInvoiceReducer:state.AccountantInvoicesAddition1.creatInvoiceReducer */
    };
  } 
export default compose(
  withReducer("ExpensePreview1", reducer),
  connect(mapStateToProps),withRouter
)(ExpensePreview); 
