import React, { Component, useEffect } from "react";

import "./newnooneinvoice.scss";
// import Invoiceview from './invoiceview';
import {
  Dropdown,
  Input,
  Label,
  Icon,
  Checkbox,
  TextArea,
  Button,
  Header,
  Image,
  Divider,
} from "semantic-ui-react";
import ReactToPrint from "react-to-print";
import ModalImage from "react-modal-image";
/*  import html2canvas from 'html2canvas';

import jsPDF from 'jspdf'; */
/* import { Visible, Hidden, setConfiguration } from "react-grid-system"; */
/* import ReactToPrint from 'react-to-print-advanced'; */
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Media,
  Modal,
  ButtonGroup,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  withRouter,
  browserhistory,
} from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { Popover, DatePicker, Popconfirm } from "antd";

import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
import Invoiceview from "./invoiceview";

var moment = require("moment");
const options = [
  { key: "save", icon: "save", text: "Save and Send", value: "Save and Send" },
  {
    key: "delete",
    icon: "credit card outline",
    text: "Save and Record A Payment",
    value: "Save and Record A Payment",
  },
];
class InvoiceTopAreaPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      invoiceData: {},
      open: false,
      show: false,
      sendInvoiceLoading: false,
      sendInvoiceSuccess: false,
      sendInvoiceErrorFlag: false,
      sendInvoiceFlag: false,
      sendInvoiceError: "",
      show1: false,
      loaderFlag: false,
      recordPaymentSuccess: false,
      recoredPaymentError: "",
      recordPaymentFlag: false,
      recordPaymentFlagError: false,
      recordPaymentType: "",
      preview1: "",
      photo1: "",
    };
    this.ref = React.createRef();
  }
  /* /* 
sendInvoiceErrorFlag
sendInvoiceFlag
sendInvoiceError
*/
  /* 
sendInvoiceLoading ?'':
            this.state.sendInvoiceSuccess
*/
  handleClose = () => {
    this.setState({
      show: false,
      sendInvoiceError: "",
      sendInvoiceErrorFlag: false,
      sendInvoiceLoading: false,
    });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose1 = () => {
    this.setState({
      show1: false,
      loaderFlag: false,
      recoredPaymentError: "",
      recordPaymentSuccess: false,
    });
  };

  handleShow1 = () => {
    this.setState({ show1: true });
  };
  //   open = () => this.setState({ open: true })
  //   close = () => this.setState({ open: false })

  show = (dimmer) => () => this.setState({ dimmer, open: true });

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true });
  };

  close = () =>
    this.setState({ open: false, opentwo: false, openthree: false });

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }
  /* sendInvoiceToCustomer */

  handleSendInvoice = () => {
    this.setState({
      show: true,
      sendInvoiceLoading: true,
      sendInvoiceError: false,
      sendInvoiceSuccess: false,
      sendInvoiceErrorFlag: true,
      sendInvoiceFlag: true,
    });
    let obj = {
      invoiceId: this.state.invoiceData._id,
    };
    this.props.dispatch(Actions.sendInvoiceToCustomer(obj));
    setTimeout(() => {
      this.props.dispatch(Actions.getUserInvoice(localStorage.invoiceId));
    }, 1000);
  };
  componentWillReceiveProps(nextProps) {
    let resNumber4 = nextProps.getUserInvoiceReducer;
    if (!resNumber4.isLoading) {
      if (resNumber4.errMsg == null && !(resNumber4.data === undefined)) {
        this.setState({ invoiceData: resNumber4.data.invoice });
      } else {
        /*  this.setState({createFileError:resNumber4.errMsg,loaderFlag:false,invoiceFlag78:false})  
     console.log(resNumber4.errMsg) */
      }
    }

    let resNumber5 = nextProps.sendInvoiceEmailReducer;
    if (!resNumber5.isLoading) {
      if (resNumber5.errMsg == null && !(resNumber5.data === undefined)) {
        if (this.state.sendInvoiceFlag) {
          this.setState({
            sendInvoiceLoading: false,
            sendInvoiceSuccess: true,
            sendInvoiceErrorFlag: false,
            sendInvoiceFlag: false,
          });
        }
      } else {
        if (this.state.sendInvoiceErrorFlag) {
          this.setState({
            sendInvoiceLoading: false,
            sendInvoiceSuccess: false,
            sendInvoiceErrorFlag: false,
            sendInvoiceError: resNumber5.errMsg,
          });
        }
      }
    }

    /* RecoredUserPaymentReducer */
    let resNumber6 = nextProps.RecoredUserPaymentReducer;
    if (!resNumber6.isLoading) {
      if (resNumber6.errMsg == null && !(resNumber6.data === undefined)) {
        if (this.state.recordPaymentFlag) {
          this.setState({
            loaderFlag: false,
            recordPaymentSuccess: true,
            recordPaymentFlagError: false,
            recordPaymentFlag: false,
          });
        }
      } else {
        if (this.state.recordPaymentFlagError) {
          this.setState({
            loaderFlag: false,
            recordPaymentSuccess: false,
            recordPaymentFlagError: false,
            recoredPaymentError: resNumber6.errMsg,
          });
        }
      }
    }
  }
  handleRecordPayment = () => {
    this.setState({ show1: true });
  };
  handleRecordPaymentSubmit = (e) => {
    e.preventDefault();
    if (this.state.recordPaymentType === "CHEQUE") {
      if(this.state.photo1){
        this.setState({loaderFlag:true,recordPaymentFlag:true,recordPaymentFlagError:true})
        let amount=this.amountPaid.value;
        let memo=this.memo.value;
 
      let  Data=  new FormData();
        
      Data.append('invoiceId',this.state.invoiceData._id) 
      Data.append('paidAmount',amount) 
      Data.append('chequeImage',this.state.photo1) 
      Data.append('userId',this.state.invoiceData.userId) 
      Data.append('notes',memo) 
      Data.append('paymentType',"CHEQUE") 
      Data.append('chequeNumber',this.checkNumber.value) 
      Data.append('expiry',new Date(this.checkDate.value).toISOString()) 
      this.props.dispatch(Actions.recordPaymentCustomer(Data))
       }else{
     this.setState({recoredPaymentError:'Please select the Cheque image  First'})
       }
  
    } else {
      this.setState({loaderFlag:true,recordPaymentFlag:true,recordPaymentFlagError:true})
      let amount=this.amountPaid.value;
      let memo=this.memo.value;
   /*    let obj={
        invoiceId:this.state.invoiceData._id,
        paidAmount:amount,
        userId:this.state.invoiceData.userId,
        notes:memo,
        paymentType:"CASH"
    } */
    let  Data=  new FormData();
      
    Data.append('invoiceId',this.state.invoiceData._id) 
    Data.append('paidAmount',amount) 
    Data.append('userId',this.state.invoiceData.userId) 
    Data.append('notes',memo) 
    Data.append('paymentType',this.state.recordPaymentType) 
    this.props.dispatch(Actions.recordPaymentCustomer(Data))
    }
    setTimeout(() => {
      this.props.dispatch(Actions.getUserInvoice(localStorage.invoiceId));
    }, 1500);
    setTimeout(() => {
      this.props.dispatch(Actions.getUserInvoice(localStorage.invoiceId));
    }, 2500);
  };
  handleFileChange1 = (e) => {
    this.setState({
      photo1: e.target.files[0],
      BankReceiptError: "",
    });

    var reader = new FileReader();
    var url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      this.setState({
        preview1: [reader.result],
      });
    };
  };

  render() {
    const {
      open,
      closeOnEscape,
      closeOnDimmerClick,
      dimmer,
      opentwo,
      openthree,
    } = this.state;
    return (
      <div>
        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} mg={12}>
                <div className="invoice-payment-voucher-view-area">
                  <div
                    className="new-invoice-payment-voucher-header-text-area"
                    role="alert"
                  >
                    <h2>
                      Invoice#{" "}
                      {this.state.invoiceData.invoiceNo &&
                        this.state.invoiceData.invoiceNo}
                    </h2>

                    <div className="two-button-edi-save-new-invoice-payment-vouche">
                      <Button.Group color="black">
                        <Button
                          onClick={() => this.props.history.push("/addInvoice")}
                          style={{
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                            marginRight: "-2px",
                          }}
                        >
                          {" "}
                          Create another invoice
                        </Button>
                        <Dropdown
                          className="button icon"
                          style={{
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                            borderLeft: "1px solid dimgray",
                          }}
                          floating
                          /*   options={options} */
                          /* onClick={this.handleDropDown} */
                          trigger={<React.Fragment />}
                        />
                      </Button.Group>
                    </div>
                  </div>

                  <div></div>

                  <div className="contemporary-template__divider--full-width">
                    <div className="le-divider-top-invoiceone"></div>
                  </div>

                  <div class="invoice-preview__warning-message le-text--body"></div>

                  <div className="invoice-previe-div-body-le">
                    <div className="invoice-contemplary-body-inside">
                      <Container>
                        <div className="status-cust-invoice">
                          <div>
                            <div className="status-cust-headings-invoice">
                              Status
                            </div>
                            <p className="status-cust-draft-invoice">Saved</p>
                          </div>

                          <div>
                            <div className="status-cust-headings-invoice">
                              Customer
                            </div>
                            <p className="status-cust-name-invoice">
                              {this.state.invoiceData.userProfile &&
                                this.state.invoiceData.userProfile.firstName +
                                  " " +
                                  this.state.invoiceData.userProfile.lastName}
                              <span>
                                <i
                                  class="fa fa-exclamation-circle exclamation-icon"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </p>
                          </div>

                          <div>
                            <div className="status-cust-headings-invoice">
                              Amount due
                            </div>
                            <p
                              style={{ fontSize: "17px" }}
                              className="status-cust-amount-invoice"
                            >
                              AED{" "}
                              {this.state.invoiceData.amountToBePaid &&
                                new Intl.NumberFormat().format(
                                  this.state.invoiceData.amountToBePaid
                                )}
                            </p>
                          </div>

                          <div>
                            <div className="status-cust-headings-invoice">
                              Due Date
                            </div>
                            <p
                              style={{ fontSize: "16px" }}
                              className="status-cust-dueday-invoice"
                            >
                              {this.state.invoiceData.generatedAt &&
                                moment(
                                  this.state.invoiceData.generatedAt
                                ).format("MMMM Do YYYY")}
                            </p>
                          </div>
                        </div>
                      </Container>

                      <div className="invoice-previe-div-body-le-small-box">
                        <div className="invoice-contemplary-body-inside-small-box">
                          <Row>
                            <Col lg={12} md={12} xl={12}>
                              {/* <div style={{marginTop:'2rem'}}>
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <img src="IMAGES/circle-doc.png"></img>
                              <div style={{marginTop:'2rem'}}>
                                Creative Invoice
                              </div>

                            </div>

                          </div> */}
                              <div className="invoice-one-border-box">
                                <Media
                                  as="li"
                                  className="media-ride-list-invoice-small-box"
                                >
                                  <div className="list-steps-of-ride-invoice-small-box">
                                    <div className="invoice-contemplary-body-inside__header__logo-small-box">
                                      <img src={require("./circle-doc.png")} />
                                    </div>
                                  </div>
                                  <Media.Body>
                                    <div className="contemporary-template__header__info-small-box">
                                      <div className="le-heading--title-small-box">
                                        Invoice Created
                                      </div>
                                      <strong className="le-text--strong-small-box">
                                        Created:{" "}
                                        <span style={{ color: "#666666" }}>
                                          on{" "}
                                          {this.state.invoiceData.generatedAt &&
                                            moment(
                                              this.state.invoiceData.generatedAt
                                            ).format("MMMM Do YYYY")}
                                        </span>
                                      </strong>
                                      <div class="address__field"></div>
                                      <div class="address__field"></div>
                                    </div>
                                  </Media.Body>
                                </Media>

                                {/* </Col>


      <Col lg={6} md={6} xl={6}> */}

                                <div>
                                  {/*       <Button className="le-newinvoice-payment-voucher-edit-button" variant="dark">
                  Edit
            </Button> */}

                                  <Button
                                    /* onClick={this.handleExportPDf} */ className="le-newinvoice-payment-voucher-edit-button"
                                    variant="outline-grey"
                                  >
                                    <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Export As PDF</div>;
                                      }}
                                      content={() => this.componentRef}
                                    />
                                  </Button>

                                  <Button
                                    className="le-newinvoice-payment-voucher-edit-button"
                                    variant="outline-grey"
                                  >
                                    <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Print</div>;
                                      }}
                                      content={() => this.componentRef}
                                    />
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="invoice-previe-div-body-le-small-box-invoicegetpaid">
                        <div className="invoice-contemplary-body-inside-small-box-invoicegetpaid">
                          <Row>
                            <Col lg={12} md={12} xl={12}>
                              <div className="invoice-one-border-box-invoicegetpaid">
                                <Media
                                  as="li"
                                  className="media-ride-list-invoice-small-box-invoicegetpaid"
                                >
                                  <div className="list-steps-of-ride-invoice-small-box-invoicegetpaid">
                                    <div className="invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid">
                                      <img
                                        src={require("./circle-cursor.png")}
                                      />
                                    </div>
                                  </div>
                                  <Media.Body>
                                    {/*   {console.log(this.state.invoiceData,"-------p")} */}
                                    <div className="contemporary-template__header__info-small-box-invoicegetpaid">
                                      <div className="le-heading--title-small-box-invoicegetpaid">
                                        Send Invoice
                                      </div>
                                      {/*
                                       */}
                                      <strong className="le-text--strong-small-box-invoicegetpaid">
                                        Last Sent :{" "}
                                        {this.state.invoiceData.sentAt &&
                                          (this.state.invoiceData.sentAt
                                            .length > 0
                                            ? moment(
                                                this.state.invoiceData.sentAt[
                                                  this.state.invoiceData.sentAt
                                                    .length - 1
                                                ]
                                              ).format("MMMM Do YYYY")
                                            : "Never")}
                                      </strong>
                                      <div class="address__field"></div>
                                      <div class="address__field"></div>
                                    </div>
                                  </Media.Body>
                                </Media>

                                <div style={{ marginTop: "20px" }}>
                                  <div>
                                    <Popconfirm
                                      title="Are you sure to send this Invoice to Client?"
                                      onConfirm={this.handleSendInvoice}
                                      onCancel={() => console.log("cancel")}
                                      okText="Send"
                                      cancelText="No"
                                      placement="left"
                                    >
                                      <Button
                                        className="le-newinvoice-payment-voucher-edit-button"
                                        variant="outline-grey"
                                      >
                                        Send Email
                                      </Button>
                                    </Popconfirm>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="invoice-previe-div-body-le-small-box-invoicegetpaid">
                        <div className="invoice-contemplary-body-inside-small-box-invoicegetpaid">
                          <Row>
                            <Col lg={12} md={12} xl={12}>
                              <div className="invoice-one-border-box-invoicegetpaid">
                                <Media
                                  as="li"
                                  className="media-ride-list-invoice-small-box-invoicegetpaid"
                                >
                                  <div className="list-steps-of-ride-invoice-small-box-invoicegetpaid">
                                    <div className="invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid">
                                      <img
                                        src={require("./circle-credit.png")}
                                      />
                                    </div>
                                  </div>
                                  <Media.Body>
                                    <div className="contemporary-template__header__info-small-box-invoicegetpaid">
                                      <div className="le-heading--title-small-box-invoicegetpaid">
                                        Get Paid
                                      </div>
                                      <strong className="le-text--strong-small-box-getpaid-invoicegetpaid">
                                        Amount Due: AED{" "}
                                        {this.state.invoiceData
                                          .amountToBePaid &&
                                          new Intl.NumberFormat().format(
                                            this.state.invoiceData
                                              .amountToBePaid
                                          )}{" "}
                                        <br />
                                      </strong>
                                      <strong className="le-text--strong-small-box-getpaid-invoicegetpaid">
                                        Status:{" "}
                                        {this.state.invoiceData.status &&
                                          this.state.invoiceData.status}
                                      </strong>
                                      <div class="address__field"></div>
                                      <div class="address__field"></div>
                                    </div>
                                  </Media.Body>
                                </Media>
                                <div style={{ marginTop: "30px" }}>
                                  <div>
                                    {this.state.invoiceData.status && this.state.invoiceData.status==="PAID" || this.state.invoiceData.status==="CHEQUE_RECIEVED"?
                                    <Button
                                    style={{
                                      color: "white",
                                      backgroundColor: "#666666",
                                    }}
                                    onClick={this.handleRecordPayment}
                                    className="le-newinvoice-payment-voucher-edit-button"
                                    variant="outline-grey"
                                    disabled
                                  >
                                   Record a Payment
                                  </Button>
                                    :
                                    <Button
                                      style={{
                                        color: "white",
                                        backgroundColor: "#666666",
                                      }}
                                      onClick={this.handleRecordPayment}
                                      className="le-newinvoice-payment-voucher-edit-button"
                                      variant="outline-grey"
                                    >
                                      Record a Payment
                                    </Button>
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                              {/* <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top-newinvoiceone"></div>
                      </div> */}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="invoice-expense-docs-area" >
                      <h3>Expense Doements</h3>  
                     <div className="invoice-IMages-area">
                       {this.state.invoiceData.items && this.state.invoiceData.items.map((doc)=>{
                         if(doc.imageUrl){
                           return   <div className="expense-doc-item">
                           <h6>{doc.serviceType}</h6>
                           <ModalImage
  small={`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${doc.imageUrl}`} 
  large={`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${doc.imageUrl}`} 
  
/>
                     
                         </div>
                         }
                       })}
                  
                    
                     </div>
                        </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <center>
          <div className="addInvoiceTpArea-heading-left-type-print">
            <ReactToPrint
              removeAfterPrint={true}
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <div
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    <Icon name="print"></Icon> Print
                  </div>
                );
              }}
              content={() => this.componentRef}
            />
          </div>
        </center>

        <div style={{ overflow: "auto" }}>
          {" "}
          <Invoiceview ref={(el) => (this.componentRef = el)} />
        </div>

        <Modal
          show={this.state.show}
          /* onHide={this.handleClose} */ size="sm"
          centered
        >
          <Modal.Body>
            {/* fa fa-exclamation-circle */}
            {this.state.sendInvoiceLoading ? (
              <div
                className="sent-message-area"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <center>
                  <div className="loader1188"></div>
                  <p style={{ paddingTop: "20px" }}>Sending Please wait....</p>
                </center>
              </div>
            ) : this.state.sendInvoiceSuccess ? (
              <div
                className="sent-message-area"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <center>
                  <p>
                    <i
                      style={{ fontSize: "80px" }}
                      class="fa fa fa-check-circle-o"
                    ></i>
                  </p>
                  <p style={{ fontSize: "24px", fontWeight: "550" }}>
                    thank you!
                  </p>
                  <p>
                    The Invoice has been successfully send to the Customer email{" "}
                  </p>
                  <button
                    className="sent-button-des"
                    onClick={this.handleClose}
                  >
                    Ok
                  </button>
                </center>
              </div>
            ) : (
              <div
                className="sent-message-area"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <center>
                  <p>
                    <i
                      style={{ fontSize: "80px", color: "#FF603B" }}
                      class="fa fa-exclamation-circle"
                    ></i>
                  </p>
                  <p style={{ fontSize: "24px", fontWeight: "550" }}>
                    Sorry!...
                  </p>
                  <p>{this.state.sendInvoiceError} </p>
                  <button
                    style={{ backgroundColor: "#FF603B" }}
                    className="sent-button-des"
                    onClick={this.handleClose}
                  >
                    Try Again later
                  </button>
                </center>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show1}
          onHide={this.handleClose1}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="heading-modal"
              style={{ textAlign: "left", fontWeight: "550" }}
            >
              <img
                style={{ width: "30px" }}
                src={require("../../images/profile/card12.png")}
              />{" "}
              Record a Payment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.recordPaymentSuccess ? (
              <div className="sent-message-area">
                <center>
                  <p>
                    <i class="fa fa fa-check-circle-o"></i>
                  </p>
                  <h2>thank you!</h2>
                  <p>Payment Record has been Updated successfully.</p>
                  <button
                    className="sent-button-des"
                    onClick={this.handleClose1}
                  >
                    Ok
                  </button>
                </center>
              </div>
            ) : (
              <div
                className="sent-message-area"
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <p style={{ fontWeight: "550" }}>
                  Record a Payment You've already Received, Such as cash, cheque
                  or Bank Payment.
                </p>
                <center>
                  <div className="record-a-payment-form-area">
                    <Form onSubmit={this.handleRecordPaymentSubmit}>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label
                          column
                          sm={4}
                          style={{
                            textAlign: "left",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Amount
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="text"
                            value={
                              this.state.invoiceData.pendingAmount &&
                              this.state.invoiceData.pendingAmount + "AED"
                            }
                            placeholder=" 44 AED"
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formHorizontalPassword">
                        <Form.Label
                          column
                          sm={4}
                          style={{
                            textAlign: "left",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Paid Amount
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            required
                            ref={(input) => (this.amountPaid = input)}
                            type="number"
                            placeholder="Amount in AED"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalPassword">
                        <Form.Label
                          column
                          sm={4}
                          style={{
                            textAlign: "left",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Payment Method
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.setState({
                                recordPaymentType: e.target.value,
                              });
                            }}
                            defaultValue="Choose..."
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="CASH">Cash</option>
                            <option value="CHEQUE">Cheque</option>
                            <option value="ONLINE_BANK_TRANSFER">Bank Transfer</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                      {this.state.recordPaymentType === "CHEQUE" ? (
                        <div>
                          <Form.Group
                            as={Row}
                            controlId="formHorizontalPassword"
                          >
                            <Form.Label
                              column
                              sm={4}
                              style={{
                                textAlign: "left",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Check No
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                required
                                ref={(input) => (this.checkNumber = input)}
                                type="text"
                                placeholder="Enter Check No"
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            controlId="formHorizontalPassword"
                          >
                            <Form.Label
                              column
                              sm={4}
                              style={{
                                textAlign: "left",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Expiry Date
                            </Form.Label>
                            <Col sm={8}>
                              <Form.Control
                                required
                                ref={(input) => (this.checkDate = input)}
                                type="date"
                              />
                            </Col>
                          </Form.Group>
                          {/* <Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={4} style={{textAlign:'left',fontWeight:'600',fontSize:'14px'}}>
  Check Amount
  </Form.Label>
  <Col sm={8}>
    <Form.Control required ref={(input=>this.checkAmount=input)} type="number" placeholder="Amount in AED" />
  </Col>
</Form.Group> */}
                          <Form.Group
                            as={Row}
                            controlId="formHorizontalPassword"
                          >
                            <Form.Label
                              column
                              sm={4}
                              style={{
                                textAlign: "left",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Upload Check
                            </Form.Label>
                            <Col sm={8}>
                              <div className="upload-photo-area-check">
                                <div>
                                  <center>
                                    {this.state.preview1.length > 0 ? (
                                      <img
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                          marginTop: "30px",
                                        }}
                                        src={this.state.preview1[0]}
                                      />
                                    ) : (
                                      <Icon
                                        style={{ marginTop: "30px" }}
                                        name="upload"
                                        size="large"
                                      />
                                    )}

                                    <div className="image-upload">
                                      <label for="file-input-cheque">
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            cursor: "pointer",
                                            fontWeight: "550",
                                          }}
                                        >
                                          Select Cheque Image
                                        </p>
                                      </label>

                                      <input
                                        id="file-input-cheque"
                                        name="photos"
                                        type="file"
                                        accept=".png,.jpg,.jpeg"
                                        onChange={this.handleFileChange1}
                                      />
                                    </div>
                                  </center>
                                </div>
                              </div>
                            </Col>
                          </Form.Group>
                        </div>
                      ) : (
                        ""
                      )}

                      <Form.Group as={Row} controlId="formHorizontalPassword">
                        <Form.Label
                          column
                          sm={4}
                          style={{
                            textAlign: "left",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Memo / Notes
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            required
                            ref={(input) => (this.memo = input)}
                            as="textarea"
                            rows="3"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group>
                        <p style={{ color: "red", fontWeight: "550" }}>
                          {this.state.recoredPaymentError}
                        </p>
                        {this.state.loaderFlag ? (
                          <div>
                            {" "}
                            <div className="loader"></div>
                            <p>Please Wait...</p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="bottom-button-area-recored-paymenfomr">
                          <div></div>
                          <div>
                            <button
                              onClick={() => this.setState({ show1: false })}
                              className="bottom-button-area-recored-paymenfomr-cancel"
                            >
                              Cancel
                            </button>
                            <button
                              type={"submit"}
                              className="bottom-button-area-recored-paymenfomr-submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                </center>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    creatInvoiceReducer: state.InvoiceTopAreaPreview1.creatInvoiceReducer,
    getUserInvoiceReducer: state.InvoiceTopAreaPreview1.getUserInvoiceReducer,
    sendInvoiceEmailReducer:
      state.InvoiceTopAreaPreview1.sendInvoiceEmailReducer,
    RecoredUserPaymentReducer:
      state.InvoiceTopAreaPreview1.RecoredUserPaymentReducer,
  };
};

export default compose(
  withReducer("InvoiceTopAreaPreview1", reducer),
  connect(mapStateToProps),
  withRouter
)(InvoiceTopAreaPreview);
