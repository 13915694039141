


import * as Actions from '../actions';

const initialState = {
    isLoading: true,
    errMsg: null,
    data: []
    };


// get active categories
export const fileSearchReducer = function(state = initialState, action) {
switch (action.type) {
case Actions.FILES_SEARCH_WITH_NUMBER_NAME:
return { ...state, isLoading: false, errMsg: null, data: action.payload };
case Actions.FILES_SEARCH_WITH_NUMBER_NAME_LOADING:
return { ...state, isLoading: true, errMsg: null, data: [] };
case Actions.FILES_SEARCH_WITH_NUMBER_NAME_LOADING_FAILED:
return { ...state, isLoading: false, errMsg: action.payload, data: [] };
default:
return state;
}
};
