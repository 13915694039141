


import * as Actions from '../actions';

const initialState = {
    isLoading: true,
    errMsg: null,
    data: []
    };


// get active categories
export const updateExpenseReducer = function(state = initialState, action) {
switch (action.type) {
case Actions.UPDATE_EXPENSE_CASES:
return { ...state, isLoading: false, errMsg: null, data: action.payload };
case Actions.UPDATE_EXPENSE_CASES_LOADING:
return { ...state, isLoading: true, errMsg: null, data: [] };
case Actions.UPDATE_EXPENSE_CASES_LOADING_FAILED:
return { ...state, isLoading: false, errMsg: action.payload, data: [] };
default:
return state;
}
};
