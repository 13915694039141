import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const CUSTOMER_APPROVE_CHEQUE = "CUSTOMER_APPROVE_CHEQUE";
export const CUSTOMER_APPROVE_CHEQUE_LOADING =
"CUSTOMER_APPROVE_CHEQUE_LOADING";
export const CUSTOMER_APPROVE_CHEQUE_LOADING_FAILED =
"CUSTOMER_APPROVE_CHEQUE_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const approveChequeLoading = ms => ({
    type: CUSTOMER_APPROVE_CHEQUE_LOADING,
    payload: ms
    });
    
    export const approveChequeLoadingFailed = ms => ({
    type: CUSTOMER_APPROVE_CHEQUE_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function approveCheque(obj) {
        const request = axios.post(
            `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/cheque/chequePaymentReceived`, obj,{
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
    
    
    return dispatch => {

    dispatch(approveChequeLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
   /*   console.log(response,"response approveCheque") */
    dispatch({
    type: CUSTOMER_APPROVE_CHEQUE,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        approveChequeLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(approveChequeLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(approveChequeLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(approveChequeLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


