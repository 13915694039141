import React, { Component, useEffect } from "react";

import './newnooneinvoice.scss';
import { Dropdown, Input,Label,Checkbox,TextArea,Button,Header,Image, Divider } from 'semantic-ui-react'
 import ReactToPrint from 'react-to-print';
/*  import html2canvas from 'html2canvas'; */
 import { Icon, Step } from 'semantic-ui-react'
 import { Popover,DatePicker,Popconfirm } from 'antd';
/* import jsPDF from 'jspdf'; */
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Media,
  Modal,
  ButtonGroup,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,withRouter,
  browserhistory,
} from "react-router-dom";
 import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";

 import reducer from "./store/reducers";
 import withReducer from "../../store/withReducer";
 import store from "../../store";
 import QuotationView from './quotaionView';

    

  

    


 var moment = require('moment');
const options = [
  { key: 'save', icon: 'save', text: 'Save and Send', value: 'Save and Send' },
  { key: 'delete', icon: 'credit card outline', text: 'Save and Record A Payment', value: 'Save and Record A Payment' },
]
class QuotationTopAreaPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      quotationData:{},
      open: false,
      show:false,
      sendInvoiceLoading:false,
      sendInvoiceSuccess:false,
      sendInvoiceErrorFlag:false,
      sendInvoiceFlag:false,
      sendInvoiceError:'',
      show1:false,
      loaderFlag:false,
      recordPaymentSuccess:false,
      recoredPaymentError:'',
      recordPaymentFlag:false,
      recordPaymentFlagError:false,
      convertQuotationLoading:false,
      convertQuotationSuccess:false,
      convertQuotationError:'',
      convertQuotationFlag:false,
      convertQuotationErrorFlag:false

    };
    this.ref=React.createRef();
  }
 
  handleClose=()=> {
    this.setState({ show: false,sendInvoiceError:'',sendInvoiceErrorFlag:false,sendInvoiceLoading:false });
  }

  handleShow=()=> {
    this.setState({ show: true });
  }
  handleClose1=()=> {
    this.setState({ show1: false,loaderFlag:false,convertQuotationErrorFlag:false,convertQuotationLoading:false,convertQuotationFlag:false});
  }

  handleShow1=()=> {
    this.setState({ show1: true });
  }

  show = (dimmer) => () => this.setState({ dimmer, open: true })

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
  }

  close = () => this.setState({ open: false ,opentwo: false, openthree: false})
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }
/* sendInvoiceToCustomer */

handleSendQuotaion=()=>{
  this.setState({show:true,sendInvoiceLoading:true,sendInvoiceError:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:true,sendInvoiceFlag:true})
  let obj={
    quotationId:this.state.quotationData._id
  }
  this.props.dispatch(Actions.sendQuotationToCustomer(obj))
  setTimeout(()=>{
    this.props.dispatch(Actions.getUserQuotation(localStorage.quotationId));
  },1000)
}
  componentWillReceiveProps(nextProps) {
    let resNumber4 = nextProps.getUserQuotationReducer;
    console.log(resNumber4)
    if (!resNumber4.isLoading) {
      if (resNumber4.errMsg == null && !(resNumber4.data === undefined)) {
  
        this.setState({ quotationData: resNumber4.data.quotation });
      } else {
        /*  this.setState({createFileError:resNumber4.errMsg,loaderFlag:false,invoiceFlag78:false})  
     console.log(resNumber4.errMsg) CONVERTED_TO_INVOICE*/
      }
    }

    let resNumber5 = nextProps.sendQuotationEmailReducer;
    if (!resNumber5.isLoading) {
      if (resNumber5.errMsg == null && !(resNumber5.data === undefined)) {
          if(this.state.sendInvoiceFlag){
    this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:true,sendInvoiceErrorFlag:false,sendInvoiceFlag:false})
          }
      } else {
        if(this.state.sendInvoiceErrorFlag){
          this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:false,sendInvoiceError:resNumber5.errMsg})
          }
      }
    }

    /* RecoredUserPaymentReducer */
    let resNumber6 = nextProps.quotationToInvoiceReducer;
    if (!resNumber6.isLoading) {
      if (resNumber6.errMsg == null && !(resNumber6.data === undefined)) {
          if(this.state.convertQuotationFlag){
    this.setState({loaderFlag:false,convertQuotationLoading:false,convertQuotationSuccess:true,convertQuotationErrorFlag:false,convertQuotationFlag:false})
          }
      } else {
        if(this.state.convertQuotationErrorFlag){
          this.setState({loaderFlag:false,convertQuotationLoading:false,convertQuotationSuccess:false,convertQuotationErrorFlag:false,convertQuotationError:resNumber6.errMsg})
          }
      }
    }
  }
  handleRecordPayment=()=>{
    this.setState({show1:true})

  }
  handleConvetToInvoice=(e)=>{
    e.preventDefault()
    this.setState({loaderFlag:true,show1:true,convertQuotationLoading:true,convertQuotationFlag:true,convertQuotationErrorFlag:false})
   
    let obj={
      quotationId:this.state.quotationData._id,
      generatedAt:this.state.quotationData.generatedAt,
      expireAt:this.state.quotationData.expireAt,
  }
  this.props.dispatch(Actions.convertInovoiceTOQuotation(obj))
  setTimeout(()=>{
    this.props.dispatch(Actions.getUserQuotation(localStorage.quotationId));
  },1500)
  }
 
  render() {
    const { open, closeOnEscape, closeOnDimmerClick, dimmer, opentwo, openthree} = this.state;
    return (
      <div>







        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} mg={12}>
                <div className="invoice-payment-voucher-view-area">
                
                  <div className="new-invoice-payment-voucher-header-text-area" role="alert">
                    <h2>Quotation# {this.state.quotationData.quotationNo &&
                                    this.state.quotationData.quotationNo}</h2>

                    <div className="two-button-edi-save-new-invoice-payment-vouche">

                   

                      <Button.Group color='black'>
    <Button onClick={()=>this.props.history.push('/addQuotation')}   style={{borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px',marginRight:'-2px'}}>   Create another Quotation</Button>
    <Dropdown
      className='button icon'
      style={{borderTopRightRadius:'20px',borderBottomRightRadius:'20px',borderLeft:'1px solid dimgray'}}
      floating
    /*   options={options} */
      /* onClick={this.handleDropDown} */
      trigger={<React.Fragment />}
    />
  </Button.Group>
                    </div>
                  </div>

                  <div></div>

  <div className="invoice-previe-div-body-le">
        <center>
        <div className="invoice-contemplary-body-inside" style={{paddingLeft:'50px',marginRight:'10px'}}>



<div style={{display:'flex',justifyContent:'center',overflow:'auto'}}>            <Step.Group stackable='tablet'>
<Step active>
<Icon color='grey' name='mail' />
<Step.Content>
<Step.Description  className="step_responsine-of-quotation">
<Popconfirm
                 title="Are you sure send to this Quotation to Client?"
                 onConfirm={this.handleSendQuotaion}
                 onCancel={()=>console.log("cancel")}
                 okText="Send"
                 cancelText="No"
              
               >
             <Button  style={{backgroundColor:'#666666',color:'white'}} className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
   Send Quotation
     
   </Button> 
               </Popconfirm>

</Step.Description>
</Step.Content>
</Step>
<Step >
<Icon color='grey' name='print' />
<Step.Content>
<Step.Description className="step_responsine-of-quotation">
<Button className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
     <ReactToPrint
removeAfterPrint={true}
  trigger={() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.
    return  <div >      Print Quotation</div>;
  }}
  content={() => this.componentRef}

/>
     
     </Button> 
</Step.Description>
</Step.Content>
</Step>
<Step active  >
<Icon color='grey' name='sync' />
<Step.Content>
<Step.Description className="step_responsine-of-quotation">
{this.state.quotationData.status==="CONVERTED_TO_INVOICE"?<Button disabled style={{backgroundColor:'#666666',color:'white'}} className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
    Already Converted
     
     </Button> :<Button onClick={this.handleConvetToInvoice} style={{backgroundColor:'#666666',color:'white'}} className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
    Convert to Invoice
     
     </Button> }

</Step.Description>
</Step.Content>
</Step>
</Step.Group></div>


              

              <Container>


</Container>
      




          


{/* */}
            </div>
        </center>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>



             <div style={{overflow:'auto'}} > <QuotationView    ref={el => (this.componentRef = el)} /></div>
       






              <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        
          <Modal.Body>
            {/* fa fa-exclamation-circle */}
            {this.state.sendInvoiceLoading ?
             <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
             <center>
                 <div className="loader1188"></div>
                 <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
             </center>
            </div>
            :
           
            this.state.sendInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
            <center>
                <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>The Quotation has been successfully send to the Customer email </p>
                <button className="sent-button-des" onClick={this.handleClose}>Ok</button>
            </center>
           </div> :
            <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
            <center>
                <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
                <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
                <p>{this.state.sendInvoiceError} </p>
                <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
            </center>
           </div>
             
           }
          </Modal.Body>
      
        </Modal> 
        
        
        <Modal show={this.state.show1} /* onHide={this.handleClose} */ size="sm" centered  >
        
        <Modal.Body>
          {/* fa fa-exclamation-circle */}
          {this.state.convertQuotationLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
           </center>
          </div>
          :
         
          this.state.convertQuotationSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
              <p>The Quotation has been successfully Converted to Invoice </p>
              <button className="sent-button-des" onClick={this.handleClose1}>Ok</button>
          </center>
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.convertQuotationError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose1}>Try Again later</button>
          </center>
         </div>
           
         }
        </Modal.Body>
    
      </Modal> 
      
     







      </div>

      
    );
  }
}
/* quotationToInvoiceReducer */
const mapStateToProps=(state)=> {
  return {
    getUserQuotationReducer: state.QuotationTopAreaPreview1.getUserQuotationReducer,
    sendQuotationEmailReducer:state.QuotationTopAreaPreview1.sendQuotationEmailReducer,
    quotationToInvoiceReducer:state.QuotationTopAreaPreview1.quotationToInvoiceReducer
} 


}

 export default compose(
 withReducer("QuotationTopAreaPreview1", reducer),
 connect(mapStateToProps),withRouter)(QuotationTopAreaPreview); 

