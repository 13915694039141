import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const CUSTOMER_BANK_CHEQUE = "CUSTOMER_BANK_CHEQUE";
export const CUSTOMER_BANK_CHEQUE_LOADING =
"CUSTOMER_BANK_CHEQUE_LOADING";
export const CUSTOMER_BANK_CHEQUE_LOADING_FAILED =
"CUSTOMER_BANK_CHEQUE_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getChequeDetailLoading = ms => ({
    type: CUSTOMER_BANK_CHEQUE_LOADING,
    payload: ms
    });
    
    export const getChequeDetailLoadingFailed = ms => ({
    type: CUSTOMER_BANK_CHEQUE_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getChequeDetail(obj) {
   /* /fileService/accountRoutes/api/v1/bankReceipts/getBankReceiptsWithPagination? */
        const request = axios.get(
            `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/cheque/getChequeWithPagination?caseInvoiceId=${obj.id}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
 
    
    return dispatch => {

    dispatch(getChequeDetailLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response getChequeDetail invoices")
    dispatch({
    type: CUSTOMER_BANK_CHEQUE,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getChequeDetailLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getChequeDetailLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getChequeDetailLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getChequeDetailLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


