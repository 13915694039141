


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../sideBar/style.scss';
import '../sideBar/style.css';
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import {Form,ButtonToolbar, Button,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Header, Table, Rating,Icon } from 'semantic-ui-react'
var moment = require('moment');
 class  ReceipnestFiles extends Component{
      constructor(props, context) {
        super(props, context);
        this.state = {
          show:false,
          limit:10,
          pageNo:1,
          userId:undefined,
          totalPages:11,
          filesData:[],
          pageNumber:1,
          options:[],
          direction:null,
          column:null
    } 
    }
componentWillMount(){
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNo
}
this.props.dispatch(Actions.getAllCustomerFiles(obj))
this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllCustomerFiles */
}  
handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,filesData:[]})
  let obj={
    userId:this.state.userId,
    limit:e.target.value,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerFiles(obj))

} 
handleJumpPage=(e)=>{
  this.setState({pageNumber:e.target.value,filesData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:e.target.value
  }
  this.props.dispatch(Actions.getAllCustomerFiles(obj))
 
}
handlePageInc=()=>{
  if(this.state.totalPages>this.state.pageNumber+1){
  this.setState((prevState, props) => ({
    pageNumber: prevState.pageNumber + 1
}));
this.setState({filesData:[]})
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNumber+1
}
this.props.dispatch(Actions.getAllCustomerFiles(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNumber>1){
    this.setState({filesData:[]})
    this.setState((prevState, props) => ({
      pageNumber: prevState.pageNumber - 1
  }));
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNumber-1
  }
  this.props.dispatch(Actions.getAllCustomerFiles(obj))
  }

}
/* allFilesReducer */ 
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.allFilesReducer;

  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.files.docs.length>0){
        this.setState({filesData:resNumber1.data.files.docs,totalPages:resNumber1.data.files.totalPages})
      }else{
        this.setState({filesData:["nothing"]})
      }
   
    } else {
    this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
    }
  }
  let resNumber2 =nextProps.fileSearchReducer;

  
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }
}
/* fileSearchReducer */
handleSearch=(e)=>{
 
if(e.length>0){
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered}) 
this.props.dispatch(Actions.getSeachedFiles(e))
}else{
   
  this.setState({filesData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerFiles(obj))

}
}
handleSelect=(e,value)=>{
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered,filesData:[]}) 
  let obj={
    userId:value.data._id,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerFiles(obj))

}
handleSort=(action)=>{
  switch (action.type) {
    case 'CHANGE_SORT':
      this.setState({column:action.column})
      this.setState((state)=>({
        direction:state.direction === 'ascending' ? 'descending' : 'ascending'
      }))
      this.setState((state)=>({
        filesData:state.filesData.reverse()
      }))
    default:
      
  }
  }
  render(){
    return (
        <div className="admin-cutomer-profile-area" >
        <div className="receipnest-file-customer-files-area">
        <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
           
           <div  className="admin-cutomer-REcepnest-files-rea">
           <div className="receipnest-file-customer-files-area-top-heading">
                 <p style={{fontSize:'16px',fontWeight:'550',marginTop:'25px'}}> All Created Files</p>
                </div>
                <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>
               
  
            </div>
            <div className="receipnest-file-customer-files-area655-bottom-area" style={{overflow:'auto',display:'block'}} >
             <div className="session-table-area-receptionist">
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
        textAlign='left' singleLine >Customer</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'invoice' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'invoice' })}
        singleLine textAlign='center'>File No</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'email' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'email' })}
        singleLine textAlign='center'>Email</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Contact' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Contact' })}
        singleLine textAlign='center'>Contact</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'created' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'created' })}
        singleLine textAlign='center'>created At</Table.HeaderCell>
        
        <Table.HeaderCell singleLine textAlign='center'>Status</Table.HeaderCell>
     
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.filesData.length>0 ?
                  this.state.filesData[0]!="nothing"?
                this.state.filesData.map((item)=>{
                return  <Table.Row>
                <Table.Cell textAlign='left'>
                  <img style={{width:'30px',height:'30px',borderRadius:'50%',marginRight:'5px'}} src={item.userProfile.profileImage?item.userProfile.profileImage:require('../../images/profiled1.png')} />{item.userProfile.firstName+" "+item.userProfile.lastName}
              
                </Table.Cell>
                <Table.Cell textAlign='center' singleLine>BZ/{item.fileNo}</Table.Cell>
                <Table.Cell textAlign='center'>
                { item.userProfile && item.userProfile.email}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                 {item.mobileNumber} 
                </Table.Cell>
                <Table.Cell textAlign='center' >

               { moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </Table.Cell>
             
                {item.fileStatus==="PAID"? <Table.Cell  textAlign='center' singleLine >{item.fileStatus==="PAID"?<div style={{color:'green',fontWeight:'550'}}><center>{item.fileStatus}</center></div>:<div style={{color:'red',fontWeight:'550'}}><center>{item.fileStatus}</center></div>} </Table.Cell>: <Table.Cell warning textAlign='center' singleLine >{item.fileStatus==="PAID"?<div style={{color:'green',fontWeight:'550'}}><center>{item.fileStatus}</center></div>:<div style={{color:'red',fontWeight:'550'}}><center> <Icon name='attention' />{item.fileStatus}</center></div>} </Table.Cell>}
               
              </Table.Row>
                })
                :<Table.Row>
                     <Table.Cell colSpan='6'
 textAlign='center'>
                    <center>
                  <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                  No File Exist Yet!
                  </div>
                  </center>    
                </Table.Cell>
                </Table.Row>:<Table.Row>
                     <Table.Cell  textAlign='center' colSpan='6'  
 textAlign='center'>
                     <center>
               <div className="loader-for-tabel-invoce">
               <div className="loader1188">
                </div> 
               </div>
               </center>  
                </Table.Cell>
                </Table.Row>}
     
    
     
    </Table.Body>
  </Table>

          </div>
           
  
                    
            </div>
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per page
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNumber} of {this.state.totalPages} <span className="pagination-table-quotaion-dec"><i title="Previous Page" onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} title="Next Page" className="fa fa-caret-right"></i></span>
              </span>
            jump to page  <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
            </div> 
           
        </div>
        </div>
    )
  }
}
  const mapStateToProps=(state)=> {
    return {
        allFilesReducer: state.ReceipnestFiles1.allFilesReducer,
        fileSearchReducer:state.ReceipnestFiles1.fileSearchReducer
    };
  } 
export default compose(
  withReducer("ReceipnestFiles1", reducer),
  connect(mapStateToProps),withRouter
)(ReceipnestFiles); 