


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import './style.css';
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "../adminSignIn/store/actions";
import store from "../../store";
import { Dropdown, Input,Label,Icon,Checkbox } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery'
import { Menu } from 'antd';
import { AppstoreOutlined, AuditOutlined, SettingOutlined ,MenuUnfoldOutlined,FileSyncOutlined,CreditCardOutlined} from '@ant-design/icons';

const { SubMenu } = Menu;
const MenuCategories=[
    {
        name:'Services',
        icon:<AppstoreOutlined />,
        child:true,
        route:'quotationAll',
        id:'Services',
        submenu:[
            {name:'Invoice',route:'invoicesAll',id:"Invoice"},
            {name:'Quotaion',route:'quotationAll',id:'Quotaion'},
            {name:'Customers',route:'customers',id:"allCustomer"},
           /*  {name:'Payment cheques',route:'quotationAll',id:"PaymentCheques"} */
           
        ]
    },{
        name:'Case Files',
        icon:<FileSyncOutlined />,
        child:true,
        id:'CaseFiles',
        submenu:[
            {name:'Open a File',route:'createFile',id:"createFileAccountant"},
            {name:'All Files',route:'allfiles',id:'allfilesAccountant'},
            {name:'File Invoices',route:'fileInvoices',id:"fileInvoices"},
           /*  {name:'Payment cheques',route:'quotationAll',id:"PaymentCheques"} */
           
        ]
    },{
        name:'Payments',
        icon:<CreditCardOutlined />,
        child:true,
        route:'quotationAll',
        id:'PaymentsAccountant',
        submenu:[
            {name:'Bank Receipts',route:'bankReceipts',id:"bankReceiptsID"},
            {name:'Case Cheques',route:'casecheques',id:"caseChequesID"},
            {name:'File Cheques',route:'filecheques',id:"filechequesID"}
           /*  {name:'Payment cheques',route:'quotationAll',id:"PaymentCheques"} */
           
        ]
    }, {
      name:'Expense',
      icon:<AuditOutlined />,
      child:true,
      route:'expense',
      id:'expense',
      submenu:[
          {name:'Expenses',route:'expenseAll',id:"expenseAll"},
          {name:'Add Expense',route:'addExpense',id:'addExpense'},
         /*  {name:'Payment cheques',route:'quotationAll',id:"PaymentCheques"} */
         
      ]
  }
    /* {
        name:'Purchase',
        icon:"icono izquierda fa fa-cart-arrow-down",
        child:true,
        route:'quotationAll',
        id:'Purchase',
        submenu:[
            {name:'Bills',route:'AcountantChat'},
            {name:'Company/Supplier',route:'quotationAll'},
            {name:'Vendors',route:'quotationAll'},
            {name:'Product & Services',route:'quotationAll'}
           
        ]
    },{
        name:'Live Chat',
        icon:'icono izquierda fa fa-commenting-o',
        child:false,
        id:'Chat',
        route:'AcountantChat',
        submenu:[]
    },{
        name:'LPO',
        icon:'icono izquierda fa fa-chrome',
        child:true,
        id:'LPO',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Voucher Receiving',
        icon:'icono izquierda fas fa fa-list-alt',
        child:true,
        id:'VoucherReceiving',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Employ Salary Slip',
        icon:'icono izquierda fa fa-drivers-license-o',
        child:true,
        id:'EmploySalarySlip',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Attendance Sheet',
        icon:'icono izquierda fa fa-file-text',
        child:true,
        id:'AttendanceSheet',
        route:'quotationAll',
        submenu:[]
    },{
        name:'VAT Statement',
        icon:'icono izquierda 	fa fa-eercast',
        child:true,
        id:'VATStatement',
        route:'quotationAll',
        submenu:[]
    } */
 
]
class  AdminSideBar extends Component{
    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
    constructor(props, context) {
        super(props, context);
        this.state = {
            openKeys: ['Services'],
            defaultSelectedKeys:['Invoice']
    }
  }


 closeNav=()=>{
  
        document.getElementById("mySidenav1").style.width = "0";
    }
    openNav=()=>{
       
        document.getElementById("mySidenav1").style.width = "260px";  
    }
    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            localStorage.setItem('openKeys12',openKeys[openKeys.length-1])
          this.setState({ openKeys:openKeys });
        } else {
          this.setState({
            openKeys: latestOpenKey ? [latestOpenKey] : [],
          });
        }
      };
      componentWillMount(){
        if(localStorage.openKeys12){
this.setState({openKeys:[localStorage.openKeys12]})
        }
        if(localStorage.defaultSelectedKeys12){
            this.setState({defaultSelectedKeys:[localStorage.defaultSelectedKeys12]})
                    }
      }
      handleLink=(id)=>{
          localStorage.setItem('defaultSelectedKeys12',id)
      }
    render(){
        return (
            <div style={{borderLeft:'1px solid lightgray',height:'100%'}}>
                <div className="sidenav1">
            <div classNameName="admin-side-bar-area" style={{backgroundColor:'white'}}>
                <div className="admin-profile-area-acountant">
    
                     <Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}} /* color='teal' */ tag>
          <div style={{fontSize:'20px',transform:'rotate(180deg)'}}>Accountant  Panel</div>
        </Label>
           
                </div>
     {/* here */}
     <Menu
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        style={{ width: '100%' }}
        defaultSelectedKeys={this.state.defaultSelectedKeys}
      >
          {MenuCategories.map((item)=>{
              return  <SubMenu
              key={item.id}
              title={
                <span>
                <span style={{marginTop:'-10px'}}>  {item.icon}</span>
              <span>{item.name}</span>
                </span>
              }
            >
            {item.submenu.map((item1)=>{
               return <Menu.Item onClick={()=>{this.handleLink(item1.id)}} key={item1.id}> <Link to={item1.route}>{item1.name}</Link></Menu.Item>
            })}
              
            </SubMenu>
          })}
       
        
      </Menu>
            </div>
            </div>
            <div id="mySidenav1" className="sidenav" >
            <div classNameName="admin-side-bar-area sidenav1" style={{backgroundColor:'white'}}>
    
            <div className="admin-profile-area-acountant">
    
    <Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}} /* color='teal' */ tag>
    <div style={{fontSize:'14px',transform:'rotate(180deg)'}}>Accountant Panel</div>
    </Label>
    <h3 className="sidebarTitle" >  <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}>&times;</a></h3>
    <Menu
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        style={{ width: '100%' }}
        defaultSelectedKeys={this.state.defaultSelectedKeys}
      >
          {MenuCategories.map((item)=>{
              return  <SubMenu
              key={item.id}
              title={
                <span>
                <span style={{marginTop:'-10px'}}>  {item.icon}</span>
              <span>{item.name}</span>
                </span>
              }
            >
            {item.submenu.map((item1)=>{
               return <Menu.Item onClick={()=>{this.handleLink(item1.id)}} key={item1.id}> <Link to={item1.route}>{item1.name}</Link></Menu.Item>
            })}
              
            </SubMenu>
          })}
       
        
      </Menu>
    </div>
   
            </div>
            </div>
    <div style={{marginBottom:'0px',marginTop:'10px',marginLeft:'30px',backgroundColor:'white',padding:'4px'}} >
    <span className="sidenav2" style={{fontSize:'25px',cursor:'pointer'}} onClick={this.openNav}>&#9776; Menu Area</span>
    </div>
            </div>
        )
    }
    
}
export default  withReducer("AdminSideBar", reducer)
  (AdminSideBar); 