import React, { Component } from "react";
import {
  Accordion,
  Card,
  Container,
  ListGroup,
  Row,
  Col,Button
} from "react-bootstrap";
import UserHeader from './user'
import HeaderSearch from './serach'

import { compose } from "redux";
import { connect } from "react-redux";
import './style.scss'
class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      class:"my-first-class-name"
    };
  }
  componentWillMount(){

  }
  componentDidMount(){

  }
  handleColor=()=>{
    this.setState({class:"my-first-class-name"})
  }

  render() {
    return (
      <div style={{backgroundColor:'#353535'}}>
      <div className="container-custom" >
      <div className="accountant-panel-header">
        
       <div className="accountant-panel-header-left">
           <img src={require('../../images/logo/logo.png')} />
           
           </div>   
           <div className="accountant-panel-header-middle">
         <HeaderSearch />
           </div> 
           <div className="accountant-panel-header-rightt">
           <img style={{marginTop:'3px'}} src={require('../../images/profiled1.png')} />
      <UserHeader />
 
           </div> 
           </div>
      </div>
      </div>
    );
  }
}
export default connect()(Header);
