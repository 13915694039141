import {combineReducers} from 'redux';
import {getUserCreateInvoceReducer} from './getUsersReducer'

import {getUserCreateInvoceFilesReducer} from './getUserFiles'
import { getServicesTypeReducer } from "./getServiceType";
import {addServicesTypeReducer} from './addServiceType'
import {updateExpenseReducer} from './updateExpenseRed'

const reducer = combineReducers({
  addServicesTypeReducer,getServicesTypeReducer,getUserCreateInvoceReducer,getUserCreateInvoceFilesReducer,updateExpenseReducer 
    });
    
    export default reducer;
