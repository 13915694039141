import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { NavLink, Link,withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./switch.css";
/* import "./style.scss"; */
import SignatureCanvas from "react-signature-canvas";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Header, Feed } from 'semantic-ui-react'

import "./formStyle.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
class CustomerFileForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fname: "",
      Nationality: "",
      Occupation: "",
      Employer: "",
      UAEAddress: "",
      PermanentAddress: "",
      MobileNumber: "",
      HomeTel: "",
      OfficeTel: "",
      Fax: "",
      email: "",
      description: "",
      case: "",
      LawyerName: "Bader Al Zaabi",
      caseNumber: "",
      OtherParty: "",
      Year: "",
      OPLawyer: "",
      Court: "",
      Stagelitgation: "",
      Accusation: "",
      description1: "",
      description2: "",
      AmountClaim: "",
      ProfessionalFees: "",
      InAdvance: "",
      FirstInstance: "",
      Appeal: "",
      CourtFee: "",
      Cassation: "",
      Execution: "",
      ProfessionalFee1: "",
      Max: "",
      OfficialFee55: "",
      Administrativefees: "",
      termsConditions: false,
      termsSubmitFlag: false,
      error: {},
      type: "password",
      passFlag: false,
      date88: "text",
      date99: "text",
      termsFlag: true,
      trimmedDataURL: null,
      paymentInvoice:false,
      captcha:null,
      buttonFlag:false,
      registrationFlag:true,
      show: false,
      cardType:'',
      loaderFlag:false,
      fileOptions:["FILE_REG"],
      ConsultationFlag:false,
      attorneyFlag:false,
      behalfFlag:false,
      CorporateFlag:false,
      BankFlag:false,
      IndividualFlag:false,
      errMessage:'',
      errMsgUser:'',
      customers:[],
      addCustomerFlag:true,
      searchFlag:false,
      key:'',
      addedCustomer:{},
      customerAdded:false,
      userId:'',
      fileNo:1,
      typingArabic:false,
      paymentType:'CASH',
      preview1:'',
      ChequeNo:'',
      photo1:'',
      ChequeDate:'',
      ChequeFile:'',
      fileDetail:{}

    };
   
  }
  handleClose=()=> {
    this.setState({ show: false });
    this.props.history.push('/allfiles')
    window.scrollTo({top:0, behavior: 'smooth'})}
  

  handleShow=()=> {
    this.setState({ show: true });
  }

  schema = {
    fname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Full Name can not be Empty";
              break;
          }
        });
        return errors;
      }),
      cardType:Joi.string().required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                default:
                    err.message = 'select the Payment Procedure first';
                    break;
            }
        });
        return errors;
    }),
  
    UAEAddress: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "UAE Address can not be Empty";
              break;
          }
        });
        return errors;
      }),
  
    MobileNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Mobile Number can not be Empty";
              break;
          }
        });
        return errors;
      }),



    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Summary Of Matter can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    LawyerName: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Lawyer Name can not be Empty ";
              break;
          }
        });
        return errors;
      }),
      PermanentAddress: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Permanent Address  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
  /*  
    AmountClaim: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Amount of Claim  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    ProfessionalFees: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Professional Fees  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    InAdvance: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "In Advance  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    FirstInstance: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "First Instance  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    Appeal: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Appeal  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    CourtFee: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "CourtFee  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    Cassation: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Cassation  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    Execution: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Execution  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    ProfessionalFee1: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Professional Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    Max: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Max Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    OfficialFee55: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Official Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }),
    Administrativefees: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Administrative Fee  can not be Empty ";
              break;
          }
        });
        return errors;
      }), */

    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "email mast be A Valid Email";
              break;
            default:
              err.message = "email can not be Empty";
              break;
          }
        });
        return errors;
      }),
    Nationality: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Nationality must be select ";
              break;
          }
        });
        return errors;
      }),

    /* case: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Case Type must be select ";
              break;
          }
        });
        return errors;
      }), */
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  changeHandler1 = (event) => {
   
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      Nationality: this.state.Nationality,
      UAEAddress: this.state.UAEAddress,
      MobileNumber: this.state.MobileNumber,
      LawyerName: this.state.LawyerName,
      cardType:this.state.cardType,
      email: this.state.email,
      description: this.state.description,
      PermanentAddress:this.state.PermanentAddress,
      /* AmountClaim: this.state.AmountClaim,
      ProfessionalFees: this.state.ProfessionalFees,
      InAdvance: this.state.InAdvance,
      FirstInstance: this.state.FirstInstance,
      Appeal: this.state.Appeal,
      CourtFee: this.state.CourtFee,
      Cassation: this.state.Cassation,
      Execution: this.state.Execution,
      ProfessionalFee1: this.state.ProfessionalFee1,
      Max: this.state.Max,
      OfficialFee55: this.state.OfficialFee55,
      Administrativefees: this.state.Administrativefees, 

      case: this.state.case,*/
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    this.setState({submitFlag:true})
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
        if (this.state.termsFlag) {
          this.setState({ termsSubmitFlag: true });
          /* 
                  termsSubmitFlag
                  termsFlag
                  */
        } else {
      
     
    this.setState({adminSignFlag:true,loaderFlag:true})

             /*customerFileSubmission*/  
           /* customerFileReducer 
           trimmedDataURL
           */
                   if(this.state.cardType==="CHEQUE"){
                    if(this.state.ChequeNo.length>0 && this.state.ChequeFile.length>0 && this.state.ChequeDate.length>0){
                    var  data=  new FormData();
      
                    data.append('userId',this.state.userId) 
                   data.append('fileOptions',this.state.fileOptions) 
                    data.append('fullName',this.state.fname) 
                    data.append('nationality',this.state.Nationality) 
                    data.append('companyName',this.Occupation.value) 
                     data.append('personal',this.Employer.value) 
                     data.append('address',this.state.UAEAddress) 
                     data.append('permanentAddress',this.state.PermanentAddress) 
                     data.append('mobileNumber',this.state.MobileNumber) 
                      data.append('homeTel',this.HomeTel.value) 
                       data.append('officeTel',this.OfficeTel.value) 
                        data.append('fax',this.Fax.value) 
                        data.append('email',this.state.email) 
                        data.append('clientSignature', '') 
                        data.append('paymentType', this.state.cardType) 
                        data.append('summary', this.state.description) 
                        data.append('chequeNumber', this.state.ChequeNo) 
                        data.append('expiry', this.state.ChequeDate) 
                        data.append('bankCheque', this.state.photo1) 
                      

                        this.props.dispatch(Actions.customerFileSubmission(data))
                    }else{

                    }
             }else{
                    let  obj={
                      userId:this.state.userId,
                      fileOptions:this.state.fileOptions,
                      fullName: this.state.fname,
                      nationality: this.state.Nationality,
                      companyName: this.Occupation.value,
                      personal:this.Employer.value,
                      address: this.state.UAEAddress,
                      permanentAddress: this.state.PermanentAddress,
                      mobileNumber: this.state.MobileNumber,
                      homeTel: this.HomeTel.value,
                      officeTel: this.OfficeTel.value,
                      fax: this.Fax.value,
                      email: this.state.email,
                      clientSignature:'',
                      paymentType:this.state.cardType,
                      summary: this.state.description
                     }
                     this.props.dispatch(Actions.customerFileSubmission(obj))
                   }
             
               
       
         

        }
    }
  };
  
  
  handleHidePass = () => {
    this.setState({ passFlag: true, type: "text" });
  };
  handleShowPass = () => {
    this.setState({ passFlag: false, type: "password" });
  };
  handleCVChange = (e) => {
    this.changeHandler(e);
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name99").innerHTML = e.target.files[0].name;
  };
  handleDateChange = (e) => {
    /*   this.changeHandler(e) */
    this.setState({
      cv: e.target.files[0],
    });
    document.getElementById("file-name88").innerHTML = e.target.files[0].name;
  };
  handleConditions = () => {
    if (this.state.termsFlag) {
      this.setState({ termsFlag: false });
    } else {
      this.setState({ termsFlag: true });
    }
  };
  onChangeCaptha=(value)=>{
    if(value===null){
      this.setState({captcha:null,buttonFlag:true})
    }
    else{
      this.setState({captcha:value,buttonFlag:false})
    }
  }
  handleRegistration=()=>{
    if(this.state.registrationFlag){
this.setState({registrationFlag:false})
    }else{
      this.setState({registrationFlag:true})
    }

  }

  /* ConsultationFlag */
  handleConsultation=()=>{
    if(this.state.ConsultationFlag){
      let newArray = this.state.fileOptions.filter((item)=>item !=='CONSULTATION')
      this.setState({ConsultationFlag:false,fileOptions:newArray})  
    }else{
     this.state.fileOptions.push("CONSULTATION") 
     this.setState({ConsultationFlag:true})     
    }
  }
  /*  checked={this.state.attorneyFlag}
                            onChange={this.handleAttorney} */
    handleAttorney=()=>{
    if(this.state.attorneyFlag){
      let newArray = this.state.fileOptions.filter((item)=>item !=='CREATE_ATTORNEY')
      this.setState({attorneyFlag:false,fileOptions:newArray})  
    }else{
     this.state.fileOptions.push("CREATE_ATTORNEY") 
     this.setState({attorneyFlag:true})     
    }
  }     
   
                            
     handleBehalf=()=>{
    if(this.state.behalfFlag){
      let newArray = this.state.fileOptions.filter((item)=>item !=='BEHALF_OF_ANOTHER')
      this.setState({behalfFlag:false,fileOptions:newArray})  
    }else{
     this.state.fileOptions.push("BEHALF_OF_ANOTHER") 
     this.setState({behalfFlag:true})     
    }
  } 

  handleCorporate=()=>{
    if(this.state.CorporateFlag){
      let newArray = this.state.fileOptions.filter((item)=>item !=='CORPORATE')
      this.setState({CorporateFlag:false,fileOptions:newArray})  
    }else{
     this.state.fileOptions.push("CORPORATE") 
     this.setState({CorporateFlag:true})     
    }
  } 
      handleBank=()=>{
    if(this.state.BankFlag){
      let newArray = this.state.fileOptions.filter((item)=>item !=='BANK')
      this.setState({BankFlag:false,fileOptions:newArray})  
    }else{
     this.state.fileOptions.push("BANK") 
     this.setState({BankFlag:true})     
    }
  }    
    
           handleIndividual=()=>{
    if(this.state.IndividualFlag){
      let newArray = this.state.fileOptions.filter((item)=>item !=='INDIVIDUAL')
      this.setState({IndividualFlag:false,fileOptions:newArray})  
    }else{
     this.state.fileOptions.push("INDIVIDUAL") 
     this.setState({IndividualFlag:true})     
    }
  }    
  componentWillMount(){
    this.props.dispatch(Actions.getUsers())
  }
  componentWillReceiveProps(nextProps){
    let resNumber1 =nextProps.customerFileReducer;    
    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
          if(this.state.adminSignFlag){
       console.log(resNumber1.data,"file submission area")
            this.setState({loaderFlag:false,show:true,fileNo:resNumber1.data.file.fileNo,fileDetail:resNumber1.data.file})
           
            setTimeout(()=>{
              this.setState({
                termsSubmitFlag: false,
                paymentInvoice: false,
                fname: "",
                Employer: "",
                UAEAddress: "",
                PermanentAddress: "",
                MobileNumber: "",
                HomeTel: "",
                OfficeTel: "",
                Fax: "",
                description: "",
                email: "",
                case: "",
                LawyerName: "",
                caseNumber: "",
                OtherParty: "",
                Year: "",
                OPLawyer: "",
                Court: "",
                Stagelitgation: "",
                Accusation: "",
                description1: "",
                cardType:'',
                description2: "",
                AmountClaim: "",
                ProfessionalFees: "",
                InAdvance: "",
                FirstInstance: "",
                Appeal: "",
                CourtFee: "",
                Cassation: "",
                Execution: "",
                ProfessionalFee1: "",
                Max: "",
                OfficialFee55: "",
                Administrativefees: "",
                termsFlag:true,
                Nationality: "",
                cardType:''
              });
  
             },10)    
                  
            
          }
      } else {
      this.setState({loaderFlag:false,errMessage:resNumber1.errMsg})
      }
    }
    /* getUsersReducer */
    let resNumber2 =nextProps.getUsersReducer;
 
    if (!resNumber2.isLoading) {
      if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
        if(this.state.addCustomerFlag){
         this.setState({addCustomerFlag:false,errMsgUser:'',customers:resNumber2.data.users})
        }
 /*      console.log(resNumber2.data.users) */
      }else{
        this.setState({errMsgUser:resNumber2.errMsg})
        if(resNumber2.errMsg==="This token is expire! Please log in again"){
          this.props.dispatch(Actions.logoutAdmin())
                }
      }
    }
  }  
  handleBack=()=>{
    this.props.history.push(`/${localStorage.fileLink}`)
    window.scrollTo({top:60, behavior: 'smooth'})
  }    
  handleSearch=(e)=>{
    let search=e.target.value
    this.setState({searchFlag:true,key:search})
 
     
   }  
   handleCustomer=(item)=>{
    this.setState({addedCustomer:item,customerAdded:true,email:item.email,fname:item.firstName+" "+item.lastName,UAEAddress:item.address,MobileNumber:item.phoneNumber,userId:item._id})

   /*  console.log(item) "5ef1e66c55faca3525f432d7" */
  }
   
  handleArabic=(e)=>{
    if(e.target.checked){
this.setState({typingArabic:true})
    }else{
      this.setState({typingArabic:false})
    }
  }
  handleFileChange1 = e => {
     
    this.setState({
      photo1: e.target.files[0],ChequeFile:'fffffff'
    })
   
    var reader = new FileReader();
  var url = reader.readAsDataURL(e.target.files[0]);
  reader.onloadend =  (e)=> {
   
    this.setState({
        preview1: [reader.result]
    })
  }
  }
  render() {
    const options1=[
      { level: 'Select Type', value: '' },
      { level: 'CASH', value: 'CASH' },
      {level:'Through Cheque',value:'CHEQUE'},
      { level: 'Bank Transfer', value: 'ONLINE_BANK_TRANSFER' }
  ]
    const optionsLawyer = [
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
      { level: "Bader Al Zaabi", value: "Bader Al Zaabi" },
    ];
    const options2 = [
      { level: "Select Our Pricing Plan* ", value: "" },
      { level: "Basic Plan", value: "Basic Plan" },
      { level: "Silver", value: "Silver" },
      { level: "Gold", value: "Gold" },
    ];

    const options = [
      { level: "Select a Case* ", value: "Case Type" },
      { level: "Criminal Cases", value: "Criminal Cases" },
      { level: "Corporate Law", value: "Corporate Law" },
      { level: "Arbitration", value: "Arbitration" },
      {
        level: "International/ Interpol Cases",
        value: "International/ Interpol Cases",
      },
      { level: "Financial Crime", value: "Financial Crime" },
      { level: "Family business", value: "Family business" },
      { level: "Commercial", value: "Commercial" },
      { level: "Banking", value: "Banking" },
      {
        level: "Construction & Infrastructure",
        value: "Construction & Infrastructure",
      },
      { level: "Competition", value: "Competition" },
      {
        level: "Data Privacy & Cybersecurity",
        value: "Data Privacy & Cybersecurity",
      },
      { level: "Intellectual Property", value: "Intellectual Property" },
      { level: "Merger & Acquisitions", value: "Merger & Acquisitions" },
      { level: "Private Notary", value: "Private Notary" },
      { level: "Insurance", value: "Insurance" },
      { level: "Capital Market", value: "Capital Market" },
      { level: "Private Client Services", value: "Private Client Services" },
      { level: "Family Law", value: "Family Law" },
      { level: "Business Law", value: "Business Law" },
      { level: "Real Estate Law", value: "Real Estate Law" },
      { level: "Injury Law", value: "Injury Law" },
      { level: "Educational Law", value: "Educational Law" },
      { level: "VAT Law", value: "VAT Law" },
      { level: "Tax Law Advisory", value: "Tax Law Advisory" },
      { level: "International Tax Law", value: "International Tax Law" },
      { level: "Technology/Cyber Law", value: "Technology/Cyber Law" },
      { level: "Chemical", value: "Chemical" },
      { level: "Aviation", value: "Aviation" },
      { level: "Consumer Product", value: "Consumer Product" },
      { level: "Financial institution", value: "Financial institution" },
      {
        level: "Manufacture and Industrial",
        value: "Manufacture and Industrial",
      },
      { level: "Maritime & Shipping", value: "Maritime & Shipping" },
      { level: "Media", value: "Media" },
      { level: "Mining and Metals", value: "Mining and Metals" },
      { level: "Oil and Gas", value: "Oil and Gas" },
      {
        level: "Pharmaceuticals and Healthcare",
        value: "Pharmaceuticals and Healthcare",
      },
      { level: "Power", value: "Power" },
      { level: "Private Equity", value: "Private Equity" },
      { level: "Sovereigns", value: "Sovereigns" },
      { level: "Telecommunication", value: "Telecommunication" },
      { level: "Space Laws", value: "Space Laws" },
      { level: "Establishing Business", value: "Establishing Business" },
      { level: "Anti-Money Laundering", value: "Anti-Money Laundering" },
      { level: "Drug Law", value: "Drug Law" },
      { level: "Multinational Disputes", value: "Multinational Disputes" },
      { level: "Labor Law", value: "Labor Law" },
      { level: "Others", value: "Others" }
    ];

    return (
      <div className="admin-cutomer-profile-area" >
      <div className="receipnest-file-customer-files-area" >
    <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'100px'}}>
      <div style={{padding:'20px',paddingLeft:'20px',paddingRight:'20px'}}>
   
<div className="visitor-form-border-box1">
        <div className="visitor-form-border-box-left1">
m
</div>
<div className="visitor-form-border-box-right1">
j
</div>
        </div>
        <form onSubmit={this.submitHandler} className="counsellingForm">
        
          <div className="caseFiletopAreaHeaderREceipnest">
            <h1 style={{fontWeight:'550'}}>Open a  File <span className="arabic-style-font-visitor" style={{marginLeft:'20px'}}>فتح ملف</span></h1>
            <img src={require("../../images/logo/logo55.png")} />
          </div>
          <div className="caseFiletopAreaHeaderb">
            <p>
              {" "}
              <p >
                Date{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getDate() + 1 > 9 ? "" : 0}
                  {new Date().getDate()}
                </span>{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getMonth() + 1 > 9 ? "" : 0}
                  {new Date().getMonth() + 1}
                </span>{" "}
                <span className="date-label-deisgin-box">
                  {new Date().getFullYear()}
                </span>{" "}
              </p>
            </p>
            <div className="file-no-area" >
              <p style={{fontSize:'15px'}}>File no. BZ/....</p>
            </div>
          </div>
          <div className="visitor-form-border-box">
        <div className="visitor-form-border-box-left">
m
</div>
<div className="visitor-form-border-box-right">
j
</div>
        </div>
          <div className="client-area-design" style={{display:'flex',justifyContent:'space-between'}}>
           
            <h3 style={{fontWeight:'550'}}> Client*<span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'24px'}}>الموكل</span></h3>
     
           <div className="visitor-type-direction" style={{ marginTop: "10px",marginBottom:'0px' }}>
           <p style={{marginBottom:'10px' }}>  Typing In Arabic </p>  <div>  <label className="switch99"><input onChange={this.handleArabic} type="checkbox" id="togBtn" /><div className="slider99 round99"><span className="on99">Yes</span><span className="off99">No</span></div></label>
</div>
           </div>
          </div>
          <div className="row">
            
            <div className="col-12" style={{ marginTop: "20px" }}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                        <p>File Registration <span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'18px'}}>تسجيل الملف</span></p>
                        <div>
                        
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-1"
                            onClick={this.handleRegistration}
                            type="checkbox"
                            defaultChecked
                            value="value1"
                          />
                          <label for="styled-checkbox-1"></label>
                        </div>
                      </div>
                      {this.state.registrationFlag ? '': <p style={{color:'red'}}>Must select the File Registration </p>}
                    </div>
                     
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                        <p>Consultation<span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'18px'}}>استشارة</span></p>
                        <div>
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-3"
                            type="checkbox"
                            value="CONSULTATION"
                           /* onChange */
                           checked={this.state.ConsultationFlag}
                           onChange={this.handleConsultation}
                          />
                          <label for="styled-checkbox-3"></label>
                        </div>
                      </div>
                    </div>
                    {/*  <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>استشارة</p>   
                   <div>

<span className="checkbo-label-A" >B</span>
                   <input class="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4"/>
    <label for="styled-checkbox-4"></label>
                   </div>

 
                    </div>  
                     </div> */}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                        <p>Create a power of attorney <span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'18px'}}>عمل وكالة</span></p>
                        <div>
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-5"
                            type="checkbox"
                            value="value5"
                            checked={this.state.attorneyFlag}
                            onChange={this.handleAttorney}
                          />
                          <label for="styled-checkbox-5"></label>
                        </div>
                      </div>
                    </div>
                    {/*      <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>عمل وكالة</p>   
                   <div>

<span className="checkbo-label-A" >C</span>
                   <input class="styled-checkbox" id="styled-checkbox-6" type="checkbox" value="value6"/>
    <label for="styled-checkbox-6"></label>
                   </div>

 
                    </div>  
                     </div> */}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="client-file-area-left-side">
                        <p>Behalf of another Person <span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'18px'}}>نيابة عن شخص آخر</span></p>
                        <div>
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-be"
                            type="checkbox"
                            value="valuebe"
                               checked={this.state.behalfFlag}
                            onChange={this.handleBehalf}
                          />
                          <label for="styled-checkbox-be"></label>
                        </div>
                      </div>
                    </div>
                    {/*  <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>نيابة عن شخص آخر
</p>   
                   

 
                    </div>  
                     </div> */}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-10">
                      <div className="client-file-area-left-side">
                        <p>Corporate <span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'18px'}}>الشركات</span></p>
                        <div>
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-co"
                            type="checkbox"
                            value="valueco"
                            checked={this.state.CorporateFlag}
                            onChange={this.handleCorporate}
                          />
                          <label for="styled-checkbox-co"></label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>الشركات 
</p>   
                   

 
                    </div>  
                     </div> */}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-10">
                      <div className="client-file-area-left-side">
                        <p>Bank <span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'18px'}}>بنوك</span></p>
                        <div>
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-ba"
                            type="checkbox"
                            value="valueba"
                            checked={this.state.BankFlag}
                            onChange={this.handleBank}
                          />
                          <label for="styled-checkbox-ba"></label>
                        </div>
                      </div>
                    </div>
                    {/*    <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>بنوك
</p>   
                   

 
                    </div>  
                     </div> */}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-10">
                      <div className="client-file-area-left-side">
                        <p>Individual<span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'18px'}}>فرد</span></p>
                        <div>
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-in"
                            type="checkbox"
                            value="valuein"
                            checked={this.state.IndividualFlag}
                            onChange={this.handleIndividual}
                          />
                          <label for="styled-checkbox-in"></label>
                        </div>
                      </div>
                    </div>
                    {/*  <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>فرد 
</p>   
                   

 
                    </div>  
                     </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h3
                className="customer-top-area-heading"
                style={{ marginBottom: "20px" }}
              >
                {" "}
                Client Details
              </h3>
            </div>
            <div className="col-12 col-md-6">
            <div className="receptionist-note-add-file">
              <p style={{fontSize:'24px',fontWeight:'600'}}>Note*</p>
         <p>You can only register the file of client once you have selected the client first, then you can proceed further. His name, address and other credentials will be filled automatically.</p>
            </div>
            </div>
            <div className="col-12 col-md-6">
            <div className="receptionist-note-add-customer">
              <p> <i className="fa fa-plus"></i> 
              <Dropdown
    text={this.state.customerAdded?' choose another Client':' Add Client'}
    floating
    className='icon'
    defaultOpen={this.state.open}
  >
    <Dropdown.Menu>
      <Input icon='search' onChange={this.handleSearch} placeholder="Type Client Name" onClick={e => e.stopPropagation()} iconPosition='left' name='search' />
     {this.state.errMsgUser.length>0 ? <Dropdown.Item>
        <center><p style={{color:'red'}}>{this.state.errMsgUser}</p></center>
        </Dropdown.Item>:''}
      
      <Dropdown.Header style={{fontSize:'14px',textTransform:'capitalize',fontWeight:'520'}} icon='user outline' content='Existing Clients' />
  
      <Dropdown.Divider />
     {/*  <div  className="dropSown-area-addcutomer"> */}
     <Dropdown.Menu scrolling>
      {this.state.customers.map((item,index)=>{
    
        if(this.state.searchFlag){
       
          var reg45 = new RegExp(this.state.key.split('').join('.*'), 'i');
          if ((item.firstName+' '+item.lastName).match(reg45)){
            return <Dropdown.Item onClick={()=>this.handleCustomer(item)}>
            <span style={{fontSize:'14px',fontWeight:'520'}}>{item.firstName+' '+item.lastName}</span> <br />
        <span  style={{fontSize:'13px',fontWeight:'500'}}>{item.phoneNumber}</span>
          </Dropdown.Item>
          }else{
            if(index===0){
              return   <Dropdown.Item key={index}>
              <span  style={{fontSize:'14px',fontWeight:'520'}}>Not Found</span>
                </Dropdown.Item>
            }
      
          }
        }else{
          return <Dropdown.Item onClick={()=>this.handleCustomer(item)}>
          <span style={{fontSize:'14px',fontWeight:'520'}}>{item.firstName+' '+item.lastName}</span> <br />
      <span  style={{fontSize:'13px',fontWeight:'500'}}>{item.phoneNumber}</span>
        </Dropdown.Item>
        }
     
        
      })}
   
      </Dropdown.Menu>
    
   
    {/*   <Dropdown.Divider />
      <Dropdown.Item
      >
        <center> <p> <center><i className="fa fa-plus-circle"></i> Add Customers</center></p></center>
        </Dropdown.Item> */}
     
    </Dropdown.Menu>
  </Dropdown>
              </p>
            </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                    Full Name*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
الاسم الكامل
                </span>
                </div>
                <input
                  placeholder=""
                  value={this.state.fname}
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  name="fname"
                  className="form-control"
                  type="text"
                />

                {this.state.error.fname && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.fname}
                  </p>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                   Nationality*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
الجنسية
                </span>
                </div>
                <input
                  className="Nationality1-list"
                  value={this.state.Nationality}
                  onChange={this.changeHandler}
                  name="Nationality"
                  placeholder="search..."
                  autoComplete="on"
                  style={{ width: "100%" }}
                  list="Nationality1"
                  name="Nationality"
                />
                <datalist id="Nationality1">
                  {/*      <option selected disabled>Nationality</option> */}
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan, Province of China">
                    Taiwan, Province of China
                  </option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </datalist>
                {this.state.error.Nationality && (
                  <p>{this.state.error.Nationality}</p>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="formInput">
               
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                Company Name
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
اسم الشركة
                </span>
                </div>
                <input
                  placeholder=""
                 ref={(input=>this.Occupation=input)}
                  className="form-control"
                  type="text"
                  dir={this.state.typingArabic?"rtl":"ltr"}
                />

              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                personal/company Manager
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
الشخص بصفتة الشخصية/ مدير الشركة
                </span>
                </div>
                <input
                 dir={this.state.typingArabic?"rtl":"ltr"}
                  placeholder=""
             /*      value={this.state.Employer} */
                  name="Employer"
                /*   onChange={this.changeHandler} */
                ref={(input)=>this.Employer=input}
                  className="form-control"
                  type="text"
                />

               
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="formInput">
             
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
              UAE Address*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
العنوان الإماراتي
                </span>
                </div>
                <input
                 dir={this.state.typingArabic?"rtl":"ltr"}
                  placeholder=""
                  value={this.state.UAEAddress}
                  name="UAEAddress"
               /*    onChange={this.changeHandler} */
                  className="form-control"
                  type="text"
                />

                {this.state.error.UAEAddress && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.UAEAddress}
                  </p>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
           Permanent Address*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
العنوان الثابت
                </span>
                </div>
                <input
                        dir={this.state.typingArabic?"rtl":"ltr"}
                  placeholder=""
                  value={this.state.PermanentAddress}
                  name="PermanentAddress"
                  onChange={this.changeHandler}
               ref={(input)=>this.PermanentAddress=input}
                  className="form-control"
                  type="text"
                />
  {this.state.error.PermanentAddress && (
                  <p>{this.state.error.PermanentAddress}</p>
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
         Mobile Number*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
رقم الهاتف المحمول
               </span>
                </div>
                <input
                
                  placeholder=""
                  value={this.state.MobileNumber}
                  name="MobileNumber"
                  /* onChange={this.changeHandler} */
                  className="form-control"
                  type="text"
                />

                {this.state.error.MobileNumber && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.MobileNumber}
                  </p>
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
        Home Tel
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
هاتف المنزل
               </span>
                </div>
                <input
                  placeholder=""
                /*   value={this.state.HomeTel} */
                  name="HomeTel"
              /*     onChange={this.changeHandler} */
              ref={(input)=>this.HomeTel=input}
                  className="form-control"
                  type="text"
                />

             {/*    {this.state.error.HomeTel && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.HomeTel}
                  </p>
                )} */}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="formInput">
               
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
       Office Tel
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
هاتف المكتب
               </span>
                </div>
                <input
                  placeholder=""
                /*   value={this.state.OfficeTel} */
                  name="OfficeTel"
               /*    onChange={this.changeHandler} */
               ref={(input)=>this.OfficeTel=input}
                  className="form-control"
                  type="text"
                />

              {/*   {this.state.error.OfficeTel && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.OfficeTel}
                  </p>
                )} */}
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="formInput">
               
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
       Fax
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
فاكس
               </span>
                </div>
                <input
                  placeholder=""
             /*      value={this.state.Fax} */
                  name="Fax"
                /*   onChange={this.changeHandler} */
                ref={(input)=>this.Fax=input}
                  className="form-control"
                  type="text"
                />

             {/*    {this.state.error.Fax && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.Fax}
                  </p>
                )} */}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formInput">
           
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
  Certified Email*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
البريد الالكتروني المعتمد
               </span>
                </div>
                <input
                  placeholder=""
                  value={this.state.email}
                  name="email"
               /*    onChange={this.changeHandler} */
                  className="form-control"
                  type="text"
                />

                {this.state.error.email && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.email}
                  </p>
                )}
              </div>
            </div>

            <div className="col-12">
              <div className="formInput">
              
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
   Summary Of Matter*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
ملخص الموضوع
               </span>
                </div>
                <textarea
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  className="form-control"
                  value={this.state.description}
                  onChange={this.changeHandler}
                  placeholder="Type your case detail*"
                  name="description"
                />
                {this.state.error.description && (
                  <p>{this.state.error.description}</p>
                )}
              </div>
            </div>
            <div className="col-12">
             
              <h3 style={{fontWeight:'550'}}> Payment Type*<span className="arabic-style-font-visitor" style={{marginLeft:'20px',fontSize:'24px'}}>نوع الدفع
</span></h3>
     
            </div>
            <div className="col-12 col-lg-6 col-md-6">
                        <div className="formInput">
                 
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    Payment Method*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
طريقة الدفع او السداد
               </span>
                </div>
                                    <select
                                        value={this.state.cardType} 
                                        className="form-control"
                                         onChange={this.changeHandler} 
                                        name="cardType">
                                        {options1.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.error.cardType && <p>{this.state.error.cardType}</p>}
                        </div>
                    </div>
                {this.state.cardType==="CHEQUE"?<div className="col-12">   <div className="row"> 
                    <div className="col-12 col-lg-6 col-md-6">
                        <div className="formInput">
                 
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    Cheque No*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
رقم الشيك
               </span>
                </div>
                <input
                  placeholder=""
              value={this.state.ChequeNo} 
                  name="ChequeNo"
                  onChange={this.changeHandler1}
                ref={(input)=>this.ChequeNo=input}
                  className="form-control"
                  type="text"
                />
                                 <p>   {this.state.submitFlag?this.state.ChequeNo.length<1?'Please enter cheque No':'':''}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6">
                        <div className="formInput">
                 
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
    Cheque Date*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
تحقق من التاريخ
               </span>
                </div>
                <input
                  placeholder=""
                  value={this.state.ChequeDate}
                  name="ChequeDate"
                  onChange={this.changeHandler1}
                ref={(input)=>this.ChequeDate=input}
                  className="form-control"
                  type="date"
                />
                             <p>   {this.state.submitFlag?this.state.ChequeDate.length<1?'Please enter cheque Date':'':''}</p>
                           </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6">
                    <div className="upload-photo-area-check">
                     <div>
                      
          <center>{this.state.preview1.length>0?<embed width="100" height="60" name="plugin" src={this.state.preview1[0]}  /> :<Icon style={{marginTop:'30px'}} name="upload" size="large" />}
                    
                     <div className="image-upload">
                                    <label for="file-input-cheque">
                                    <p style={{fontSize:'12px',cursor:'pointer',fontWeight:'550'}}>Select Cheque Image/PDF</p>
                                    </label>

                                    <input id="file-input-cheque"  name="photos" type="file" accept=".png,.jpg,.jpeg,.pdf"    onChange={this.handleFileChange1} />
                                  </div>
                                  </center>
                        </div> 
                        <p style={{color:'red'}}>   {this.state.submitFlag?this.state.ChequeFile.length<1?'Please select Cheque':'':''}</p>               
                    
                   
            </div>
            </div>
             </div></div>:''}
                     
                    <div className="col-12" style={{ marginBottom: "30px" }}>
              <p
                style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Our professional fees of 1000 + VAT for open a file or verbal
                legal advise will be deducted from our fees for matter of
                further work is required.
              </p>
              <p className="office-visit-first-time-arabic" style={{marginBottom:'0px',color:'#282e3f',fontWeight:'550',textTransform:'capitalize',textAlign:'right'}}>يتقاضى المكتب 1000 درهم + ضريبة القيمة المضافة عن فتح ملف او الاستشارة الشفوية و سيتم خصم
                                                  المبلغ من أتعاب القضية اذا ما تم قبولها او تسجيلها لدى المكتب</p>
{" "}
            </div>

          
            <div className="col-12">
              {this.state.trimmedDataURL ? (
                <div className="customer-file-signature-area1">
                  {" "}
                  <p>
                    Your Signatures has been saved You can clear and save again:{" "}
                  </p>{" "}
                  <center>
                    <img src={this.state.trimmedDataURL} />
                  </center>{" "}
                </div>
              ) : null}
            </div>
            {/* signatureFlag */}
         

            <div className="col-12" style={{ marginTop: "0px" }}>
              <div
                className="checkbow-style-areafile"
                style={{ color: "#666666" }}
              >
                <span>
                  {" "}
                  <input
                    class="styled-checkbox"
                    name="termsConditions"
                    onChange={this.handleConditions}
                    id="styled-checkbox-term"
                    type="checkbox"
                    value="valueterm"
                  />
                  <label for="styled-checkbox-term"></label>
                </span>{" "}
                <p
                  style={{
                    fontSize: "15px",
                    marginLeft: "10px",
                    fontWeight: "550",
                    color: "#282e3f",
                  }}
                >
               <ol>
                   <li>	This a computer generated invoice to open a file payment. This is an electronic receipt; any signature or stamp on it is not required.</li>
                  <li>	Our professional fess of AED 1000 + VAT for open a file or verbal legal advice will be deducted from our fess for matter of further work is required.</li>
                   <li>	The acknowledgment of this opening a file receipt by the customer of the paid amount AED 1000 is shown as a NON-REFUNDABLE payment. The customer has no right to claim the refund of opening a file payment.</li>
                   </ol>{" "}
{" "}
                </p>
              </div>
              {this.state.termsSubmitFlag ? (
                this.state.termsFlag ? (
                  <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                    You must accept the terms & Conditions
                  </p>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
           
              <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                      {this.state.errMessage}
                  </p>

              {/* 
<p style={{textAlign:'right',fontWeight:'550',color:'#282e3f'}}>في حال عدم دفع الأقساط المتفق عليها و الرسوم بالعقد أو عدم تزويد المكتب بكافة المستندات المطلوبة و رسوم الخبرة وكافة الرسوم الإدارية يحق للمكتب عدم الحضور بالدعوى دون أد¦ مسؤولية مع حفظ
حق المكتب في المطالبة بباقي الأتعاب المتفق عليها</p>        
              
                      
            */}
            </div>

            <div className="col-12" style={{ marginTop: "0px" }}>
              
              <div className="signon-bottom-area1">
             {/*  <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       /> */}
                 <div></div>      
            <div className="customer-file-loader-with-button" >  <span style={{width:'50px',height:'50px'}}>{this.state.loaderFlag?<div style={{marginRight:'10px'}} className="loader"></div> :""}</span>  <button style={{display:'inline'}} disabled={this.state.buttonFlag}  type="submit">submit</button>
             </div>
             </div>
            </div>
          </div>
          <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        <Modal.Header closeButton>
            <Modal.Title className="heading-modal"
            >
         <img style={{width:'30px'}} src={require('../../images/about/man-01.png')} />  File Messsage
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
              <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
               <center>
                   <p><i style={{fontSize:'120px'}} class="fa fa fa-check-circle-o"></i></p>
                   <h2>thank you!</h2>
                   {this.state.fileDetail.invoice?
                   this.state.fileDetail.invoice.paymentType==="CHEQUE"?  <p>
                     Your cheque is received and your file is opened with a file number BZ/{this.state.fileNo}. However, the file status will remain pending until the cheque is cleared from the bank.
                    </p>
                   :  <p>Your file has been opened under file number BZ/{this.state.fileNo}</p>
                 
                   :''}
                  <button onClick={this.handleClose} className="sent-button-des" ><Link to="/allfiles"  style={{color:'white'}} >OK</Link></button>
               </center>
              </div>
           
          </Modal.Body>
      
        </Modal>
        </form>
  </div>
  </div>
       </div>
       </div>
    );
  }
}
const mapStateToProps=(state)=> {
  return {
    customerFileReducer: state.CustomerFileForm1.customerFileReducer,
    getUsersReducer:state.CustomerFileForm1.getUsersReducer


  };
}
export default compose(
  withReducer("CustomerFileForm1", reducer),
  connect(mapStateToProps),withRouter
)(CustomerFileForm); 