import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const UPDATE_BANK_RECEIPT1 = "UPDATE_BANK_RECEIPT1";
export const UPDATE_BANK_RECEIPT1_LOADING =
"UPDATE_BANK_RECEIPT1_LOADING";
export const UPDATE_BANK_RECEIPT1_LOADING_FAILED =
"UPDATE_BANK_RECEIPT1_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const uploadBankReceipt1Loading = ms => ({
    type: UPDATE_BANK_RECEIPT1_LOADING,
    payload: ms
    });
    
    export const uploadBankReceipt1LoadingFailed = ms => ({
    type: UPDATE_BANK_RECEIPT1_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function uploadBankReceipt1(data) {
  /* ${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getCountOfQuotation  */
    const request = axios.post(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/addBankReceipt`,data,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });

    return dispatch => {

    dispatch(uploadBankReceipt1Loading());
 
    request
    .then(response => {   
      console.log(response,"response, cancel invoice")   
    if (response.status == 200) {
    dispatch({
    type: UPDATE_BANK_RECEIPT1,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        uploadBankReceipt1LoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(uploadBankReceipt1LoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(uploadBankReceipt1LoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(uploadBankReceipt1LoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


