import {combineReducers} from 'redux';
import {customerEmailVerifyReducer } from './customerEmailVerify';
import {getUserCreateInvoceFilesReducer} from './getUserFiles'
/* import {creatInvoiceReducer} from '../../../AddInvoice/store/reducers/createInvoice'
import {getInvoiceReducer} from '../../../AddInvoice/store/reducers/getCurrentInvoice' */
import {getUserInvoiceReducer} from './getUserInvoices'
import {sendInvoiceEmailReducer} from './sendCustomerReducer'
import {RecoredUserPaymentReducer} from './recorderPaymentReducer'
const reducer = combineReducers({
   RecoredUserPaymentReducer,sendInvoiceEmailReducer,getUserInvoiceReducer,customerEmailVerifyReducer,getUserCreateInvoceFilesReducer
    });
    
    export default reducer;

    /* ,creatInvoiceReducer,getInvoiceReducer,getInvoiceReducer */
