


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../sideBar/style.scss';
import '../sideBar/style.css';
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import {Form,ButtonToolbar,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import * as Actions from "./store/actions";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { compose } from "redux";
import InvoiceFileView from './invoiecFIleView'
import { Alert } from 'antd';
import { Popover,DatePicker,Popconfirm,message } from 'antd';
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { Header, Table, Rating,Icon, Button } from 'semantic-ui-react'
import { Dropdown, Input,Label,Checkbox } from 'semantic-ui-react'
import { AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
var moment = require('moment');
 class  FileInvoices extends Component{
      constructor(props, context) {
        super(props, context);
        this.state = {
          show:false,
          limit:10,
          pageNo:1,
          userId:undefined,
          totalPages:11,
          filesData:[],
          pageNumber:1,
          options:[],
          direction:null,
          column:null,
          printInvoice:{},
          show1:false,
          loaderFlag:false,
          recordPaymentSuccess:false,
          recoredPaymentError:'',
          recordPaymentFlag:false,
          recordPaymentFlagError:false,
          loaderFlag:false,
          fileInvoiceData:{},
          sendInvoiceLoading:false,
          sendInvoiceSuccess:false,
          sendInvoiceErrorFlag:false,
          sendInvoiceFlag:false,
          sendInvoiceError:'',
          show5:false,
          BankReceiptSuccess:false,
          BankReceiptError:'',
          BankReceiptFlag:false,
          BankReceiptErrorFlag:false,
          loaderFlag3:false,
          preview:'',
          photo:'',
          uploadReceipt:{},
          depositedAmount:''
    } 
    }
    handleClose2=()=> {
      this.setState({ show2: false });
    }
    
    handleShow2=()=> {
      this.setState({ show2: true });
    }
    handleClose1=()=> {
      this.setState({ show1: false,loaderFlag:false,recoredPaymentError:''});
    }
  
    handleShow1=()=> {
      this.setState({ show1: true });
    }
    handleClose=()=> {
      this.setState({ show: false,sendInvoiceError:'',sendInvoiceErrorFlag:false,sendInvoiceLoading:false });
    }
  
    handleShow=()=> {
      this.setState({ show: true });
    }
    handleClose5=()=> {
      this.setState({ show5: false });
      setTimeout(()=>{
        this.setState({ preview:'',photo:'',loaderFlag3:false,BankReceiptSuccess:false,BankReceiptFlag:false,BankReceiptErrorFlag:false,BankReceiptError:'' });
    
      },500)
    
    }
    
    handleShow5=()=> {
      this.setState({ show5: true });
    }
componentWillMount(){
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNo
}
this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))
this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllCustomerFilesINvoices */
}  
handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,filesData:[],pageNumber:1})
  let obj={
    userId:this.state.userId,
    limit:e.target.value,
    pageNo:1
  }
  this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))

} 
handleJumpPage=(e)=>{
  this.setState({pageNumber:e.target.value,filesData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:e.target.value
  }
  this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))
 
}
handlePageInc=()=>{
  if(this.state.totalPages>this.state.pageNumber){
  this.setState((prevState, props) => ({
    pageNumber: prevState.pageNumber + 1
}));
this.setState({filesData:[]})
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNumber+1
}
this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNumber>1){
    this.setState({filesData:[]})
    this.setState((prevState, props) => ({
      pageNumber: prevState.pageNumber - 1
  }));
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNumber-1
  }
  this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))
  }

}
/* allFilesInvoicesReducer */ 
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.allFilesInvoicesReducer;

  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.fileInvoices.docs.length>0){
        this.setState({filesData:resNumber1.data.fileInvoices.docs,totalPages:resNumber1.data.fileInvoices.totalPages})
      }else{
        this.setState({filesData:["nothing"]})
      }
   
    } else {
    this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
    }
  }
  let resNumber2 =nextProps.fileSearchReducer;

  
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }
  let resNumber3=nextProps.recoredFilePaymentReducer
  if (!resNumber3.isLoading) {
    if (resNumber3.errMsg == null && !(resNumber3.data === undefined)) {
        if(this.state.recordPaymentFlag){
          this.setState({loaderFlag:false,recordPaymentFlag:false,recordPaymentSuccess:true})
    }
    } else {
    if(this.state.recordPaymentFlagError){
      this.setState({loaderFlag:false,recordPaymentFlagError:false,recoredPaymentError:resNumber3.errMsg})
    }
    }
  }
  let resNumber5 = nextProps.sendFileInvoiceReducer;
  if (!resNumber5.isLoading) {
    if (resNumber5.errMsg == null && !(resNumber5.data === undefined)) {
        if(this.state.sendInvoiceFlag){
  this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:true,sendInvoiceErrorFlag:false,sendInvoiceFlag:false})
        }
    } else {
      if(this.state.sendInvoiceErrorFlag){
        this.setState({sendInvoiceLoading:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:false,sendInvoiceError:resNumber5.errMsg})
        }
    }
  }
    /* bankReceiptInvoiceReducer1 */
    let resNumber9= nextProps.bankReceiptInvoiceReducer1;
    if (!resNumber9.isLoading) {
      if (resNumber9.errMsg == null && !(resNumber9.data === undefined)) {
          if(this.state.BankReceiptFlag){
    this.setState({loaderFlag3:false,BankReceiptSuccess:true,BankReceiptError:'',BankReceiptFlag:false})
          }
      } else {
        if(this.state.BankReceiptErrorFlag){
          this.setState({loaderFlag3:false,BankReceiptSuccess:false,BankReceiptErrorFlag:false,BankReceiptError:resNumber9.errMsg})
          }
      }
    }
}
/* fileSearchReducer */
handleSearch=(e)=>{
 
if(e.length>0){
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered}) 
this.props.dispatch(Actions.getSeachedFiles(e))
}else{
   
  this.setState({filesData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))

}
}
handleSelect=(e,value)=>{
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered,filesData:[]}) 
  let obj={
    userId:value.data._id,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))

}
handleSort=(action)=>{
switch (action.type) {
  case 'CHANGE_SORT':
    this.setState({column:action.column})
    this.setState((state)=>({
      direction:state.direction === 'ascending' ? 'descending' : 'ascending'
    }))
    this.setState((state)=>({
      filesData:state.filesData.reverse()
    }))
  default:
    
}
}
handleViewFileInvoice=(id)=>{
  localStorage.setItem('fileInvoiceId',id)
  this.props.history.push('/fileInvoicePreview')
}
handlePrint=(item)=>{
  this.setState({printInvoice:item})

  this.setState({show2:true})
}
handleRecordPayment=(item)=>{
  this.setState({show1:true,fileInvoiceData:item})

}
handleRecordPaymentSubmit=()=>{
  this.setState({loaderFlag:true,recordPaymentFlag:true,recordPaymentFlagError:true})
  let obj={
    fileId:this.state.fileInvoiceData.fileId
  }
  this.props.dispatch(Actions.recoredFilePayment(obj))
  setTimeout(()=>{
    let obj={
      userId:undefined,
      limit:this.state.limit,
      pageNo:this.state.pageNo
    }
    this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))
  },1000)
  setTimeout(()=>{
    let obj={
      userId:undefined,
      limit:this.state.limit,
      pageNo:this.state.pageNo
    }
    this.props.dispatch(Actions.getAllCustomerFilesINvoices(obj))
  },2000)
}

handleSendFileInvoice=(item)=>{
  this.setState({show:true,sendInvoiceLoading:true,sendInvoiceError:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:true,sendInvoiceFlag:true})
  let obj={
    invoiceId:item._id
  }
  
  this.props.dispatch(Actions.sendFileInvoice(obj))
  
}
hnadleConfirm=()=>{
  this.setState({show1:false,recordPaymentSuccess:false})
}
handleREceipt=(item)=>{
  this.setState({show5:true,uploadReceipt:item})
}
handleFileChange = e => {
  this.setState({
    photo: e.target.files[0],BankReceiptError:''
  })
 
  var reader = new FileReader();
var url = reader.readAsDataURL(e.target.files[0]);
reader.onloadend =  (e)=> {
  this.setState({
      preview: [reader.result]
  })
}
}

handleBankReceiptSumbit=()=>{
  if(!(this.state.depositedAmount==="")){
  if(!(this.state.photo==="")){
    this.setState({loaderFlag3:true,BankReceiptFlag:true,BankReceiptErrorFlag:true})
    /*       const formData = new FormData();
    console.log(this.state.photo,"kkkkkkkkk")
      formData.append('cv', this.state.cv); */
   const  Data=  new FormData();
  
   Data.append('fileInvoiceId',this.state.uploadReceipt._id) 
   Data.append('fileId',this.state.uploadReceipt.fileId) 
   Data.append('bankReceipt',this.state.photo) 
   Data.append('userId',this.state.uploadReceipt.userProfile.userId) 
   Data.append('depositedAmount',this.state.depositedAmount) 
      this.props.dispatch(Actions.uploadBankReceipt1(Data))
  }else{
    message.error('Please select the Bank Receipt First');
    this.setState({BankReceiptError:"Please select the Bank Receipt First"})
  }
}else{
  this.setState({BankReceiptError:"Please Enter the Deposit Amount"})
}
}
  render(){
    return (
        <div className="admin-cutomer-profile-area" >
        <div className="receipnest-file-customer-files-area">
        <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
           
           <div  className="admin-cutomer-REcepnest-files-rea">
           <div className="receipnest-file-customer-files-area-top-heading">
                 <p style={{fontSize:'16px',fontWeight:'550',marginTop:'25px'}}>  File Invoices</p>
                </div>
                <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>
               
  
            </div>
            <div className="receipnest-file-customer-files-area655-bottom-area" style={{overflow:'auto',display:'block'}} >
            <div className="session-table-area-receptionist">
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
        textAlign='left' singleLine >Customer</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'invoice' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'invoice' })}
        singleLine textAlign='center'>Invoice No</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'email' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'email' })}
        singleLine width='3' textAlign='center'>Email</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Contact' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Contact' })}
        singleLine textAlign='center'>Contact</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'created' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'created' })}
        singleLine textAlign='center'>created At</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'Payment' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Payment' })}
        singleLine textAlign='center'>Payment Type</Table.HeaderCell>
        <Table.HeaderCell singleLine textAlign='center'>Amount</Table.HeaderCell>
        <Table.HeaderCell singleLine textAlign='center'>Status</Table.HeaderCell>
        <Table.HeaderCell  singleLine textAlign='center'>Actions</Table.HeaderCell>
     
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.filesData.length>0 ?
                  this.state.filesData[0]!="nothing"?
                this.state.filesData.map((item)=>{
                return  <Table.Row>
                <Table.Cell textAlign='left'>
                {item.userProfile && item.userProfile.firstName+" "+item.userProfile.lastName}
              
                </Table.Cell>
                <Table.Cell textAlign='center' singleLine>BZ/{item.invoiceNo}</Table.Cell>
                <Table.Cell textAlign='center'>
                { item.userProfile && item.userProfile.email}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                 {item.userProfile && item.userProfile.phoneNumber} 
                </Table.Cell>
                <Table.Cell textAlign='center' >

                { moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </Table.Cell>
                <Table.Cell textAlign='center'>
{item.paymentType}
              {/*   { moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')} */}
                </Table.Cell>
                <Table.Cell textAlign='center'>
AED {item.amountToBePaid}
              {/*   { moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')} */}
                </Table.Cell>
               
                {item.invoiceStatus==="PAID"? <Table.Cell  textAlign='center' singleLine >{item.invoiceStatus==="PAID"?<div style={{color:'green',fontWeight:'550'}}><center>{item.invoiceStatus}</center></div>:<div style={{color:'red',fontWeight:'550'}}><center>{item.invoiceStatus}</center></div>} </Table.Cell>: <Table.Cell warning textAlign='center' singleLine >{item.invoiceStatus==="PAID"?<div style={{color:'green',fontWeight:'550'}}><center>{item.invoiceStatus}</center></div>:<div style={{color:'red',fontWeight:'550'}}><center> <Icon name='attention' />{item.invoiceStatus}</center></div>} </Table.Cell>}
                <Table.Cell textAlign='center'>
                <Popover placement="bottomRight" content={( <ul  className="dropw-down-cursor-menu-invoice">
                <li onClick={()=>this.handleViewFileInvoice(item._id)}>View</li>
                <li >
               <div onClick={()=>{this.handlePrint(item)}}>
               Print & PDF
             
             
               </div>
                </li>
                {item.invoiceStatus==='PAID'   ?<li><div onClick={()=>this.handleREceipt(item)}>Upload Receipt</div></li> :<li></li>  }
               
               <li  >
               <Popconfirm
                 title="Are you sure to send this Open File Invoice to Client?"
                 onConfirm={()=>this.handleSendFileInvoice(item)}
                 onCancel={()=>console.log("cancel")}
                 okText="Send"
                 cancelText="No"
                 placement="left"
               >
                 <div>Send File Invoice</div>
               </Popconfirm>
                 </li>
                {item.invoiceStatus==="UNPAID"  ?  <li><div  onClick={()=>this.handleRecordPayment(item)} className="add-border-drop">Record Payment</div></li>:<li></li>}
                
               
               
                
                
               
                
               
               
                </ul>)}  trigger="hover">
                <Icon name='play circle outline' size="large" />
    </Popover>
    </Table.Cell>
              </Table.Row>
                })
                :<Table.Row>
                     <Table.Cell colSpan='9'
 textAlign='center'>
                    <center>
                  <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                  No Invoice Exist Yet!
                  </div>
                  </center>    
                </Table.Cell>
                </Table.Row>:<Table.Row>
                     <Table.Cell  textAlign='center' colSpan='9' /* width={6} */ 
 textAlign='center'>
                     <center>
               <div className="loader-for-tabel-invoce">
               <div className="loader1188">
                </div> 
               </div>
               </center>  
                </Table.Cell>
                </Table.Row>}
     
    
     
    </Table.Body>
  </Table>

          </div>
           
           
            </div>
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per page
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNumber} of {this.state.totalPages} <span className="pagination-table-quotaion-dec"><i title="Previous Page" onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} title="Next Page" className="fa fa-caret-right"></i></span>
              </span>
            jump to page  <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
            </div> 
           
        </div>
        <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        
        <Modal.Body>
          {/* fa fa-exclamation-circle */}
          {this.state.sendInvoiceLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Sending Please wait....</p>
           </center>
          </div>
          :
         
          this.state.sendInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
              <p>The Invoice has been successfully send to the Customer email </p>
              <button className="sent-button-des" onClick={this.handleClose}>Ok</button>
          </center>
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.sendInvoiceError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
          </center>
         </div>
           
         }
        </Modal.Body>
    
      </Modal> 
        <Modal show={this.state.show2}  onHide={this.handleClose2}  size="sm" centered  >
      <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Invoice Number#{this.state.printInvoice.invoiceNo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa-print"></i></p>
              {/* <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p> */}
              <p>Your Invoice is ready to Print. </p>
              <button className="sent-button-des" >
              <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
          {({ handlePrint }) => (
              <div onClick={handlePrint}>Print </div>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
              </button>
          </center>
         </div>
        </Modal.Body>
    
      </Modal>
      <Modal show={this.state.show1} onHide={this.handleClose1} size="sm" centered  >
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Confirmation
          </Modal.Title>
        </Modal.Header>
    
      <Modal.Body>
      {this.state.recordPaymentSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
            <center>
                <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>You Have Successfully Record Payment of this File Invoice </p>
                <button className="sent-button-des" onClick={this.hnadleConfirm}>Ok</button>
            </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Have you received AED 1000? Confirm it before recording the payment!</p>
       {this.state.loaderFlag? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
       <p style={{color:'red'}}>{this.state.recoredPaymentError}</p>
     </div>
      }
     
        </Modal.Body>
        {this.state.recordPaymentSuccess?'':
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose1}>Cancel</Button>
    <Button.Or />
    <Button onClick={this.handleRecordPaymentSubmit} color='black'>Record Payment</Button>
  </Button.Group>
        </Modal.Footer> }
      </Modal> 
      <Modal show={this.state.show5} onHide={this.handleClose5} size="sm" centered  >
      {this.state.BankReceiptSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Upload Bank Receipt
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body>
      {this.state.BankReceiptSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="Bank Receipt of this Invoice is Successfuly Uploaded"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose5} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Upload the Bank Receipt of this Invoice here Please selete the Bank Receipt and then Upload</p>
       <div style={{fontSize:'12px',fontWeight:'600',marginLeft:'2px'}}>Deposit Amount</div>
     <Input  labelPosition='right' type='text' placeholder='Amount'>
     <Label style={{fontSize:'12px',paddingTop:'13px'}} basic>AED</Label>
    <input onChange={(e)=>this.setState({depositedAmount:e.target.value})} value={this.state.depositedAmount} style={{width:"180px"}} />
    <Label>.00</Label>
  </Input>
  <br />
  <br />
       <div className="upload-photo-area">
                     <div>
          <center>{this.state.preview.length>0? <img style={{width:'150px',marginTop:'30px'}} src={this.state.preview} />:<Icon style={{marginTop:'30px'}} name="upload" size="huge" />}
                    
                     <div className="image-upload">
                                    <label for="file-input2">
                                    <p style={{fontSize:'12px',cursor:'pointer',fontWeight:'550'}}>Select Bank Receipt</p>
                                    </label>

                                    <input id="file-input2"  name="photo15" type="file" accept=".png,.jpg,.jpeg"    onChange={this.handleFileChange} />
                                  </div>
                                  </center>
                    </div>    
                   
            </div>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag3? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}>Uploading Please wait....</p>
            </center>:''}
     {this.state.BankReceiptError.length>0?<Alert message={this.state.BankReceiptError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.BankReceiptSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose5}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleBankReceiptSumbit} color='black'>Upload</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal> 
    
      <div style={{display:'none'}}><InvoiceFileView printInvoice={this.state.printInvoice} ref={el => (this.componentRef = el)} /></div>
  
        </div>
    )
  }
}
  const mapStateToProps=(state)=> {
    return {
        allFilesInvoicesReducer: state.FileInvoicesofAll.allFilesInvoicesReducer,
        fileSearchReducer:state.FileInvoicesofAll.fileSearchReducer,
        recoredFilePaymentReducer:state.FileInvoicesofAll.recoredFilePaymentReducer,
        sendFileInvoiceReducer:state.FileInvoicesofAll.sendFileInvoiceReducer,
        bankReceiptInvoiceReducer1:state.FileInvoicesofAll.bankReceiptInvoiceReducer1
    };
  } 
export default compose(
  withReducer("FileInvoicesofAll", reducer),
  connect(mapStateToProps),withRouter
)(FileInvoices); 