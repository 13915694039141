import {combineReducers} from 'redux';



import {fileSearchReducer} from './fileSearch'
import {allCustomerChequesFileReducer } from './allCustomerbankCheques';
import {fileApproveChequeReducer} from './approveChequeRed'
import {FilecancelChequeReducer} from './cancelChequeRed'

const reducer = combineReducers({
  allCustomerChequesFileReducer,fileSearchReducer,fileApproveChequeReducer,FilecancelChequeReducer
    });
    
    export default reducer;
