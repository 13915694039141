import {combineReducers} from 'redux';



import {fileSearchReducer} from './fileSearch'
import {allFilesReducer } from './allCustomerFilesRed';
const reducer = combineReducers({
   allFilesReducer,fileSearchReducer 
    });
    
    export default reducer;
