import React, { useEffect, useState, Component } from "react";
import { NavLink, Link,withRouter } from "react-router-dom";

import "./style.css";
import "./formStyle.scss";
import "./style.scss";
import AvatarEditor from "react-avatar-editor";
import StarRatings from "react-star-ratings";
import ReactToPrint from 'react-to-print';

import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Button,Modal,Header } from 'semantic-ui-react'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";

import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import QuotationTopAreaPreview from './fileInvoiceVeiwDetail'
import Invoiceview from './invoiecFIleView';
import './newnooneinvoice.scss'

import { ToastContainer, toast } from 'react-toastify';
import {
  Form,
  ButtonToolbar,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
  Accordion,
  Card,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

class PreviewFileInvoice extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.toastId=React.createRef(null);
  }
  
  render() {
 /*    console.log(this.state.customers,"lllllllllllllllllllll") */

    return (
      <div className="admin-cutomer-profile-area">
        <div className="receipnest-file-customer-files-area">
      
          <div
            className="receipnest-file-customer-files-area655"
            style={{ paddingBottom: "300px",overflow:'auto' }}
          >
            <QuotationTopAreaPreview />
          </div>
      
        </div>
        <Modal
       /*  trigger={<Button onClick={this.handleOpen}>Show Modal</Button>} style={{overflow:'auto'}} */
        open={this.state.modalOpen}
      
        basic
        size='small'
        centered
        
      >
        <Header icon='browser' content='Congratulations' />
        <Modal.Content>
          <p style={{color:'white',fontSize:'22px'}}>You have Successfully create the invocie thanks!</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.handleSuccess} inverted>
            <Icon name='checkmark' /> Got it
          </Button>
        </Modal.Actions>
      </Modal>
      </div>
    );
  }
}
  const mapStateToProps=(state)=> {
    return {
 /*      getUserCreateInvoceReducer: state.AccountantInvoicesAddition1.getUserCreateInvoceReducer,
      getUserCreateInvoceFilesReducer:state.AccountantInvoicesAddition1.getUserCreateInvoceFilesReducer,
      creatInvoiceReducer:state.AccountantInvoicesAddition1.creatInvoiceReducer */
    };
  } 
export default compose(
  withReducer("PreviewQuotation1", reducer),
  connect(mapStateToProps),withRouter
)(PreviewFileInvoice); 
