import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import QuotationPreviewArea from '../../../components/quotationPreview'
import './style.scss'
const PreviewQuotationPage = () => {

    return (
        <Fragment>
            
           <QuotationPreviewArea
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default PreviewQuotationPage
/* QuotationPreviewArea */