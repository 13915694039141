import React, { Component, useEffect } from "react";

import ReactToPrint, {
  PrintContextConsumer,
} from "react-to-print"; /* 
import { Visible, Hidden, setConfiguration } from "react-grid-system"; */

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
  Media,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  withRouter,
  browserhistory,
} from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";

var QRCode = require('qrcode.react');
var moment = require('moment');
class Invoiceview extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      invoiceFlag78: false,
      createFileError: "",
      invoiceData: {},
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }
  componentDidMount() {
    /*   store.subscribe(() => {
      let resNumber5 = store.getState().Invoiceview1.getInvoiceReducer;
      if(!(resNumber5.data===null)){
      this.setState({invoiceData:resNumber5.data.invoice})
      }

      }) */
    /*   if (window.performance) {
        if (performance.navigation.type == 1) {
        this.props.history.push("/AccountantInvoice")
        } else {
       
        }
      } */
  }

  render() {
    return (
      <div id="divIdToPrintInovoice">
        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="invoice-le-view_area">
                  <div class="invoice-preview__warning-message le-text--body"></div>

                  <div className="invoice-previe-div-body-le">
                    <div className="invoice-contemplary-body-inside">
                      <section className="invoice-contemplary-body-inside__header">
                        <Row>
                          <Col lg={6} xl={6} md={5} sm={5} xs={5}>
                            {/* #BD9B3E
    #CBBC99
    */}
                            <div className="preview-invoiece-top-area">
                              <center>
                                <div className="preview-invoiece-top-area-top"></div>
                                <p
                                  style={{
                                    fontSize: "34px",
                                    color: "white",
                                    marginTop: "40px",
                                    marginBottom: "10px",
                                  }}
                                >
                               EXPENSE
                                </p>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    color: "white",
                                    marginBottom: "0px",
                                  }}
                                >
                                 TRN # 100455656700003
                                </p>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    color: "white",
                                    marginBottom: "0px",
                                  }}
                                >
                                  File No :{" "}BZ/
                                  {this.props.printInvoice.fileNo &&
                                    this.props.printInvoice.fileNo}
                                </p>
                                <p style={{ fontSize: "18px", color: "white" }}>
                                  Expense No :{" "}
                                  {this.props.printInvoice.fileNo &&
                                    this.props.printInvoice.expenseNo}
                                </p>
                              </center>
                            </div>
                            {/*   <div className="invoice-contemplary-body-inside__header__logo-invoice">
    <img src="https://drive.google.com/uc?export=view&id=1-_drJvm5qH_2kdvRpPvcFhhOEm3vOn8F"/>
   </div> */}
                          </Col>

                          <Col lg={6} xl={6} md={7} sm={7} xs={7}>
                            <Media as="li" className="media-ride-list-invoice">
                              {/* drive.google.com/uc?export=view&id=1f3-K2REZmxDl_vPFLHmjipx7ZFtDZG71 */}
                              <div className="list-steps-of-ride-invoice">
                                <div className="invoice-contemplary-body-inside__header__logo">
                                  <img src="https://wave-prod-accounting.s3.amazonaws.com/uploads/invoices/business_logos/efcee799-07f1-4667-a72c-eaf2265ecbb7.png" />
                                </div>
                              </div>
                              <Media.Body className="ride-body-content">
                                <div className="contemporary-template__header__info">
                                  <div className="le-heading--title" style={{fontWeight:'550'}}>
                                    BADER HAMAD AL ZAABI
                                  </div>
                                  <strong className="le-text--strong">
                                    ADVOCATES & LEGAL CONSULTANTS
                                  </strong>
                                  <div className="address-le-view-invoice fs-exclude">
                                    <div className="address__field-invoice-view">
                                      <p
                                        className="le-text le-text--body"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Office No. 3403 - 34th Floor - Burj Al
                                        Salam Tower - Trade Centre 1 - Sheikh
                                        Zayed Road - Dubai - United Arab
                                        Emirates - P.O. Box No. 89498, Dubai.{" "}
                                        {/* TRN: 10037287460003 */}
                                        Tel: +9714 2555111, Fax: +9714 2368888
                                        E-MAIL: info@legalemirates.com
                                      </p>
                                    </div>
                                    <div class="address__field"></div>
                                    <div class="address__field"></div>
                                  </div>
                                </div>
                              </Media.Body>
                            </Media>
                          </Col>
                        </Row>
                      </section>

                      <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top"></div>
                      </div>

                      <div class="description_of_card_of_invoicetable">
                        <div class="description_of_card_of_invoicetable_area">
                          <div className="contemporary-template__header__logo-invoice-view">
                            <Row style={{ width: "100%" }}>
                              <Col lg={5} xl={4} md={4} sm={4} sm={4} xs={4}>
                                <div style={{ marginLeft: "0px" }}>
                                  <h2 className="le-table-customer-data-heading">
                                    <span>
                                      <i class="fa fa-square box-gradient-icon-invoice-view"></i>
                                      Client Details
                                    </span>
                                  </h2>

                                  <div>
                                    <table className="le-table--plain">
                                      <tbody className="le-table__body">
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong  className="le-text--strong-invoice">
                                              {this.props.printInvoice
                                                .userProfile &&
                                                this.props.printInvoice
                                                  .userProfile.firstName +
                                                  " " +
                                                  this.props.printInvoice
                                                    .userProfile.lastName}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong  style={{fontWeight:'600'}} className="le-text--invoice">
                                              {this.props.printInvoice
                                                .userProfile &&
                                                this.props.printInvoice
                                                  .userProfile.phoneNumber}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong style={{fontWeight:'600'}} className="le-text--invoice">
                                              {this.props.printInvoice
                                                .userProfile &&
                                                this.props.printInvoice
                                                  .userProfile.email}
                                            </strong>
                                          </td>
                                        </tr>

                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong
                                              style={{ maxWidth: "24ch",fontWeight:'600' }}
                                              className="le-text--invoice"
                                            >
                                              {this.props.printInvoice
                                                .userProfile &&
                                                this.props.printInvoice
                                                  .userProfile.address}
                                            </strong>
                                          </td>
                                        </tr>

                                        {/*    <tr className="le-table__row">
            <td className="le-table__cell" colspan="1">
              <strong className="le-text--invoice">United Arab Emirates</strong>
              </td>
            </tr> */}

                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--invoice">
                                              {/* {this.props.data.customer.email && this.props.data.customer.email} */}
                                            </strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={2} xl={2} md={2} sm={12} xs={2}>
                                <div className="invoice-contemplary-body-inside__header__logo-inside-tale-customer">
                                { this.props.printInvoice.userProfile &&
                                   <div style={{position:'relative',width:'150px',height:'150px'}}>
     <QRCode size="150" style={{width:'150px'}} value={`Case Invoice \n Name:${ this.props.printInvoice
                                                  .userProfile.firstName +
                                                  " " +
                                                  this.props.printInvoice
                                                    .userProfile.lastName} \n Your email: ${ this.props.printInvoice
                                                      .userProfile.email}  \n  Your Contact: ${this.props.printInvoice
                                                        .userProfile.phoneNumber} \n \n Invoice No: BZ/${this.props.printInvoice.expenseNo} \n File No: BZ/${this.props.printInvoice.fileNo}\n Invoice Status:${this.props.printInvoice.status} \n Expiry Date:  ${ moment( this.props.printInvoice.expireAt).format('MMMM Do YYYY')}  \n Pending Amount: AED ${this.props.printInvoice.pendingAmount} \n Paid Amount: AED ${this.props.printInvoice.amountToBePaid-this.props.printInvoice.pendingAmount}  \n Total Amount: AED ${this.props.printInvoice.amountToBePaid}  \n \n Bader Al Zaabi Advocates Office \n Contact:+971 4 2555111 \n Email info@legalemirates.com \n Website: https://legalemirates.com/` } />
     <img src={require('../../images/favicon.png')} style={{position:'absolute',top:'0',left:'0',bottom:'0',right:'0',margin:'auto'}} />
       </div>
       }
                                </div>
                              </Col>
                              <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                               <center>
                               <div className="date-info-invoice-le">
                                  <h2 style={{textAlign:'left',marginLeft:'50px'}} className="le-table-customer-data-heading">
                                    <span  style={{marginLeft:'20px'}}>
                                      <i class="fa fa-square box-gradient-icon-invoice-view"></i>
                                      DATE & TIME
                                    </span>
                                  </h2>

                                  <div>
                                    <table className="le-table--plain">
                                      <tbody className="le-table__body">
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--issuedate-invoice">
                                              Issue date:{" "}
                                              {this.props.printInvoice
                                                .generatedAt &&
                                                moment( this.props.printInvoice.generatedAt).format('MMMM Do YYYY')
                                               }
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--issuedate-invoice">
                                              Issue Time: { moment(new Date(this.props.printInvoice.expireAt)).format('h:mm:ss a')}
                                          {/*     {console.log(this.props.printInvoice.expireAt,"fffffffffffff")} */}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <div style={{backgroundColor:'#BD9B3E',fontWeight:'550',padding:'5px',color:'white',marginTop:'15px',minWidth:'200px'}} className="le-text--issuedate-invoice">
                                            <center>
                                            Due Date : {" "}
                                              {this.props.printInvoice
                                                .expireAt &&
                                                moment( this.props.printInvoice.expireAt).format('MMMM Do YYYY')
                                              }
                                            </center>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                               </center>
                              </Col>
                            </Row>
                          </div>

                          <Col xl={12} md={12} lg={12}>
                            <div class="contemporary-template__items-table">
                              <table class="le-table">
                                <thead
                                  class="le-table__header"
                                  style={{
                                    backgroundColor: "rgb(189, 155, 62)",
                                  }}
                                >
                                  <tr class="le-table__row">
                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                     
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Sr. No
                                    </th>
                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                     
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                    Expense  Type
                                    </th>

                                    <th
                                      class="le-table__cell--amount class-table-particular"
                                      colspan="5"
                                      style={{
                                        color: "rgb(255, 255, 255)",
                                        width: "335px",
                                      }}
                                    >
                                      Description of Expenses
                                    </th>

                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Amount
                                    </th>
                                  </tr>
                                </thead>

                                {this.props.printInvoice.items &&
                                  this.props.printInvoice.items.map(
                                    (item, index) => {
                                      return (
                                        <tr class="le-table__row">
                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized"  style={{fontWeight:'600',color:'#666666'}}>
                                                {index + 1}.
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized"  style={{fontWeight:'600',color:'#666666'}}>
                                                {item.serviceType}
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            class="le-table__cell"
                                            colspan="5"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized" style={{fontWeight:'600',color:'#666666'}}>
                                                {item.detail}
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized" style={{fontWeight:'600',color:'#666666',textAlign:'right'}}>
                                                AED{" "}
                                                {new Intl.NumberFormat().format(
                                                  item.amount
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </table>
                            </div>
                          </Col>

                          <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                            <div class="le-divider-below"></div>
                          </div>

                          <Row className="how-to-work-grid">
                            <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginBottom: "20px",
                                }}
                              >
                                <h2 className="le-table-customer-data-heading-behind-table">
                                  <span>
                                   {/*  <i
                                      style={{
                                        fontSize: "18px",
                                        paddingRight: "5px",
                                      }}
                                      class="fa fa-square  box-gradient-icon-invoice-view"
                                    ></i> */}
                                 {/*    Payment Method */}
                                  </span>
                                </h2>

                                <div>
                                  <table className="le-table--plain-behind-table">
                                    <tbody className="le-table__body-behind-table">
                                      <tr className="le-table__row-behind-table">
                                        <td className="le-table__cell-behind-table">
                                          <strong className="le-text--strong-invoice-behind-table">
                                          {/*   By Cash */}
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                              <div class="contemporary-template__totals__amounts">
                                <div class="contemporary-template__totals__amounts__line">
                                  <div class="contemporary-template__totals__amounts__line__label">
                                    <strong class="le-text--strong-totals__amounts" >
                                      Sub Total:
                                    </strong>
                                  </div>
                                  <div class="contemporary-template__totals__amounts__line__amount">
                                    <span class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                      AED{" "}
                                      {this.props.printInvoice.amountToBePaid &&
                                        new Intl.NumberFormat().format(
                                          this.props.printInvoice
                                            .amountToBePaid -
                                            this.props.printInvoice.vatIncluded
                                        )}
                                    </span>
                                  </div>
                                </div>
                                <div></div>

                                <div>
                                  <div class="contemporary-template__totals__amounts__line">
                                    <div class="contemporary-template__totals__amounts__line__label">
                                      <strong class="le-text--strong-totals__amounts">
                                        VAT (5%):
                                      </strong>
                                    </div>
                                    <div class="contemporary-template__totals__amounts__line__amount">
                                      <strong class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                        AED{" "}
                                        {this.props.printInvoice.vatIncluded &&
                                          new Intl.NumberFormat().format(
                                            this.props.printInvoice.vatIncluded
                                          )}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="contemporary-template__totals__amounts__line">
                                    <div class="contemporary-template__totals__amounts__line__label">
                                      <strong class="le-text--strong-totals__amounts">
                                         Discount:
                                      </strong>
                                    </div>
                                    <div class="contemporary-template__totals__amounts__line__amount">
                                      <strong class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                        AED{" "}{/* discount */}
                                        {this.props.printInvoice.discount?
                                          (new Intl.NumberFormat().format(
                                            this.props.printInvoice.discount
                                          )):'0'}
                                      </strong>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="contemporary-template__totals__amounts__line" style={{fontWeight:'600'}}>
                                    <div class="contemporary-template__totals__amounts__line__label">
                                      <strong class="le-text--strong-totals__amounts">
                                        Outstanding Balance:
                                      </strong>
                                    </div>
                                    <div class="contemporary-template__totals__amounts__line__amount">
                                      <strong class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                        AED{" "}
                                        {this.props.printInvoice.amountToBePaid &&
                                  new Intl.NumberFormat().format(
                                    this.props.printInvoice.amountToBePaid
                                  )}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="contemporary-template__divider--full-width-sign contemporary-template__divider--bold-sign">
                                <div class="le-divider-amount-small"></div>
                              </div>
            {this.props.printInvoice.payments && this.props.printInvoice.payments.length>0 ?
            this.props.printInvoice.payments.map((item)=>{
              return <div className="le-text-al-payment-amount-usingcash">
              <div  className="le-text-al-payment-amount-usingcash"  style={{fontWeight:'550',fontSize:'14px',color:'#000000'}}>
                Payment on {moment(item.createdAt).format('MMMM Do YYYY')} using {item.paymentType}:</div>
            <div className="text-authorized-payment-amount-usingcash" style={{marginRight:'10px',fontWeight:'550',fontSize:'14px',color:'#000000'}}>AED { new Intl.NumberFormat().format(
                                   item.paidAmount
                                  )}</div>
    

    </div>

            })
            :''}
                              

                              <div className="amount-padding-color-price">
                            Remaining    Amount  : &nbsp; &nbsp;&nbsp; 
                                &nbsp; <span style={{marginRight:'8px'}}>AED</span>{" "}
                                {/* pendingAmount */}
                             <span style={{marginRight:'-8px'}}>   {this.props.printInvoice.pendingAmount &&
                                   new Intl.NumberFormat().format(
                                    this.props.printInvoice.pendingAmount
                                  )}</span>
                              </div>

                                     
                              {/* <div class="contemporary-template__divider--bold contemporary-template__divider--small-margin">
                <div class="le-divider">
                  </div>
                  </div> */}
                            </Col>
                          </Row>

                          <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                            <div class="le-divider"></div>
                          </div>

                          <div class="description_of_card_of_blogpost2">
                            <div class="description_of_card_of_newsroom_area-blogpost2">
                              <h2 class="blogs-description-area-of-main-headings-blogpost2">
                                Terms & Conditions
                              </h2>

                              <p style={{fontWeight:'600',fontSize:'13px'}} class="invoice-description-area-of-preview--text">
                              1.	Kindly retain the original computer generated receipt for any upcoming claims. If original receipt is lost, no claims or reprint of the receipt will be entertained.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} class="invoice-description-area-of-preview--text">
                                2.	If no claims or objections are made within the previously mentioned period in clause, then it shall be deemed as an approval of consumer in relation to the provided transaction and their values.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} className="invoice-description-area-of-preview--text">
                                3. 	Documents for the transactions done to be forwarded along with the original invoice.
                              </p>
                              <p style={{fontWeight:'600',fontSize:'13px'}} className="invoice-description-area-of-preview--text">
                                4. 	The maximum liability in the event of any claims or objections is limited to the value of the claimed transactions, which is/are mentioned in the invoice.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} className="invoice-description-area-of-preview--text">
                              5.	BADER HAMAD AL ZAABI ADVOCATES & LEGAL CONSULTANTS reserve the right to review and aimed the above terms from time as it judges fit, and as per the applicable laws.
                              </p>
                              <p style={{fontWeight:'900',fontSize:'13px'}} className="invoice-description-area-of-preview---belowfooter-text">
                                For Questions concerning this invoice, please
                                contact info@legalemirates.com |
                                www.legalemirates.com
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* {console.log(this.props.creatInvoiceReducer,"kkkkkkkkkkkkkkyyyyyyyyyyyy")} */}
      </div>
    );
  }
}

export default Invoiceview;
