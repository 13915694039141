import React from 'react'
import FormAdminSignIn from './FormAdminSignIn'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import './style.css';
const AdminSignINArea = ({ className }) => {
    return (
        <div className={`${className}  center-ALign_contianer`}>
            <div className="container">
                <div className="row flex-column-reverse flex-md-row">
                    <div className="col-lg-7 col-12">
                      <div className="admin-login-left-area-legalemirates">
                       <div className="admin-login-left-area-legalemirates-top-area">
                           <center>
                           <p><span style={{color:'#E9D276'}}>Accountant</span><span  style={{color:'white'}}>Panel</span></p>
                         
                           <img src={require('../../images/logo/logo2.png')} style={{width:'200px',marginBottom:'15px'}} />
                       
                           </center>
                        </div>    
                        <div className="admin-login-left-area-legalemirates-bottom-area">
                       <center>
                       <h2>Welcome to Legal Emirates Accountant Panel</h2> 
                         <p>Only authorized users have access to create, handle, and administer the Legal Emirates website, IOS & Android application from this Admin Panel. You are not allowed to access our Admin Panel without the Company's permission. We can take legal action against any suspicious or unauthorized activity.</p>  
                       <p style={{marginTop:'40px'}}><i className="fa fa-circle"></i> <i className="fa fa-circle-o"></i> <i className="fa fa-circle-o"></i></p>
                       </center>
                         </div>   
                    

                        </div>  
                    
                    </div>
                    <div className="col-12 col-lg-5 admin-login-right-area-legalemirates-area">
           
             <div className="admin-login-right-area-legalemirates-content">
             <h2 style={{color:'white'}}><img style={{width:'40px',marginRight:'15px'}} src={require('../../images/signIn/01-01.png')} />Accountant Login</h2>
                     
                     <FormAdminSignIn /> 
             </div>
       
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default AdminSignINArea