import {combineReducers} from 'redux';
/* import {creatInvoiceReducer} from '../../../AddInvoice/store/reducers/createInvoice'
import {getInvoiceReducer} from '../../../AddInvoice/store/reducers/getCurrentInvoice' */
import {getFileIinvoiceReducer} from './getFileInvoice'
import {sendFileInvoiceReducer} from './sendFileInvoiceRed'
import {recoredFilePaymentReducer} from './recordFilePay'
const reducer = combineReducers({
    recoredFilePaymentReducer,sendFileInvoiceReducer,getFileIinvoiceReducer,
    });
    
    export default reducer;
/* creatInvoiceReducer,getInvoiceReducer */