import {combineReducers} from 'redux';
/* import {creatInvoiceReducer} from '../../../AddInvoice/store/reducers/createInvoice'
import {getInvoiceReducer} from '../../../AddInvoice/store/reducers/getCurrentInvoice' */
import {getUserQuotationReducer} from './getUserQuotation'
import {sendQuotationEmailReducer} from './sendQuotation'
import {quotationToInvoiceReducer} from './invoiceToQuotation'
const reducer = combineReducers({
   quotationToInvoiceReducer,sendQuotationEmailReducer,getUserQuotationReducer,
    });
    
    export default reducer;
/* creatInvoiceReducer,getInvoiceReducer */