


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import $ from 'jquery'
import './style.css';
import './style.scss';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { AutoComplete } from 'antd';
import { Dropdown, Input,Label,Icon,Checkbox } from 'semantic-ui-react'
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import { Alert } from 'antd';
import Invoiceview from './invoiceview'
import {Form,ButtonToolbar,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import { Header, Table, Rating,Popup , Button} from 'semantic-ui-react'
import { Popover,DatePicker,Popconfirm,message } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Dimmer, Loader, Image, Segment,List } from 'semantic-ui-react'
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import { useReactToPrint } from 'react-to-print';
import withReducer from "../../store/withReducer";
import Webcam from "react-webcam";

const WebcamComponent = () => <Webcam />;
var moment = require('moment');
class  AccountantExpenseAll extends Component{
  constructor(props, context) {
    super(props, context);
    this.state = {
      pageNo:1,
      InvoiceExist:false,
      type:'text',
      type1:'text',
      AllCustomer:'',
      AllStatements:'',
      endDate:'',
      startDate:'',
      filterDropdown:false,
      counterFilter:0,
      invoicesData:[],
      pageNo:1,
      limit:10,
      status:'',
      isSent:false, 
      allInovices:[],
      allInovicesSent:[],
      allInovicesPaid:[],
      allInovicesUpaid:[],
      show:false,
      sendInvoiceLoading:false,
      sendInvoiceSuccess:false,
      sendInvoiceErrorFlag:false,
      sendInvoiceFlag:false,
      sendInvoiceError:'',
      show1:false,
      loaderFlag:false,
      recordPaymentSuccess:false,
      recoredPaymentError:'',
      recordPaymentFlag:false,
      recordPaymentFlagError:false,
      invoiceData:'',
      printInvoice:{},
      printInvoice:{},
      show2:false,
      stats:{},
      totalDocs:1,
      totalPages:1,
      sendInvoiceLoading:false,
      sendInvoiceSuccess:false,
      sendInvoiceErrorFlag:false,
      sendInvoiceFlag:false,
      sendInvoiceError:'',
      loaderFlag:false,
      recordPaymentSuccess:false,
      recoredPaymentError:'',
      recordPaymentFlag:false,
      recordPaymentFlagError:false,
      show2:false,
      options:[],
      userId:undefined,
      direction:null,
      column:null,
      open:false,
      CancelInvoiceSuccess:false,
      CancelInvoiceFlag:false,
      CancelInvoiceErrorFlag:false,
      loaderFlag1:false,
      show3:false,
      CancelInvoiceError:'',
      cancelItem:{},
      editInvoice:{},
      show4:false,
      editInvoiceSuccess:false,
      editInvoiceFlag:false,
      editInvoiceErrorFlag:false,
      editInvoiceError:'',
      editInvoice1:{},
      show5:false,
      BankReceiptSuccess:false,
      BankReceiptError:'',
      BankReceiptFlag:false,
      BankReceiptErrorFlag:false,
      loaderFlag3:false,
      preview:'',
      photo:'',
      uploadReceipt:{} ,
      depositedAmount:'',
      recordPaymentType:'', 
      preview1:'',
      photo1:'',
      show6:false,
      show7:false,
      loaderFlagCheque:false,
      chequeDetail:{},
      imageLoader:false,
      show11:false,
      sendInvoiceSmsLoading:false,
      sendInvoiceSmsSuccess:false,
      sendInvoiceErrorSmsFlag:false,
      sendInvoiceSmsFlag:false,
      sendInvoiceSmsError:'',
      currentPreview:''
} 
/* this.componentRef = React.createRef(); */
this.webcamRef=React.createRef(null);
}
handleClose=()=> {
  this.setState({ show: false,sendInvoiceError:'',sendInvoiceErrorFlag:false,sendInvoiceLoading:false });
}

handleShow=()=> {
  this.setState({ show: true });
}
handleClose11=()=> {
  this.setState({ show11: false,sendInvoiceSmsError:'',sendInvoiceErrorSmsFlag:false,sendInvoiceSmsLoading:false });
}

handleShow11=()=> {
  this.setState({ show11: true });
}
handleClose1=()=> {
  this.setState({ show1: false,loaderFlag:false,recoredPaymentError:'',recordPaymentSuccess:false });
}

handleShow1=()=> {
  this.setState({ show1: true });
}


handleClose3=()=> {
  this.setState({ show3: false,loaderFlag1:false,CancelInvoiceError:'',CancelInvoiceSuccess:false });
}

handleShow3=()=> {
  this.setState({ show3: true });
}

handleClose4=()=> {
  this.setState({ show4: false,loaderFlag2:false,editInvoiceError:'',editInvoiceSuccess:false });
}

handleShow4=()=> {
  this.setState({ show4: true });
}
handleClose2=()=> {
  this.setState({ show2: false });
}

handleShow2=()=> {
  this.setState({ show2: true });
}
handleClose5=()=> {
  this.setState({ show5: false });
  setTimeout(()=>{
    this.setState({ preview:'',photo:'',loaderFlag3:false,BankReceiptSuccess:false,BankReceiptFlag:false,BankReceiptErrorFlag:false,BankReceiptError:'' });

  },500)

}

handleShow5=()=> {
  this.setState({ show5: true });
}
handleShow6=()=> {
  this.setState({ show6: true });
}
handleClose6=()=> {
  this.setState({ show6: false });
}
handleShow7=()=> {
  this.setState({ show7: true });
}
handleClose7=()=> {
  this.setState({ show7: false });
}
handlePageInc=()=>{
    if(this.state.totalPages>this.state.pageNo){
  this.setState((prevState, props) => ({
    pageNo: prevState.pageNo + 1
}));
let obj={
  status:this.state.status,
  isSent:this.state.isSent,
  limit:this.state.limit,
  pageNo:this.state.pageNo+1,
  userId:this.state.userId
}
this.setState({invoicesData:[]})
this.props.dispatch(Actions.getAllExpenses(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNo>1){
    this.setState((prevState, props) => ({
      pageNo: prevState.pageNo - 1
  }));
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:this.state.pageNo-1,
    userId:this.state.userId
  }
  this.setState({invoicesData:[]})
  this.props.dispatch(Actions.getAllExpenses(obj))
  }

}
changeHandler = event => {
 
    this.setState({
        [event.target.name]: event.target.value 
    })


};

handleFilterDrop=()=>{
    if(this.state.filterDropdown){
 this.setState({filterDropdown:false})
    }else{
        this.setState({filterDropdown:true})
    }
}
componentWillMount(){
  this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllExpenses
  status=UNPAID&isSent=false&limit=10&pageNo=1
  */
 this.setState({invoicesData:[],status:undefined,isSent:undefined})
  let obj={
    status:undefined,
    isSent:undefined,
    limit:10,
    pageNo:1,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllExpenses(obj))
  this.props.dispatch(Actions.invoiceCounter())
}

handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,invoicesData:[],pageNo:1})
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:e.target.value,
    pageNo:1,
    userId:this.state.userId
  }
  this.props.dispatch(Actions.getAllExpenses(obj))
}
handleJumpPage=(e)=>{
    this.setState({pageNo:e.target.value,invoicesData:[]})
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:e.target.value,
      userId:this.state.userId
    }
    this.props.dispatch(Actions.getAllExpenses(obj))
   
  }

componentDidMount(){
/* nd-invoices-filter-area-items-alla removeClass */
$(document.getElementById("allInvoice")).removeClass('nd-invoices-filter-area-items-all');
  $(document.getElementById("allInvoice")).addClass('nd-invoices-filter-area-items-alla');

}
/* getAllInvoicesReducer */
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.getAllInvoicesReducer;
  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.invoices.docs.length>0){
        this.setState({invoicesData:resNumber1.data.invoices.docs,totalDocs:resNumber1.data.invoices.totalDocs,totalPages:resNumber1.data.invoices.totalPages})  
     

      }else{
        this.setState({invoicesData:["nothing"]})  
      }
     
    } else {
      this.setState({invoicesErr:resNumber1.errMsg})  
    }
  }
 
  let resNumber2 =nextProps.fileSearchReducer;

  
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }
}
handleViewInvoice=(id)=>{
this.props.history.push({
  pathname:'/expensepreview',
  state:{id:id}
})
}

handleSendInvoice=(item)=>{
  this.setState({show:true,sendInvoiceLoading:true,sendInvoiceError:false,sendInvoiceSuccess:false,sendInvoiceErrorFlag:true,sendInvoiceFlag:true})
  let obj={
    invoiceId:item._id
  }
  this.props.dispatch(Actions.sendInvoiceToCustomer(obj,this.handleSuccessSendInvoice))
}
handleSuccessSendInvoice=()=>{
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:1
  }
  this.props.dispatch(Actions.getAllExpenses(obj))
  this.props.dispatch(Actions.invoiceCounter())
}
handleSendInvoicesms=(item)=>{
  this.setState({show11:true,sendInvoiceSmsLoading:true,sendInvoiceSmsError:false,sendInvoiceSmsSuccess:false,sendInvoiceSmsErrorFlag:true,sendInvoiceSmsFlag:true})
  let obj={
    invoiceId:item._id
  }
  this.props.dispatch(Actions.sendSMSInvoice(obj,this.handleSendInvoicesmsSuccess))
}
handleSendInvoicesmsSuccess=()=>{
  let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:1
  }
  this.props.dispatch(Actions.getAllExpenses(obj))
  this.props.dispatch(Actions.invoiceCounter())
}

  recordPaymentCustomerSuccess=()=>{
    let obj={
      status:this.state.status,
      isSent:this.state.isSent,
      limit:this.state.limit,
      pageNo:1
    }
    this.props.dispatch(Actions.getAllExpenses(obj))
    this.props.dispatch(Actions.invoiceCounter())
  }
  handlePrint=(item)=>{
    this.setState({printInvoice:item})
  
    this.setState({show2:true})
  }
  handleSearch=(e)=>{
 
    if(e.length>0){
      let uniq = {}
      let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
      this.setState({options:arrFiltered}) 
    this.props.dispatch(Actions.getSeachedFiles(e))
    }else{
       
      let obj={
        status:this.state.status,
        isSent:this.state.isSent,
        limit:this.state.limit,
        pageNo:this.state.pageNo,
        userId:this.state.userId
      }
      this.setState({invoiceData:[]}) 
      this.props.dispatch(Actions.getAllExpenses(obj))
    
    }
    }
  handleSelect=(e,value)=>{
    let uniq = {}
    let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
     let obj={
    status:this.state.status,
    isSent:this.state.isSent,
    limit:this.state.limit,
    pageNo:this.state.pageNo,
    userId:value.data._id
  }
  this.setState({options:arrFiltered,invoiceData:[]}) 
  this.props.dispatch(Actions.getAllExpenses(obj))
  
  }
  handleSort=(action)=>{
    switch (action.type) {
      case 'CHANGE_SORT':
        this.setState({column:action.column})
        this.setState((state)=>({
          direction:state.direction === 'ascending' ? 'descending' : 'ascending'
        }))
        this.setState((state)=>({
          invoicesData:state.invoicesData.reverse()
        }))
      default:
        
    }
    }

    handleEditInvoice=(item)=>{

      this.props.history.push({
        pathname:'/updateexpense',
        state:item
      })
/*       let obj={
      amountToBePaid: item.amountToBePaid,
      expireAt: item.expireAt.toLocaleString().slice(0,10),
      vatIncluded: item.vatIncluded,
      items:item.items
      }
this.setState({editInvoice:obj,show4:true,editInvoice1:item}) */
    }

render(){

  
  return (
    <div className="admin-cutomer-profile-area" >
    <div className="receipnest-file-customer-files-area" >
    <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
{/*     <Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}}  tag>
      <div style={{fontSize:'20px',transform:'rotate(180deg)'}}>Accountant Panel</div>
    </Label>  */}
       <div  className="admin-cutomer-REcepnest-files-rea">
     
       <div className="receipnest-file-customer-files-area-top-heading">
             <p style={{fontSize:'22px',fontWeight:'550',marginTop:'15px',marginLeft:'0px'}}>All Expenses</p>
            </div>
          
           

        </div>
        <div  className="admin-cutomer-REcepnest-files-rea">
      
        <div className="create-invoice-button">
            <p><Link style={{color:'white'}} to="/addExpense">Add an Expense</Link> </p>
          
           </div>
           <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>

      {/*   <div className="filter-area-button">
  <p style={{marginLeft:'0px'}} onClick={this.handleFilterDrop} >Filter {this.state.filterDropdown ? <i style={{cursor:'pointer'}} class="icono5454 fa fa-chevron-up"></i>:<i style={{cursor:'pointer'}} class="icono derecha fa fa-chevron-down"></i>}</p>
        </div> */}
        </div>
        {this.state.filterDropdown ?        <div className="invoice-filter-area">
            <div className="invoive-Filter-Form">
               <div className="container"> 
                <div className="row">
              <div className="col-12">
                  <div className="filter-counter-area-invoice">
                   <div className="filter-counter-area-invoice-left">
                   <span className="filter-counter-area-invoice-counter">{this.state.counterFilter}</span> <span>Active Filter </span>
                   </div>
                   <div  className="filter-counter-area-invoice-right">
                   Clear Filter
                   </div>
                  </div>
                 
                </div>      
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="AllCustomer"
               value={this.state.AllCustomer}
               onChange={this.changeHandler}
               placeholder="All Customer"
               autoComplete="on"
               style={{ width: "100%" }}
               list="AllCustomer"
             />
            {this.state.AllCustomer.length >0? <span className="formInput invoice-filter-close2"><i  onClick={this.handleCustomer} style={{color:'#666666'}} className="fa fa-close"></i></span>
            :''}
             <datalist id="AllCustomer">
               {/*      <option selected disabled>Nationality</option> */}
               <option value="Asad">Asad</option>
               <option value="Haider">Haider</option>
               <option value="Usman">Usman</option>
               <option value="Asim">Asim</option>
</datalist>
</div>
               </div>
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col1">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="AllStatements"
               value={this.state.AllStatements}
               onChange={this.changeHandler}
               placeholder="All Statement"
               autoComplete="on"
               style={{ width: "100%" }}
               list="statement"
             />
             {this.state.AllStatements.length > 0 ?        <span className="formInput invoice-filter-close2"><i onClick={this.handleStatement} style={{color:'#666666'}} className="fa fa-close"></i></span>
             :''}            
             <datalist id="statement">
               {/*      <option selected disabled>Nationality</option> */}
               <option value="Sent">Sent</option>
               <option value="Unsent">Unsent</option>
               <option value="Viewed">Viewed</option>
               <option value="Paid">Paid</option>
</datalist>
</div>
               </div>
               <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col">
               <div className="formInput  invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="startDate"
               value={this.state.startDate}
               placeholder="From"
               onChange={this.changeHandler}
               autoComplete="on"
               style={{ width: "100%",display:'inline' }}
               onFocus={()=>this.setState({type:'date'})}
               type="text"
               type={this.state.type}
             />
               {this.state.startDate.length>0 ?     <span className="formInput invoice-filter-close1"><i  onClick={this.handleStartDate} style={{color:'#666666'}} className="fa fa-close"></i></span>
            :''}
             </div>
             </div>
             <div className="col-6 col-md-4 col-lg-3 my-invoice-filter-col1">
               <div className="formInput invoice-filter-close">
             

             <input
               className="invoice-data-list-cutomer"
               name="endDate"
               value={this.state.endDate}
               onChange={this.changeHandler}
               placeholder="To"
               autoComplete="on"
               style={{ width: "100%" }}
               onFocus={()=>this.setState({type1:'date'})}
               type={this.state.type1}
             />
          {this.state.endDate.length>0 ?     <span className="formInput invoice-filter-close1"><i onClick={this.handleEndDate} style={{color:'#666666'}} className="fa fa-close"></i></span>
           :''}
            </div>
             </div>
</div>

            </div>
            </div>
        </div> :''}
       
 
         
        <div className="receipnest-file-customer-files-area655-bottom-area table-area-invoive" style={{display:'block'}} >
      
   
        <div className="session-table-area-receptionist" style={{marginTop:'25px'}}>
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
        textAlign='left' singleLine>Customer</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'Number' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Number' })}
         textAlign='center' singleLine>Expense No</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'Status' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Status' })}
        singleLine textAlign='center' width="3">File No</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Date' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Date' })}
        singleLine textAlign='center'>Created Date</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'Due' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Due' })}
        singleLine textAlign='center'>Due Date</Table.HeaderCell>
        <Table.HeaderCell
         sorted={this.state.column === 'AmountDue' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'AmountDue' })}
        singleLine textAlign='center'>Total Amount </Table.HeaderCell>
  <Table.HeaderCell
         sorted={this.state.column === 'AmountPen' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'AmountPen' })}
        singleLine textAlign='center'>Pending Amount</Table.HeaderCell>
      
        <Table.HeaderCell singleLine><center>Actions</center></Table.HeaderCell>
     
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.invoicesData.length>0 ?
                  this.state.invoicesData[0]!="nothing"?
                this.state.invoicesData.map((item)=>{
                return  <Table.Row>
                <Table.Cell textAlign='left'>
                {item.userProfile && item.userProfile.firstName+" "+item.userProfile.lastName}
              
                </Table.Cell>
                  <Table.Cell textAlign='center'>
                {item.expenseNo}
                </Table.Cell>
             
              <Table.Cell textAlign='center' singleLine>
                <div className="invoiceStatus" style={{color:'#008000'}}>{item.fileNo}</div>
                </Table.Cell>
         
                

                <Table.Cell textAlign='center'>
              {item.generatedAt.slice(0,10)}
                </Table.Cell>
              
                <Table.Cell textAlign='center' >

               {item.expireAt.slice(0,10)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
AED {item.amountToBePaid.toFixed(2)}
            
                </Table.Cell>
                <Table.Cell textAlign='center'>
AED {item.pendingAmount && item.pendingAmount.toFixed(2)}
            
                </Table.Cell>
                  {/* pendingAmount */}
                <Table.Cell textAlign='center'>
                <Popover placement="bottomRight" content={( <ul  className="dropw-down-cursor-menu-invoice">
                <li onClick={()=>this.handleViewInvoice(item._id)}>View Expense</li>
                {item.status==='PAID' || item.status==='CANCEL' || item.status==='PARTIAL_PAID' ? <li></li> :<li><div onClick={()=>{this.handleEditInvoice(item)}}>Update Expense</div></li>}
                
                <li >
               <div onClick={()=>{this.handlePrint(item)}}>
               Print & PDF
             
             
               </div>
                </li>
               
               
               {/* CHEQUE_RECIEVED
 */}
                
                
               
                
               
               
                </ul>)}  trigger="hover">
                <Icon name='play circle outline' size="large" />
    </Popover>

          
                </Table.Cell>
            
              </Table.Row>
                })
                :<Table.Row>
                     <Table.Cell colSpan='8'
 textAlign='center'>
                    <center>
                  <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                  No Expense Exist Yet!
                  </div>
                  </center>    
                </Table.Cell>
                </Table.Row>:<Table.Row>
                     <Table.Cell  textAlign='center' colSpan='8' 
 textAlign='center'>
                     <center>
               <div className="loader-for-tabel-invoce">
                 <i style={{fontSize:'60px'}} class="fa fa-spinner fa-spin"></i>
              
               </div>
               </center>  
                </Table.Cell>
                </Table.Row>}
     
    
     
    </Table.Body>
  </Table>

          </div>
           
        
            {this.state.InvoiceExist ?<div className="dont-have-quotation">
            <p> <i className="fa fa-info-circle"></i> You don't have any Expense. Why not create an Expense?</p>
            </div> :''}
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
            <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per Page/total:{this.state.totalDocs}
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNo} of {this.state.totalPages}  <span className="pagination-table-quotaion-dec"><i onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} className="fa fa-caret-right"></i></span>
              </span>
             Jump to Page <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
        </div>
        </div> 
        <Modal show={this.state.show} /* onHide={this.handleClose} */ size="sm" centered  >
        
        <Modal.Body>
          {/* fa fa-exclamation-circle */}
          {this.state.sendInvoiceLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Sending Please wait....</p>
           </center>
          </div>
          :
         
          this.state.sendInvoiceSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
              <p>The Expense has been successfully send to the Customer email </p>
              <button className="sent-button-des" onClick={this.handleClose}>Ok</button>
          </center>
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.sendInvoiceError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
          </center>
         </div>
           
         }
        </Modal.Body>
    
      </Modal> 
   <Modal show={this.state.show2}  onHide={this.handleClose2}  size="sm" centered  >
      <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Expense Number#{this.state.printInvoice.expenseNo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px'}} class="fa fa-print"></i></p>
              {/* <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p> */}
              <p>Your Expense is ready to Print. </p>
              <button className="sent-button-des" >
              <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
          {({ handlePrint }) => (
              <div onClick={handlePrint}>Print </div>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
              </button>
          </center>
         </div>
        </Modal.Body>
    
      </Modal> 
   
      
<div style={{display:'none'}}><Invoiceview printInvoice={this.state.printInvoice} ref={el => (this.componentRef = el)} /></div>
    </div>
    </div>
)
}  
  

}
const mapStateToProps=(state)=> {
  return {
    getAllInvoicesReducer: state.AccountantExpenseAll1.getAllInvoicesReducer,
    fileSearchReducer:state.AccountantExpenseAll1.fileSearchReducer,
  };
} 
export default compose(
  withReducer("AccountantExpenseAll1", reducer),
  connect(mapStateToProps),withRouter
)(AccountantExpenseAll); 

