
import React from 'react'
import { Button, Header, Placeholder, Popup,Icon, Card, Image } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../adminSignIn/store/actions/codeVerifyAuth'
const UserHeader = () => {
  const [data, setData] = React.useState(null)
  const timer = React.useRef()
  const dispatch = useDispatch();

  function handleLogout(){
    dispatch(Actions.logoutAdmin()) 
  }
  return (
    <Popup
      on='click'
      onClose={() => {
        setData(null)
        clearTimeout(timer.current)
      }}
      onOpen={() => {
        setData(null)

        timer.current = setTimeout(() => {
          setData({
            description: "jhmjhkjkm,",
            name: "Asim jkehsfjkds",
            title: "fghfdjgidlfk",
          })
        }, 2000)
      }}

      popperDependencies={[!!data]}
      trigger={<Button style={{backgroundColor:'#353535',color:'white'}}   >   {localStorage.accountantToken ? localStorage.accountantName:'Muhammad Tahir'}    <Icon name='angle down' /></Button>}
      wide
      position='bottom right'
    >
      {data === null ? (
        <Placeholder style={{ minWidth: '200px' }}>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='short' />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <>
         <Card>
      <Card.Content>
        <Image
          floated='right'
          size='mini'
          src={require('../../images/profiled1.png')}
        />
        
        <Card.Header>{localStorage.accountantToken ? localStorage.accountantName:'Muhammad Tahir'}</Card.Header>
        <Card.Meta><Icon name='phone' />{localStorage.accountantToken ? localStorage.accountantPhone:'+971558889544'}</Card.Meta>
        <Card.Meta><Icon name='mail' />{localStorage.accountantToken ? localStorage.accountantEmail:'tahir@wowets.com'}</Card.Meta>
        <Card.Description>
        Recording of financial transactions,
Classifying,
Summarizing,
Finding net results,
Exhibiting financial affairs,
Analyzing financial data,
and
Communicating financial information. <strong>Thanks</strong>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
         
          <Button onClick={handleLogout} basic color='black'>
          <Icon name='power off' />Logout
          </Button>
        </div>
      </Card.Content>
    </Card>
        </>
      )}
    </Popup>
  )
}

export default UserHeader