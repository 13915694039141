import {combineReducers} from 'redux';



import {fileSearchReducer} from './fileSearch'
import {allCustomerChequesReducer } from './allCustomerbankCheques';
import {approveChequeReducer} from './approveChequeRed'
import {cancelChequeReducer} from './cancelChequeRed'

const reducer = combineReducers({
  allCustomerChequesReducer,fileSearchReducer,approveChequeReducer,cancelChequeReducer
    });
    
    export default reducer;
