import {combineReducers} from 'redux';
import {getUserCreateInvoceReducer} from './getUsersReducer'

import {getUserCreateInvoceFilesReducer} from './getUserFiles'
import {createInvoiceReducer} from './createQuotaion'
import { getServicesTypeReducer } from "./getServiceType";
import {addServicesTypeReducer} from './addServiceType'

const reducer = combineReducers({
  addServicesTypeReducer,getServicesTypeReducer,getUserCreateInvoceReducer,getUserCreateInvoceFilesReducer,createInvoiceReducer 
    });
    
    export default reducer;
