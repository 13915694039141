import {combineReducers} from 'redux';
import {getAllQuotationsReducer} from './getAllQuotaion'
import {quotationToInvoiceReducer} from '../../../quotationPreview/store/reducers/invoiceToQuotation'
import {sendQuotationEmailReducer} from '../../../quotationPreview/store/reducers/sendQuotation'
import {getQuotationCounterReducer} from './getQuotationCounter'
import {fileSearchReducer} from '../../../receipnestFiles/store/reducers/fileSearch'
import {cancelQuotationReducer} from './cancelQuotaionRed'
import {updateQuotationReducer} from './updateQuotationRed'
const reducer = combineReducers({
   updateQuotationReducer,cancelQuotationReducer,getAllQuotationsReducer,getQuotationCounterReducer,quotationToInvoiceReducer,sendQuotationEmailReducer,fileSearchReducer
 });
    
    export default reducer;
/* , */