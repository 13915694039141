import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_LOADING =
"GET_ALL_USER_LOADING";
export const GET_ALL_USER_LOADING_FAILED =
"GET_ALL_USER_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getUsersLoading = ms => ({
    type: GET_ALL_USER_LOADING,
    payload: ms
    });
    
    export const getUsersLoadingFailed = ms => ({
    type: GET_ALL_USER_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getUsers() {
    const request = axios.get(
    `${process.env.REACT_APP_PROFILE}/api/v1/accountant/userData/getUsers`, {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });
    return dispatch => {

    dispatch(getUsersLoading());
    request
    .then(response => {   
  
    if (response.status == 200) {
    /*  console.log(response,"response getUsers") */
    dispatch({
    type: GET_ALL_USER,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.message));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getUsersLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getUsersLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getUsersLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getUsersLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


