import {combineReducers} from 'redux';



import {fileSearchReducer} from './fileSearch'
import {allCustomerReducer } from './allCustomers';
import {updareCustomerReducer} from './updateCustomer'
const reducer = combineReducers({
   allCustomerReducer,fileSearchReducer,updareCustomerReducer
    });
    
    export default reducer;
