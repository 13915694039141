import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const VEIW_RECEIPT_OF_CUSTOMER = "VEIW_RECEIPT_OF_CUSTOMER";
export const VEIW_RECEIPT_OF_CUSTOMER_LOADING =
"VEIW_RECEIPT_OF_CUSTOMER_LOADING";
export const VEIW_RECEIPT_OF_CUSTOMER_LOADING_FAILED =
"VEIW_RECEIPT_OF_CUSTOMER_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const veiwReceiptImageLoading = ms => ({
    type: VEIW_RECEIPT_OF_CUSTOMER_LOADING,
    payload: ms
    });
    
    export const veiwReceiptImageLoadingFailed = ms => ({
    type: VEIW_RECEIPT_OF_CUSTOMER_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function veiwReceiptImage(value) {
        const request = axios.get(
            `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${value}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
    
    
    return dispatch => {

    dispatch(veiwReceiptImageLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
   /*   console.log(response,"response veiwReceiptImage") */
    dispatch({
    type: VEIW_RECEIPT_OF_CUSTOMER,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        veiwReceiptImageLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(veiwReceiptImageLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(veiwReceiptImageLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(veiwReceiptImageLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


