import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const GET_USER_QUOTATION = "GET_USER_QUOTATION";
export const GET_USER_QUOTATION_LOADING =
"GET_USER_QUOTATION_LOADING";
export const GET_USER_QUOTATION_LOADING_FAILED =
"GET_USER_QUOTATION_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getUserQuotationLoading = ms => ({
    type: GET_USER_QUOTATION_LOADING,
    payload: ms
    });
    
    export const getUserQuotationLoadingFailed = ms => ({
    type: GET_USER_QUOTATION_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getUserQuotation(id) {
    const request = axios.get(
    `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getQuotationById?quotationId=${id}`,
    {
      headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
    });
    return dispatch => {

    dispatch(getUserQuotationLoading());
 
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response getUserQuotation")
    dispatch({
    type: GET_USER_QUOTATION,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getUserQuotationLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getUserQuotationLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getUserQuotationLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getUserQuotationLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


