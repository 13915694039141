import {combineReducers} from 'redux';
import {getAllInvoicesReducer} from './getAllInvoices'
/* import {quotationToInvoiceReducer} from '../../../quotationPreview/store/reducers/invoiceToQuotation'
import {sendQuotationEmailReducer} from '../../../quotationPreview/store/reducers/sendQuotation' */
import {getInvociesCounterReducer} from './getInvoicesCounter'
import {sendInvoiceEmailReducer} from '../../../invoiePreview/store/reducers/sendCustomerReducer'
import {RecoredUserPaymentReducer} from '../../../invoiePreview/store/reducers/recorderPaymentReducer'
import {fileSearchReducer} from '../../../receipnestFiles/store/reducers/fileSearch'
import {cancelInvoiceReducer} from './cancelInvoiceRed'
import {updateInvoiceReducer} from './updateInvoiceRed'
import {bankReceiptInvoiceReducer} from './updateReceiptRed'
import {chequeDetailReducer} from './chequeDetailREd'
import {sendSmsInvoiceReducer} from './sendSmsInvoice'
const reducer = combineReducers({
   sendSmsInvoiceReducer,chequeDetailReducer,bankReceiptInvoiceReducer,updateInvoiceReducer,cancelInvoiceReducer,getAllInvoicesReducer,getInvociesCounterReducer,sendInvoiceEmailReducer,RecoredUserPaymentReducer,fileSearchReducer
 });
    
    export default reducer;
    
/* 



*/
/* quotationToInvoiceReducer,sendQuotationEmailReducer, */