import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const UPDATE_INVOICE_CASES = "UPDATE_INVOICE_CASES";
export const UPDATE_INVOICE_CASES_LOADING =
"UPDATE_INVOICE_CASES_LOADING";
export const UPDATE_INVOICE_CASES_LOADING_FAILED =
"UPDATE_INVOICE_CASES_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const updateQuotationLoading = ms => ({
    type: UPDATE_INVOICE_CASES_LOADING,
    payload: ms
    });
    
    export const updateQuotationLoadingFailed = ms => ({
    type: UPDATE_INVOICE_CASES_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function updateQuotation(obj,id) {
  /* ${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/getCountOfQuotation  */
    const request = axios.patch(
      `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/caseQuotations/editQuotation/${id}`,obj,
      {
        headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
      });

    return dispatch => {

    dispatch(updateQuotationLoading());
 
    request
    .then(response => {   
      console.log(response,"response, cancel invoice")   
    if (response.status == 200) {
    dispatch({
    type: UPDATE_INVOICE_CASES,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        updateQuotationLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(updateQuotationLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(updateQuotationLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(updateQuotationLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


