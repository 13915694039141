import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import AdminResetArea from '../../../components/adminpassReset'
import './style.scss'
const AdminPassReset = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
           
           <AdminResetArea
           className="AdminSignInArea"
            />
        </Fragment>
    )
}
export default AdminPassReset