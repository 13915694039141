import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import AccountantQuotation from '../../../components/quotation'
import './style.scss'
const AccountantQuotationPage = () => {

    return (
        <Fragment>
            
           <AccountantQuotation
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default AccountantQuotationPage
/* QuotationPreviewArea */