import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const FILE_RECORD_PAYMENT = "FILE_RECORD_PAYMENT";
export const FILE_RECORD_PAYMENT_LOADING =
"FILE_RECORD_PAYMENT_LOADING";
export const FILE_RECORD_PAYMENT_LOADING_FAILED =
"FILE_RECORD_PAYMENT_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const recoredFilePaymentLoading = ms => ({
    type: FILE_RECORD_PAYMENT_LOADING,
    payload: ms
    });
    
    export const recoredFilePaymentLoadingFailed = ms => ({
    type: FILE_RECORD_PAYMENT_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function recoredFilePayment(obj) {
    const request = axios.post(
    `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/files/recordPaymentForFile`,obj,
    {
      headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
    });
    return dispatch => {

    dispatch(recoredFilePaymentLoading());
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response recoredFilePayment")
    dispatch({
    type: FILE_RECORD_PAYMENT,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        recoredFilePaymentLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(recoredFilePaymentLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(recoredFilePaymentLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(recoredFilePaymentLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


