import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import AllCustomers from '../../../components/customers'
import './style.scss'
const AllCustomersPage = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            
           <AllCustomers
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default AllCustomersPage

/* AllCustomers */