import React, { Component, useEffect } from "react";

import "./newnooneinvoice.scss";
// import ExpenseViewPri from './invoiceview';
import {
  Dropdown,
  Input,
  Label,
  Icon,
  Checkbox,
  TextArea,
  Button,
  Header,
  Image,
  Divider,
} from "semantic-ui-react";
import ReactToPrint from "react-to-print";
import ModalImage from "react-modal-image";
/*  import html2canvas from 'html2canvas';

import jsPDF from 'jspdf'; */
/* import { Visible, Hidden, setConfiguration } from "react-grid-system"; */
/* import ReactToPrint from 'react-to-print-advanced'; */
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Media,
  Modal,
  ButtonGroup,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  withRouter,
  browserhistory,
} from "react-router-dom";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import { Popover, DatePicker, Popconfirm } from "antd";

import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import store from "../../store";
import ExpenseViewPri from "./expenseView";

var moment = require("moment");
const options = [
  { key: "save", icon: "save", text: "Save and Send", value: "Save and Send" },
  {
    key: "delete",
    icon: "credit card outline",
    text: "Save and Record A Payment",
    value: "Save and Record A Payment",
  },
];
class InvoiceTopAreaPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      expenseData: {},
      open: false,
      show: false,
      sendInvoiceLoading: false,
      sendInvoiceSuccess: false,
      sendInvoiceErrorFlag: false,
      sendInvoiceFlag: false,
      sendInvoiceError: "",
      show1: false,
      loaderFlag: false,
      recordPaymentSuccess: false,
      recoredPaymentError: "",
      recordPaymentFlag: false,
      recordPaymentFlagError: false,
      recordPaymentType: "",
      preview1: "",
      photo1: "",
    };
    this.ref = React.createRef();
  }
  /* /* 
sendInvoiceErrorFlag
sendInvoiceFlag
sendInvoiceError
*/
  /* 
sendInvoiceLoading ?'':
            this.state.sendInvoiceSuccess
*/
  handleClose = () => {
    this.setState({
      show: false,
      sendInvoiceError: "",
      sendInvoiceErrorFlag: false,
      sendInvoiceLoading: false,
    });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose1 = () => {
    this.setState({
      show1: false,
      loaderFlag: false,
      recoredPaymentError: "",
      recordPaymentSuccess: false,
    });
  };

  handleShow1 = () => {
    this.setState({ show1: true });
  };
  //   open = () => this.setState({ open: true })
  //   close = () => this.setState({ open: false })

  show = (dimmer) => () => this.setState({ dimmer, open: true });

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true });
  };

  close = () =>
    this.setState({ open: false, opentwo: false, openthree: false });

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }
  /* sendInvoiceToCustomer */

  handleSendInvoice = () => {
    this.setState({
      show: true,
      sendInvoiceLoading: true,
      sendInvoiceError: false,
      sendInvoiceSuccess: false,
      sendInvoiceErrorFlag: true,
      sendInvoiceFlag: true,
    });
    let obj = {
      invoiceId: this.state.expenseData._id,
    };
    this.props.dispatch(Actions.sendInvoiceToCustomer(obj));
    setTimeout(() => {
      this.props.dispatch(Actions.getUserExpense(localStorage.invoiceId));
    }, 1000);
  };
  componentWillReceiveProps(nextProps) {
    let resNumber4 = nextProps.getUserExpenseReducer;
    if (!resNumber4.isLoading) {
      if (resNumber4.errMsg == null && !(resNumber4.data === undefined)) {
        this.setState({ expenseData: resNumber4.data.expense });
      } else {
        /*  this.setState({createFileError:resNumber4.errMsg,loaderFlag:false,invoiceFlag78:false})  
     console.log(resNumber4.errMsg) */
      }
    }


  }


  render() {

    return (
      <div>
        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} mg={12}>
                <div className="invoice-payment-voucher-view-area">
                  <div
                    className="new-invoice-payment-voucher-header-text-area"
                    role="alert"
                  >
                    <h2>
                      Expense#{" "}
                      {this.state.expenseData.expenseNo &&
                        this.state.expenseData.expenseNo}
                    </h2>

                    <div className="two-button-edi-save-new-invoice-payment-vouche">
                      <Button.Group color="black">
                        <Button
                          onClick={() => this.props.history.push("/addExpense")}
                          style={{
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                            marginRight: "-2px",
                          }}
                        >
                          {" "}
                          Create another Expense
                        </Button>
                        <Dropdown
                          className="button icon"
                          style={{
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                            borderLeft: "1px solid dimgray",
                          }}
                          floating
                          /*   options={options} */
                          /* onClick={this.handleDropDown} */
                          trigger={<React.Fragment />}
                        />
                      </Button.Group>
                    </div>
                  </div>

                  <div></div>

                  <div className="contemporary-template__divider--full-width">
                    <div className="le-divider-top-invoiceone"></div>
                  </div>

                  <div class="invoice-preview__warning-message le-text--body"></div>

                  <div className="invoice-previe-div-body-le">
                    <div className="invoice-contemplary-body-inside">
                      <Container>
                        <div className="status-cust-invoice">
                          <div>
                            <div className="status-cust-headings-invoice">
                              Status
                            </div>
                            <p className="status-cust-draft-invoice">Saved</p>
                          </div>

                          <div>
                            <div className="status-cust-headings-invoice">
                              Customer
                            </div>
                            <p className="status-cust-name-invoice">
                              {this.state.expenseData.userProfile &&
                                this.state.expenseData.userProfile.firstName +
                                  " " +
                                  this.state.expenseData.userProfile.lastName}
                              <span>
                                <i
                                  class="fa fa-exclamation-circle exclamation-icon"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </p>
                          </div>

                          <div>
                            <div className="status-cust-headings-invoice">
                              Amount due
                            </div>
                            <p
                              style={{ fontSize: "17px" }}
                              className="status-cust-amount-invoice"
                            >
                              AED{" "}
                              {this.state.expenseData.amountToBePaid &&
                                new Intl.NumberFormat().format(
                                  this.state.expenseData.amountToBePaid
                                )}
                            </p>
                          </div>

                          <div>
                            <div className="status-cust-headings-invoice">
                              Due Date
                            </div>
                            <p
                              style={{ fontSize: "16px" }}
                              className="status-cust-dueday-invoice"
                            >
                              {this.state.expenseData.generatedAt &&
                                moment(
                                  this.state.expenseData.generatedAt
                                ).format("MMMM Do YYYY")}
                            </p>
                          </div>
                        </div>
                      </Container>

                      <div className="invoice-previe-div-body-le-small-box">
                        <div className="invoice-contemplary-body-inside-small-box">
                          <Row>
                            <Col lg={12} md={12} xl={12}>
                              {/* <div style={{marginTop:'2rem'}}>
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <img src="IMAGES/circle-doc.png"></img>
                              <div style={{marginTop:'2rem'}}>
                                Creative Invoice
                              </div>

                            </div>

                          </div> */}
                              <div className="invoice-one-border-box">
                                <Media
                                  as="li"
                                  className="media-ride-list-invoice-small-box"
                                >
                                  <div className="list-steps-of-ride-invoice-small-box">
                                    <div className="invoice-contemplary-body-inside__header__logo-small-box">
                                      <img src={require("./circle-doc.png")} />
                                    </div>
                                  </div>
                                  <Media.Body>
                                    <div className="contemporary-template__header__info-small-box">
                                      <div className="le-heading--title-small-box">
                                        Expense Created
                                      </div>
                                      <strong className="le-text--strong-small-box">
                                        Created:{" "}
                                        <span style={{ color: "#666666" }}>
                                          on{" "}
                                          {this.state.expenseData.generatedAt &&
                                            moment(
                                              this.state.expenseData.generatedAt
                                            ).format("MMMM Do YYYY")}
                                        </span>
                                      </strong>
                                      <div class="address__field"></div>
                                      <div class="address__field"></div>
                                    </div>
                                  </Media.Body>
                                </Media>

                                {/* </Col>


      <Col lg={6} md={6} xl={6}> */}

                                <div>
                                  {/*       <Button className="le-newinvoice-payment-voucher-edit-button" variant="dark">
                  Edit
            </Button> */}

                                  <Button
                                    /* onClick={this.handleExportPDf} */ className="le-newinvoice-payment-voucher-edit-button"
                                    variant="outline-grey"
                                  >
                                    <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Export As PDF</div>;
                                      }}
                                      content={() => this.componentRef}
                                    />
                                  </Button>

                                  <Button
                                    className="le-newinvoice-payment-voucher-edit-button"
                                    variant="outline-grey"
                                  >
                                    <ReactToPrint
                                      removeAfterPrint={true}
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <div> Print</div>;
                                      }}
                                      content={() => this.componentRef}
                                    />
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    <div className="invoice-expense-docs-area" >
                      <h3>Expense Doements</h3>  
                     <div className="invoice-IMages-area">
                       {this.state.expenseData.items && this.state.expenseData.items.map((doc)=>{
                         if(doc.imageUrl){
                           return   <div className="expense-doc-item">
                           <h6>{doc.serviceType}</h6>
                           <ModalImage
  small={`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${doc.imageUrl}`} 
  large={`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${doc.imageUrl}`} 
  
/>
                     
                         </div>
                         }
                       })}
                  
                    
                     </div>
                        </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <center>
          <div className="addInvoiceTpArea-heading-left-type-print">
            <ReactToPrint
              removeAfterPrint={true}
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <div
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    <Icon name="print"></Icon> Print
                  </div>
                );
              }}
              content={() => this.componentRef}
            />
          </div>
        </center>

        <div style={{ overflow: "auto" }}>
          {" "}
          <ExpenseViewPri ref={(el) => (this.componentRef = el)} />
        </div>
</div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getUserExpenseReducer: state.InvoiceTopAreaPreview1.getUserExpenseReducer,
  };
};

export default compose(
  withReducer("InvoiceTopAreaPreview1", reducer),
  connect(mapStateToProps),
  withRouter
)(InvoiceTopAreaPreview);
