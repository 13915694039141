import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import FileInvoicesIndex from '../../../components/fileInvoices'
import './style.scss'
const FileInvoicesPage = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            
           <FileInvoicesIndex
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default FileInvoicesPage
/* BankReceiptIndex */