


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../sideBar/style.scss';
import '../sideBar/style.css';
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import {Form,ButtonToolbar,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import * as Actions from "./store/actions";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { compose } from "redux";
import { Alert } from 'antd';
import { Popover,DatePicker,Popconfirm,message } from 'antd';
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { Header, Table, Rating,Icon, Button,List } from 'semantic-ui-react'
import { AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
var moment = require('moment');
 class  FileChequesAll extends Component{
      constructor(props, context) {
        super(props, context);
        this.state = {
          show:false,
          limit:10,
          pageNo:1,
          userId:undefined,
          totalPages:11,
          ChequeData:[],
          pageNumber:1,
          options:[],
          direction:null,
          column:null,
          show:false,
          veiwReceiptLoading:false,
          veiwReceiptSuccess:false,
          veiwReceiptFlag:false,
          veiwReceiptErrorFlag:false,
          veiwReceiptError:'',
          receiptIMage:'',
          size:'sm',
          ClearChequeSuccess:false,
          ClearChequeFlag:false,
          ClearChequeErrorFlag:false,
          loaderFlag1:false,
          show3:false,
          ClearChequeError:'',
          chequeDetail:{},
          CancelChequeSuccess:false,
          CancelChequeFlag:false,
          CancelChequeErrorFlag:false,
          loaderFlag2:false,
          show3:false,
          CancelChequeError:'',
          height:'200px',
          show7:false,
          loaderFlagCheque:true,
          chequeDetail:{},
          imageLoader:false
        
    } 
    }
    handleShow7=()=> {
      this.setState({ show7: true });
    }
    handleClose7=()=> {
      this.setState({ show7: false });
    }
    handleShow=()=> {
      this.setState({ show: true });
    }
    handleClose=()=> {
      this.setState({ show: false,
        veiwReceiptLoading:false,veiwReceiptSuccess:true });
      }
      handleShow3=()=> {
        this.setState({ show3: true });
      }
      handleClose3=()=> {
        this.setState({ show3: false,CancelChequeSuccess:false,CancelChequeError:'',CancelChequeErrorFlag:false,CancelChequeFlag:false });
        }
        handleShow2=()=> {
          this.setState({ show2: true });
        }
        handleClose2=()=> {
          this.setState({ show2: false,ClearChequeSuccess:false,ClearChequeError:'',ClearChequeErrorFlag:false,ClearChequeFlag:false });
          }
componentWillMount(){
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNo
}
this.props.dispatch(Actions.getAllCustomerFileCheques(obj))
this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllCustomerFileCheques */
}  
handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,ChequeData:[],pageNumber:1})
  let obj={
    userId:this.state.userId,
    limit:e.target.value,
    pageNo:1
  }
  this.props.dispatch(Actions.getAllCustomerFileCheques(obj))

} 
handleJumpPage=(e)=>{
  this.setState({pageNumber:e.target.value,ChequeData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:e.target.value
  }
  this.props.dispatch(Actions.getAllCustomerFileCheques(obj))
 
}
handlePageInc=()=>{
  if(this.state.totalPages>this.state.pageNumber){
  this.setState((prevState, props) => ({
    pageNumber: prevState.pageNumber + 1
}));
this.setState({ChequeData:[]})
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNumber+1
}
this.props.dispatch(Actions.getAllCustomerFileCheques(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNumber>1){
    this.setState({ChequeData:[]})
    this.setState((prevState, props) => ({
      pageNumber: prevState.pageNumber - 1
  }));
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNumber-1
  }
  this.props.dispatch(Actions.getAllCustomerFileCheques(obj))
  }

}
/* allCustomerChequesFileReducer */ 
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.allCustomerChequesFileReducer;

  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.cheques.docs.length>0){
        this.setState({ChequeData:resNumber1.data.cheques.docs,totalPages:resNumber1.data.cheques.totalPages})
      }else{
        this.setState({ChequeData:["nothing"]})
      }
   
    } else {
    this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
    }
  }
  /* fileApproveChequeReducer */
  let resNumber3 =nextProps.fileApproveChequeReducer;

  
  if (!resNumber3.isLoading) {
    if (resNumber3.errMsg == null && !(resNumber3.data===undefined)) {
      if(this.state.ClearChequeFlag){
this.setState({ClearChequeFlag:false,ClearChequeErrorFlag:false,loaderFlag1:false,ClearChequeSuccess:true})
      }
   /* console.log(resNumber3.data,"fffffffff") */
    } else {
      if(this.state.ClearChequeErrorFlag){
        this.setState({ClearChequeError:resNumber3.errMsg,ClearChequeErrorFlag:false,loaderFlag1:false})
      }
 
    }
  }
  let resNumber4 =nextProps.FilecancelChequeReducer;

  
  if (!resNumber4.isLoading) {
    if (resNumber4.errMsg == null && !(resNumber4.data===undefined)) {
      if(this.state.CancelChequeFlag){
this.setState({CancelChequeFlag:false,CancelChequeErrorFlag:false,loaderFlag2:false,CancelChequeSuccess:true})
      }
   /* console.log(resNumber3.data,"fffffffff") */
    } else {
      if(this.state.ClearChequeErrorFlag){
        this.setState({CancelChequeError:resNumber4.errMsg,CancelChequeErrorFlag:false,loaderFlag2:false})
      }
 
    }
  }

  let resNumber2 =nextProps.fileSearchReducer;

  
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }

}
/* fileSearchReducer */
handleSearch=(e)=>{
 
if(e.length>0){
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered}) 
this.props.dispatch(Actions.getSeachedFiles(e))
}else{
   
  this.setState({ChequeData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerFileCheques(obj))

}
}
handleSelect=(e,value)=>{
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered,ChequeData:[]}) 
  let obj={
    userId:value.data._id,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerFileCheques(obj))

}
handleSort=(action)=>{
switch (action.type) {
  case 'CHANGE_SORT':
    this.setState({column:action.column})
    this.setState((state)=>({
      direction:state.direction === 'ascending' ? 'descending' : 'ascending'
    }))
    this.setState((state)=>({
      ChequeData:state.ChequeData.reverse()
    }))
  default:
    
}
}
handleShowCheque=(item)=>{

  /* receiptPath */
  /* veiwReceiptImage */
  this.setState({show:true,height:'150px',veiwReceiptLoading:true,veiwReceiptSuccess:false,size:'sm',receiptIMage:item.chequePath})
 /* ReceiptImage */
/* 
 var logo = document.getElementById('ReceiptImage11');

 logo.onload =  ()=> {
 
 }; */
 
  setTimeout(()=>{

 },1500)
  /*  this.props.dispatch(Actions.veiwReceiptImage(item.receiptPath))
console.log(item,"hhhhhhhhhhh") */
}
handleLoad=()=>{
  this.setState({veiwReceiptLoading:false,veiwReceiptSuccess:true,size:'lg',height:'500px'})	
}
handleClearCheck=(item)=>{
  this.setState({show3:true,chequeDetail:item})
}
handleCancelCheque=(item)=>{
  this.setState({show2:true,chequeDetail:item})
}
/* approveCheque */
handleClearChequeSubmit=()=>{
  this.setState({loaderFlag1:true,ClearChequeErrorFlag:true,ClearChequeFlag:true})
  let obj={
    chequeId:this.state.chequeDetail._id
  }
  this.props.dispatch(Actions.FileapproveCheque(obj))
  setTimeout(()=>{
    let obj={
      userId:this.state.userId,
      limit:this.state.limit,
      pageNo:this.state.pageNo
    }
    this.props.dispatch(Actions.getAllCustomerFileCheques(obj))
  },1500)
}
handleCancelChequeSubmit=()=>{
  this.setState({loaderFlag2:true,CancelChequeErrorFlag:true,CancelChequeFlag:true})
  let obj={
    chequeId:this.state.chequeDetail._id,
    cancelReason:this.cancelReason.value
  }
  this.props.dispatch(Actions.FileCancelCheque(obj))
  setTimeout(()=>{
    let obj={
      userId:this.state.userId,
      limit:this.state.limit,
      pageNo:this.state.pageNo
    }
    this.props.dispatch(Actions.getAllCustomerFileCheques(obj))
  },1500)
}
handleDetailCheque=(item)=>{
  this.setState({show7:true,chequeDetail:item,imageLoader:false})
  setTimeout(()=>{
    this.setState({loaderFlagCheque:false})
  },1500)
}
  render(){
    return (
        <div className="admin-cutomer-profile-area" >
        <div className="receipnest-file-customer-files-area">
        <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
           
           <div  className="admin-cutomer-REcepnest-files-rea">
           <div className="receipnest-file-customer-files-area-top-heading">
                 <p style={{fontSize:'16px',fontWeight:'550',marginTop:'25px'}}>  File Cheques</p>
                </div>
                <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>
               
  
            </div>
            <div className="receipnest-file-customer-files-area655-bottom-area" style={{overflow:'auto',display:'block'}} >
            <div className="session-table-area-receptionist">
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
        textAlign='left' singleLine >Customer</Table.HeaderCell>
      
        <Table.HeaderCell
         sorted={this.state.column === 'email' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'email' })}
        singleLine width='3' textAlign='center'>Email</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Contact' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Contact' })}
        singleLine textAlign='center'>Contact</Table.HeaderCell>
        
         <Table.HeaderCell 
         sorted={this.state.column === 'expire' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'expire' })}
        singleLine textAlign='center'>Expire At</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'Status' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Status' })}
        singleLine textAlign='center'>Status</Table.HeaderCell>
         <Table.HeaderCell 
         sorted={this.state.column === 'ChequeNo' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'ChequeNo' })}
        singleLine textAlign='center'>Cheque No</Table.HeaderCell>
         <Table.HeaderCell 
         sorted={this.state.column === 'depositedAmount' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'depositedAmount' })}
        singleLine textAlign='center'>Cheque Amount</Table.HeaderCell>
       {/* depositedAmount */}
        <Table.HeaderCell  singleLine textAlign='center'>Cheque</Table.HeaderCell>
        <Table.HeaderCell  singleLine textAlign='center'>Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.ChequeData.length>0 ?
                  this.state.ChequeData[0]!="nothing"?
                this.state.ChequeData.map((item)=>{
                return  <Table.Row>
                <Table.Cell textAlign='left'>
                {item.userProfile && item.userProfile.firstName+" "+item.userProfile.lastName}
              
                </Table.Cell>
               
                <Table.Cell textAlign='center'>
                { item.userProfile && item.userProfile.email}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                 {item.userProfile && item.userProfile.phoneNumber} 
                </Table.Cell>
             
                <Table.Cell textAlign='center' >

{ moment(item.expiry).format('MMMM Do YYYY')}
</Table.Cell>
{item.status==="PENDING"?<Table.Cell warning textAlign='center'  >
                <div style={{color:'#A90941'}}><Icon name="attention" /> { item.status}</div>
                </Table.Cell>:<Table.Cell textAlign='center' >
                { item.status}
                </Table.Cell>}
                <Table.Cell textAlign='center' >
 {item.chequeNumber}
</Table.Cell>
                <Table.Cell textAlign='center' >

 AED {item.chequeAmount}
</Table.Cell>
             
             {/* depositedAmount */}
              <Table.Cell textAlign='center'>
                
             <div style={{color:"#353535",textDecoration:'underline',cursor:'pointer'}} onClick={()=>this.handleShowCheque(item)} >  show Cheque</div>
    </Table.Cell>
    <Table.Cell textAlign='center'>
                <Popover placement="bottomRight" content={( <ul  className="dropw-down-cursor-menu-invoice">
             
                
              
              
                {item.status==="PENDING"  ?  <li><div  onClick={()=>this.handleClearCheck(item)} className="add-border-drop">Clear Cheque</div></li>:<li>Already Cashed/Cancelled</li>}
                
                {item.status==="PENDING"  ?  <li onClick={()=>this.handleCancelCheque(item)}>Cancel Cheque</li>:<li></li>}
               <li><div onClick={()=>this.handleDetailCheque(item)}>Cheque Detail</div></li>
                
                
               
                
               
               
                </ul>)}  trigger="hover">
                <Icon name='play circle outline' size="large" />
    </Popover>
    </Table.Cell>
              </Table.Row>
                })
                :<Table.Row>
                     <Table.Cell colSpan='9'
 textAlign='center'>
                    <center>
                  <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                  No Cheque Exist Yet!
                  </div>
                  </center>    
                </Table.Cell>
                </Table.Row>:<Table.Row>
                     <Table.Cell  textAlign='center' colSpan='9' /* width={6} */ 
 textAlign='center'>
                     <center>
               <div className="loader-for-tabel-invoce">
               <div className="loader1188">
                </div> 
               </div>
               </center>  
                </Table.Cell>
               
                </Table.Row>}
     
    
     
    </Table.Body>
  </Table>

          </div>
           
           
            </div>
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per page
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNumber} of {this.state.totalPages} <span className="pagination-table-quotaion-dec"><i title="Previous Page" onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} title="Next Page" className="fa fa-caret-right"></i></span>
              </span>
            jump to page  <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
            </div> 
           
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} size={this.state.size} centered  >
        
        <Modal.Body style={{padding:'0px'}}>
          {/* fa fa-exclamation-circle */}
          {this.state.veiwReceiptLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
           </center>
          </div>
          :
         
          this.state.veiwReceiptSuccess? <div  >
          
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.veiwReceiptError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
          </center>
         </div>
           
         }
         <center>
         {
   this.state.receiptIMage.slice(this.state.receiptIMage.length-3)==="pdf"?<iframe  style={{border:'1px solid lightgrey',width:'100%',minHeight:this.state.height,msOverflowY:'auto'}} onLoad={this.handleLoad} src={this.state.receiptIMage.slice(0,5)==="https"? this.state.receiptIMage:`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.receiptIMage}`}  ></iframe>
   :<img style={{border:'1px solid lightgrey',width:'100%'}} onLoad={this.handleLoad} src={this.state.receiptIMage.slice(0,5)==="https"? this.state.receiptIMage:`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.receiptIMage}`} />

 }
       {/*      <img style={{width:'100%'}} onLoad={this.handleLoad} id="ReceiptImage11" src={this.state.receiptIMage.slice(0,5)==="https"? this.state.receiptIMage:`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.receiptIMage}`} />
         */}  </center>
        </Modal.Body>
    
      </Modal> 
      
      <Modal show={this.state.show3} onHide={this.handleClose3} size="sm" centered  >
      {this.state.ClearChequeSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Confirmation
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body>
      {this.state.ClearChequeSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="The payment against this cheque is Updated Successfully"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose3} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Are you sure that Payment against this Cheque is Received From Bank</p>
       <List divided verticalAlign='middle'>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
        {this.state.chequeDetail.chequeAmount && this.state.chequeDetail.chequeAmount} AED
      </List.Content>
     
      <List.Content><Icon name="credit card outline" />Cheque Amount</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.chequeNumber && this.state.chequeDetail.chequeNumber}
      </List.Content>
     
      <List.Content><Icon name="calendar outline" />Cheque No</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.createdAt &&  moment(this.state.chequeDetail.createdAt).format('MMMM Do YYYY')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />Cheque Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.expiry && moment(this.state.chequeDetail.expiry).format('MMMM Do YYYY')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />PDC Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.status && this.state.chequeDetail.status}
      </List.Content>
     
      <List.Content><Icon name="dna" />Status Subject to Realization</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
     BZ/{this.state.chequeDetail.fileNo && this.state.chequeDetail.fileNo}
      </List.Content>
     
      <List.Content><Icon name="file alternate outline" />File NO</List.Content>
    </List.Item>
    
  </List>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag1? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
     {this.state.ClearChequeError.length>0?<Alert message={this.state.ClearChequeError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.ClearChequeSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose3}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleClearChequeSubmit} color='black'>Clear Cheque</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal>
      <Modal show={this.state.show2} onHide={this.handleClose2} size="sm" centered  >
      {this.state.CancelChequeSuccess? null:
        <Modal.Header closeButton>
          <Modal.Title 
          style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
       <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Cancel Cheque
          </Modal.Title>
        </Modal.Header>
}
      <Modal.Body>
      {this.state.CancelChequeSuccess? <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           
            {/*    <p><i style={{fontSize:'80px'}} class="fa fa fa-check-circle-o"></i></p>
                 <p style={{fontSize:'24px',fontWeight:'550'}}>thank you!</p>
                <p>this Invoice is Successfuly cancelled </p> */}
                <Alert
      message="thank you!"
      description="This Cheque is  Successfully Cancelled"
      type="success"
      showIcon
    />
     <center>
     <Button style={{marginTop:'20px'}}  onClick={this.handleClose2} secondary>Go Back</Button>
          {/*       <button style={{marginTop:'20px'}} className="sent-button-des" onClick={()=>this.setState({show3:false})}>Ok</button>
           */}  </center>
           </div>:
       <div>
       <p style={{fontWeight:'550'}}>Are you sure to Cancel this cheque</p>
       <Form.Group as={Row} controlId="formHorizontalPassword">
  <Form.Label column sm={12} style={{textAlign:'left',fontWeight:'600',fontSize:'14px',marginBottom:'-5px'}}>
  Reason*
  </Form.Label>
  <Col sm={12}>
    <Form.Control required ref={(input=>this.cancelReason=input)} type="text" placeholder="Reason of cancel Cheque" />
  </Col>
</Form.Group>
       <List divided verticalAlign='middle'>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
        {this.state.chequeDetail.chequeAmount && this.state.chequeDetail.chequeAmount} AED
      </List.Content>
     
      <List.Content><Icon name="credit card outline" />Cheque Amount</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.chequeNumber && this.state.chequeDetail.chequeNumber}
      </List.Content>
     
      <List.Content><Icon name="calendar outline" />Cheque No</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.createdAt &&  moment(this.state.chequeDetail.createdAt).format('MMMM Do YYYY')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />Cheque Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.expiry && moment(this.state.chequeDetail.expiry).format('MMMM Do YYYY')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />PDC Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.status && this.state.chequeDetail.status}
      </List.Content>
     
      <List.Content><Icon name="dna" />Status Subject to Realization</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
     BZ/{this.state.chequeDetail.fileNo && this.state.chequeDetail.fileNo}
      </List.Content>
     
      <List.Content><Icon name="file alternate outline" />File NO</List.Content>
    </List.Item>
    
  </List>
   {/* <Icon name='attention' /> */}
       {this.state.loaderFlag2? <center>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}> Please wait....</p>
            </center>:''}
     {this.state.CancelChequeError.length>0?<Alert message={this.state.CancelChequeError} type="error" showIcon />:""}  
     </div>
      }
     
        </Modal.Body>
        {this.state.CancelChequeSuccess?null:
        <Modal.Footer>
        <Button.Group>
    <Button onClick={this.handleClose2}>Back</Button>
    <Button.Or />
    <Button onClick={this.handleCancelChequeSubmit} color='black'>Cancel Cheque</Button>
  </Button.Group>
        </Modal.Footer> }
    
      </Modal>
      <Modal show={this.state.show7} onHide={this.handleClose7} size="md" centered  >
     
     <Modal.Header closeButton>
       <Modal.Title 
       style={{textAlign:'left',fontWeight:'550',fontSize:'16px'}} >
    <img style={{width:'30px'}} src={require('../../images/profile/card12.png')} /> Cheque Detail
       </Modal.Title>
     </Modal.Header>
   <Modal.Body>
  
  {this.state.loaderFlagCheque?
<center>

<div style={{marginTop:'150px',marginBottom:'150px'}}>
                <div className="loader"></div>
                <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
            </div>
</center>:<div>
<List divided verticalAlign='middle'>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
        {this.state.chequeDetail.chequeAmount && this.state.chequeDetail.chequeAmount} AED
      </List.Content>
     
      <List.Content><Icon name="credit card outline" />Cheque Amount</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.chequeNumber && this.state.chequeDetail.chequeNumber}
      </List.Content>
     
      <List.Content><Icon name="calendar outline" />Cheque No</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.createdAt &&  moment(this.state.chequeDetail.createdAt).format('MMMM Do YYYY,h:mm:ss a')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />Cheque Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.expiry && moment(this.state.chequeDetail.expiry).format('MMMM Do YYYY')}
      </List.Content>
     
      <List.Content><Icon name="calendar alternate outline" />PDC Date</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
      {this.state.chequeDetail.status && this.state.chequeDetail.status}
      </List.Content>
     
      <List.Content><Icon name="dna" />Status Subject to Realization</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
     BZ/{this.state.chequeDetail.fileNo && this.state.chequeDetail.fileNo}
      </List.Content>
     
      <List.Content><Icon name="file alternate outline" />File NO</List.Content>
    </List.Item>
    <List.Item style={{fontWeight:'600',fontSize:'13px'}}>
      <List.Content floated='right'>
     {this.state.chequeDetail.cancelReason && this.state.chequeDetail.cancelReason}
      </List.Content>
     
      <List.Content><Icon name="file alternate outline" />Cancel Reason</List.Content>
    </List.Item>
    {/* cancelReason */}
  </List>
  {this.state.imageLoader?'':
 <center>

 <div style={{marginTop:'50px',marginBottom:'50px'}}>
                 <div className="loader"></div>
                 <p style={{paddingTop:'20px'}}>Loading Cheque ....</p>
             </div>
 </center>
 }
 {
   this.state.chequeDetail.chequePath.slice(this.state.chequeDetail.chequePath.length-3)==="pdf"?<iframe  style={{border:'1px solid lightgrey',width:'100%',minHeight:'330px',msOverflowY:'auto'}} onLoad={()=>this.setState({imageLoader:true})} src={this.state.chequeDetail.chequePath.slice(0,5)==="https"? this.state.chequeDetail.chequePath:`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.chequeDetail.chequePath}`}  ></iframe>
   :<img style={{border:'1px solid lightgrey',width:'100%'}} onLoad={()=>this.setState({imageLoader:true})} src={this.state.chequeDetail.chequePath.slice(0,5)==="https"? this.state.chequeDetail.chequePath:`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.chequeDetail.chequePath}`} />

 }
  
  </div>}
     </Modal.Body>
     
 
   </Modal> 
        </div>
    )
  }
}
  const mapStateToProps=(state)=> {
    return {
        allCustomerChequesFileReducer: state.FileChequesAll.allCustomerChequesFileReducer,
        fileSearchReducer:state.FileChequesAll.fileSearchReducer,
        fileApproveChequeReducer:state.FileChequesAll.fileApproveChequeReducer,
        FilecancelChequeReducer:state.FileChequesAll.FilecancelChequeReducer
    };
  } 
export default compose(
  withReducer("FileChequesAll", reducer),
  connect(mapStateToProps),withRouter
)(FileChequesAll); 