


import React,{useEffect,useState,Component} from 'react'
import { NavLink, Link,withRouter } from 'react-router-dom'
import '../sideBar/style.scss';
import '../sideBar/style.css';
import AvatarEditor from 'react-avatar-editor';
import StarRatings from 'react-star-ratings';
import {Form,ButtonToolbar,Modal,FormGroup,FormControl,Col,Row,ProgressBar, Container} from 'react-bootstrap';
import * as Actions from "./store/actions";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { compose } from "redux";
import { Alert } from 'antd';
import { Popover,DatePicker,Popconfirm,message } from 'antd';
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { Header, Table, Rating,Icon, Button } from 'semantic-ui-react'
import { AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
var moment = require('moment');
 class  BankReceipts extends Component{
      constructor(props, context) {
        super(props, context);
        this.state = {
          show:false,
          limit:10,
          pageNo:1,
          userId:undefined,
          totalPages:11,
          filesData:[],
          pageNumber:1,
          options:[],
          direction:null,
          column:null,
          show:false,
          veiwReceiptLoading:false,
          veiwReceiptSuccess:false,
          veiwReceiptFlag:false,
          veiwReceiptErrorFlag:false,
          veiwReceiptError:'',
          receiptIMage:'',
          size:'sm',
          height:'150px'
        
    } 
    }
    handleShow=()=> {
      this.setState({ show: true });
    }
    handleClose=()=> {
      this.setState({ show: false,
        veiwReceiptLoading:false,veiwReceiptSuccess:true });
      }
componentWillMount(){
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNo
}
this.props.dispatch(Actions.getAllCustomerReceipt(obj))
this.props.dispatch(Actions.getSeachedFiles(""))
  /* getAllCustomerReceipt */
}  
handleLimit=(e)=>{
 
  this.setState({limit:e.target.value,filesData:[],pageNumber:1})
  let obj={
    userId:this.state.userId,
    limit:e.target.value,
    pageNo:1
  }
  this.props.dispatch(Actions.getAllCustomerReceipt(obj))

} 
handleJumpPage=(e)=>{
  this.setState({pageNumber:e.target.value,filesData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:e.target.value
  }
  this.props.dispatch(Actions.getAllCustomerReceipt(obj))
 
}
handlePageInc=()=>{
  if(this.state.totalPages>this.state.pageNumber){
  this.setState((prevState, props) => ({
    pageNumber: prevState.pageNumber + 1
}));
this.setState({filesData:[]})
let obj={
  userId:this.state.userId,
  limit:this.state.limit,
  pageNo:this.state.pageNumber+1
}
this.props.dispatch(Actions.getAllCustomerReceipt(obj))
}
}
handlePageDec=()=>{
  if(this.state.pageNumber>1){
    this.setState({filesData:[]})
    this.setState((prevState, props) => ({
      pageNumber: prevState.pageNumber - 1
  }));
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNumber-1
  }
  this.props.dispatch(Actions.getAllCustomerReceipt(obj))
  }

}
/* allCustomerBankReducer */ 
componentWillReceiveProps(nextProps){
  let resNumber1 =nextProps.allCustomerBankReducer;

  
  if (!resNumber1.isLoading) {
    if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
      if(resNumber1.data.bankReceipts.docs.length>0){
        this.setState({filesData:resNumber1.data.bankReceipts.docs,totalPages:resNumber1.data.bankReceipts.totalPages})
      }else{
        this.setState({filesData:["nothing"]})
      }
   
    } else {
    this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
    }
  }
  /* viewReceiptReducer */
  let resNumber3 =nextProps.viewReceiptReducer;

  
  if (!resNumber3.isLoading) {
    if (resNumber3.errMsg == null && !(resNumber3.data===undefined)) {
      if(this.state.veiwReceiptFlag){
this.setState({veiwReceiptFlag:false,veiwReceiptLoading:false,veiwReceiptSuccess:true,receiptIMage:resNumber3.data})
      }
   console.log(resNumber3.data,"fffffffff")
    } else {
    this.setState({veiwReceiptError:resNumber3.errMsg,veiwReceiptLoading:false})
    }
  }
  let resNumber2 =nextProps.fileSearchReducer;

  
  if (!resNumber2.isLoading) {
    if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
      if(resNumber2.data.users.length>0){
        resNumber2.data.users.map((item)=>{
           let obj={
            value:`${item.firstName} ${item.lastName} (${item.phoneNumber})`,
            data:item
          }
          this.state.options.push(obj)
        
        })
        this.setState({falged:true})
      }
    }else{
      if(resNumber2.errMsg==="This token is expire! Please log in again"){
this.props.dispatch(Actions.logoutAdmin())
      }
    }
  }

}
/* fileSearchReducer */
handleSearch=(e)=>{
 
if(e.length>0){
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered}) 
this.props.dispatch(Actions.getSeachedFiles(e))
}else{
   
  this.setState({filesData:[]})
  let obj={
    userId:this.state.userId,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerReceipt(obj))

}
}
handleSelect=(e,value)=>{
  let uniq = {}
  let arrFiltered = this.state.options.filter(obj => !uniq[obj.value] && (uniq[obj.value] = true));
  this.setState({options:arrFiltered,filesData:[]}) 
  let obj={
    userId:value.data._id,
    limit:this.state.limit,
    pageNo:this.state.pageNo
  }
  this.props.dispatch(Actions.getAllCustomerReceipt(obj))

}
handleSort=(action)=>{
switch (action.type) {
  case 'CHANGE_SORT':
    this.setState({column:action.column})
    this.setState((state)=>({
      direction:state.direction === 'ascending' ? 'descending' : 'ascending'
    }))
    this.setState((state)=>({
      filesData:state.filesData.reverse()
    }))
  default:
    
}
}
handleShowReceipt=(item)=>{

  /* receiptPath */
  /* veiwReceiptImage */
  this.setState({show:true,veiwReceiptLoading:true,veiwReceiptSuccess:false,height:'150px',size:'sm',receiptIMage:item.receiptPath})
 /* ReceiptImage */
/* 
 var logo = document.getElementById('ReceiptImage11');

 logo.onload =  ()=> {
 
 }; */
 
  setTimeout(()=>{

 },1500)
  /*  this.props.dispatch(Actions.veiwReceiptImage(item.receiptPath))
console.log(item,"hhhhhhhhhhh") */
}
handleLoad=()=>{
  this.setState({veiwReceiptLoading:false,veiwReceiptSuccess:true,size:'lg',height:'500px'})	
}
  render(){
    return (
        <div className="admin-cutomer-profile-area" >
        <div className="receipnest-file-customer-files-area">
        <div  className="receipnest-file-customer-files-area655" style={{paddingBottom:'200px'}}>
           
           <div  className="admin-cutomer-REcepnest-files-rea">
           <div className="receipnest-file-customer-files-area-top-heading">
                 <p style={{fontSize:'16px',fontWeight:'550',marginTop:'25px'}}>  Bank Receipts</p>
                </div>
                <div className="admin-cutomer-profile-area-search">
                 <div className="search-area-admin-top">
                 <i className="fa fa-search"></i> | {/* <input className="input-search-area-admin" type="text" placeholder="Search" /> */} <AutoComplete
   
    className="search_input_width_file_rep"
    options={this.state.options}
    onSearch={(e)=>this.handleSearch(e)}
    onSelect={(e,value)=>this.handleSelect(e,value)}
    placeholder="search with Name or Phone No"
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
  />
                 </div>
                </div>
               
  
            </div>
            <div className="receipnest-file-customer-files-area655-bottom-area" style={{overflow:'auto',display:'block'}} >
            <div className="session-table-area-receptionist">
          <Table celled fixed padded  selectable sortable
>
    <Table.Header style={{fontSize:'12px'}}>
      <Table.Row>
        <Table.HeaderCell
          sorted={this.state.column === 'name' ? this.state.direction : null}
          onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'name' })}
        textAlign='left' singleLine >Customer</Table.HeaderCell>
      
        <Table.HeaderCell
         sorted={this.state.column === 'email' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'email' })}
        singleLine width='3' textAlign='center'>Email</Table.HeaderCell>
        <Table.HeaderCell
        sorted={this.state.column === 'Contact' ? this.state.direction : null}
        onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'Contact' })}
        singleLine textAlign='center'>Contact</Table.HeaderCell>
        <Table.HeaderCell 
         sorted={this.state.column === 'created' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'created' })}
        singleLine textAlign='center'>created At</Table.HeaderCell>
         <Table.HeaderCell 
         sorted={this.state.column === 'depositedAmount' ? this.state.direction : null}
         onClick={() => this.handleSort({ type: 'CHANGE_SORT', column: 'depositedAmount' })}
        singleLine textAlign='center'>Deposite Amount</Table.HeaderCell>
       {/* depositedAmount */}
        <Table.HeaderCell  singleLine textAlign='center'>Receipt</Table.HeaderCell>
     
      </Table.Row>
    </Table.Header>

    <Table.Body style={{fontSize:'12px',fontWeight:'550'}}>
    {this.state.filesData.length>0 ?
                  this.state.filesData[0]!="nothing"?
                this.state.filesData.map((item)=>{
                return  <Table.Row>
                <Table.Cell textAlign='left'>
                {item.userProfile && item.userProfile.firstName+" "+item.userProfile.lastName}
              
                </Table.Cell>
               
                <Table.Cell textAlign='center'>
                { item.userProfile && item.userProfile.email}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                 {item.userProfile && item.userProfile.phoneNumber} 
                </Table.Cell>
                <Table.Cell textAlign='center' >

                { moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </Table.Cell>
                <Table.Cell textAlign='center' >

 AED {item.depositedAmount}
</Table.Cell>
             
             {/* depositedAmount */}
              <Table.Cell textAlign='center'>
                
             <div style={{color:"#353535",textDecoration:'underline',cursor:'pointer'}} onClick={()=>this.handleShowReceipt(item)} >  show Receipt</div>
    </Table.Cell>
              </Table.Row>
                })
                :<Table.Row>
                     <Table.Cell colSpan='6'
 textAlign='center'>
                    <center>
                  <div className="loader-for-tabel-invoce" style={{fontWeight:'550'}}>
                  No Receipt Exist Yet!
                  </div>
                  </center>    
                </Table.Cell>
                </Table.Row>:<Table.Row>
                     <Table.Cell  textAlign='center' colSpan='6' /* width={6} */ 
 textAlign='center'>
                     <center>
               <div className="loader-for-tabel-invoce">
               <div className="loader1188">
                </div> 
               </div>
               </center>  
                </Table.Cell>
                </Table.Row>}
     
    
     
    </Table.Body>
  </Table>

          </div>
           
           
            </div>
            <div className="quotaion-table-pagination-area">
            <div  style={{marginTop:'10px'}}>
            show  <select  onChange={this.handleLimit} >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='200'>200</option>

            </select> per page
            </div>
            <div  style={{marginTop:'10px'}}>
              <span style={{marginRight:'15px'}}>
                Page {this.state.pageNumber} of {this.state.totalPages} <span className="pagination-table-quotaion-dec"><i title="Previous Page" onClick={this.handlePageDec} className="fa fa-caret-left"></i></span>
                <span className="pagination-table-quotaion-inc"><i onClick={this.handlePageInc} title="Next Page" className="fa fa-caret-right"></i></span>
              </span>
            jump to page  <select onChange={this.handleJumpPage} >
              {Array.from({length: this.state.totalPages}, (x, i) => i).map((item,index)=>{
                return  <option value={index+1}>{index+1}</option>
              })}
            </select>
            </div>
            </div>
            </div> 
           
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} size={this.state.size} centered  >
        
        <Modal.Body style={{padding:'0px'}}>
          {/* fa fa-exclamation-circle */}
          {this.state.veiwReceiptLoading ?
           <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
           <center>
               <div className="loader1188"></div>
               <p style={{paddingTop:'20px'}}>Loading Please wait....</p>
           </center>
          </div>
          :
         
          this.state.veiwReceiptSuccess? <div  >
          
         </div> :
          <div className="sent-message-area" style={{paddingTop:'20px',paddingBottom:'20px'}}>
          <center>
              <p><i style={{fontSize:'80px',color:'#FF603B'}} class="fa fa-exclamation-circle"></i></p>
              <p style={{fontSize:'24px',fontWeight:'550'}}>Sorry!...</p>
              <p>{this.state.veiwReceiptError} </p>
              <button style={{backgroundColor:'#FF603B'}} className="sent-button-des" onClick={this.handleClose}>Try Again later</button>
          </center>
         </div>
           
         }
         <center>
          {/*   <img style={{width:'100%'}} onLoad={this.handleLoad} id="ReceiptImage11" src={this.state.receiptIMage} />
            */}        {
   this.state.receiptIMage.slice(this.state.receiptIMage.length-3)==="pdf"?<iframe  style={{border:'1px solid lightgrey',width:'100%',minHeight:this.state.height,msOverflowY:'auto'}} onLoad={this.handleLoad} src={this.state.receiptIMage.slice(0,5)==="https"? this.state.receiptIMage:`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.receiptIMage}`}  ></iframe>
   :<img style={{border:'1px solid lightgrey',width:'100%'}} onLoad={this.handleLoad} src={this.state.receiptIMage.slice(0,5)==="https"? this.state.receiptIMage:`${process.env.REACT_APP_FILE}/accountRoutes/api/v1/bankReceipts/readBankReceipt?receiptPath=${this.state.receiptIMage}`} />

 }
          </center>
        </Modal.Body>
    
      </Modal> 
        </div>
    )
  }
}
  const mapStateToProps=(state)=> {
    return {
        allCustomerBankReducer: state.BankReceiptsAll.allCustomerBankReducer,
        fileSearchReducer:state.BankReceiptsAll.fileSearchReducer,
        viewReceiptReducer:state.BankReceiptsAll.viewReceiptReducer
    };
  } 
export default compose(
  withReducer("BankReceiptsAll", reducer),
  connect(mapStateToProps),withRouter
)(BankReceipts); 