import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo/logo2.png'
import './style.scss'
import barCode from '../../images/address.jpg'
import Clock from 'react-live-clock';

const FooterArea = () => {
    return (
        <footer className="footerArea">
       
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>Privacy Policy | © {new Date().getFullYear()} legalemirates.com All rights reserved</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li><a href="https://www.facebook.com/Legal-Emirates-104109144570908/" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="https://twitter.com/legal_emirates1" target='_blank'><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/legal-emirates1/" target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.instagram.com/legal_emirates1/" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                               {/*      <li><a href="https://www.pinterest.com/wowets2020/" target='_blank'><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>*/}
                                    <li><a href="https://www.youtube.com/channel/UC1Sa8Miung_yj8h-zlGEWfQ" target='_blank'><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                 </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea