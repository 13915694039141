import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import ExpensePreviewArea from '../../../components/expensePreview'
import './style.scss'
const ExpensePreviewPage = () => {

    return (
        <Fragment>
            
           <ExpensePreviewArea
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default ExpensePreviewPage
/* ExpensePreviewArea */