import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const ALL_CUSTOMER_FILES_INVOICES = "ALL_CUSTOMER_FILES_INVOICES";
export const ALL_CUSTOMER_FILES_INVOICES_LOADING =
"ALL_CUSTOMER_FILES_INVOICES_LOADING";
export const ALL_CUSTOMER_FILES_INVOICES_LOADING_FAILED =
"ALL_CUSTOMER_FILES_INVOICES_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getAllCustomerFilesINvoicesLoading = ms => ({
    type: ALL_CUSTOMER_FILES_INVOICES_LOADING,
    payload: ms
    });
    
    export const getAllCustomerFilesINvoicesLoadingFailed = ms => ({
    type: ALL_CUSTOMER_FILES_INVOICES_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getAllCustomerFilesINvoices(obj) {
    if(obj.userId===undefined){
        var request = axios.get(
            `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/fileInvoices/getFileInvoicesWithPagination?pageNo=${obj.pageNo}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
        
    }else{
        var request = axios.get(
            `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/fileInvoices/getFileInvoicesWithPagination?userId=${obj.userId}&pageNo=${obj.pageNo}&limit=${obj.limit}`, {
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
    }
    
    return dispatch => {

    dispatch(getAllCustomerFilesINvoicesLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
     console.log(response,"response getAllCustomerFilesINvoices invoices")
    dispatch({
    type: ALL_CUSTOMER_FILES_INVOICES,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getAllCustomerFilesINvoicesLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getAllCustomerFilesINvoicesLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getAllCustomerFilesINvoicesLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getAllCustomerFilesINvoicesLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


