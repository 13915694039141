import axios from "axios";
import setAuthToken from '../../setAuthToken';
import jwt_decode from 'jwt-decode';
import history from '../../../../history';
//GET ALL CARCATAGORY BY COUNTRY
export const CODE_VERIFY_ATUH_ADMIN = "CODE_VERIFY_ATUH_ADMIN";
export const CODE_VERIFY_ATUH_ADMIN_LOADING =
"CODE_VERIFY_ATUH_ADMIN_LOADING";
export const CODE_VERIFY_ATUH_ADMIN_LOADING_FAILED =
"CODE_VERIFY_ATUH_ADMIN_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const codeVerifuAuthLoading = ms => ({
    type: CODE_VERIFY_ATUH_ADMIN_LOADING,
    payload: ms
    });
    
    export const codeVerifuAuthLoadingFailed = ms => ({
    type: CODE_VERIFY_ATUH_ADMIN_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function codeVerifuAuth(obj) {
    const request = axios.post(
    `${process.env.REACT_APP_PROFILE}/api/v1/accountant/auth/accountantVerify`,
    obj
    );
    
    return dispatch => {
    dispatch(codeVerifuAuthLoading());
    request
    .then(response => {
       
    
    
       /*  console.log(response,"code verify") */
    if (response.status === 200) {
       
        if(response.data.user.emailVerified === true){
            let  token  = response.data.user.token;
            localStorage.setItem('accountantToken', token);
            localStorage.setItem('accountant',response.data.user.firstName);
            localStorage.setItem('loginFlagAccountant','login')
            localStorage.setItem('accountantName',response.data.user.firstName+" "+response.data.user.lastName);
            localStorage.setItem('accountantEmail',response.data.user.email);
            localStorage.setItem('accountantPhone',response.data.user.phoneNumber);
            
            localStorage.setItem('accountantBar',"Services");
            localStorage.setItem('accountantLink',"Invoice");
      
             /* 
   
   
  */

            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentAdmin(response.data.user.firstName));
            dispatch({
                type: CODE_VERIFY_ATUH_ADMIN,
                payload: response.data
                });
        }
   
    }
     else {
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
    if (error.response) {
    if (error.response.status === 401 || error.response.status === 404 || error.response.status === 402 || error.response.status === 403 || error.response.status === 400) {
    dispatch(
        codeVerifuAuthLoadingFailed(error.response.data)
    );
    } else {
    dispatch(codeVerifuAuthLoadingFailed(error.response.data));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(codeVerifuAuthLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(codeVerifuAuthLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


    export const setCurrentAdmin = decoded => {
        return {
            type: 'SET_CURRENT_ADMIN',
            payload: decoded
        }
      }
      export const logoutAdmin = () => dispatch => {
     
        localStorage.removeItem('accountantToken');
        localStorage.setItem('loginFlagaAccountant','') 
        localStorage.removeItem('receipnestBar');
        localStorage.removeItem('receipnestLink');
        setAuthToken(false);
        dispatch(setCurrentAdmin(undefined));
       window.location.reload(history.push('/login'));
       
       
        
      
      }
             /* receipnestBar ServicesReceipnest
 
    receipnestLink createFileReceipnest
    
    */