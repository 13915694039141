import React, { Component, useEffect } from "react";



import ReactToPrint, { PrintContextConsumer } from 'react-to-print';/* 
import { Visible, Hidden, setConfiguration } from "react-grid-system"; */

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
  Media
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";



var moment = require('moment');
class QuotationView extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  render() {
    return (
      <div>


        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="invoice-le-view_area">
                
              

                

                  <div class="invoice-preview__warning-message le-text--body"></div>



                  <div className="invoice-previe-div-body-le">
                    <div className="invoice-contemplary-body-inside">




                      <section className="invoice-contemplary-body-inside__header">

<Row>



                        <Col lg={12} xl={12} md={12} sm={12} xs={12}>

                        





<Media
                          as="li"
                          className="media-ride-list-invoice"
                          
                        >
                          <div className="list-steps-of-ride-invoice">
                              <div className="invoice-contemplary-body-inside__header__logo">
                                <img style={{marginTop:'-40px'}} src="https://wave-prod-accounting.s3.amazonaws.com/uploads/invoices/business_logos/efcee799-07f1-4667-a72c-eaf2265ecbb7.png" />
                              </div>
                          </div>
                          <Media.Body className="ride-body-content">
                          <div className="contemporary-template__header__info">
                          <div className="le-heading--title" style={{fontWeight:'700'}}>BADER HAMAD AL ZAABI
                           </div>
                          <strong className="le-text--strong">
                            ADVOCATES & LEGAL CONSULTANTS
                          </strong>
                          <div className="address-le-view-invoice fs-exclude">
                            <div className="address__field-invoice-view">
                              <p className="le-text le-text--body" style={{fontWeight:'550',lineHeight:'13px'}}>
                           
                              Office No. 3403 - 34th Floor - Burj Al Salam Tower
                                 - Trade Centre 1 - Sheikh Zayed Road <br />
                                Dubai - United Arab Emirates  - P.O. Box No.
                                89498, Dubai. {/* TRN: 10037287460003 */}
                                Tel: +9714 2555111, Fax: +9714 2368888<br />
                                E-MAIL: info@legalemirates.com
                              </p>
                            </div>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>
                        </div>

                          </Media.Body>
                        </Media>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={12} xs={12}>
    {/* #BD9B3E
    #CBBC99
    */}
    <div className="preview-quotation-top-area">
     <div className="preview-quotation-top-area-left-Area">
      <div className="preview-quotation-top-area-left-Area-box">
      </div> 
      <div className="preview-quotation-top-area-left-Area-text">
      Quotation      </div>
     </div>
     <div className="preview-quotation-top-area-right-Area">
 File No : {this.props.data.fileNo && this.props.data.fileNo} {" "} | {" "} TRN # 100455656700003
</div>
    </div>
{/*   <div className="invoice-contemplary-body-inside__header__logo-invoice">
    <img src="https://drive.google.com/uc?export=view&id=1-_drJvm5qH_2kdvRpPvcFhhOEm3vOn8F"/>
   </div> */}
</Col>
                        </Row>
                      </section>

                   
                   
                   
                   


                   
                      <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top"></div>
                      </div>


<div class="description_of_card_of_invoicetable">
 <div class="description_of_card_of_invoicetable_area">


 <div className="contemporary-template__header__logo-invoice-view">
                            <Row style={{ width: "100%" }}>
                              <Col lg={5} xl={5} md={5} sm={12} sm={5} xs={5}>
                                <div style={{ marginLeft: "0px" }}>
                                  <h2 className="le-table-customer-data-heading">
                                    <span>
                                      <i class="fa fa-square box-gradient-icon-invoice-view"></i>
                                      Client Details
                                    </span>
                                  </h2>

                                  <div>
                                    <table className="le-table--plain">
                                      <tbody className="le-table__body">
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong  className="le-text--strong-invoice">
                                              {this.props.data
                                                .customer &&
                                                this.props.data
                                                  .customer.firstName +
                                                  " " +
                                                  this.props.data
                                                    .customer.lastName}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong  style={{fontWeight:'600'}} className="le-text--invoice">
                                              {this.props.data
                                                .customer &&
                                                this.props.data
                                                  .customer.phoneNumber}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong style={{fontWeight:'600'}} className="le-text--invoice">
                                              {this.props.data
                                                .customer &&
                                                this.props.data
                                                  .customer.email}
                                            </strong>
                                          </td>
                                        </tr>

                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong
                                              style={{ maxWidth: "24ch",fontWeight:'600' }}
                                              className="le-text--invoice"
                                            >
                                              {this.props.data
                                                .customer &&
                                                this.props.data
                                                  .customer.address}
                                            </strong>
                                          </td>
                                        </tr>

                                        {/*    <tr className="le-table__row">
            <td className="le-table__cell" colspan="1">
              <strong className="le-text--invoice">United Arab Emirates</strong>
              </td>
            </tr> */}

                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--invoice">
                                              {/* {this.props.data.customer.email && this.props.data.customer.email} */}
                                            </strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={2} xl={2} md={2} sm={12} xs={2}>
                                <div className="invoice-contemplary-body-inside__header__logo-inside-tale-customer">
                                  <img
                                  style={{marginTop:'40px'}}
                                    src={require("../../images/address.jpg")}
                                  />
                                </div>
                              </Col>
                              <Col lg={5} xl={5} md={5} sm={12} xs={5}>
                               <center>
                               <div className="date-info-invoice-le">
                                  <h2 style={{textAlign:'left',marginLeft:'50px'}} className="le-table-customer-data-heading">
                                    <span>
                                      <i class="fa fa-square box-gradient-icon-invoice-view"></i>
                                      DATE & TIME
                                    </span>
                                  </h2>

                                  <div>
                                    <table className="le-table--plain">
                                      <tbody className="le-table__body">
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--issuedate-invoice">
                                              Issue date:{" "}
                                              {this.props.data
                                                .invoiceDate &&
                                                moment( this.props.data.invoiceDate).format('MMMM Do YYYY')
                                               }
                                            </strong>
                                          </td>
                                        </tr>
                                     {/*    <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <strong className="le-text--issuedate-invoice">
                                              Issue Time: { moment(new Date(this.props.data.invoiceDueDate)).format('h:mm:ss a')}
                                         
                                            </strong>
                                          </td>
                                        </tr> */}
                                        <tr className="le-table__row">
                                          <td
                                            className="le-table__cell"
                                            colspan="1"
                                          >
                                            <div style={{backgroundColor:'#BD9B3E',fontWeight:'550',padding:'5px',color:'white',marginTop:'15px',minWidth:'200px'}} className="le-text--issuedate-invoice">
                                            <center>
                                            Due Date : {" "}
                                              {this.props.data
                                                .invoiceDueDate &&
                                                moment( this.props.data.invoiceDueDate).format('MMMM Do YYYY')
                                              }
                                            </center>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                               </center>
                              </Col>
                            </Row>
                          </div>






<Col xl={12} md={12} lg={12}>
                      <div class="contemporary-template__items-table">
                      <table class="le-table">
                                <thead
                                  class="le-table__header"
                                  style={{
                                    backgroundColor: "rgb(189, 155, 62)",
                                  }}
                                >
                                  <tr class="le-table__row">
                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                     
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Sr. No
                                    </th>
                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                     
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Type
                                    </th>

                                    <th
                                      class="le-table__cell--amount class-table-particular"
                                      colspan="5"
                                      style={{
                                        color: "rgb(255, 255, 255)",
                                        width: "335px",
                                      }}
                                    >
                                      Description of Services
                                    </th>

                                    <th
                                      class="le-table__cell--amount"
                                      colspan="1"
                                      style={{ color: "rgb(255, 255, 255)" }}
                                    >
                                      Amount
                                    </th>
                                  </tr>
                                </thead>

                                {this.props.data.item &&
                                  this.props.data.item.map(
                                    (item, index) => {
                                      return (
                                        <tr class="le-table__row">
                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized"  style={{fontWeight:'600',color:'#666666'}}>
                                                {index + 1}.
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized"  style={{fontWeight:'600',color:'#666666'}}>
                                                {item.serviceType}
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            class="le-table__cell"
                                            colspan="5"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized" style={{fontWeight:'600',color:'#666666'}}>
                                                {item.detail}
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            class="le-table__cell"
                                            colspan="1"
                                          >
                                            <div class="no-items-message">
                                              <div class="le-text--emphasized" style={{fontWeight:'600',color:'#666666',textAlign:'right'}}>
                                                AED{" "}
                                                {new Intl.NumberFormat().format(
                                                  item.amount
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </table>
                  </div>
                  </Col>


          
                  <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                    <div class="le-divider-below"></div>
                    </div>







                    <Row className="how-to-work-grid">
                            <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginBottom: "20px",
                                }}
                              >
                                <h2 className="le-table-customer-data-heading-behind-table">
                                  <span>
                                   {/*  <i
                                      style={{
                                        fontSize: "18px",
                                        paddingRight: "5px",
                                      }}
                                      class="fa fa-square  box-gradient-icon-invoice-view"
                                    ></i> */}
                                 {/*    Payment Method */}
                                  </span>
                                </h2>

                                <div>
                                  <table className="le-table--plain-behind-table">
                                    <tbody className="le-table__body-behind-table">
                                      <tr className="le-table__row-behind-table">
                                        <td className="le-table__cell-behind-table">
                                          <strong className="le-text--strong-invoice-behind-table">
                                          {/*   By Cash */}
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                              <div class="contemporary-template__totals__amounts">
                                <div class="contemporary-template__totals__amounts__line">
                                  <div class="contemporary-template__totals__amounts__line__label">
                                    <strong class="le-text--strong-totals__amounts" >
                                      Sub Total:
                                    </strong>
                                  </div>
                                  <div class="contemporary-template__totals__amounts__line__amount">
                                    <span class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                      AED{" "}
                                      {this.props.data.subTotal &&
                                        new Intl.NumberFormat().format(
                                          this.props.data
                                            .subTotal
                                        )}
                                        {console.log( this.props,"hhhhhhhhhhhhh")}
                                    </span>
                                  </div>
                                </div>
                                <div></div>

                                <div>
                                  <div class="contemporary-template__totals__amounts__line">
                                    <div class="contemporary-template__totals__amounts__line__label">
                                      <strong class="le-text--strong-totals__amounts">
                                        VAT (5%):
                                      </strong>
                                    </div>
                                    <div class="contemporary-template__totals__amounts__line__amount">
                                      <strong class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                        AED{" "}
                                        {this.props.data.vatTotal &&
                                          new Intl.NumberFormat().format(
                                            this.props.data.vatTotal
                                          )}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                               {/*  <div>
                                  <div class="contemporary-template__totals__amounts__line">
                                    <div class="contemporary-template__totals__amounts__line__label">
                                      <strong class="le-text--strong-totals__amounts">
                                         Discount:
                                      </strong>
                                    </div>
                                    <div class="contemporary-template__totals__amounts__line__amount">
                                      <strong class="le-text-totals__amounts le-text--body-totals__amounts" style={{marginRight:'-20px',fontWeight:'600',color:'#000000'}}>
                                        AED{" "}
                                        {this.props.data.discount?
                                          (new Intl.NumberFormat().format(
                                            this.props.data.discount
                                          )):'0'}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
 */}
                              
                              </div>

                              <div class="contemporary-template__divider--full-width-sign contemporary-template__divider--bold-sign">
                                <div class="le-divider-amount-small"></div>
                              </div>
            {this.props.data.payments && this.props.data.payments.length>0 ?
            this.props.data.payments.map((item)=>{
              return <div className="le-text-al-payment-amount-usingcash">
              <div  className="le-text-al-payment-amount-usingcash"  style={{fontWeight:'550',fontSize:'14px',color:'#000000'}}>
                Payment on {moment(item.createdAt).format('MMMM Do YYYY')} using {item.paymentType}:</div>
            <div className="text-authorized-payment-amount-usingcash" style={{marginRight:'10px',fontWeight:'550',fontSize:'14px',color:'#000000'}}>AED {item.paidAmount}</div>
    

    </div>

            })
            :''}
                              

                              <div className="amount-padding-color-price">
                            Total    Amount  : &nbsp; &nbsp;&nbsp; 
                                &nbsp; AED{" "}
                                {/* pendingAmount */}
                             <span style={{marginRight:'-8px'}}>      {this.props.data.total &&
                                  this.props.data.total}</span>
                              </div>

                                     
                              {/* <div class="contemporary-template__divider--bold contemporary-template__divider--small-margin">
                <div class="le-divider">
                  </div>
                  </div> */}
                            </Col>
                          </Row>





              <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                    <div class="le-divider"></div>
                    </div>







 
                    <div class="description_of_card_of_blogpost2">
                            <div class="description_of_card_of_newsroom_area-blogpost2">
                              <h2 class="blogs-description-area-of-main-headings-blogpost2">
                                Terms & Conditions
                              </h2>

                              <p style={{fontWeight:'600',fontSize:'13px'}} class="invoice-description-area-of-preview--text">
                                1. This a computer generated invoice to open a
                                file payment. This is an electronic receipt; any
                                signature or stamp on it is not required.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} class="invoice-description-area-of-preview--text">
                                2. The acknowledgment of this opening a file
                                receipt by the customer of the paid amount is
                                shown as a NON-REFUNDABLE payment. The customer
                                has no right to claim the refund of opening a
                                file payment.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} className="invoice-description-area-of-preview--text">
                                3. BADER HAMAD AL ZAABI’s ADVOCATES & LEGAL
                                CONSULTANTS reserve the right to review and aim
                                the above terms as per the judge's fit and as
                                per the applicable laws.
                              </p>

                              <p style={{fontWeight:'600',fontSize:'13px'}} className="invoice-description-area-of-preview---belowfooter-text">
                                For Questions concerning this invoice, please
                                contact info@legalemirates.com |
                                www.legalemirates.com
                              </p>
                            </div>
                          </div>





        </div>
        </div>








                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>




        









      </div>
    );
  }
}



export default QuotationView;
