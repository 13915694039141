import {combineReducers} from 'redux';



import {fileSearchReducer} from './fileSearch'
import {recoredFilePaymentReducer} from '../../../fileInvoicePreview/store/reducers/recordFilePay'
import {allFilesInvoicesReducer } from './allCustomerFilesRed';
import {sendFileInvoiceReducer} from '../../../fileInvoicePreview/store/reducers/sendFileInvoiceRed'
import {bankReceiptInvoiceReducer1} from './updateReceiptRed'
const reducer = combineReducers({
   bankReceiptInvoiceReducer1,allFilesInvoicesReducer,fileSearchReducer,recoredFilePaymentReducer,sendFileInvoiceReducer 
    });
    
    export default reducer;
