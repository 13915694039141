import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import InvoicePreviewArea from '../../../components/invoiePreview'
import './style.scss'
const InvoicePreviewPage = () => {

    return (
        <Fragment>
            
           <InvoicePreviewArea
           className="SignInArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default InvoicePreviewPage
/* InvoicePreviewArea */