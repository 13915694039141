import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const RECORD_PAYMENT_CUSTOMER = "RECORD_PAYMENT_CUSTOMER";
export const RECORD_PAYMENT_CUSTOMER_LOADING =
"RECORD_PAYMENT_CUSTOMER_LOADING";
export const RECORD_PAYMENT_CUSTOMER_LOADING_FAILED =
"RECORD_PAYMENT_CUSTOMER_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const recordPaymentCustomerLoading = ms => ({
    type: RECORD_PAYMENT_CUSTOMER_LOADING,
    payload: ms
    });
    
    export const recordPaymentCustomerLoadingFailed = ms => ({
    type: RECORD_PAYMENT_CUSTOMER_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function recordPaymentCustomer(obj,recordPaymentCustomerSuccess) {
    const request = axios.post(
    `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/receipts/recordPayment`,obj,
    {
      headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
    });
    return dispatch => {

    dispatch(recordPaymentCustomerLoading());
    console.log(request,"gggggggggggggggg")
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response recordPaymentCustomer")
    dispatch({
    type: RECORD_PAYMENT_CUSTOMER,
    payload: response.data
    });
  if(recordPaymentCustomerSuccess){
    recordPaymentCustomerSuccess()
  }
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        recordPaymentCustomerLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(recordPaymentCustomerLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(recordPaymentCustomerLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(recordPaymentCustomerLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


