import {combineReducers} from 'redux';



import {fileSearchReducer} from './fileSearch'
import {allCustomerBankReducer } from './allCustomerbankReceipt';
import {viewReceiptReducer} from './viewReceiptRed'

const reducer = combineReducers({
  allCustomerBankReducer,fileSearchReducer,viewReceiptReducer
    });
    
    export default reducer;
