import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const GET_USER_ALL_INVOICE = "GET_USER_ALL_INVOICE";
export const GET_USER_ALL_INVOICE_LOADING =
"GET_USER_ALL_INVOICE_LOADING";
export const GET_USER_ALL_INVOICE_LOADING_FAILED =
"GET_USER_ALL_INVOICE_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const getAllUsersInvoiceLoading = ms => ({
    type: GET_USER_ALL_INVOICE_LOADING,
    payload: ms
    });
    
    export const getAllUsersInvoiceLoadingFailed = ms => ({
    type: GET_USER_ALL_INVOICE_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function getAllUsersInvoice() {
    const request = axios.get(
    `${process.env.REACT_APP_PROFILE}/api/v1/accountant/userData/getUsers`,
    {
      headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
    });
    return dispatch => {

    dispatch(getAllUsersInvoiceLoading());
   
    request
    .then(response => {   
      console.log(response,"response")   
    if (response.status == 200) {
     console.log(response,"response getAllUsersInvoice")
    dispatch({
    type: GET_USER_ALL_INVOICE,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        getAllUsersInvoiceLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(getAllUsersInvoiceLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(getAllUsersInvoiceLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(getAllUsersInvoiceLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


