import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const FILE_CUSTOMER_APPROVE_CHEQUE = "FILE_CUSTOMER_APPROVE_CHEQUE";
export const FILE_CUSTOMER_APPROVE_CHEQUE_LOADING =
"FILE_CUSTOMER_APPROVE_CHEQUE_LOADING";
export const FILE_CUSTOMER_APPROVE_CHEQUE_LOADING_FAILED =
"FILE_CUSTOMER_APPROVE_CHEQUE_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const FileapproveChequeLoading = ms => ({
    type: FILE_CUSTOMER_APPROVE_CHEQUE_LOADING,
    payload: ms
    });
    
    export const FileapproveChequeLoadingFailed = ms => ({
    type: FILE_CUSTOMER_APPROVE_CHEQUE_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function FileapproveCheque(obj) {
        const request = axios.post(
            `${process.env.REACT_APP_FILE}/accountRoutes/api/v1/fileCheque/chequePaymentReceived`, obj,{
                headers: { 'Authorization': 'bearer ' +localStorage.accountantToken}
              });
    
    
    return dispatch => {

    dispatch(FileapproveChequeLoading());
    request
    .then(response => {   
  
    if (response.status == 200 || response.status == 201) {
   /*   console.log(response,"response FileapproveCheque") */
    dispatch({
    type: FILE_CUSTOMER_APPROVE_CHEQUE,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        FileapproveChequeLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(FileapproveChequeLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(FileapproveChequeLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(FileapproveChequeLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


